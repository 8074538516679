import React, { Component } from "react";
import ReactModal from "react-modal";
import DinamicModal from "../dinamicModal/dinamicModal";
import Request from "../../core/httpClient";
import cogoToast from "cogo-toast";
import moment from "moment-timezone";

const request = new Request();

class BudgetPagosEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fecha: this.props.pago.fecha,
      forma_pago: this.props.pago.forma_pago,
      banco: this.props.pago.banco,
      num_movimiento: this.props.pago.num_movimiento,
      monto: this.props.pago.monto,
      formasPagos: ["Efectivo", "Transferencia"],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.pago !== prevProps.pago) {
      this.setState({
        fecha: this.props.pago.fecha,
        forma_pago: this.props.pago.forma_pago,
        banco: this.props.pago.banco,
        num_movimiento: this.props.pago.num_movimiento,
        monto: this.props.pago.monto,
      });
    }
  }

  render() {
    return (
      <div>
        <DinamicModal
          idModal={"editBudgetPago"}
          sizeModalContainer={"small"}
          title={"Editar pago"}
          success={this.handleSubmit.bind(this)}
          showBtnSuccess={this.props.escritura}
          btnTextSuccess={"ACTUALIZAR"}
          showBtnCancel={true}
          btnTextCancel={"CANCELAR"}
          loadingSave={this.state.loadingSave}
          message={this.state.message}
        >
          {this.renderEditBudget()}
        </DinamicModal>
      </div>
    );
  }

  renderEditBudget() {
    return (
      <div className="full row align-center" style={{ padding: "20px" }}>
        <div className="column full">
          <div className="white-space-16" />
          <div className="input-form-content row full align-center">
            <div className="column label-duo-big">
              <p>
                <b>Fecha:</b>
              </p>
            </div>
            <div className="column full">
              <input
                type="date"
                name="fecha"
                className="input input-modals"
                value={moment(this.state.fecha).format("YYYY-MM-DD")}
                onChange={(event) =>
                  this.setState({ fecha: event.target.value })
                }
                placeholder="Fecha"
                required
              />
            </div>
            <div className="column label-duo-normal align-end">
              <div className="column label-duo-big">
                <p>
                  <b>F.Pago:</b>
                </p>
              </div>
            </div>
            <div className="column full">
              <div className="column full">
                <select
                  className={"input input-modals"}
                  name="forma_pago"
                  value={this.state.formasPagos.findIndex(
                    (fpago) => fpago === this.state.forma_pago
                  )}
                  onChange={(event) => {
                    this.setState({
                      forma_pago: this.state.formasPagos[event.target.value],
                    });
                  }}
                >
                  {this.state.formasPagos.map((fpago, key) => (
                    <option value={key} key={key}>
                      {fpago}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="white-space-16" />
          <div className="input-form-content row full align-center">
            {" "}
            <div className="column label-duo-big">
              <p>
                <b>Banco:</b>
              </p>
            </div>
            <div className="column full">
              <input
                type="text"
                name="banco"
                id="banco"
                className="input input-modals"
                value={this.state.banco}
                autoComplete="off"
                maxLength="50"
                required
                onChange={(event) => {
                  this.setState({ banco: event.target.value });
                }}
              />
            </div>
            <div className="column label-duo-normal align-end">
              <div className="column label-duo-big">
                <p>
                  <b>N° Mov:</b>
                </p>
              </div>
            </div>
            <div className="column full">
              <div className="column full">
                <input
                  type="text"
                  name="num_movimiento"
                  id="num_movimiento"
                  className="input input-modals"
                  value={this.state.num_movimiento}
                  autoComplete="off"
                  maxLength="50"
                  onChange={(event) => {
                    this.setState({ num_movimiento: event.target.value });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="white-space-16" />
          <div className="input-form-content row full align-center">
            {" "}
            <div className="column label-duo-big">
              <p>
                <b>Monto:</b>
              </p>
            </div>
            <div className="column full">
              <input
                type="number"
                name="monto"
                className="input input-modals"
                value={this.state.monto}
                onChange={(event) =>
                  this.setState({ monto: event.target.value })
                }
                autoComplete="off"
                required
                minLength="2"
                maxLength="4"
              />
            </div>
            <div className="column label-duo-normal align-end">
              <div className="column label-duo-big">
                <p></p>
              </div>
            </div>
            <div className="column full">
              <div className="column full"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  async handleSubmit(event) {
    event.preventDefault();

    let data = {
      id_pago: this.props.pago.id_pago,
      fecha: this.state.fecha,
      forma_pago: this.state.forma_pago,
      banco: this.state.banco,
      num_movimiento: this.state.num_movimiento,
      monto: this.state.monto,
    };
    const response = await request.post("/facturas/pago/update", data);
    if (response.updated && !response.empty) {
      this.props.reload();
      cogoToast.success("Pago actualizado.", {
        position: "bottom-right",
      });
    } else {
      this.setState({
        empty: true,
        //message: "NO SE PUDO ACTUALIZAR CONCEPTO"
      });
      cogoToast.error("No se pudo actualizar el pago.", {
        position: "bottom-right",
      });
    }
  }
}

export default BudgetPagosEdit;