import React from 'react';
import Core from './../../core/SuperCore';

class Header extends Core{
    constructor(props){
        super(props);
        this.state ={
            id:     this.props.id,
            title:  this.props.title
        }
    }

    componentDidUpdate(prevProps, prevState){

        if(prevProps.id != this.props.id){
            this.setState({id: this.props.id});
        }
        
        if(prevProps.title != this.props.title){
            this.setState({title: this.props.title});
        }
    }

    render(){

        let title = this.state.title ? this.state.title.trim().toUpperCase():'Simple Modal';

        return(
        <React.Fragment>
            <p>{title}</p>
            <button>
                <i  className   = "fas fa-times" 
                    onClick     =   {(event)=>{this.superCloseModal(this.props.id)}}
                ></i>
            </button>
        </React.Fragment>
        );
    }
}

export default Header;
