import React, { useState, useEffect } from "react";
import Resizer from 'react-image-file-resizer';
import moment from 'moment-timezone';
import TimeField from 'react-simple-timefield';
import cogoToast from 'cogo-toast';
import Request from "../../../core/httpClient";
const request = new Request();

const Form = (props) => {
    const [Restaurante, setRestaurante] = useState({});
    const [imageLogo, setImageLogo] = useState('');
    //Arreglo de categorias
    const [categorias, setCategorias] = useState([]);
    const [idLoadingCat, setIdLoadingCat] = useState(0);
    //Arreglo de horarios
    const [horarios, setHorarios] = useState([]);
    const [idLoadingHor, setIdLoadingHor] = useState(0);
    //Elemento dentro del arreglo de categorias
    const [categoria, setCategoria] = useState({});
    //Elemento dentro del arreglo de horarios
    const [horario, setHorario] = useState({});

    useEffect(() => {
        if (props.restaurante) {
            const { restaurante } = props;
            //console.log("props");
            //console.log(restaurante);
            if (restaurante.imagen)
                if (restaurante.imagen.indexOf('data:image/') === 0) {
                    const data = {
                        img: restaurante.imagen,
                        id: restaurante.id_restaurante,
                        type: 1
                    }
                    base64ToUrl(data);
                } else {
                    setImageLogo(restaurante.imagen);
                }
            setRestaurante(restaurante);
            setCategoria({});
            if (restaurante.categorias) {
                const cats = JSON.parse(restaurante.categorias);
                if (Array.isArray(cats)) {
                    setCategorias(cats);
                }
            } else {
                setCategorias([]);
            }
            if (restaurante.horarios) {
                const hors = JSON.parse(restaurante.horarios);
                if (Array.isArray(hors)) {
                    setHorarios(hors);
                }
            } else {
                setHorarios([]);
            }
        } else {
            setRestaurante({
                nombre: '',
                activo: false, 
                propinas: '',
                observaciones: '',
                entrega: ''
            });
            setImageLogo('');
            setCategoria({});
        }
        // }
        /*if (props.getValues) {
            const cats = JSON.stringify(categorias);
            const hors = JSON.stringify(horarios);
            props.getValues({ ...Restaurante, imagen: imageLogo, categorias: cats, horarios: hors });
        }*/
    }, [props.restaurante]);

    useEffect(() => {
        const cats = JSON.stringify(categorias);
        const hors = JSON.stringify(horarios);
        props.getValues({ ...Restaurante, imagen: imageLogo, categorias: cats, horarios: hors });
    }, [Restaurante, imageLogo, categorias, horarios])

    async function base64ToUrl(info) {
        const data = {
            type: info.type,
            img: info.img,
            idfrac: localStorage.getItem('frac') || 0,
            id: info.id,
            module: "restaurantes"
        }

        const res = await request.post("/restaurantes/base64ToUrl", data);
        if (res) {
            //console.log(res);
            if (!res.error) {
                await setImageLogo(res.url);
            }
        }
    }

    function addPlatoCat() {
        //obtener el id dentro del arreglo de categorias
        let id = 1;
        let cats = categorias || [];
        if (Array.isArray(cats)) {
            cats.forEach(elemento => {
                if (elemento.id >= id)
                    id = elemento.id + 1
            });
            if (!categoria.categoria) {
                cogoToast.warn('Se debe indicar un nombre de categoría.', {
                    position: 'bottom-right',
                });
                //alert('Se debe indicar un nombre de categoría');
                return;
            }
            cats.push({ id, categoria: categoria.categoria, id_status: 1 })
            setCategorias(cats);
        }
        setCategoria({});
    }

    async function removePlatoCat(id) {
        setIdLoadingCat(id);
        let edit = true;
        if (props.validateCats) {
            edit = await props.checkCats(id);
        }
        if (edit) {
            const cats = categorias.map(cat => {
                if (cat.id === id) {
                    cat.id_status = 0;
                }
                return cat;
            });
            setCategorias(cats);
        }
        setIdLoadingCat(0);
    }

    function addPlatoHor() {
        //obtener el id dentro del arreglo de categorias
        let id = 1;
        let hors = horarios || [];
        if (Array.isArray(hors)) {
            hors.forEach(elemento => {
                if (elemento.id >= id)
                    id = elemento.id + 1
            });
            if (!horario.descripcion) {
                cogoToast.warn('Se debe indicar un nombre de horario.', {
                    position: 'bottom-right',
                });
                //alert('Se debe indicar un nombre de horario');
                return;
            }
            if (!horario.hasta) {
                cogoToast.warn('Se debe indicar una hora límite.', {
                    position: 'bottom-right',
                });
                //alert('Se debe indicar una hora límite.');
                return;
            }
            hors.push({ id, descripcion: horario.descripcion, hasta: moment(horario.hasta, 'HH:mm').format('YYYY-MM-DD HH:mm'), id_status: 1 });
            setHorarios(hors);
        }
        setHorario({});
    }

    async function removePlatoHor(id) {
        setIdLoadingHor(id)
        let edit = true;
        if (props.validateHors) {
            edit = await props.checkHors(id);
        }
        if (edit) {
            const hors = horarios.map(hor => {
                if (hor.id === id) {
                    hor.id_status = 0;
                }
                return hor;
            });
            setHorarios(hors);
        }
        setIdLoadingHor(0)
    }

    async function changeImg(event) {
        let file = event.target;
        if (file) {
            if (file.files[0]) {
                Resizer.imageFileResizer(
                    file.files[0],
                    400,//337
                    200,//180
                    'JPEG',
                    30,
                    0,
                    async uri => {
                        //console.log(uri)
                        await setImageLogo(uri);
                    },
                    'base64'
                );
            }
        }
    }

    let categoryList = categorias;
    categoryList = categoryList.filter(cat => cat.id_status > 0);

    let horariosList = horarios;
    horariosList = horariosList.filter(hor => hor.id_status > 0);

    return (
        <div className="full column align-center">
            <div className="row full justify-between">
                <div className="column full">
                    <div className="input-form-content row full align-center">
                        <div className="row full align-center">
                            <div className="column auto">
                                <p>
                                    <b>Nombre:</b>
                                </p>
                            </div>
                            <div className="column full space-16">
                                <input
                                    type="text"
                                    name="Nombre"
                                    id="nombre"
                                    value={Restaurante.nombre || ''}
                                    onChange={(event) => setRestaurante({ ...Restaurante, nombre: event.target.value })}
                                    required
                                    className="input input-modals"
                                />
                            </div>
                        </div>
                        <div className="row auto align-center">
                            <div className="column label-duo-normal space">
                                <p>
                                    <b>Activo:</b>
                                </p>
                            </div>
                            <div className="column full space-16">
                                <label className="container-check" style={{paddingLeft: 0}}>
                                    <input
                                        type="checkbox"
                                        name="active"
                                        checked={Restaurante.activo || false}
                                        onChange={(event) => setRestaurante({ ...Restaurante, activo: event.target.checked })}
                                    />
                                    <span className="checkmark" ></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="white-space-16"></div>
                    <div className="input-form-content row full align-start">
                        <div className="row full">
                            <div className="column width-30">
                                <p>
                                    <b>% Propinas:</b>
                                </p>
                            </div>
                            <div className="column full">
                                <input
                                    type="number"
                                    name="Propina"
                                    value={Restaurante.propinas || ''}
                                    onChange={(event) => setRestaurante({ ...Restaurante, propinas: event.target.value })}
                                    className="input input-modals"
                                />
                            </div>
                        </div>
                        <div className="row full space-16">
                            <div className="column width-30">
                                <p>
                                    <b>% Entrega:</b>
                                </p>
                            </div>
                            <div className="column full">
                                <input
                                    type="number"
                                    name="Entrega"
                                    value={Restaurante.entrega || ''}
                                    onChange={(event) => setRestaurante({ ...Restaurante, entrega: event.target.value })}
                                    className="input input-modals"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="white-space-8"></div>
                    <div className="row full">
                        <div className="row full">
                            <div className="input-form-content column full align-start">
                                <div className="row ">
                                    <p>
                                        <b>Descripción:</b>
                                    </p>
                                </div>
                                <div className="row full">
                                    <textarea
                                        type="text"
                                        name="Propina"
                                        value={Restaurante.observaciones || ''}
                                        onChange={(event) => setRestaurante({ ...Restaurante, observaciones: event.target.value })}
                                        className="textarea input input-modals text-big"
                                        rows="2"
                                        maxLength="250"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="responsive-img column space align-start">
                            <div className="img-restaurant space" style={{width: '100%', paddingLeft: '16px'}}>
                                <div className="container-img justify-center">
                                    <img src={imageLogo ? imageLogo : require('../../../img/empty_image.png')} className="img-duo-preview" id="previewArea" />
                                    <div className="middle-overlay">
                                        <label className="btn-upload-image column align-center justify-center">
                                            <i className="fas fa-file-upload"></i>
                                            <input type="file" id="imagenLogo" name="AreaImage" accept="image/*" onChange={changeImg.bind(this)} />
                                            <div className="white-space-8"></div>
                                            <p className="color-white">Subir imagen del restaurante</p>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="white-space-8"></div>
                    <div className="column">
                        <div>
                            <h4>Platos</h4>
                        </div>
                        <div className="input-form-content row full align-start">
                            <div className="column full">
                                <div className="row align-center">
                                    <div className="column auto">
                                        <p>
                                            <b>Categoría:</b>
                                        </p>
                                    </div>
                                    <div className="column full space-8">
                                        <div className="row full input-add">
                                            <input
                                                className="input input-modals"
                                                name="plato_cat"
                                                value={categoria.categoria || ''}
                                                onChange={(event) => setCategoria({ ...categoria, categoria: event.target.value })}
                                            />
                                            <button className="space btn-add" type="button" onClick={addPlatoCat.bind(this)}>
                                                <i className="fas fa-plus color-green"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="white-space-8"></div>
                                <div className="input-form-content column full align-center" style={{ maxHeight: '150px', overflow: 'auto' }}>
                                    {categoryList.map(cat =>
                                        <div className="column justify-center" key={cat.id}>
                                            <div className="column input-add">
                                                <input
                                                    type="text"
                                                    className="input input-modals"
                                                    name="categoria_nombre"
                                                    value={cat.categoria || ''}
                                                    onChange={(event) => {
                                                        categoryList = categoryList.map(categoria => {
                                                            if (categoria.id == cat.id) {
                                                                categoria.categoria = event.target.value
                                                            }
                                                            return categoria
                                                        });
                                                        setCategorias(categoryList);
                                                    }}
                                                />
                                                {idLoadingCat == cat.id ?
                                                    <i className="fas fa-spinner fa-spin color-red btn-add"></i>
                                                    :
                                                    <button className="space btn-add" type="button" onClick={removePlatoCat.bind(this, cat.id)}>
                                                        <i className="fas fa-minus color-red"></i>
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="column full space-24">
                                <div className="input-form-content row full align-center full">
                                    <div className="row align-center">
                                        <div className="column auto">
                                            <p>
                                                <b>Horario:</b>
                                            </p>
                                        </div>
                                        <div className="column space-8">
                                            <div className="row full input-add">
                                                <input
                                                    type="text"
                                                    className="input input-modals"
                                                    name="horario_descripcion"
                                                    value={horario.descripcion || ''}
                                                    onChange={(event) => setHorario({ ...horario, descripcion: event.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="white-space-8"></div>
                                    <div className="row align-center space-16">
                                        <div className="column width-70">
                                            <p>
                                                <b>Max (24h):</b>
                                            </p>
                                        </div>
                                        <div className="column full space-8">
                                            <div className="row full input-add">
                                                <TimeField
                                                    name="horario_hasta"
                                                    value={horario.hasta || ''}
                                                    onChange={(event) => setHorario({ ...horario, hasta: event.target.value })}
                                                    input={<input className="input input-modals"></input>}
                                                />
                                                <button className="space btn-add" type="button" onClick={addPlatoHor.bind(this)}>
                                                    <i className="fas fa-plus color-green"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="white-space-8"></div>
                                <div className="input-form-content full column align-center" style={{ maxHeight: '150px', overflow: 'auto' }}>
                                    {horariosList.map(hor =>
                                        <div className="row full" key={hor.id}>
                                            <div className="column justify-center">
                                                <div className="row input-add">
                                                    <input
                                                        type="text"
                                                        className="input input-modals"
                                                        name="horario_descripcion"
                                                        value={hor.descripcion || ''}
                                                        onChange={(event) => {
                                                            horariosList = horariosList.map(horario => {
                                                                if (horario.id == hor.id) {
                                                                    horario.descripcion = event.target.value
                                                                }
                                                                return horario
                                                            });
                                                            setHorarios(horariosList);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="column justify-center">
                                                <div className="row input-add space-16">
                                                    <TimeField
                                                        name="horario_hasta"
                                                        value={hor.change ? hor.hasta : moment(hor.hasta).format('HH:mm')}
                                                        onChange={(event) => {
                                                            horariosList = horariosList.map(horario => {
                                                                if (horario.id == hor.id) {
                                                                    horario.hasta = event.target.value;
                                                                    horario.change = true;
                                                                }
                                                                return horario
                                                            });
                                                            setHorarios(horariosList);
                                                        }}
                                                        input={<input className="input input-modals"></input>}
                                                    />
                                                    {idLoadingHor == hor.id ?
                                                        <i className="fas fa-spinner fa-spin color-red btn-add"></i>
                                                        :
                                                        <button className="space btn-add" type="button" onClick={removePlatoHor.bind(this, hor.id)}>
                                                            <i className="fas fa-minus color-red"></i>
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Form;
