/**
 *  users.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Manejador de ejecuciones del Context para Usuarios
*/

import moment from 'moment-timezone';
import Request from '../core/httpClient';

const req = new Request();

let id_fraccionamiento = 0;

//API PUSH NOTIFICATIONS
//Produccion
let PUSH_API = "88653a19-ad70-4258-8f42-5180848f1183";
//Test
//let PUSH_API = "bccb4af4-79ed-49b6-bdea-6531761ffa3c";
if (process.env.NODE_ENV !== 'production') {
    PUSH_API = "1e5dab3c-a195-4045-b9bc-61e42f927789";
};

export async function loadUser() {

    let user = window.localStorage.getItem("iGateAdmin");

    if (user) {

        user = JSON.parse(user);

        let fecha_login = user.fecha;
        let fecha_actual = moment();

        if (user.fecha) {

            let duration = fecha_actual.diff(fecha_login, 'hours');

            if (duration < 12) {

                const res = await req.post('/users/tokuser', { id: user.id });

                if (res.data) {

                    user.id = JSON.parse(res.data);

                    if (id_fraccionamiento !== parseInt(localStorage.getItem('frac'))) {
                        id_fraccionamiento = parseInt(localStorage.getItem('frac'));
                    };

                } else {
                    user.id = { id: 0, tipo: 0 }
                };

            } else {
                user.id = { id: 0, tipo: 0 }
                user.auth = false;
            };

        } else {
            user.id = { id: 0, tipo: 0 }
            user.auth = false;
        };

        this.setState({ user: user });

        return user;
    };
};

export function login(data) {
    window.localStorage.setItem("iGateAdmin", JSON.stringify(data));
    this.setState({ user: data });
};

export function logout() {

    window.localStorage.setItem("iGateAdmin", '{"auth": false}');
    window.localStorage.removeItem("iGateAdmin");
    window.localStorage.removeItem("fracName");
    window.localStorage.removeItem('frac');
    window.localStorage.removeItem('menusInfo');
    window.localStorage.removeItem('pedIdRes');
    window.localStorage.removeItem('plaIdRes');
    window.localStorage.removeItem('plaIdCat');
    window.localStorage.removeItem('subfrac');
    window.localStorage.removeItem('submenu_loc');
    window.localStorage.removeItem('submenu');
    window.localStorage.removeItem('submenu_name');

    this.setState({ user: { auth: false } });
};