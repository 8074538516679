import React, {Component} from "react";
import {Link} from "react-router-dom";
import Request from "../../core/httpClient";
import SuperCore from "./../superModal/SuperModalCore";
import NumberFormat from "react-number-format";
import ModalBorrar from "../../components/modals/modalDelete";
import cogoToast from "cogo-toast";
import Draggable from "react-draggable";

const request = new Request();

class DocsTable extends SuperCore {
	constructor(props) {
		super(props);
		this.state = {
			docs: [],
			Id_DocCobro: 0,
		};
	}

	async componentDidUpdate(prevProps, prevState) {
		if (prevProps.docs != this.props.docs) {
			await this.setState({rules: this.props.docs});
		}
	}

	async getUserInfo() {
		let iGateAdmin = JSON.parse(localStorage.getItem("iGateAdmin"));
		const response = await request.post("/admin/administracion/get/decodetoken", {
			token: iGateAdmin.id,
		});
		return response;
	}

	async savePDF(doc, key) {
		this.setState({downloadPdf: true, key: key});
		const response = await request.get("/documentos/get/pdf", {
			Id_Fraccionamiento: parseInt(doc.Id_Fraccionamiento),
			Id_DocCobro: parseInt(doc.Id_DocCobro),
		});
		if (!response.error) {
			const linkSource = `data:application/pdf;base64,${response.content}`;
			const downloadLink = document.createElement("a");
			const fileName = `${doc.Factura}.pdf`;
			downloadLink.href = linkSource;
			downloadLink.download = fileName;
			downloadLink.click();

			this.setState({downloadPdf: false, key: -1});
		} else {
			cogoToast.error(response.message, {
				position: "bottom-right",
				hideAfter: 5,
			});
			this.setState({downloadPdf: false, key: -1});
		}
	}

	async previewPDF(doc, key) {
		this.setState({previewPdf: true, key});

		const response = await request.post("/documentos/get/preview/cobro", {
			Id_Fraccionamiento: parseInt(doc.Id_Fraccionamiento),
			Id_DocCobro: parseInt(doc.Id_DocCobro),
			preview: true,
		});

		if (response.pdf) {
			const linkSource = `data:application/pdf;base64,${response.pdf}`;
			const iframe = document.getElementById("pdfDocumentoPago");
			if (iframe) {
				iframe.src = linkSource;
				let modal = document.getElementById("preview-pdf");
				if (modal) {
					modal.classList.remove("hide-dinamic-modal");
					modal.classList.add("show-dinamic-modal");
				}
			}
		} else {
			cogoToast.error(response.message || "No se pudo obtener documento.", {position: "bottom-right"});
		}

		this.setState({previewPdf: false});
	}

	async openModalDelete(doc) {
		await this.setState({Id_DocCobro: doc.Id_DocCobro});
		let modal = document.getElementById("deleteModal-docCobro");
		if (modal) {
			modal.classList.remove("hide-modal");
			modal.classList.add("show-modal");
		}
	}

	async closeModalDelete() {
		let modal = document.getElementById("deleteModal-docCobro");
		if (modal) {
			modal.classList.add("hide-modal");
			modal.classList.remove("show-modal");
		}
	}

	async handleDelete(id) {
		let user = await this.getUserInfo();

		const data = {
			Id_Fraccionamiento: localStorage.getItem("frac"),
			Id_DocCobro: parseInt(id),
			userId: user.info.id_usuario,
		};

		const response = await request.post("/documentos/eliminar/documento", data);

		if (response.deleted) {
			cogoToast.success("Documento eliminado.", {
				position: "bottom-right",
			});

			this.props.reload();
			this.closeModalDelete();
		} else {
			cogoToast.error(response.message || "No se pudo eliminar el documento.", {
				position: "bottom-right",
			});
		}
	}

	render() {
		let docs = this.props.docs;
		const filteredData = this.props.status === "" ? docs.filter((dc)=> dc.Fecha_Creacion.startsWith(this.props.year)) 
		: docs.filter((dc) => dc.EstadoCobro === this.props.status && dc.Fecha_Creacion.startsWith(this.props.year));

		return (
			<div className="card-table card-owners column full" id="card-owners">
				<div className="row">
					<table className="full">
						<tbody>
							<tr className="pad-bot row-without-border">
								<th colSpan="7" className="text-left">
									<h4 className="weight-semi">Detalle</h4>
								</th>
							</tr>
							<tr className="text-left">
								<th className="text-start ">N° Folio</th>
								<th className="text-start ">Concepto</th>
								<th className="text-center">Monto</th>
								<th className="text-center">Estado cobro</th>
								<th className="text-center">Ver</th>
								<th className="text-center">Factura</th>
								<th className="text-center th-options">Eliminar</th>
							</tr>
							{filteredData.length == 0 ? (
								<tr>
									<td className="text-center" colSpan="8">
										Sin datos que mostrar.
									</td>
								</tr>
							) : (
								filteredData
								.map((doc, key) => (
									<React.Fragment key={key}>
										<tr>
											<td className="text-start">{doc.NumFolio}</td>
											<td className="text-start">{doc.Concepto}</td>
											<td className="text-right">
												<NumberFormat value={doc.Monto} displayType={"text"} thousandSeparator={true} prefix={"$"} decimalScale={2} fixedDecimalScale={true} />
											</td>
											<td className="text-center ">{doc.EstadoCobro}</td>
											<td>
												<button
													className="btn-full btn-mini btn-secondary color-white"
													type="button "
													onClick={() => {
														this.previewPDF(doc, key);
													}}
												>
													{this.state.previewPdf && this.state.key == key ? <i className="fas fa-spinner fa-spin"></i> : <i className="fas fa-eye"></i>}
												</button>
											</td>
											<td className="text-center ">
												{doc.Factura}
												&nbsp; &nbsp;
												{doc.Factura !== "" && (
													<Link
														to="#"
														className="font-small"
														onClick={() => {
															if (!this.state.downloadPdf) {
																this.savePDF(doc, key);
															}
														}}
													>
														{this.state.downloadPdf && this.state.key == key ? <i className="fas fa-spinner fa-spin"></i> : <i className="fas fa-eye"></i>}
													</Link>
												)}
											</td>
											<td className="text-center">
												<button
													className="btn-full justify-center btn-mini btn-secondary color-white"
													type="button"
													onClick={this.openModalDelete.bind(this, doc)}
													style={{padding: "5px"}}
													disabled={!this.props.escritura}
												>
													<i className="font-text fas fa-trash-alt " />
												</button>
											</td>
										</tr>
									</React.Fragment>
								))
							)}
						</tbody>
					</table>
				</div>
				<ModalBorrar name="deleteModal-docCobro" id={this.state.Id_DocCobro} delete={this.handleDelete.bind(this)} />
				<div className="overlay hide-dinamic-modal" id="preview-pdf">
					<Draggable handle=".dinamic-header">
						<div className="column dinamic-modal-container dinamic-modal-big" style={{height: "90%"}}>
							<div className="dinamic-header row justify-center">
								<button
									className="btn-modal-close justify-center align-center"
									onClick={() => {
										let modal = document.getElementById("preview-pdf");
										if (modal) {
											modal.classList.add("hide-dinamic-modal");
											modal.classList.remove("show-dinamic-modal");
										}
									}}
								>
									<i className="fas fa-times"></i>
								</button>
							</div>
							<div className="dinamic-body" style={{display: "contents", height: "100%", width: "100%"}}>
								<div className="column align-center justify-center" style={{height: "100%", width: "100%"}}>
									<iframe src="" frameBorder="0" height="100%" width="100%" id="pdfDocumentoPago"></iframe>
								</div>
							</div>
						</div>
					</Draggable>
				</div>
			</div>
		);
	}
}

export default DocsTable;
