import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from "../../components/header/header";
import SidebarOperacion from "../../components/header/sidebarOperacion";
import Controls from '../../components/controls/controls';
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import Paginador from "../../components/paginador/paginador";
import Request from "../../core/httpClient";
import Permissions from '../../middlewares/Permissions';
import ConfiguracionTable from '../../components/restaurantes/configuracion/table';
import NewForm from '../../components/restaurantes/configuracion/form';
import cogoToast from 'cogo-toast';
import sortableData from '../../helpers/sortableDataTable';

const request = new Request();

let values = {};

class Configuracion extends Component {
	constructor(props){
		super(props);

		this.state = {
			showModal: 				false,
			commonAreas: 			[],
			categories: 			[],
			categoria: 				0,
			filterData: 			[],
			keys: 					[],
			page: 					1,
			filter: 				'',
			subfraccionamiento: 	localStorage.getItem('subfrac') || 0,
            fraccionamiento: 		localStorage.getItem('frac') || 0,
			escritura:				false,
			lectura:				false,
			logo:					require ("../../img/logo-AppMosphera-r.png"),
            menu:					null,
            isModalOpen:            false,
			direction: {
				nombre: 'desc'
			},
			filterColumn: {
				col: '',
				filt: ''
			}
		}

		this.validarPermisos();
	}

    componentDidMount() {
        this.getRestaurantes();
	}

	async validarPermisos(){
		let permisos = new Permissions();
		//  permisos.getResult();
		let permisosInfo = await permisos.getResult()
		await this.setState({escritura: permisosInfo.escritura, lectura: permisosInfo.lectura});
	}

	componentDidUpdate(prevProps, prevState) {
		
	}

	async openModal(idModal) {
		await this.setState({ message: null, imageArea: ''});
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
        }
        this.setState({isModalOpen: true });
        const input = document.getElementById('name');
        if (input) {
            input.focus();
        }
    }

	closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
	}

	/*funcion del paginador*/
	setPage(page) {
		this.setState({ page });
	}

	reload() {
		this.getRestaurantes()
	}

	changeFrac() {
		this.getRestaurantes()
	}

	subChange(sub) {
		this.setState({ subfraccionamiento: sub });
	}

	setFilter(filter) {
		this.setState({ filter: filter.toLowerCase() });
	}

	filter(Restaurantes) {
		Restaurantes = Restaurantes.filter(res => {
			if (res.nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		});
		return Restaurantes;
	}

    async getRestaurantes() {
        this.setState({loading: true});
        const data = {
            idFraccionamiento: this.state.fraccionamiento
        }
        const res = await request.post('/restaurantes/get', data);
        if (Array.isArray(res.restaurantes)) {
            this.setState({restaurantes: res.restaurantes});
        } else {
            this.setState({empty: true, restaurantes: [], message: res.message || 'No se pudo obtener información.'});
        }
        this.setState({loading: false});
    }

	sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, this.state.restaurantes, this.state.direction[key]);

		this.setState({
			restaurantes: items,
			direction: {
				[key]: direction
			},
			filterColumn: {
				col: key,
				filt: direction
			}
		});
	}

	render() {
		let restaurantes = [];

		if (Array.isArray(this.state.restaurantes)) {
			restaurantes = this.state.restaurantes;
			restaurantes = this.filter(restaurantes);
		}
		let menu =  this.state.menu;
		
		return (
			<div className="admin column">
				<Helmet>
					<title>{process.env.REACT_APP_NAME} - Configuración Restaurantes</title>
				</Helmet>
				<Header 
					reload	=	{this.reload.bind(this)} 
					change	=	{this.changeFrac.bind(this)} 
					sidebar	=	{true} 
					active	=	{'activas'} 
					parent	=	{'areas'} 
					panel	=	{'panel3'} 
					nav		=	{'operacion'} 

					menu = {this.state.menu != null ? this.state.menu : null}
					/>
					
				<div className="row">
					<div className="column" id="sidebar">
						<SidebarOperacion />
					</div>
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								{this.state.lectura?
								<React.Fragment>
									<Controls title="Configuración restaurantes"
										nuevo		=	{this.openModal.bind(this, "newRestaurante")}
										dataSet		=	{restaurantes}
										setFilter	=	{this.setFilter.bind(this)}
										subChange	=	{this.subChange.bind(this)} 
                                        newButton	=	{this.state.escritura}
                                        selectSub	=	{false}
									/>
									{this.state.loading ?
										<div className="row justify-center">
											<i className="fas fa-spinner fa-spin"></i>
										</div>
										:
										<ConfiguracionTable
											restaurantes    =   {this.state.restaurantes}
											page            =   {this.state.page}
											escritura       =   {this.state.escritura}
											reload          =   {this.reload.bind(this)}
											sortBy			=	{this.sortableTable.bind(this)}
											filterColumn	=	{this.state.filterColumn}
										/>
									}
									<div className="white-space-16"></div>
									<Paginador pages={Math.ceil(restaurantes.length / 20)} setPage={this.setPage.bind(this)} />
								
								</React.Fragment>:
								null
								}
							
							</div>
						</div>
					</div>
				</div>

				<DinamicModal
					idModal				=	{'newRestaurante'}
					sizeModalContainer	=	{'big'}
					title				=	{'Añadir restaurante'}
					success				=	{this.handleSubmit.bind(this)}
					showBtnSuccess		=	{this.state.escritura}
					btnTextSuccess		=	{"GUARDAR"}
					showBtnCancel		=	{true}
					btnTextCancel		=	{"CANCELAR"}
					loadingSave			=	{this.state.loadingSave}
                    message				=	{this.state.message}
                    closeModal          =   {() => this.setState({isModalOpen: false})}
				>
					<NewForm 
                        isModalOpen = {this.state.isModalOpen}
                        getValues   = {this.getValues.bind(this)}
					    // escritura   = {this.state.escritura}			
					/>
				</DinamicModal>
			</div>
		);
    }

    getValues(data) {
        values = data;
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({loadingSave: true, message: null});
        const res = await request.post('/restaurantes/create', {...values, idFraccionamiento: this.state.fraccionamiento});
        if (res.created) {
            this.reload();
			this.closeModal('newRestaurante');
			cogoToast.success('Restaurante agregado.', {
				position: 'bottom-right',
			});
        } else {
			//this.setState({message: res.message || 'No se pudo agregar el restaurante.'});
			cogoToast.error('No se pudo agregar restaurante.', {
				position: 'bottom-right',
			});
        }
        this.setState({loadingSave: false});
    }
}

export default Configuracion;