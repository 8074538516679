// Third-parties
import moment from 'moment-timezone';

export default function exportExcel(dataSet, title, fraccionamiento, usuario, dateFormat, resTable = false, 
    generateExcel, exportFormat, excelConfig) {

    if(!dataSet) return { error: "Sin datos para exportar" };

    if(generateExcel === undefined || exportFormat === undefined) return { error: "Sin metodos para exportar o generar excel" };

    if (!Array.isArray(dataSet)) return { error: "Datos en formato incorrecto" };

    if (dataSet.length <= 0) return { error: "Sin datos para exportar" };

    if (!dataSet[0]) return { error: "No se han establecido las configuraciones para reporte." };

    const { excelColumnsShow, excelColumnsName } = excelConfig;

    if (!excelColumnsShow && !excelColumnsName) 
        return { error: "No se han establecido las configuraciones para reporte." };

    try {
        const data = exportFormat(dataSet, title, fraccionamiento, usuario, dateFormat, resTable, excelConfig)
        const excelData = generateExcel(data);

        if (!excelData || excelData.error) return { error: "No se pudo crear el documento de excel." };

        const bytes = new Uint8Array(excelData.buffer);
        const blob = new Blob([bytes]);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        const fileName = `${title}_${moment().format("YYMMDDHHmm")}.xlsx`;
        link.download = fileName;
        link.click();

        return { created: "Generando archivo." };

    } catch (error) {
        return { error }
    }
}