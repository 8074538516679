import React, { Component } from 'react';
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import ReactModal from "react-modal";

class BitacoraModal extends Component {
    constructor(props) {
        super(props);
        
    }
    render() {
    
        return (
            <div>
                <DinamicModal 
                    idModal					=	{'bitacoraModal'}
					sizeModalContainer		=	{'big'}
					title					=	{'Bitácora de seguridad'} 
					loadingSave				=	{false}
                >
                    {this.renderModal()}
                </DinamicModal>
            </div>

        );
	}
	getPrioridad(prioridad){
		switch(prioridad){
			case 1:
				return "Baja";
				break;
			case 2:
				return "Moderada";
				break;
			case 3:
				return "Alta";
				break;
			default:
				return "Indefinida";
				break;

		}
	}
    renderModal(){
        let bit = this.props.data
        return(
            <div className="full row ">
			<div className="column full">
				
				<div className="input-form-content row full align-center">
					<div className="column label-medium">
						<p><b>Reportado por:</b></p>
					</div>
					<div className="column full">
						<input 
								type		=	"text"  
								name		=	"guardia" 
								className	=	"input input-modals" 
								value		=	{bit.usuario} 
								disabled
						/>
					</div>
			    </div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
					<div className="column label-medium">
						<p><b>Prioridad:</b></p>
					</div>
					<div className="column full">
						<input 
								type="text"
								className	=	"input input-modals" 
								value		=	{this.getPrioridad(parseInt(bit.prioridad))} 
								disabled
						/>
					</div>
			    </div>
				<div className="white-space-16"></div>
				<div className="column label">
					<p><b> Descripción de la solución:</b></p>
				</div>
				<div className="white-space-8"></div>
				<div className="align-center row full justify-between">
					<div className="column full">
						<textarea name="solucion" id="" cols="30" rows="3" className='textarea input input-modals'
							value={bit.comentarios}
							disabled
                        >
                        </textarea>
					</div>

				</div>
			</div>
			<div className="column full">
				<div className="img img-view" style={{width: '100%', height: 'auto', paddingLeft: '8px'}}>
					{bit.imagen != null ? 
					<img src={bit.imagen} onError={require('../../img/empty_image.png')} alt="Imagen Bitacora" 
					className="img-duo-preview"/>: 
					<img src={require('../../img/empty_image.png')} alt="Imagen Bitacora" className="img-duo-preview"/>
					
					}
					{/* <img src={bit.imagen.includes('data') ? bit.imagen : require('../../img/empty_image.png')} alt="Imagen Bitacora" className="img-duo-preview"/> */}
				</div>
			</div>
		</div>
        );
    }

    handleOpen() {
        this.setState({ showModal: !this.state.showModal })

    }
}


export default BitacoraModal;