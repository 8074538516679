import React, {Component} from 'react';
import DatePicker from "react-datepicker";
import cogoToast from "cogo-toast";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es)

class General extends Component{
    constructor(props){
        super(props);
        this.state = { id_sub_init: 0 }
    }

    handleChange = date => {
       
        this.updateData({ monthStart: date });
    };

    updateNotificar() {
        
        if(this.props.notificar) {
            this.updateData({ notificar: false, notificacion_pago_dias: 0 });
        }else{
            this.updateData({ notificar: true });
        }
    }

    updateData(data){
        this.props.updateData(data);
    }

    render(){

        const { 
            notificacion_pago_dias, redondear, concepts: conceptsList,
            FrecList: frecList, id_concept, monthStart, monthsNumber,
            id_frecuency, notificar
        } = this.props;

        return(
            <div className="container">
                <div className="row align-center">
                    <div className="selection column full">
                        <div className="white-space-24"></div>
                        <h4 className="color-purple">Defina los parámetros de facturación</h4>
                        <div className="white-space-16"></div>
                        <div className="input-form-content row full align-center">
                            <div className="column full">
                                <p>
                                    <b>Mes inicio:</b>
                                </p>
                            </div>
                            <div className="column full">
                                <DatePicker
                                    className="input input-modals"
                                    selected={monthStart}
                                    onChange={this.handleChange}
                                    dateFormat="MMM-yy"
                                    locale="es"
                                    showMonthYearPicker
                                />
                            </div>
                            <div className="column full space">
                                <p>
                                    <b>N&#176; Cobros:</b>
                                </p>
                            </div>
                            <div className="column full">
                                <input
                                    type="number"
                                    name="numMonths"
                                    className="input input-modals"
                                    value={monthsNumber}
                                    onChange={(event) => { this.updateData({ monthsNumber: event.target.value }) }}
                                    min={1}
                                    step={1}
                                />
                            </div>
                        </div>
                        <div className="white-space-16"></div>
                        <div className="input-form-content row full align-center">
                            <div className="column label-extra">
                                <p>
                                    <b>Frecuencia:</b>
                                </p>
                            </div>

                            <div className="column full">
                                <select
                                    className="input input-modals"
                                    name="conceptos"
                                    value={id_frecuency}
                                    onChange={(event) => { this.updateData({ id_frecuency: event.target.value }) }}
                                    required
                                    disabled={this.state.id_sub_init == 0 ? false : true}
                                >
                                    <option value={0}>Seleccione una frecuencia</option>
                                    {
                                        frecList.map((frec, key) => (
                                            <option value={frec.Id_ReglaFrecuencia} key={key}>{frec.Nombre}</option>))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="white-space-16"></div>
                        <div className="input-form-content row full align-center">
                            <div className="column label-extra">
                                <p>
                                    <b>Concepto:</b>
                                </p>
                            </div>
                            <div className="column full">
                                <select
                                    className="input input-modals"
                                    name="conceptos"
                                    value={id_concept}
                                    onChange={(event) => { this.updateData({ id_concept: event.target.value }) }}
                                    required
                                    disabled={this.state.id_sub_init == 0 ? false : true}
                                >
                                    <option value={0}>Seleccione una concepto</option>
                                    {
                                        conceptsList.map((concept, key) => (
                                            <option value={concept.Id_Producto} key={key}>{concept.Nombre}</option>))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="white-space-24"></div>
                        <div className="input-form-content row   align-center">
                            <div className="column auto">
                                <label className="container-check container-check-billing">
                                    <input
                                        type="checkbox"
                                        name="notif"
                                        checked={notificar}
                                        onChange={(event) => this.updateNotificar()}
                                    />
                                    <span className="checkmark"></span>
                                    <p><b>Enviar notificación de pago al residente a los:</b></p>
                                </label>
                            </div>
                            <div className="column num">
                                <input
                                    type="number"
                                    name="numDays"
                                    className="input input-modals  text-center"
                                    min={0}
                                    placeholder={'Número de días'}
                                    value={notificacion_pago_dias}
                                    disabled={!notificar}
                                    onChange={(event) => {
                                        this.updateData({ notificacion_pago_dias: event.target.value })
                                    }}
                                />
                            </div>
                            <div className="column align-left">
                                <p><b>días</b></p>
                            </div>
                        </div>
                        <div className="white-space-24"></div>
                        <div className="input-form-content row  full align-center">
                            <div className="column full">
                                <label className="container-check container-check-billing">
                                    <input
                                        type="checkbox"
                                        name=""
                                        id=""
                                        checked={redondear}
                                        onChange={(event) => {
                                            this.updateData({ redondear: !redondear })
                                        }}
                                    />
                                    <span className="checkmark"></span>
                                    <p>
                                        <b>Redondear cuotas calculadas a 0 decimales</b>
                                    </p>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="info column space-48">
                        <div className="card-info">
                            <div className="box-header justify-center">
                                <p className="color-white">Información</p>
                            </div>
                            <div className="box-body-small">
                                <p><b>MES INICIO: </b> Es cuando se empezará a facturar.</p>
                                <div className="white-space-8"></div>
                                <p><b>N&#176; COBROS: </b> Es el n&#176; de veces que se repetirá el cobro.</p>
                                <div className="white-space-8"></div>
                                <p><b>FRECUENCIA: </b> Cada cuando se generarán los cobros.</p>
                                <div className="white-space-8"></div>
                                <p><b>CONCEPTO: </b> Es el servicio a cobrar o facturar.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default General;

export const generalOk = (data) => {

    let generalOK = true;

    const { id_concept, monthStart, monthsNumber, id_frecuency } = data;

    const cogoAlert = (message) => {
		cogoToast.warn(message, {
			position: "bottom-right",
			heading: "General",
			hideAfter: 5,
		});
	}

    if (parseInt(id_concept) === 0 || id_concept === null) {
        cogoAlert("Debes elegir un concepto.");
        generalOK = false;
    };

    if (monthStart === null) {
        cogoAlert("Debes elegir una fecha de inicio.");
        generalOK = false;
    };

    if (parseInt(monthsNumber) < 1) {
        cogoAlert("El número de meses debe ser mayor o igual a 1.");
        generalOK = false;
    };

    if (parseInt(id_frecuency) === 0 || id_frecuency == null) {
        cogoAlert("Debes elegir una frecuencia.");
        generalOK = false;
    };

    return generalOK;
};