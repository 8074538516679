/**
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Tablas de Viviendas
 */

import React, { Component } from "react";
import { Consumer } from "../../context";
import Request from "../../core/httpClient";
import moment from "moment";
import DinamicModal from "../../components/dinamicModal/dinamicModal";
const request = new Request();

class ReceiverTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			subfraccionamiento: this.props.sfrac_name,
			frac_name: this.props.frac_name,
			direccion: "",
			Nregistro: "",
			mts: "",
			pIndiviso: "",
			vacacional: "",
			// monto: 					'',
			comentarios: "",
			subfraccionamientos: this.props.subfraccionamientos,
			message: null,
			sub_id: this.props.sub_id,
			sub_fraccionamiento: 0,
			vivienda: [],

			CFDI: [],
			paises: [],
			states: [],
			wayToPay: [],
			paymentMethod: [],
			regimen: [],
			Coin: [],
			id_viviendaFrac: [],

			BillingData: {
				Id_Vivienda: 0,
				Id_SubFraccionamiento: 0,
				nombre_subf: null,
				Id_Fraccionamiento: 0,
				nombre_frac: null,
				RazonSocial: null,
				RFC: null,
				Domicilio: null,
				Municipio: null,
				Ciudad: null,
				CP: null,
				Id_EstadoPais: 0,
				nombre_edo: null,
				Id_Pais: 1,
				nombre_pais: null,
				Telefono: null,
				Correo: null,
				UsoCFDI: null,
				fecha_update: null,
				usuario_update: null,
				Observaciones: null,
				Id_FormaPago: 0,
				Id_MetodoPago: 0,
				id_regimenFiscal: null,
				Id_Moneda: 0,
			}
		};
	}

	async componentDidMount() {
		await this.setState({ sub_fraccionamiento: this.props.sub_id });
		this.getCFDI();
		this.getCountries();
		this.getWayToPay();
		this.getTypeCoins();
		this.getPaymentMethod();
		this.getRegimen();

	}

	async getCFDI() {
		const response = await request.post("/facturacion/cfdi", []);
		if (response.empty || response.error) {
			await this.setState({ CFDI: [] });
		}

		if (response.CFDI) {
			await this.setState({ CFDI: response.CFDI });
		}
	}

	async getPaymentMethod() {
		const response = await request.post("/facturacion/metodopago", []);
		if (response.empty || response.error) {
			await this.setState({ paymentMethod: [] });
		}

		if (response.metodo) {
			await this.setState({ paymentMethod: response.metodo });
		}
	}

	async getRegimen() {
		const response = await request.post("/facturacion/regimen", []);
		if (response.empty || response.error) {
			await this.setState({ regimen: [] });
		}

		if (response.regimen) {
			await this.setState({ regimen: response.regimen });
		}
	}

	async getWayToPay() {
		const response = await request.post("/facturacion/formapago", []);
		if (response.empty || response.error) {
			await this.setState({ wayToPay: [] });
		}
		if (response.formaPago) {
			await this.setState({ wayToPay: response.formaPago });
		}
	}

	async getTypeCoins() {
		const response = await request.post("/facturacion/moneda", []);
		if (response.empty || response.error) {
			await this.setState({ Coin: [] });
		}
		if (response.moneda) {
			await this.setState({ Coin: response.moneda });
		}
	}

	async getCountries() {
		const response = await request.post("/facturacion/paises", []);
		if (response.empty || response.error) {
			await this.setState({ paises: [] });
		}

		if (response.paises) {
			await this.setState({ paises: response.paises });
			this.getStates(this.state.BillingData.Id_Pais);
		}
	}

	async getStates(idCountry) {
		let data = {
			id_pais: idCountry,
		};

		const response = await request.post("/facturacion/estados", data);
		if (response.empty || response.error) {
			await this.setState({ estados: [] });
		}

		if (response.estados) {
			await this.setState({ states: response.estados });
		}
	}

	render() {
		let houses = [];

		if (this.props.dataHouses) {
			houses = this.props.dataHouses;
		}

		return (
			<div className="card-table card-owners column" id="card-owners">
				<div className="table-responsiv column">
					<table>
						<thead className="sticky-th">
							<tr className="text-left">
								<th className="text-left">
									<div className="row">
										Vivienda &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'numero_registro' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
											}
										</div>
									</div>
								</th>
								<th className="text-left description">
									<div className="row">
										Dirección &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'direccion' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'direccion')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'direccion')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'direccion')}></i>
											}
										</div>
									</div>
								</th>
								{this.props.sub_id == 0 && (
									<th className="text-left">
										<div className="row">
											{this.props.subLabel} &nbsp;
											<div className="align-center">
												{this.props.filterColumn.col === 'nombre_subfrac' ? this.props.filterColumn.filt === 'asc' ?
													<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'nombre_subfrac')}></i>
													:
													<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'nombre_subfrac')}></i>
													:
													<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'nombre_subfrac')}></i>
												}
											</div>
										</div>
									</th>
								)}

								<th className="text-center th-options">Editar</th>
							</tr>
						</thead>
						<tbody>
							{houses
								.slice((this.props.page - 1) * this.props.pageLength, this.props.page * this.props.pageLength)
								.map((house, key) => {
									return (
										<React.Fragment key={house.id_vivienda}>
											<tr
												className={
													this.state.id_vivienda_row === house.id_vivienda
														? "row-active text-left"
														: ""
												}
											>
												<td className="text-left">{house.numero_registro}</td>
												<td className="text-left">{house.direccion}</td>
												{this.state.sub_id == 0 && (
													<td className="text-left">{house.nombre_subfrac}</td>
												)}

												<td className="text-center">
													<button
														type="button"
														className="btn-full justify-center btn-mini btn-secondary color-white"
														onClick={this.openModal.bind(
															this,
															"editHouse",
															house
														)}
													>
														<i className="font-small fas fa-pen" />
													</button>
												</td>
											</tr>
										</React.Fragment>
									);
								})}
						</tbody>
					</table>
				</div>

				<DinamicModal
					idModal={"editHouse"}
					sizeModalContainer={"big"}
					title={"Datos facturación de la vivienda"}
					success={this.handledSubmit.bind(this)}
					showBtnSuccess={this.props.escritura}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.formMessage}
					closeModal={this.resetData.bind(this)}
				>
					{this.renderNewHouse()}
				</DinamicModal>
			</div>
		);
	}

	onChangeData(key, event) {
		const BillingData = this.state.BillingData;
		BillingData[key] = event.target.value;
		this.setState({ BillingData });
	}

	async resetData() {
		await this.setState({
			BillingData: {
				Id_Vivienda: 0,
				Id_SubFraccionamiento: 0,
				nombre_subf: "",
				Id_Fraccionamiento: 0,
				nombre_frac: "",
				RazonSocial: "",
				RFC: "",
				Domicilio: "",
				Municipio: "",
				Ciudad: "",
				CP: "",
				Id_EstadoPais: 0,
				nombre_edo: "",
				Id_Pais: 1,
				nombre_pais: "",
				Telefono: "",
				Correo: "",
				UsoCFDI: "",
				fecha_update: "",
				usuario_update: "",
				Observaciones: "",
				Id_FormaPago: 0,
				Id_MetodoPago: 0,
				id_regimenFiscal: null,
				Id_Moneda: 0,
			},
		});
	}

	renderNewHouse() {
		let subfraccionamientos = [];
		if (Array.isArray(this.state.subfraccionamientos)) {
			subfraccionamientos = this.state.subfraccionamientos;
		}

		let CFDI = [];
		if (this.state.CFDI) {
			CFDI = this.state.CFDI;
		}

		let paises = [];
		if (this.state.paises) {
			paises = this.state.paises;
		}

		let estados = [];
		if (this.state.states) {
			estados = this.state.states;
		}

		let wayToPay = [];
		if (this.state.wayToPay) {
			wayToPay = this.state.wayToPay;
		}

		let paymentMethod = [];
		if (this.state.paymentMethod) {
			paymentMethod = this.state.paymentMethod;
		}

		let regimen = [];
		if (this.state.regimen) {
			regimen = this.state.regimen;
		}

		let coin = [];
		if (this.state.Coin) {
			coin = this.state.Coin;
		}

		let Billing = [];
		if (this.state.BillingData) {
			Billing = this.state.BillingData;
		}

		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b className="label-red">{this.props.subLabel}:</b>
							</p>
						</div>
						<select
							className="input input-modals"
							name="subfraccionamiento"
							disabled
							value={this.state.sub_fraccionamiento}
							id="subfrac"
							onChange={(event) =>
								this.setState({ sub_fraccionamiento: event.target.value })
							}
						>
							{subfraccionamientos.map((sub, index) => (
								<option key={index} value={sub.id_subfraccionamiento}>
									{sub.nombre}
								</option>
							))}
						</select>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Vivienda:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="houseNumber"
								id="houseNum"
								className="input input-modals input-disabled"
								autoComplete="off"
								required
								value={this.state.vivienda.numero_registro}
								autoFocus={this.props.isAutoFocus}
								disabled
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Razón social:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="RazonSocial"
								id="RazonS"
								className="input input-modals"
								autoComplete="off"
								value={Billing.RazonSocial}
								onChange={this.onChangeData.bind(this, "RazonSocial")}
								required
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Correo(s):</b>
							</p>
						</div>
						<div className="column full tooltip bottom" data-tooltip="Separar correos con una coma">
							<input
								type="email"
								name="email"
								className="input input-modals"
								autoComplete="off"
								value={Billing.Correo}
								multiple
								onChange={this.onChangeData.bind(this, "Correo")}
								required
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Domicilio:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="adress"
								id="adress"
								className="input input-modals"
								autoComplete="off"
								value={Billing.Domicilio}
								onChange={this.onChangeData.bind(this, "Domicilio")}
								required
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Uso CFDI:</b>
							</p>
						</div>
						<div className="column full">
							<select
								className={"input input-modals"}
								name="cfdi"
								value={this.state.BillingData.UsoCFDI}
								onChange={(event) => {
									this.setState({ BillingData: { UsoCFDI: event.target.value } });
								}}
								disabled={this.state.disabled}
							>
								{CFDI.map((reg, key) => (
									<option value={reg.UsoCFDI} key={key}>
										{reg.Nombre}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Régimen Fiscal:</b>
							</p>
						</div>
						<div className="column full">
							<select
								className={"input input-modals"}
								name="regFiscal"
								value={this.state.BillingData.Id_RegimenFiscal}
								onChange={(event) => {
									this.setState({ BillingData: { id_regimenFiscal: event.target.value } });
								}}
								disabled={this.state.disabled}
							>
								{regimen.map((reg, key) => (
									<option
										value={reg.Id_RegimenFiscal}
										key={key}
									>
										{reg.clave_nombre}
									</option>
								))
								}
							</select>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center ">
						<div className="column label-duo-big">
							<p>
								<b>Teléfono:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="phone"
								className="input input-modals"
								autoComplete="off"
								maxLength="10"
								value={Billing.Telefono}
								onChange={this.onChangeData.bind(this, "Telefono")}
								required
							/>
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start">
								<b>RFC:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="rfc"
								className="input input-modals"
								autoComplete="off"
								value={Billing.RFC}
								onChange={this.onChangeData.bind(this, "RFC")}
								required
							/>
						</div>
					</div>

					<div className="white-space-8" />
					<div className="input-form-content row full align-center ">
						<div className="column label-duo-big">
							<p>
								<b>Municipio:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="municipality"
								className="input input-modals"
								autoComplete="off"
								value={Billing.Municipio}
								onChange={this.onChangeData.bind(this, "Municipio")}
								required
							/>
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start">
								<b>Ciudad:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="city"
								className="input input-modals"
								autoComplete="off"
								value={Billing.Ciudad}
								onChange={this.onChangeData.bind(this, "Ciudad")}
								required
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center ">
						<div className="column label-duo-big">
							<p>
								<b>País:</b>
							</p>
						</div>
						<div className="column full">
							<select
								className={"input input-modals"}
								name="country"
								value={this.state.BillingData.Id_Pais}
								onChange={(event) => {
									this.setState({ BillingData: { Id_Pais: event.target.value } });
								}}
							>
								{paises.map((country, key) => (
									<option value={country.id} key={key}>
										{country.nombre}
									</option>
								))}
							</select>
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start">
								<b>Estado:</b>
							</p>
						</div>
						<div className="column full">
							<select
								className={"input input-modals"}
								name="state"
								value={this.state.BillingData.Id_EstadoPais}
								onChange={(event) => {
									this.setState({
										BillingData: { Id_EstadoPais: event.target.value },
									});
								}}
							>
								{estados.map((state, key) => (
									<option value={state.id_estado} key={key}>
										{state.nombre}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center ">
						<div className="column label-duo-big">
							<p>
								<b>C.P:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="zip"
								className="input input-modals"
								autoComplete="off"
								maxLength="10"
								value={Billing.CP}
								onChange={this.onChangeData.bind(this, "CP")}
								required
							/>
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start">
								<b>Forma de pago:</b>
							</p>
						</div>
						<div className="column full">
							<select
								className={"input input-modals"}
								name="waytopay"
								value={this.state.BillingData.Id_FormaPago}
								onChange={(event) => {
									this.setState({
										BillingData: { Id_FormaPago: event.target.value },
									});
								}}
								disabled={this.state.disabled}
							>
								{wayToPay.map((pay, key) => (
									<option value={pay.Id_FormaPago} key={key}>
										{pay.Descripcion}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center ">
						<div className="column label-duo-big">
							<p>
								<b>Método de pago:</b>
							</p>
						</div>
						<div className="column full">
							<select
								className={"input input-modals"}
								name="methodPayment"
								value={this.state.BillingData.Id_MetodoPago}
								onChange={(event) => {
									this.setState({
										BillingData: { Id_MetodoPago: event.target.value },
									});
								}}
								disabled={this.state.disabled}
							>
								{paymentMethod.map((method, key) => (
									<option value={method.Id_MetodoPago} key={key}>
										{method.Descripcion}
									</option>
								))}
							</select>
						</div>
						<div className="column label-duo-normal space">
							<p className="justify-start">
								<b>Moneda:</b>
							</p>
						</div>
						<div className="column full">
							<select
								className={"input input-modals"}
								name="typeCoin"
								value={this.state.BillingData.Id_Moneda}
								onChange={(event) => {
									this.setState({ BillingData: { Id_Moneda: event.target.value } });
								}}
								disabled={this.state.disabled}
							>
								{coin.map((type, key) => (
									<option value={type.Id_Moneda} key={key}>
										{type.Descripcion}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Observaciones:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="obs"
								id="obs"
								className="input input-modals"
								autoComplete="off"
								value={Billing.Observaciones}
								onChange={this.onChangeData.bind(this, "Observaciones")}
							// required
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	async openModal(idModal, vivienda) {
		await this.setState({
			formMessage: null,
			vivienda: vivienda,
			sub_fraccionamiento: vivienda.id_subfraccionamiento,
			subfraccionamientos: this.props.subfraccionamientos,
			comentarios: vivienda.comentarios,
			id_vivienda_row: vivienda.id_vivienda
		});
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove("hide-dinamic-modal");
			modal.classList.add("show-dinamic-modal");
			this.getDataViviendaBilling();
		}
	}

	async getDataViviendaBilling() {
		let data = {
			id_vivienda: this.state.vivienda.id_vivienda,
		};

		const response = await request.post("/facturacion/viviendas", data);
		if (response.empty || response.error) {
			if (response.empty) {
				const dataPropietario = await request.post("/propietarios/get/info/vivienda", { id_vivienda: this.state.vivienda.id_vivienda });

				if (dataPropietario.empty || dataPropietario.error) {
					this.resetData();
				}
				else {
					await this.setState({
						BillingData: {
							RFC: dataPropietario.result.rfc || "",
							RazonSocial: dataPropietario.result.nombre || "",
							Ciudad: dataPropietario.result.ciudad || "",
							Municipio: dataPropietario.result.ciudad || "",
							CP: dataPropietario.result.cp || "",
							Correo: dataPropietario.result.email || "",
							Domicilio: dataPropietario.result.direccion || ""
						}
					})
				}

			} else {
				this.resetData();
			}
		}

		if (response.descriptores) {
			await this.setState({
				BillingData: response.descriptores[0],
				id_viviendaFrac: response.descriptores[0].Id_Fraccionamiento,
			});
		}
	}

	// ENVIA LOS DATOS DEL FORMULARIO
	async handledSubmit(event) {
		this.setState({ loadingSave: true });
		event.preventDefault();
		const form = event.target;
		let Billing = this.state.BillingData;

		let data = {
			id_vivienda: this.state.vivienda.id_vivienda,
			id_subfraccionamiento: parseInt(form.subfrac.value),
			id_fraccionamiento: parseInt(localStorage.getItem("frac")),
			razon_social: form.RazonSocial.value,
			rfc: form.rfc.value,
			domicilio: form.adress.value,
			municipio: form.municipality.value,
			ciudad: form.city.value,
			cp: form.zip.value,
			id_estado_pais: parseInt(form.state.value),
			id_pais: parseInt(form.country.value),
			telefono: form.phone.value,
			correo: form.email.value,
			cfdi: form.cfdi.value,
			fecha: moment().format("YYYY-MM-DD"),
			usuario_update: this.props.userName,
			observaciones: form.obs.value,
			id_forma_pago: parseInt(form.waytopay.value),
			id_metodo_pago: parseInt(form.methodPayment.value),
			id_regimenFiscal: parseInt(form.regFiscal.value),
			id_moneda: parseInt(form.typeCoin.value),
			numero_registro: this.state.vivienda.numero_registro,
			pro_indiviso: this.state.vivienda.pro_indiviso,
		};

		const response = await request.post("/facturacion/viviendas/update", data);
		if (response.updated) {
			this.setState({ formMessage: "DATOS ACTUALIZADOS CORRECTAMENTE" });
			this.props.reload();
		} else {
			this.setState({
				formMessage: "LOS DATOS NO SE PUDIERON ACTUALIZAR CORRECTAMENTE",
			});
		}
		this.setState({ loadingSave: false });
	}
}

export default Consumer(ReceiverTable);
