import React, { useEffect, useState } from 'react';
import ReactPasswordStrength from 'react-password-strength';
import { useHistory, useRouteMatch } from "react-router-dom";
import cogoToast from 'cogo-toast';
import { Consumer } from "../../context";
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import Request from "../../core/httpClient";

const request = new Request();

const ActualizarPassword = ({context}) => {
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState(false);
	const [estadoPass, setEstadoPass] = useState({});
	const history = useHistory();
	const match = useRouteMatch();
	const [passStatusB, setPassStatusB] = useState(false);
	const [passStatusN, setPassStatusN] = useState(false);
	const [passStatusR, setPassStatusR] = useState(false);

	useEffect(() => {
		const { params } = match;
		if (params) {
			if (params.expiro) {
				setMessage('El periodo de tu contraseña expiró, ingresa una nueva contraseña');
			}
		}
	}, [])

	async function handleSubmit(event) {
		event.preventDefault();

		const form = event.target;

		const score = estadoPass.score;

		if (score) {
			setLoading(true);

			if (!score.isValid) {
				cogoToast.warn('La contraseña no supera el mínimo de seguridad requerido.', {
					position: 'bottom-center',
				});
				setLoading(false);
				return;
			}

			const newPassword = form.newPassword.value;
			const confirmPassword = form.confirmPassword.value;
			const oldPassword = form.oldPassword.value;
			const user = await context.loadUser();
		
			if (newPassword !== confirmPassword) {
				cogoToast.warn('Las contraseñas no coinciden.', {
					position: 'bottom-center',
				});
				setLoading(false);
				return;
			}

			if (newPassword === oldPassword) {
				cogoToast.warn('La nueva contraseña debe ser diferente a la anterior.', {
					position: 'bottom-center',
				});
				setLoading(false);
				return;
			}

			const reg = new RegExp("^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*#?&.-])[A-Za-z0-9@$!%*#?&.-]{8,})$");

			if (!reg.test(newPassword)) {
				cogoToast.warn('La contraseña debe contener al menos una letra minúscula, una letra mayúscula, un número y un carácter especial.', {
					position: 'bottom-center',
				});
				setLoading(false);
				return;
			}

			let data = {
				Id_usuario: user.id.id_usuario,
				Password: oldPassword,
				Password_New: newPassword
			};
	
			let response = await request.post("/users/password/update", data);
	
			if (response) {
				//console.log(response);
				if (!response.error) {
					cogoToast.success(response.message, {
						position: 'bottom-center',
					});

					const modal = document.getElementById('expiry-change-password');
					if (modal) {
						modal.classList.add('hide-dinamic-modal');
						modal.classList.remove('show-dinamic-modal');
					}

				} else {
					cogoToast.error(response.message, {
						position: 'bottom-center',
					});
				}
			} else {
				cogoToast.error('Error al actualizar contraseña.', {
					position: 'bottom-center',
				});
			}

			setLoading(false);
		}
	}

	return (
		<DinamicModal
			idModal					=	{'expiry-change-password'}
			sizeModalContainer		=	{'big'}
			title					=	{null}
			success					=	{handleSubmit.bind(this)}
			noClose					=	{context.expirate_passowrd}>
			<div className="cambiar-password column full">
				<div className="justify-center content">
					<div className="row align-center justify-center">
						<div className="contraseña-politica">
							<div className="contraseña-politica-content">
								<p>Política Constraseña Segura</p>
								<br/>
								<ul>
									<li><p>Deberás de cambiarla cada 3 meses.</p></li>
									<li><p>8 dígitos mínimo.</p></li>
									<li><p>Una letra minúscula.</p></li>
									<li><p>Una letra mayúscula.</p></li>
									<li><p>Un número.</p></li>
									<li><p>Un carácter especial @$!%*#?&-.</p></li>
								</ul>
							</div>
						</div>
						<div className="column full">
							<div className="responsive-img justify-center">
								<img src={require ("../../img/LogoAppMosph.png")}/>
							</div>
							<div className="white-space-8" />
							<h3 className="text-center">Actualizar contraseña</h3>
							<p className="text-center color-red">{message}</p>
							<div className="white-space-16" />
							<div className="column">
								<div className="input-container">
									<p className="text-center">La nueva contraseña debe contener al menos una letra minúscula, una letra mayúscula, un número y un carácter especial.</p>
									<div className="white-space-8" />
								</div>
								<div className="white-space-8" />
								<div className="input-container pass-eye">
									{passStatusB ? <input
										type="text"
										name="oldPassword"
										id="oldPassword"
										autoComplete='off'
										placeholder="Contraseña anterior"
										className="weight-semi text-center"
										required />
									: <input
										type="password"
										name="oldPassword"
										id="oldPassword"
										autoComplete='off'
										placeholder="Contraseña anterior"
										className="weight-semi text-center"
										required/>}
									{passStatusB ? <i className="far fa-eye-slash cursor-pointer" onClick={() => setPassStatusB(false)}></i> : <i className="far fa-eye cursor-pointer" onClick={() => setPassStatusB(true)}></i>}
								</div>
								<div className="white-space-16" />
								<div className="input-container pass-eye">
									{passStatusN ? <ReactPasswordStrength
										style			=	{{border: 'none'}}
										minLength		=	{8}
										minScore		=	{2}
										changeCallback 	=	{(score, password) => setEstadoPass({score, password})}
										scoreWords		=	{['mala', 'regular', 'buena', 'segura', 'segura']}
										tooShortWord	=	{'mala'}
										inputProps		=	{{ required: true, placeholder: "Nueva contraseña", name: "newPassword", id:"newPassword", autoComplete: "off", className: "weight-semi text-center", type:"text" }}/> :
										<ReactPasswordStrength
											style={{ border: 'none' }}
											minLength={8}
											minScore={2}
											changeCallback={(score, password) => setEstadoPass({ score, password })}
											scoreWords={['mala', 'regular', 'buena', 'segura', 'segura']}
											tooShortWord={'mala'}
											inputProps={{ required: true, placeholder: "Nueva contraseña", name: "newPassword", id: "newPassword", autoComplete: "off", className: "weight-semi text-center" }} />}
										{passStatusN ? <i className="far fa-eye-slash cursor-pointer" onClick={() => setPassStatusN(false)}></i> : <i className="far fa-eye cursor-pointer" onClick={() => setPassStatusN(true)}></i>}
								</div>
								<div className="white-space-20" />
								<div className="input-container pass-eye">
									{passStatusR ? <input
										name="confirmPassword"
										//type="confirmPassword"
										type="text"
										minLength="8"
										autoComplete="off"
										placeholder="Repetir contraseña"
										className="weight-semi text-center"
										required
									/> :
									<input
										name="confirmPassword"
										//type="confirmPassword"
										type="password"
										minLength="8"
										autoComplete="off"
										placeholder="Repetir contraseña"
										className="weight-semi text-center"
										required
									/>}
									{passStatusR ? <i className="far fa-eye-slash cursor-pointer" onClick={() => setPassStatusR(false)}></i> : <i className="far fa-eye cursor-pointer" onClick={() => setPassStatusR(true)}></i>}
								</div>
								<div className="white-space-16"></div>
								<div className="btn-container">
									<button
										type="submit"
										className="btn btn-primary color-white">
										{loading ? (
											<i className="fas fa-spinner fa-spin font-text" />
										) : (
											<span className="color-white">
												<i className="fas fa-sign-in-alt font-text" />
												&nbsp; Actualizar
											</span>
										)}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</DinamicModal>
	)
}

export default Consumer(ActualizarPassword);
