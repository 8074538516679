import React, { Component } from 'react';
import ModalBorrar from '../modals/modalDelete';
import DinamicModal from '../dinamicModal/dinamicModal';
import JornadaForm from './formJornada';
import Request from "../../core/httpClient";
import cogoToast from 'cogo-toast';

const request = new Request();


class JornadasTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
			jornada: {},
			loadingSave: false
		}
    }

    render() {
        let jornadas = []
        if (this.props.jornadas) {
            jornadas = this.props.jornadas
        }
        return (
            <div className="card-table card-owners column" id="card-owners">
                <div className="row">
                    <table className="full">
                        <thead className="sticky-th">
                            <tr className="text-left">
                                <th className="th-options-huge">
									<div className="row">
										Jornada &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'jornada' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'jornada')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'jornada')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'jornada')}></i>
											}
										</div>
									</div>
								</th>
                                <th className="text-center th-options-small">Editar</th>
								<th className="text-center th-options-small">Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {jornadas.slice(((this.props.page - 1) * this.props.pageLength), (this.props.page * this.props.pageLength)).map((jornada, key) => (
                                <tr className={this.state.id_jornada_row === jornada.id_personal_jornada && 'row-active'} key={key}>
                                    <td>
                                        {jornada.jornada}
                                    </td>
									<td className="text-center">
                                        <button
											type        =   "button justify-center" 
											onClick     =   {this.openModal.bind(this, 'modal-edit-jornada', jornada)}
                                        >
											<i className="font-small fas fa-pen" />
										</button>
									</td>
									<td className="text-center">
										<button 
											className = "btn-full justify-center align-center btn-mini btn-secondary color-white"
											type = 'button' 
											onClick = {this.openModalDelete.bind(this, jornada)}
											style = {{ padding: '5px' }}
										>
											<i className="font-text fas fa-trash-alt " />
										</button>
									</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
				</div>
				<DinamicModal 
					idModal				=	{'modal-edit-jornada'}
					sizeModalContainer	=	{'small'}
					title				=	{'Editar jornada'} 
					success				=	{this.handleSubmit.bind(this)} 
					showBtnSuccess		=	{true}
					btnTextSuccess		=	{"GUARDAR"}
					showBtnCancel		=	{true}
					btnTextCancel		=	{"CANCELAR"}
					loadingSave			=	{this.state.loadingSave}
					message				=	{this.state.message}
					closeModal			=	{this.closeModal.bind(this)}
				>
					<JornadaForm jornada={this.state.jornada} jornadaInput={this.props.jornada} setJornada={this.props.setJornada} />
				</DinamicModal>
				<ModalBorrar
				    id = {this.state.id_personal_jornada}
				    delete = { this.handleDelete.bind(this)}
				/>
            </div>
        );
	}

	closeModal(idModal) {
		this.setState({jornada: {}});

		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}

		this.props.setJornada('');
	}

	openModal(idModal, data) {
		this.setState({ jornada: data, id_jornada_row: data.id_personal_jornada});
        
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
        }
	}

	openModalDelete(jornada) {
		this.setState({id_personal_jornada: jornada.id_personal_jornada});
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

	async handleSubmit(event) {
		event.preventDefault();
		this.setState({loadingSave: true});
		const form = event.target;
		const data = {
			id_personal_jornada: this.state.jornada.id_personal_jornada,
			jornada: form.jornada_title.value
		}

		const response = await request.post('/personal/jornadas/update', data);

		if (response.updated) {
            this.props.reload();
            cogoToast.success('Jornada actualizada.', {
                position: 'bottom-right',
            });
        } else {
            cogoToast.error(response.message || 'No se pudo actualizar la jornada.', {
                position: 'bottom-right',
            });
        }

		this.props.setJornada('');
		this.setState({loadingSave: false});
	}

	async handleDelete(id) {
		this.setState({loadingSave: true});

		let data = {
			id_personal_jornada: id,
		}
		
		const response = await request.post('/personal/jornadas/delete', data);

		if (response.delete) {
            cogoToast.success('Jornada eliminada.', {
                position: 'bottom-right',
            });
			this.props.reload();
        } else {
            cogoToast.error('No se pudo eliminar la jornada.', {
                position: 'bottom-right',
            });
        }
		this.props.setJornada('');
		this.setState({loadingSave: false});
	}
}

export default JornadasTable;