import React from 'react'
import Core from './../core/SuperCore';
import DatePicker from "react-datepicker";
import DinamicModal from '../dinamicModal/dinamicModal';
import ModalBorrar from '../modals/modalDelete';
import Dropzone from '../dropzone';
import cogoToast from 'cogo-toast';
import { URL_API } from '../../core/urlsApi';
import Request from '../../core/httpClient';
import moment from 'moment-timezone';
import sortableData from '../../helpers/sortableDataTable';

const req = new Request();

class GaleriaTable extends Core{

    constructor(props){
        super(props);
        this.state = {
			galerias: this.props.galerias,
			nombre: '',
			month_year:      new Date(),
            min_date:       new Date(),
            nombre:         '',
			descripcion:     '',
			direction: {
				nombre: 'desc',
				descripcion: 'desc'
			},
			filterColumn: {
				col: '',
				filt: ''
			}
        }
    }

    async componentDidUpdate(prevProps, prevState){

        if(prevProps.galerias != this.props.galerias){
            this.setState({galerias: this.props.galerias});
        }
    }

    async beforeOpenModal(galeria){
        await this.setState({
			id_galeria: galeria.id_galeria,
			nombre: galeria.nombre,
			descripcion: galeria.descripcion,
			month_year: moment(`${galeria.mes} ${galeria.anio}`, 'M YYYY').toDate()
        });

        this.openModal('editGaleria');
    }

	async openImages(id_galeria) {
		await this.setState({id_galeria});
		const res = await req.post('/galerias/get/galeria/imagenes', {id_galeria});
		if (res.imagenes) {
			////console.log(res.imagenes);
			this.setState({imagenes: res.imagenes});
		} else {
			cogoToast.error('No se pudo obtener las imágenes.', { position: 'bottom-right'});
		}
		this.openModal('galeriaImagenes');
	}

	sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, this.state.galerias, this.state.direction[key]);

		this.setState({
			galerias: items,
			direction: {
				[key]: direction
			},
			filterColumn: {
				col: key,
				filt: direction
			}
		});
	}

    render(){

        const { galerias } = this.state;

		let files = [];
		if (Array.isArray(this.state.imagenes)) {
			files = this.state.imagenes.map(img => {
				return {
					name: img.descripcion,
					url: img.imagen
				}
			})
			//[{name:'Test.jpg', url:'https://docsappmosphera.dwitsites.com/galeria/19/1/masa-para-hotcakes.jpg'}]
		}
        return (
            <div className="card-table card-owners column" id="card-owners">
				{/*<div className="row full justify-end align-center">
					<button className="btn btn-small btn-primary color-white" onClick={() => {
							this.setState({
								id_galeria: 0,
								nombre: '',
								descripcion: '',
								month_year: new Date()
							});
							this.openModal('newGaleria')}
						}>
						<i className="fas fa-plus"></i> &nbsp; Agregar galería
					</button>
				</div>*/}
                <div className="table-responsive">
					<table>
						<tbody>
							<tr className="row-without-border">
								<th colSpan="5" className="text-left">
									<h4 className="weight-semi">Galerías</h4>
								</th>
								<th>
									<button className="btn btn-mini btn-primary color-white" onClick={() => {
										this.setState({
											id_galeria: 0,
											nombre: '',
											descripcion: '',
											month_year: new Date()
										});
										this.openModal('newGaleria')
									}
									}>
										<i className="fas fa-plus mini-i"></i>&nbsp; Añadir galería
									</button>
								</th>
							</tr>
							<tr className="text-left">
								<th className="text-left" style={{ width: '20%' }}>
									<div className="row">
										Nombre &nbsp;
									<div className="align-center">
											{this.state.filterColumn.col === 'nombre' ? this.state.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.sortableTable.bind(this, 'nombre')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.sortableTable.bind(this, 'nombre')}></i>
												:
												<i className="fas fa-sort" onClick={this.sortableTable.bind(this, 'nombre')}></i>
											}
										</div>
									</div>
								</th>
								<th className="text-justify" style={{ width: '50%' }}>
									<div className="row">
										Descripción &nbsp;
									<div className="align-center">
											{this.state.filterColumn.col === 'descripcion' ? this.state.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.sortableTable.bind(this, 'descripcion')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.sortableTable.bind(this, 'descripcion')}></i>
												:
												<i className="fas fa-sort" onClick={this.sortableTable.bind(this, 'descripcion')}></i>
											}
										</div>
									</div>
								</th>
								<th className="text-center" style={{ width: '10%' }}>Mes / Año</th>
								<th className="text-center th-options-small">Imágenes</th>
								<th className="text-center th-options-small">Editar</th>
								<th className="text-center th-options-small">Eliminar</th>
							</tr>
							{galerias.map((gal, key) => {
								return (
								<React.Fragment key={key}>
									<tr >
										<td className="text-left">{gal.nombre}</td>
										<td className="text-justify">{gal.descripcion}</td>
										<td className="text-center">{this.getDate(gal)}</td>
										<td className="text-right">
											<button 
													className   =   "btn-full justify-center align-center btn-mini btn-secondary color-white" 
													type        =   "button" 
													onClick     =   {(event)=>this.openImages(gal.id_galeria)} 
													
													>
												<i className="font-small fas fa-images" />
											</button>
										</td>
										<td className="text-right">
											<button 
													className   =   "btn-full justify-center align-center btn-mini btn-secondary color-white" 
													type        =   "button" 
													onClick     =   {(event)=>this.beforeOpenModal(gal)} 
													>
												<i className="font-small fas fa-pen" />
											</button>
										</td>
										<td className="text-right">
											<button 
													className   =   "btn-full justify-center align-center btn-mini btn-secondary color-white" 
													type        =   "button" 
													onClick     =   {() => {
														this.setState({id_galeria: gal.id_galeria});
														this.openModalDelete('deleteModal')
													}}
													
													>
												<i className="font-small fas fa-trash-alt" />
											</button>
										</td>
									</tr>
								</React.Fragment>
								)}
							)}
						</tbody>
					</table>
                </div>

				<DinamicModal
					idModal					=	{'newGaleria'}
					enforceFocus		    =	{true}
					sizeModalContainer		=	{'small'}
					title					=	{'Añadir galería'} 
					success					=	{this.crearGaleria.bind(this)} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}
					btnTextCancel			=	{"CANCELAR"}
					loadingSave				=	{this.state.loadingSave}
					message					=	{this.state.saveMessage}
					closeModal				=	{this.closeModal.bind(this)}
				>
					{this.renderGaleria()}
				</DinamicModal> 
                <DinamicModal 
                    idModal					=	{'editGaleria'}
                    enforceFocus		    =	{true}
                    sizeModalContainer		=	{'small'}
                    title					=	{'Editar galería'} 
                    success					=	{this.editGaleria.bind(this)} 
                    showBtnSuccess			=	{true}
                    btnTextSuccess			=	{"GUARDAR"}
                    showBtnCancel			=	{true}
                    btnTextCancel			=	{"CANCELAR"}
                    loadingSave				=	{this.state.loadingSave}
                    message					=	{this.state.saveMessage}
                    closeModal				=	{this.closeModal.bind(this)}
                >
                {this.renderGaleria()}
                </DinamicModal> 

				<DinamicModal 
                    idModal					=	{'galeriaImagenes'}
                    enforceFocus		    =	{true}
                    sizeModalContainer		=	{'small'}
                    title					=	{'Imágenes'} 
                    success					=	{this.editGaleria.bind(this)} 
                    showBtnSuccess			=	{false}
                    btnTextSuccess			=	{"GUARDAR"}
                    showBtnCancel			=	{true}
                    btnTextCancel			=	{"SALIR"}
                    loadingSave				=	{this.state.loadingSave}
                    message					=	{this.state.saveMessage}
                    closeModal				=	{() => {
						this.setState({id_galeria: 0});
						this.closeModal('galeriaImagenes');
					}}
                >
					<Dropzone
						url 		= 	{`${URL_API}/galerias/add/galeria/imagen`}
						id 			=	{this.state.id_galeria}
						id_delete 	=	{this.state.id_galeria}
						usuario		= 	{this.props.usuario}
						files 		= 	{files}
						url_delete	=	{'/galerias/delete/galeria/imagen'}
						data		=	{{
								id_galeria: this.state.id_galeria,
								id_fraccionamiento: localStorage.getItem('frac'),
								usuario: this.props.usuario? this.props.usuario.usuario : 'undefined'
							}}/>
                </DinamicModal> 
				<ModalBorrar
					id          =   {this.state.id_galeria}
					delete      =   {this.deleteGaleria.bind(this)}
				/>
            </div>
        );
    }

	async clearData(){
		this.setState({
			month_year:     new Date(),
			nombre:         '',
			descripcion:    ''
		});
	}

	async crearGaleria(event) {
		event.preventDefault();
		this.setState({loadingSave: true});
		const usuario = await this.getUserInfo();

		const month = this.state.month_year.getUTCMonth() + 1;
		const year  = this.state.month_year.getFullYear();

		const data = {
			IdFraccionamiento:  parseInt(localStorage.getItem('frac')),
			Nombre:             this.state.nombre,
			Descripcion:        this.state.descripcion,
			Mes:                month < 10 ? `0${month}`: month.toString(),
			Anio:                year < 10 ? `0${year}`: year.toString(),
			Usuario:            usuario.info.usuario,
			id_carpeta:			this.props.id_carpeta
		}

		const response = await req.post('/galerias/add/galeria', data);

		if(response.error) {
			cogoToast.error('No se pudo crear galería.', {
				position: 'bottom-right'
			});
			this.setState({loadingSave: false});
			return false;
		}
		this.props.reload();
		cogoToast.success('Galería creada con éxito.',{
			position: 'bottom-right'
		});
		this.closeModal('newGaleria');
		this.clearData();
		this.setState({loadingSave: true});
	}

	async editGaleria(e) {
        e.preventDefault();
		this.setState({loadingSave: true});
		const month = this.state.month_year.getUTCMonth() + 1;
		const year  = this.state.month_year.getFullYear();
		const data = {
			id_galeria: this.state.id_galeria,
			id_carpeta: this.props.id_carpeta,
			Nombre: this.state.nombre,
			Descripcion: this.state.descripcion,
			Mes: month < 10 ? `0${month}`: month.toString(),
			Anio: year < 10 ? `0${year}`: year.toString(),
			Usuario: this.props.usuario
		};
		const response = await req.post('/galerias/update/galeria', data);
		if (response.updated) {
			cogoToast.success(response.message || 'Carpeta actualizada.', { position: 'bottom-right' });
			this.closeModal('editCarpeta');
			this.clearData();
			this.props.reload();
		} else {
			cogoToast.error(response.message || 'No se pudo actualizar la carpeta.', { position: 'bottom-right' });
		}
		this.setState({loadingSave: false});
    }

	async deleteGaleria(id) {
        let data = { 
			id_galeria: id,
			Usuario: this.props.usuario
		}
        const response = await req.post("/galerias/delete/galeria", data);

        if(response.error || response.empty){
            cogoToast.error('No se pudo eliminar la galería.', {
                position: 'bottom-right',
            });
        }
        
        if (response.deleted) {
            cogoToast.success('Carpeta eliminada.', {
                position: 'bottom-right',
			});
			this.props.reload();
        }
        this.closeModalDelete('deleteModal');
	}

	deleteImagen(event) {
		//console.log(event);
	}

    renderGaleria() {
    	return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center"> 
						<div className="column label-medium">
							<p>
								<b>Nombre:</b>
							</p>
						</div>
						<div className="column full">
							<input 
								typeof      =   "text" 
								className   =   "input input-modals"
								required
								autoComplete = 'off'
								value   =   {this.state.nombre}
								onChange =  { (e)=>{this.setState({nombre: e.target.value})}}
							/>
						</div>
					</div>
					<div className="white-space-16" />

					<div className="input-form-content row full align-center"> 
						<div className="column label-medium">
							<p>
								<b>Mes y año:</b>
							</p>
						</div>
						<div className="column full">
							<DatePicker
								className   =   "input input-modals"
								selected    =   {this.state.month_year}
								onChange    =   {(event)=>{this.changeDate(event)}}
								dateFormat  =   "MMM-yy"
								locale      =   "es"
								// minDate     =  	{this.state.min_date}
								showMonthYearPicker
							/>
						</div>
					</div>
					<div className="white-space-16" />

					<div className="input-form-content row full align-center"> 
						<div className="column label-medium">
							<p>
								<b>Descripción:</b>
							</p>
						</div>
					</div>
					<div className="white-space-16" />

					<div className="input-form-content row full align-center"> 
						<div className="column full">
							<textarea
								className       =   "input input-modals"
								autoComplete    =   'off'
								required
								value   =   {this.state.descripcion}
								onChange =  { (e)=>{this.setState({descripcion: e.target.value})}}
							></textarea>
						</div>
					</div>
					<div className="white-space-16" />
				</div>
			</div>
		);
	}

	async changeDate(date){
		await this.setState({month_year: date});
	}

    getDate({mes:month, anio:year}){

        let date = '';
        
        date += month < 10 ? `0${month}`: month.toString();
        date += '/';
        date += year.toString(); 
        return date;
    }

}

export default GaleriaTable;
