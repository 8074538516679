import React, { forwardRef, useState } from 'react';
import cogoToast from 'cogo-toast';
import NumberFormat from 'react-number-format';

const AbonarForm = forwardRef(function AbonarFormRef(props, ref) {

    const { numero_registro, subfraccionamiento, inquilino, id_inquilino, metodosPago = [], loadingInquilinos, onSubmit } = props;

    const [cantidad, setCantidad] = useState(0);

    const handleSubmit = (event) => {

        event.preventDefault();

        const fd = new FormData(event.target);
        const data = Object.fromEntries(fd.entries());

        console.log('form data: ', data);

        const cantidadNumber = +cantidad;
        const metodoPagoIdNumber = +data.metodosPago;

        if (cantidadNumber === 0) {
            cogoToast.warn("Ingresar cantidad", { position: "bottom-right", hideAfter: 5 });
            return;
        }

        if(metodoPagoIdNumber === 0) {
            cogoToast.warn("Ingresar Metodo de Pago", { position: "bottom-right", hideAfter: 5 });
            return;
        }

        onSubmit({
            id_inquilino, 
            monto: cantidadNumber, 
            id_metodoPago: metodoPagoIdNumber, 
        });
    }

    return (
        <form ref={ref} className="modal-container light-font" onSubmit={handleSubmit} autoComplete="off">
            <div className="white-space-16"></div>
            <div className="input-form-content row full align-center">
                <label htmlFor='subfraccionamiento' className="column label-medium">
                    <p>
                        <b>Subfraccionamiento:</b>
                    </p>
                </label>
                <input
                    className="input input-modals"
                    name="subfraccionamiento"
                    type='text'
                    value={subfraccionamiento}
                    readOnly
                    disabled
                />
            </div>
            <div className="white-space-8" />
            <div className="input-form-content row full align-center">
                <label htmlFor='vivienda' className="column label-medium">
                    <p>
                        <b>Vivienda:</b>
                    </p>
                </label>
                <input
                    className="input input-modals"
                    name="vivienda"
                    type='text'
                    value={numero_registro}
                    readOnly
                    disabled
                />
            </div>
            <div className="white-space-8" />
            <div className="input-form-content row full align-center">
                <label htmlFor='inquilino' className="column label-medium">
                    <p>
                        <b>Residente:</b>
                    </p>
                </label>
                <input
                    className="input input-modals"
                    name="inquilino"
                    type='text'
                    value={inquilino}
                    readOnly
                    disabled
                />
            </div>
            <div className="white-space-8" />
            <div className="input-form-content row full align-center">
                <label htmlFor='metodosPago' className="column label-medium">
                    <p>
                        <b>Metodo de Pago:</b>
                    </p>
                </label>
                <select
                    className="input input-modals"
                    disabled={loadingInquilinos}
                    name="metodosPago"
                    required
                >
                    <option value={0}>Seleccionar</option>
                    {metodosPago.map((metodo, index) =>
                        <option key={index} value={metodo.Id_FormaPago}>
                            {metodo.Descripcion}
                        </option>
                    )}
                </select>
            </div>
            <div className="white-space-8" />
            <div className="input-form-content row full align-center">
                <label htmlFor='cantidad' className="column label-medium">
                    <p>
                        <b>Cantidad:</b>
                    </p>
                </label>
                <NumberFormat
                    className="input input-modals text-right"
                    displayType={"input"}
                    name="cantidad"
                    thousandSeparator={true}
                    defaultValue={0}
                    required
                    prefix={"$"}
                    decimalScale={2}
                    maxLength={10}
                    fixedDecimalScale={true}
                    allowLeadingZeros={false}
                    value={cantidad}
                    onValueChange={({ value }) => setCantidad(value)}
                />
            </div>
            <div className="white-space-16"></div>
        </form>
    )
})

export default AbonarForm;

