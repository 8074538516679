import React, { Component } from 'react';
import ReportTypeEddit from './reportTypeEddit'
import ModalBorrar from '../modals/modalDelete'
import cogoToast from 'cogo-toast';

import Request from "../../core/httpClient";
const request = new Request();

class ReportsTypeTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id_tipoqueja: 	'',
			tipo: 			''
		}
	}
	reload() {
		this.props.reload()
	}
	delete = id => {

		this.handleDelete(id)

	}

	openModal(idModal,type) {
		this.setState({ mesagge: null,tipo: type, id_tipo_row: type.id_tipoqueja });
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

	render() {
		let types = [];
		if (this.props.types) {
			types = this.props.types;
		}

		return (
			<div className="card-table card-table-budget card-owners column" id="card-owners">
				<div className="table-responsiv column">
					<table>
						<thead className="sticky-th">
							<tr className="text-left">
								<th className="">
									<div className="row">
										Tipo &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'descripcion' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'descripcion')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'descripcion')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'descripcion')}></i>
											}
										</div>
									</div>
								</th>
								<th className=" text-center th-options-small">Editar</th>
								<th className=" text-center th-options-small">Eliminar</th>
							</tr>
						</thead>
						<tbody>
							{types.slice((this.props.page - 1) * 7, this.props.page * 7).map((tipo, key) => (
								<tr className={this.state.id_tipo_row === tipo.id_tipoqueja ? 'row-active' : ''} key={key}>
									<td>{tipo.descripcion}</td>
									<td className="text-center">
										<button className="btn-full justify-center btn-mini btn-secondary color-white" type="button justify-center" onClick={this.openModal.bind(this, "editNewTypeReport",tipo)} >
											<i className="font-small fas fa-pen" />
										</button>
									</td>
									<td>
										<button className="btn-full justify-center align-center btn-mini btn-secondary color-white" 
												type		=	'button' 
												onClick		=	{this.openModalDelete.bind(this, tipo.id_tipoqueja)} 
												style		=	{{ padding: '5px' }} 
												disabled	=	{!this.props.escritura}
										>
											<i className="font-text fas fa-trash-alt " />
										</button>
									</td>
								</tr>
							))

							}

						</tbody>
					</table>
				</div>
				<ReportTypeEddit 
					tipo		=	{this.state.tipo} 
					reload		=	{this.reload.bind(this)}
					escritura 	= {this.props.escritura}
				>

				</ReportTypeEddit>
				<ModalBorrar 
					id		=	{this.state.id_tipoqueja} 
					delete	=	{this.delete.bind(this)} 
				/>
			</div>
		);
	}

	openModalDelete(id) {
		this.setState({ id_tipoqueja: id, id_tipo_row: id });
		let modal = document.getElementById("deleteModal");
		if (modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
		document.getElementById('cname').focus();
	}

	async handleDelete(id) {
		let data = {

			IdReporte: id
		}
		const response = await request.post("/incidencias/delete/type", data);


		if (response && !response.error) {
			if (response.deleted && !response.empty) {
				this.setState({
					message: response.message,

				});
				this.reload();
				cogoToast.success('Tipo de incidencia eliminado.', {
					position: 'bottom-right',
				});
			} else {
				this.setState({
					empty: true,
					//message: response.message,
					loading: false
				});
				cogoToast.error('No se pudo eliminar tipo de incidencia.', {
					position: 'bottom-right',
				});
			}
		} else {
			cogoToast.error('No se pudo eliminar tipo de incidencia.', {
				position: 'bottom-right',
			});
		}
		this.reload()
	}
}

export default ReportsTypeTable;