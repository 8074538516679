import React from 'react';
import Core from '../core/SuperCore';
import Header from '../Modales/Structure/Header';

import SubHeader from '../Modales/Structure/SubHeader';
import Body from '../Modales/Structure/Body';
import Footer from './../coreModal/structure/footer'

import Draggable from 'react-draggable';

class Modal1 extends Core{
    constructor(props){
        super(props);
        this.state = {
            id:     this.props.id,
            title:  this.props.title,

            id_menu_activo: this.props.id_menu_activo,
            menus:          this.props.menus,

            screens:    this.props.screens,
            nexFooter:  this.props.nexFooter,
            showSubmit: this.props.showSubmit,
            showClose:  this.props.showClose,
            prefijo:    this.props.prefijo
        }
    }

    async componentDidUpdate(prevProps, prevState){
        if(prevProps.id !=  this.props.id){
            await this.setState({id: this.props.id});
        }

        // HEADER
        if(prevProps.title !=  this.props.title){
            await this.setState({title: this.props.title});
        }

        // SubHeader
        if(prevProps.id_menu_activo != this.props.id_menu_activo){
            await this.setState({id_menu_activo: this.props.id_menu_activo})
        }

        if(prevProps.menus != this.props.menus){
            await this.setState({menus: this.props.menus})
        }

        // Body

        if(prevProps.screens != this.props.screens){
            this.setState({screens: this.props.screens})
        }

        // Footer
        if(prevProps.nexFooter != this.props.nexFooter){
            this.setState({nexFooter: this.props.nexFooter});
        }
        
        if(prevProps.showSubmit != this.props.showSubmit){
            this.setState({showSubmit: this.props.showSubmit});
        }

        if(prevProps.showClose != this.props.showClose){
            this.setState({showClose: this.props.showClose});
        }

        if(prevProps.showClose != this.props.showClose){
            this.setState({showClose: this.props.showClose});
        }
        
    }

    async backMenu(){
        
        let sizeMenus   = 0;
        
        if(this.state.menus != null || typeof this.state.menus != 'undefined')
            sizeMenus = this.state.menus.length;

        let id_menu = this.state.id_menu_activo;
            id_menu++;
        if(id_menu < sizeMenus )
            id_menu = 0;
        
        await this.setState({id_menu_activo: id_menu });

    }
    async nextMenu(){
        let sizeMenus   = 0;
        
        if(this.state.menus != null || typeof this.state.menus != 'undefined')
            sizeMenus = this.state.menus.length;

        let id_menu = this.state.id_menu_activo;
            id_menu++;
        if(id_menu > sizeMenus )
            id_menu = sizeMenus;
        
        await this.setState({id_menu_activo: id_menu });
    }
    render(){

        let sizeMenus   = 0;
        let sizeScreens = 0;

        if(this.state.menus != null || typeof this.state.menus != 'undefined')
            sizeMenus = this.state.menus.length;
    
        if(this.state.screens != null || typeof this.state.screens != 'undefined')
            sizeScreens = this.state.screens.length;
            
            
            //console.log(`id_menu_activo: ${this.state.id_menu_activo}`);
            let  defaultFooter = false;
            if(parseInt(sizeScreens) == 1 || parseInt(sizeMenus) == parseInt(this.state.id_menu_activo + 1))
            defaultFooter = true;
         //console.log(defaultFooter);

        return(
            <div className ={`${this.props.prefijo}simple-modal-container  super-hide`} id ={this.props.id}>
                <Draggable handle  =   ".simple-header">
                {/* START  MODAL */}
                <div className={`simple-modal ${this.props.size}`}>
                    <div className = "simple-header">
                        <Header
                            id          =   {this.state.id}
                            title       =   {this.state.title}
                        ></Header>
                    </div>
                    <div className = {`simple-sub-header`} id = {`sub-header-${this.props.id}`}>
                        <SubHeader
                            id                  =   {this.state.id}
                            id_menu_activo      =   {this.state.id_menu_activo}
                            menus               =   {this.state.menus}
                            changeMenu          =   {this.changeMenu.bind(this)}
                        ></SubHeader>
                    </div>
                    <div className = {`simple-body align-center`}>
                        <Body
                            id              =   {this.state.id}
                            menus           =   {this.state.menus}
                            id_menu_activo  =   {this.state.id_menu_activo}
                            screens         =   {this.state.screens}
                        ></Body>
                        </div>
                    <div className = {`super-footer align-center`}>
                        <Footer
                            id              = {this.state.id}
                            id_menu_activo  = {this.state.id_menu_activo}
                            footer          = {this.state.footer}
                            defaultFooter   = {defaultFooter}
                            nexFooter       = {this.state.nexFooter}
                            lblSubmit       = {this.state.lblSubmit}
                            lblCancel       = {this.state.lblCancel}
                            lblBack         = {this.state.lblBack}
                            lblNext         = {this.state.lblNext}
                            onSubmit        = {this.props.onSubmit.bind(this)}
                            backMenu        = {this.backMenu.bind(this)}
                            nextMenu        = {this.nextMenu.bind(this)}

                            triggerCloseModal   =   {this.props.triggerCloseModal ? this.props.triggerCloseModal.bind(this): ()=>{}}

                            showSubmit      =   {this.state.showSubmit}
                            showClose       =   {this.state.showClose}
                        ></Footer>
                    </div>
                </div>
                {/* END SUPER MODAL */}
                </Draggable>
            </div>
        );
    }

    // Actualiza el menu activo
    async changeMenu(id){
        await this.setState({
            id_menu_activo: id || 0
        });
    }
}


export default Modal1;