import React, { Component } from "react";
import { Consumer } from "../context";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import SidebarAdministracion from "../components/header/sidebarAdministracion";
import Controls from "../components/controls/controls";
import BudgetExpensesTable from "../components/budgetExpences/BudgetExpensesTable";
import Paginador from "../components/paginador/paginador";
import Permissions from "../middlewares/Permissions";
import moment from "moment-timezone";
import cogoToast from "cogo-toast";

import Request from "../core/httpClient";
const request = new Request();

class BudgetExpenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      subfraccionamientos: [],
      subfraccionamientoNombre: "",
      subfraccionamiento: localStorage.getItem("subfrac"),
      idSub: "",
      gastos: [],
      presupuestosTeorico: [],
      idYearTeorico: 0,
      anio: 0,
      page: 1,
      filterData: [],
      keys: [],
      year: moment().format("YYYY"),
      filter: "",
      escritura: false,
      lectura: false,
      logo: require("../img/logo-AppMosphera-r.png"),
      submenu: "",
      subLabel: "",
      presupuesto: 0,
    };
    //console.log(moment().format('YYYY'));
    this.validarPermisos();
  }

  async validarPermisos() {
    let permisos = new Permissions();
    let permisosInfo = await permisos.getResult();
    await this.setState({
      escritura: permisosInfo.escritura,
      lectura: permisosInfo.lectura,
    });
    //console.log(permisosInfo);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.subfraccionamiento !== prevState.subfraccionamiento) {
      this.getFacturasConceptos();
    }

    if (this.state.presupuesto !== prevState.presupuesto) {
      this.getFacturasConceptos();
    }
  }

  /*funcion del paginador*/
  setPage(page) {
    this.setState({ page });
  }

  reload() {
    this.setState({ showModal: false });
    this.getFacturas();
  }
  stopLoading() {
    this.setState({ loading: false });
  }

  changeFrac() {
    this.getFacturasConceptos();
  }

  change() {
    this.getFacturasConceptos();
  }

  subChange(event) {
    this.setState({ subfraccionamiento: event.target.value });
  }

  filterGeneric(e) {
    e.preventDefault();

    const form = e.target;

    const param1 = form.filterIncidents.value;
    const param2 = form.textFilter.value;

    let filterData = this.state.filterData;

    //

    if (param1 === "todo") {
      this.setState({ conceptos: this.state.filterData });
      form.textFilter.value = "";
    } else {
      const key = param1;
      filterData = filterData.filter(
        (product) =>
          product[key].toLowerCase().indexOf(param2.toLowerCase()) !== -1
      );
      this.setState({ conceptos: filterData });
    }
  }

  openModal(idModal) {
    this.setState({ mesagge: null });
    const modal = document.getElementById(idModal);
    if (modal) {
      modal.classList.remove("hide-dinamic-modal");
      modal.classList.add("show-dinamic-modal");
    }
    const subfrac = document.getElementById("subfrac");
    if (subfrac) {
      document.getElementById("subfrac").focus();
    }
  }

  closeModal(idModal) {
    this.setState({ mesagge: null });
    const modal = document.getElementById(idModal);
    if (modal) {
      modal.classList.add("hide-dinamic-modal");
      modal.classList.remove("show-dinamic-modal");
    }
  }

  /*funcion del paginador*/
  setPage(page) {
    this.setState({ page });
  }

  subChange(sub) {
    this.setState({ subfraccionamiento: sub });
  }

  nuevoGasto() {
    this.setState({ showModal: true });
  }

  setFilter(filter) {
    //console.log(filter)
    this.setState({ filter });
  }

  setYear(event) {
    this.setState({ year: event.target.value });
  }

  setPresupuesto(event) {
    this.setState({ presupuesto: event.target.value });
  }

  getDefaultPresupuesto(id) {
    if (this.state.presupuesto === 0) this.setState({ presupuesto: id });
  }

  filter(gastos) {
    gastos = gastos.filter((gas) => {
      if (gas.nombre_concepto.toLowerCase().indexOf(this.state.filter) !== -1) {
        return true;
      }
    });
    return gastos;
  }

  render() {
    let gastos = [];

    if (this.state.gastos) {
      gastos = this.state.gastos;
      gastos = this.filter(gastos);
    }

    return (
      <div className="owners column">
        <Helmet>
          <title>
            {process.env.REACT_APP_NAME} - {this.state.submenu}
          </title>
        </Helmet>
        <Header
          sidebar={true}
          active={"presupuestoGastos"}
          parent={"presup"}
          panel={"panel2"}
          nav={"administracion"}
          change={this.change.bind(this)}
          reload={this.reload.bind(this)}
        />
        <div className="row">
          <div className="column" id="sidebar">
            <SidebarAdministracion />
          </div>
          <div className="column" id="content">
            <div className="justify-center">
              <div className="container column">
                {this.state.lectura ? (
                  <React.Fragment>
                    <Controls
                      title={this.state.submenu}
                      dataSet={gastos}
                      setFilter={this.setFilter.bind(this)}
                      subChange={this.subChange.bind(this)}
                    />
                    {this.state.loading ? (
                      <div className="row justify-center">
                        <i className="fas fa-spinner fa-spin"></i>
                      </div>
                    ) : (
                      <BudgetExpensesTable
                        conceptos={gastos}
                        id={this.state.idYearTeorico}
                        reload={this.reload.bind(this)}
                        setPresupuesto={this.setPresupuesto.bind(this)}
                        stopLoading={this.stopLoading.bind(this)}
                        page={this.state.page}
                        subfrac={this.state.subfraccionamiento}
                        showModal={this.state.showModal}
                        handleClose={this.handleClose.bind(this)}
                        subfraccionamientos={this.state.subfraccionamientos}
                        id_subfraccionamiento={this.state.subfraccionamiento}
                        id_presupuesto={this.state.presupuesto}
                        escritura={this.state.escritura}
                        subLabel={this.state.subLabel}
                        getDefaultPresupuesto={this.getDefaultPresupuesto.bind(
                          this
                        )}
                      />
                    )}
                    <div className="white-space-16" />
                    <Paginador
                      pages={Math.ceil(gastos.length / 7)}
                      setPage={this.setPage.bind(this)}
                    />
                  </React.Fragment>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.getSubmenu();
    this.getFacturasConceptos();
    this.getSubFraccionamientos();
  }

  async getSubmenu() {
    const submenu = await this.props.context.getSubmenuAdminName();
    if (submenu) {
      this.setState({ submenu });
    }

    const subLabel = window.localStorage.getItem("subLabel");
    if (subLabel) {
      this.setState({ subLabel });
    }
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleOpen() {
    this.setState({ showModal: true });
    this.getSubFraccionamiento();
  }

  // traer conceptos segun el presupuesto seleccionado
  async getFacturasConceptos() {
    this.setState({ conceptos: [], loading: true });
    let data = {
      id_fraccionamiento: localStorage.getItem("frac"),
      id_subfraccionamiento: this.state.subfraccionamiento,
      id_presupuesto: this.state.presupuesto,
    };

    console.log("presupuesto factura:" + data.id_presupuesto);

    const response = await request.post("/conceptos/presupuesto/get/all", data);
    console.log(response);
    if (response && !response.error) {
      if (response.conceptos[0] && !response.empty) {
        this.setState({
          gastos: response.conceptos,
          filterData: response.conceptos,
          keys: Object.keys(response.conceptos).filter(
            (key) => key.indexOf("id") === -1
          ),
        });
      } else {
        this.setState({
          empty: true,
          //message: response.message,
          gastos: [],
        });
      }
    } else {
      this.setState({
        error: true,
        //message: response.message
      });
    }
    this.setState({ loading: false });
    this.getIdPresupuesto();
  }

  async handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    let data = {
      IdSub: this.state.idSub,
      Year: parseInt(form.year.value),
      Description: form.description.value,
      idTeorico: parseInt(this.state.idYearTeorico),
    };

    const response = await request.post(
      "/presupuestos/presupuesto/create/real",
      data
    );

    if (response && !response.error) {
      if (response.created && !response.empty) {
        this.setState({ showModal: false });
        cogoToast.success("Presupesto creado.", {
          position: "bottom-right",
        });
      } else {
        this.setState({
          empty: true /*, message: "EL FOLIO YA ESXISTE, INTENTA CON UNO NUEVO"*/,
        });
        cogoToast.warn("El folio ya existe, intenta con uno nuevo.", {
          position: "bottom-right",
        });
      }
    } else {
      this.setState({
        error: true /*,message: "NO SE PUDO CREAR PRESUPUESTO"*/,
      });
      cogoToast.error("No se pudo crear presupuesto.", {
        position: "bottom-right",
      });
    }
    this.getPresupuestos();
    this.getFacturas();
  }

  cambiar() {
    let id = parseInt(document.getElementById("year").value);
    this.setState({ conceptos: [], idYearTeorico: id });
    this.getFacturas();
  }

  async getIdPresupuesto() {
    let data = {
      anio: this.state.year,
      subfrac: localStorage.getItem("subfrac"),
    };
    const response = await request.post(
      "/presupuestos/presupuesto/get/id",
      data
    );
    if (response.id_presupuesto) {
      this.setState({ IdPresupuesto: response.id_presupuesto });
    }
  }

  // traer subfraccionamientos
  async getSubFraccionamientos() {
    const fraccionamiento = localStorage.getItem("frac");
    if (!fraccionamiento) {
      return;
    }
    let data = { Idsub: fraccionamiento };
    const response = await request.post(
      "/admin/administracion/get/subfraccionamientos",
      data
    );
    if (response.subfraccionamiento) {
      this.setState({ subfraccionamientos: response.subfraccionamiento });
    } else {
      this.setState({ subfraccionamientos: [] });
    }
  }
}

export default Consumer(BudgetExpenses);