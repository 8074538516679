import React, { Component } from "react";
import { Consumer } from "../context";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import IncidentsHistoryTable from "../components/incidents/incidentsHistoryTable";
import SidebarOperacion from "../components/header/sidebarOperacion";
import moment from "moment";
import Request from "../core/httpClient";
import Controls from "../components/controls/controls";
import Paginador from "../components/paginador/paginador";
import Permissions from "../middlewares/Permissions";
import sortableData from "../helpers/sortableDataTable";

const request = new Request();

class IncidentsHistory extends Component {
    constructor(props) {
        const today = new Date();
        const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
        super(props);
        this.getIncidencias = this.getIncidencias.bind(this);
        this.state = {
            incidentes: [],
            filterData: [],
            keys: [],
            page: 1,
            filter: "",
            subfraccionamiento: localStorage.getItem("subfrac"),
            pageLength: 20,
            direction: {},
            filterColumn: {},
            submenu: "",
            startDate: lastMonth.toISOString().split("T")[0],
            endDate: today.toISOString().split("T")[0],
        };
    }

    async componentDidMount() {
        await this.validarPermisos();
        await this.getSubmenu();
        await this.getIncidencias();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.state.subfraccionamiento !== prevState.subfraccionamiento ||
            this.state.startDate !== prevState.startDate ||
            this.state.endDate !== prevState.endDate
        ) {
            this.getIncidencias();
        }
    }

    async getSubmenu() {
        const submenu = await this.props.context.getSubmenuOpeName();
        if (submenu) {
            this.setState({ submenu });
        }
    }

    async getIncidencias() {
        try {
            this.setState({
                incidentes: [],
                filterData: [],
                keys: [],
                loadingIncidencias: true
            });

            const data = {
                Fecha: moment(new Date()).format("YYYY-MM-DD"),
                IdFraccionamiento: localStorage.getItem("frac"),
                idSub: this.state.subfraccionamiento || "0",
                desde: this.state.startDate,
                hasta: this.state.endDate,
            };
            // console.log('this is incidents data', data);
            const response = await request.post("/incidencias/get/total", data);
            // console.log('this is incidents response', response);
            if (response && !response.error) {
                if (response.incidenciasTotales && !response.empty) {
                    this.setState({
                        incidentes: response.incidenciasTotales,
                        filterData: response.incidenciasTotales,
                        keys: Object.keys(response.incidenciasTotales[0]).filter(key => key.indexOf("id") === -1),
                        loadingIncidencias: false,
                    });
                } else {
                    this.setState({
                        empty: true,
                        loadingIncidencias: false,
                    });
                }
            } else {
                this.setState({
                    error: true,
                    loadingIncidencias: false,
                });
            }
            this.setState({ loadingIncidencias: false });
        } catch (error) {
            console.log(error);
        }
    }

    handleOpen(idQueja) {
        this.setState({ showModal: true, Rdescription: "" });
        this.getIncidenciaDetalle(idQueja);
    }

    // filtro por resolucion
    async handleChange() {
        try {
            let idR = parseInt(document.getElementById("resolucion").value);
            this.setState({ incidentes: [] });

            let observable = JSON.parse(localStorage.getItem("object"));
            if (!observable) {
                return;
            }

            const data = { resolution: idR, idFraccionamiento: observable.if };

            const response = await request.post("/incidencias/filtro/resolucion", data);

            if (response && !response.error) {
                if (response.incidencias && !response.empty) {
                    this.setState({
                        incidentes: response.incidencias,
                        filterData: response.incidencias,
                        keys: Object.keys(response.incidencias[0]).filter(key => key.indexOf("id") === -1),
                    });
                } else {
                    this.setState({ empty: true });
                }
            } else {
                this.setState({ error: true });
            }
        } catch (error) {
            console.log(error);
        }
    }

    async validarPermisos() {
        try {
            let permisos = new Permissions();

            let permisosInfo = await permisos.getResult();
            this.setState({ escritura: permisosInfo.escritura });
        } catch (error) {
            console.log(error);
        }
    }

    /*funcion del paginador*/
    setPage = page => this.setState({ page });

    async paginatorChange(length) {
        this.setState({ pageLength: length });
        this.setPage(1);
    }

    /* funcion para el filtro generico */
    filterGeneric(e) {
        e.preventDefault();

        const form = e.target;

        const param1 = form.filterIncidents.value;
        const param2 = form.textFilter.value;

        let filterData = this.state.filterData;

        if (param1 === "todo") {
            this.setState({ incidentes: this.state.filterData });
            form.textFilter.value = "";
        } else {
            const key = param1;
            filterData = filterData.filter(product => product[key].toLowerCase().indexOf(param2.toLowerCase()) !== -1);
            this.setState({ incidentes: filterData });
        }
    }

    filtrar = idR => this.handleChange(idR);

    subChange = sub => this.setState({ subfraccionamiento: sub });

    nuevaReporte = () => this.setState({ showModal: true });

    setFilter = filter => this.setState({ filter: filter.toLowerCase() });

    filter(incidentes) {
        incidentes = incidentes.filter(inc => {
            if (inc.descripcion.toLowerCase().indexOf(this.state.filter) !== -1) {
                return true;
            } else if (inc.nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
                return true;
            } else if (inc.quejaDesripcion.toLowerCase().indexOf(this.state.filter) !== -1) {
                return true;
            } else if (inc.numero_registro) {
                if (inc.numero_registro.toLowerCase().indexOf(this.state.filter) !== -1) {
                    return true;
                }
            } else {
                return false;
            }
        });
        return incidentes;
    }

    sortableTable(key) {
        let sd = new sortableData();
        const { items, direction } = sd.sortBy(key, this.state.incidentes, this.state.direction[key]);

        this.setState({
            incidentes: items,
            direction: {
                [key]: direction,
            },
            filterColumn: {
                col: key,
                filt: direction,
            },
        });
    }

    handleStartDateChange(event) {
        this.setState({ startDate: event.target.value });
    }

    handleEndDateChange(event) {
        this.setState({ endDate: event.target.value });
    }

    render() {
        let incidentes = [];

        if (Array.isArray(this.state.incidentes)) {
            incidentes = this.state.incidentes;
            incidentes = this.filter(incidentes);
        }

        return (
            <div className="admin column">
                <Helmet>
                    <title>
                        {process.env.REACT_APP_NAME} - {this.state.submenu}
                    </title>
                </Helmet>
                <Header
                    reload={this.getIncidencias}
                    change={this.getIncidencias}
                    sidebar={true}
                    active={"historialp"}
                    nav="operacion"
                />
                <div className="row">
                    <div className="column" id="sidebar">
                        <SidebarOperacion />
                    </div>
                    <div className="column" id="content">
                        <div className="navigation justify-center">
                            <div className="container column">
                                <Controls
                                    title={this.state.submenu}
                                    nuevo={this.nuevaReporte.bind(this)}
                                    dataSet={incidentes}
                                    dateFormat="dd/mm/yyyy"
                                    newButton={false}
                                    setFilter={this.setFilter.bind(this)}
                                    subChange={this.subChange.bind(this)}
                                />
                                <div
                                    id="second_controls"
                                    className="row-responsive full"
                                    style={{ backgroundColor: "white", marginBottom: "16px" }}
                                >
                                    <div className="data-from column full">
                                        <h5 className="color-black">DESDE:</h5>
                                        <input
                                            type="date"
                                            id="startdate"
                                            name="startdate"
                                            className="input input-text"
                                            placeholder="Desde"
                                            value={this.state.startDate}
                                            onChange={this.handleStartDateChange.bind(this)}
                                        />
                                    </div>
                                    <div style={{ width: "16px" }}></div>
                                    <div className="data-to column full">
                                        <h5 className="color-black">HASTA:</h5>
                                        <input
                                            type="date"
                                            id="enddate"
                                            name="enddate"
                                            className="input input-text"
                                            placeholder="Hasta"
                                            value={this.state.endDate}
                                            onChange={this.handleEndDateChange.bind(this)}
                                        />
                                    </div>
                                </div>
                                {this.state.loadingIncidencias ? (
                                    <div className="row justify-center">
                                        <i className="fas fa-spinner fa-spin"></i>
                                    </div>
                                ) : (
                                    <IncidentsHistoryTable
                                        incidentes={incidentes}
                                        page={this.state.page}
                                        filtrar={this.filtrar.bind(this)}
                                        pageLength={this.state.pageLength}
                                        sortBy={this.sortableTable.bind(this)}
                                        filterColumn={this.state.filterColumn}
                                    />
                                )}
                                <div className="white-space-16"></div>
                                <Paginador
                                    pages={Math.ceil(incidentes.length / this.state.pageLength)}
                                    setPage={this.setPage.bind(this)}
                                    pageLength={this.state.pageLength}
                                    paginatorChange={this.paginatorChange.bind(this)}
                                    showPageLenght={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Consumer(IncidentsHistory);
