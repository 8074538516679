import React, { Component } from 'react';
import ConceptModalEddit from './conceptModalEddit';
import ModalBorrar from '../modals/modalDelete'
import Request from "../../core/httpClient";
import cogoToast from 'cogo-toast';
import { TreeState, TreeTable } from 'cp-react-tree-table';

const request = new Request();
const rowStyles = ["rgba(161,94,189,0.5)", "rgba(161,94,189,0.2)", "rgba(161,94,189,0.07)"]

class BudgetConceptTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id_concepto: '',
      concepto: '',
      treeValue: TreeState.create(props.treeValue[1]),
      tree_conceptos: props.treeValue[0]
    }
  }

  componentDidMount() {
    this.handleOnExpandAll();
  }

  recargar() {
    this.props.reload()
  }
  delete = id => {

    this.handleDelete(id)
  }

  openModalDelete() {
    let modal = document.getElementById("deleteModal");
    if (modal) {
      modal.classList.remove('hide-modal');
      modal.classList.add('show-modal');
    }
  }

  render() {
    let conceptos = [];
    if (this.props.conceptos) {
      conceptos = this.props.conceptos
    }
    const { treeValue } = this.state;
    return (
      <div className="card-table card-table-budget card-owners column" id="card-owners">
        {/*  <div className="table-responsiv column">
                    <table>
                        <thead className="sticky-th">
                            <tr className="text-left">
                                <th className="">
                                    <div className="row">
                                        Concepto &nbsp;
										<div className="align-center">
                                            {this.props.filterColumn.col === 'descripcion' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'descripcion')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'descripcion')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'descripcion')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                <th className="date text-center th-options-small">Editar</th>
                                <th className="date text-center th-options-small">Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                        {conceptos.slice(((this.props.page - 1) * this.props.pageLength),(this.props.page * this.props.pageLength)).map((concepto, key) => { 
                         return (  
                           
                                <tr className={this.state.id_concepto_row === concepto.id_concepto ? 'row-active' : ''} key={key}>
                                    <td>{concepto.descripcion}</td>
                                    <td className="justify-arround text-center"> 
										<button className="btn-full justify-center btn-mini btn-secondary color-white" type="button justify-center" onClick={this.openModal.bind(this,"editBudget",concepto)}>
											<i className="font-small fas fa-pen" />
										</button>
                                    </td>
                                    <td className="text-center">
                                        <button className="btn-full justify-center align-center btn-mini btn-secondary color-white" 
                                                type        =   'button' 
                                                onClick     =   {this.openModalDelete.bind(this, concepto.id_concepto)} 
                                                style       =   {{ padding: '5px' }}
                                                disabled    =   {!this.props.escritura} 
                                        >
											<i className="font-text fas fa-trash-alt " />
										</button>
                                    </td>
                                </tr>
                        )})}
                        </tbody>
                    </table>
                    <ConceptModalEddit 
                        datos       =   {this.state.concepto} 
                        reload      =   {this.recargar.bind(this)} 
                        escritura   =   {this.props.escritura}
                    />
					<ModalBorrar id={this.state.id_concepto} delete={this.delete.bind(this)} />
                </div> */}


        <TreeTable
          value={treeValue}
          onChange={this.handleOnChange}>
          <TreeTable.Column basis="180px" grow="0"
            renderCell={this.renderIndexCell}
            renderHeaderCell={() => <span style={{ fontWeight: "bold" }}>Nombre</span>} />
          <TreeTable.Column
            renderCell={this.renderCuentaContableCell}
            renderHeaderCell={() => <span className='center_row' style={{ fontWeight: "bold" }}>Cuenta contable</span>} />
          <TreeTable.Column
            renderCell={this.renderPresupuestoCell}
            renderHeaderCell={() => <span style={{ fontWeight: "bold" }}>Mostrar en la App</span>} />
          <TreeTable.Column
            renderCell={this.renderActionsCell}
            renderHeaderCell={() => <span className='center_row' style={{ fontWeight: "bold" }}>Acciones</span>} />
        </TreeTable>

        <ConceptModalEddit
          datos={this.state.concepto}
          reload={this.recargar.bind(this)}
          escritura={this.props.escritura}
          tree_conceptos={this.state.tree_conceptos}
        />
        <ModalBorrar id={this.state.id_concepto} delete={this.delete.bind(this)} />

      </div>

    );
  }

  openModalDelete(id) {
    this.setState({ id_concepto: id, id_concepto_row: id });
    let modal = document.getElementById("deleteModal");
    if (modal) {
      modal.classList.remove('hide-modal');
      modal.classList.add('show-modal');
    }
  }

  openModal(idModal, concept) {
    this.setState({ mesagge: null, concepto: concept, id_concepto_row: concept.id_concepto });
    const modal = document.getElementById(idModal);
    if (modal) {
      modal.classList.remove('hide-dinamic-modal');
      modal.classList.add('show-dinamic-modal');
    }

    // document.getElementById('conceptName').focus();
  }

  async handleDelete(id) {
    let data = {

      id_concepto: id
    }
    // const response = await request.post("/presupuestos/presupuesto/delete/concept", data);
    const response = await request.post("/conceptos/concepto/delete", data);


    if (response && !response.error) {
      if (response.deleted && !response.empty) {
        this.props.reload()

        this.setState({
          showModal: false
        });
        cogoToast.success('Concepto eliminado.', {
          position: 'bottom-right',
        });
      } else {
        this.setState({
          empty: true,
          //message: response.message
        });
        cogoToast.error('No se pudo eliminar concepto.', {
          position: 'bottom-right',
        });
      }
    } else {
      this.setState({
        error: true,
        //message: response.message
      });
      cogoToast.error('No se pudo eliminar concepto.', {
        position: 'bottom-right',
      });
    }
  }

  handleOnChange = (newValue) => {
    this.setState({ treeValue: newValue });
  }

  handleOnExpandAll = () => {
    this.setState((state) => {
      return {
        treeValue: TreeState.expandAll(state.treeValue),
      };
    });
  }
  handleOnCollapseAll = () => {
    this.setState((state) => {
      return {
        treeValue: TreeState.collapseAll(state.treeValue)
      };
    });
  }

  //renders de la tabla
  renderIndexCell = (row) => {
    return (
      <div style={{ paddingLeft: 5 + (row.metadata.depth * 15) + 'px', background: rowStyles[row.metadata.depth], position: 'absolute', width: '100%' }}>
        <button className={`toggle-button ${row.$state.isExpanded ? 'expanded' : ''}`}
          onClick={row.toggleChildren}
          disabled={!row.metadata.hasChildren}>
          {row.data[0].nombre}
        </button>
      </div>
    );
  }

  renderCuentaContableCell = (row) => {
    return (
      <div className='center_row ' style={{ background: rowStyles[row.metadata.depth], position: 'absolute', width: '100%' }}>
        <td className="justify-arround text-center ">
          {row.data[0].cuenta_contable || "-"}
        </td>
      </div>
    );
  }

  renderPresupuestoCell = (row) => {
    return (
      <div className='center_row ' style={{ background: rowStyles[row.metadata.depth], position: 'absolute', width: '100%' }}>
        <td className="justify-arround text-center ">
          <span>{row.data[0].presupuesto === 1 ? <i className="fas fa-check " /> : <i className="fas fa-times" />}</span>
        </td>
      </div>
    );
  }

  renderActionsCell = (row) => {

    return (

      <div className='center_row' style={{ background: rowStyles[row.metadata.depth], position: 'absolute', width: '100%' }}>
        <td className="justify-arround text-center ">
          <button className="btn-full justify-center btn-mini btn-secondary color-white" type="button justify-center"
            onClick={this.on_showEditDialog.bind(this, row)} >
            <i className="font-small fas fa-pen" />
          </button>
        </td>
        <td className="text-center">
          <button className="btn-full justify-center align-center btn-mini btn-secondary color-white"
            type='button'
            onClick={this.on_showDeleteDialog.bind(this, row)}
            style={{ padding: '5px' }}
            disabled={!this.props.escritura}
          >
            <i className="font-text fas fa-trash-alt " />
          </button>
        </td>
      </div>


    );
  }


  //funciones adicionales
  on_showDeleteDialog(row) {
    if (row.metadata.hasChildren === false) {
      this.openModalDelete(row.data[0].id_concepto);
    }
    else {
      cogoToast.error('No se puede eliminar este concepto. Tiene dependencias ', {
        position: 'bottom-right',
      });
    }

  }

  on_showEditDialog(row) {
    let concepto = {
      id_concepto: row.data[0].id_concepto,
      id_parent: row.data[0].id_parent,
      nombre: row.data[0].nombre,
      presupuesto: row.data[0].presupuesto,
      cuenta_contable: row.data[0].cuenta_contable
    };
    this.openModal("editBudget", concepto);
  }

}

export default BudgetConceptTable;