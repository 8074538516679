import React, { Component } from 'react';
import ProfilesModal from './profilesModalEddit'
import ModalBorrar from '../modals/modalDelete'
import ProfilesModalDetail from './profilesDetail';
import ProfileDetailTable from './profileDetailTable';
import Request from "../../core/httpClient";
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import cogoToast from 'cogo-toast';
const request = new Request();

class ProfilesTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id_perfil: 0,
            enombre: ''
		}
    }

    componentDidMount(){
       
    }

    reload() { this.props.reload() }
    delete = id => { this.handleDelete(id) }

    openRow(idPerfil) {
        if (this.state.perfilId === idPerfil) {
            this.setState({ perfilId: 0 });
            return;
        }
        this.setState({ perfilId: idPerfil, id_perfil_row: idPerfil });
    }

    render() {
        let perfiles = []

        if (this.props.perfiles) {
            perfiles = this.props.perfiles
        }
        //console.log(perfiles);

        return (
            <div className="card-table card-owners column" id="card-owners">
                {/*<h4>Usuarios del fraccionamiento</h4>*/}
                    <table className="full">
                        <thead className="sticky-th">
                            <tr className="text-left">
                                <th className="nombre">
                                    <div className="row">
                                        Perfil &nbsp;
										<div className="align-center">
                                            {this.props.filterColumn.col === 'nombre' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                <th className="text-center th-options-small">Permisos</th>
                                <th className="text-center th-options-small">Editar</th>
                                <th className="text-center th-options-small">Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>

                            {perfiles.map((perfil, key) => (
                                <React.Fragment key={perfil.id_perfil}>
                                    <tr className={this.state.id_perfil_row === perfil.id_perfil ? 'row-active' : ''}>
                                        <td className='text-left'>{perfil.nombre}</td>
                                        <td className="text-center">
                                            <button type="button justify-center" 
                                                    onClick={this.openRow.bind(this, perfil.id_perfil)} /*onClick={this.handleOpen.bind(this)}*/
                                                    disabled = {!this.props.escritura}        
                                            > 
                                                <i className="font-small fas fa-user-lock"></i>
                                            </button>
                                            {/* <ProfilesModalDetail id={perfil.id_perfil} /> */}
                                        </td>
                                        <td className="text-center">
                                        <button className="btn-full justify-center btn-mini btn-secondary color-white" type="button" 
                                                onClick={this.openModal.bind(this,"editProfiles",perfil)}
                                                disabled = {!this.props.escritura}     
                                        >
                                            <i className="font-small fas fa-pen" />
                                        </button>

				
                                        </td>
                                        <td className="text-center">
                                            <button className="btn-full justify-center align-center btn-mini btn-secondary color-white" type='button' 
                                                onClick={this.openModalDelete.bind(this, perfil.id_perfil)} style={{ padding: '5px' }} 
                                                disabled = {!this.props.escritura}     
                                        >

                                                <i className="font-text fas fa-trash-alt " />
                                            </button>
                                        </td>
                                    </tr>
                                    {this.state.perfilId === perfil.id_perfil && 
                                        <tr className="acordeon-row">
                                            <td className="acordeon" colSpan="6">
                                                <div className="row full">
                                                    <ProfileDetailTable 
                                                        reload		=	{this.reload.bind(this)}
                                                        id  =   {this.state.perfilId} 
                                                    />
                                                    {/*<ProfilesModalDetail id={this.state.perfilId} />*/}
                                                </div>
                                            </td>
                                        </tr>}
                                </React.Fragment>
                            ))}

                        </tbody>
                    </table>

                <DinamicModal 
					idModal             =   {'editProfiles'}
					sizeModalContainer  =   {'small'}
					title               =   {'Editar perfil'} 
					success             =   {this.handleSubmit.bind(this)} 
					showBtnSuccess      =   {true}
					btnTextSuccess      =   {"ACTUALIZAR"}
					showBtnCancel       =   {true}
					btnTextCancel       =   {"CANCELAR"}
					loadingSave         =   {this.state.loadingSave}
					message             =   {this.state.message}
				>
				{this.renderEditProfiles()}
				</DinamicModal>
                <ModalBorrar 
                        id          =   {this.state.id_perfil} 
                        delete      =   {this.delete.bind(this)} 
                />
            </div>
        );
	}
	openModalDelete(id) {
        
        this.setState({ id_perfil: id, id_perfil_row: id});
        
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
    }
    async  openModal(idModal,perfil) {
        //console.log(perfil);
        await this.setState({ mesagge: null, enombre: perfil.nombre, id_perfil: perfil.id_perfil, id_perfil_row: perfil.id_perfil, perfilId: 0});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
        }
        document.getElementById('conname').focus();
    }
    closeModal(idModal) {
		this.setState({mesagge: null});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
	}

    async handleDelete(id) {

        let data = { idperfil: id }
        const response = await request.post("/profiles/delete/profile", data)


        if (response && !response.error) {
            if (response.deleted && !response.empty) {

                this.setState({
                    showModal: false
                });
                cogoToast.success('Perfil eliminado.', {
                    position: 'bottom-right',
                });
                
            } else {
                this.setState({
                    empty: true,
                    //message: response.message
                });
                cogoToast.error('No se pudo eliminar el perfil.', {
                    position: 'bottom-right',
                });
            }
        } else {
            this.setState({
                error: true,
                //message: response.message
            });
            cogoToast.error('No se pudo eliminar el perfil.', {
                position: 'bottom-right',
            });

        }
        this.reload()
    }
    async handleSubmit(event) {
        event.preventDefault();
        //console.log("click");


        let data = { perfil: this.state.enombre, idperfil: this.state.id_perfil }

        const response = await request.post("/profiles/eddit/profile", data);
        //console.log(response);

        
        if (response.eddited) {
            this.setState({
                showModal: false
            });
            this.closeModal("editProfiles");
            this.props.reload();
            cogoToast.success('Perfil actualizado.', {
                position: 'bottom-right',
            });
        } else {
            this.setState({
                empty: true,
                //message: "NO SE PUDO EDITAR PERFIL"
            });
            cogoToast.error('No se pudo eliminar perfil.', {
                position: 'bottom-right',
            });
        }
        this.setState({loadingSave: false});
    }


    renderEditProfiles() {
		return (
			<div className="full row align-center">
			<div className="column full">
				<div className="input-form-content row full align-center ">
					<div className="column label-normal align-start">
						<p><b>  Perfil:	</b></p>
					</div>
					<div className="column full">
                        
                        <input 
                            className   =   "input input-modals" 
                            type        =   "text" 
                            name        =   "conceptName" 
                            id          =   'conname'
                            value       =   {this.state.enombre} 
                            onChange    =   {event => this.setState({ enombre: event.target.value })} 
                            required 
                            autoComplete = 'off'
                            />
					</div>
				</div>
			</div>
		</div>
  
		)
	}




}

export default ProfilesTable;