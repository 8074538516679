import React, { Component } from 'react';

class Catalogos extends Component {
    constructor(props) {
        super(props);
        this.state = {
			menus: this.props.menus
        }
	}

	componentDidUpdate(prevProps) {
		if (this.props.menus !== prevProps.menus && Array.isArray(this.props.menus)) {
			this.setState({menus: this.props.menus});
		}
	}

	checkMenu(menu, option) {
		const { menus } = this.state;
		if (Array.isArray(menus)) {
			menus.map(menuArray => {
				if (menu.id === menuArray.id) {
					menuArray[option] = !menuArray[option];
				}
				return menuArray;
			});
			this.setState({menus});
		}
	}

	render() {
		const menus = Array.isArray(this.state.menus) ? this.state.menus : [];
		return (
			menus.map(menu => {
				return (
					<tr key={menu.id}>
						<td>
							{menu.parent > 0 ?
								<p className="" name='menuName' >
									{menu.nombre}
								</p>
								:
								<h4 className="weight-bold" name='menuName' >
									{menu.nombre}
								</h4>
							}
						</td>
						<td>
							<div className="row justify-end">
								{menu.parent > 0 &&
									<label className="container-check">
										<input type="checkbox" hidden name="lectura" id="lectura" checked={true} onChange={this.checkMenu.bind(this, menu, 'lectura')} checked={menu.lectura ? (true) : (false)} />
										<span className="checkmark"></span>
									</label>
								}
							</div>
						</td>
						<td>
							<div className="row justify-end">
								{menu.parent > 0 &&
									<label className="container-check">
										<input type="checkbox" hidden name="escritura" id="escritura" checked={true} onChange={this.checkMenu.bind(this, menu, 'escritura')} checked={menu.escritura ? (true) : (false)} />
										<span className="checkmark"></span>
									</label>
								}
							</div>
						</td>
					</tr>
				)
			})
		)
	}
}

export default Catalogos;