import React, { Component } from 'react';
import { Consumer } from '../context';
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import IncidentsTable from '../components/incidents/incidents-table';
import SidebarOperacion from "../components/header/sidebarOperacion";
import moment from "moment";
import Request from "../core/httpClient";
import Controls from '../components/controls/controls';
import Paginador from "../components/paginador/paginador";
import Permissions from '../middlewares/Permissions';
import sortableData from '../helpers/sortableDataTable';

const request = new Request();

class Incidents extends Component {
    constructor(props) {
        super(props);
        this.getSubmenu = this.getSubmenu.bind(this);
        this.getIncidencias = this.getIncidencias.bind(this);
        this.validarPermisos = this.validarPermisos.bind(this);
        this.filtrar = this.filtrar.bind(this);
        this.state = {
            user: {},
            incidentes: [],
            incidentsHoy: false,
            filterData: [],
            keys: [],
            page: 1,
            filter: '',
            subfraccionamiento: localStorage.getItem('subfrac'),
            escritura: false,
            lectura: false,
            logo: require("../img/logo-AppMosphera-r.png"),
            pageLength: 20,
            direction: {},
            filterColumn: {},
            submenu: ''
        };
    };

    async componentDidMount() {
        await this.validarPermisos();
        await this.getSubmenu();
        await this.getIncidencias();
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.subfraccionamiento !== prevState.subfraccionamiento) {
            this.getIncidencias();
        };
    };

    async validarPermisos() {
        try {
            const permisos = new Permissions();
            const permisosInfo = await permisos.getResult();
            this.setState({
                escritura: permisosInfo.escritura,
                lectura: permisosInfo.lectura
            });
        } catch (error) {
            console.log(error);
        };
    };

    async getSubmenu() {
        try {
            const submenu = await this.props.context.getSubmenuOpeName();
            if (submenu) {
                this.setState({ submenu: submenu });
            };
        } catch (error) {
            console.log(error);
        };
    };

    // TRAER INCIDENCIAS HOY//
    async getIncidencias() {
        try {
            this.setState({ loadingIncidencias: true });

            const data = {
                Fecha: moment(new Date()).format("YYYY-MM-DD"),
                IdFraccionamiento: localStorage.getItem('frac'),
                idSub: this.state.subfraccionamiento || 0
            };

            const response = await request.post("/incidencias/get/today", data);
            
            if (response && !response.error) {
                if (response.incidenciasDIa && !response.empty) {
                    this.setState({
                        incidentes: response.incidenciasDIa,
                        filterData: response.incidenciasDIa,
                        keys: Object.keys(response.incidenciasDIa[0]).filter(key => key.indexOf('id') === -1),
                        incidentsHoy: false
                    });
                } else {
                    this.setState({
                        empty: true,
                        incidentsHoy: true
                    });
                }
            } else {
                this.setState({
                    error: true,
                    loadingIncidencias: false
                });
            }
            this.setState({ loadingIncidencias: false });
        } catch (error) {
            console.log(error);
        };
    };

    // filtro por resolucion
    async handleChange() {

        let idR = parseInt(document.getElementById('resolucion').value);
        this.setState({ incidentes: [] })
        let observable = JSON.parse(localStorage.getItem('object'))
        if (!observable) {
            return
        };

        if (idR === 4) {
            this.getIncidencias()
            return
        };

        const data = {
            resolution: idR,
            idFraccionamiento: observable.if
        };

        const response = await request.post("/incidencias/filtro/resolucion", data)

        if (response && !response.error) {
            if (response.incidencias && !response.empty) {
                this.setState({
                    incidentes: response.incidencias,
                    filterData: response.incidencias,
                    keys: Object.keys(response.incidencias[0]).filter(key => key.indexOf('id') === -1)
                });

            } else {
                this.setState({
                    empty: true,
                    message: response.message
                });
            };
        } else {
            this.setState({
                error: true,
                message: response.message
            });
        };
    };

    /*funcion del paginador*/
    setPage(page) {
        this.setState({ page });
    };

    async paginatorChange(length) {
        await this.setState({ pageLength: length });
        this.setPage(1);
    };

    /* funcion para el filtro generico */
    filterGeneric(e) {
        e.preventDefault();

        const form = e.target;

        const param1 = form.filterIncidents.value;
        const param2 = form.textFilter.value;

        let filterData = this.state.filterData;

        if (param1 === "todo") {
            this.setState({ incidentes: this.state.filterData });
            form.textFilter.value = "";
        }
        else {
            const key = param1;
            filterData = filterData.filter(product => product[key].toLowerCase().indexOf(param2.toLowerCase()) !== -1);
            this.setState({ incidentes: filterData });
        }

    };

    filtrar = idR => this.handleChange(idR);

    subChange(sub) {
        this.setState({ subfraccionamiento: sub });
    };

    nuevaReporte() {
        this.setState({ showModal: true });
    };

    setFilter(filter) {
        this.setState({ filter: filter.toLowerCase() });
    };

    filter(incidentes) {
        incidentes = incidentes.filter(inc => {

            if (inc.descripcion.toLowerCase().indexOf(this.state.filter) !== -1) {
                return true;
            } else if (inc.nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
                return true;
            } else if (inc.quejaDesripcion.toLowerCase().indexOf(this.state.filter) !== -1) {
                return true;
            } else if (inc.numero_registro) {
                if (inc.numero_registro.toLowerCase().indexOf(this.state.filter) !== -1) {
                    return true;
                }
            } else {
                return false;
            }
        });
        return incidentes;
    };

    sortableTable(key) {
        let sd = new sortableData();
        const { items, direction } = sd.sortBy(key, this.state.incidentes, this.state.direction[key]);

        this.setState({
            incidentes: items,
            direction: {
                [key]: direction
            },
            filterColumn: {
                col: key,
                filt: direction
            }
        });
    };

    render() {
        let incidentes = [];

        if (Array.isArray(this.state.incidentes)) {
            incidentes = this.state.incidentes;
            incidentes = this.filter(incidentes);
        };

        return (
            <div className="admin column">
                <Helmet>
                    <title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
                </Helmet>
                <Header
                    reload={this.getIncidencias}
                    change={this.getIncidencias}
                    sidebar={true}
                    active={'pendientes'}
                    nav="operacion"
                />
                <div className="row">
                    <div className="column" id="sidebar">
                        <SidebarOperacion />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">
                                {this.state.lectura &&
                                    <React.Fragment>
                                        <Controls
                                            title={this.state.submenu}
                                            nuevo={this.nuevaReporte.bind(this)}
                                            dataSet={incidentes}
                                            dateFormat='dd/mm/yyyy'
                                            newButton={false}
                                            setFilter={this.setFilter.bind(this)}
                                            subChange={this.subChange.bind(this)}
                                        />
                                        {this.state.loadingIncidencias ?
                                            <div className="row justify-center">
                                                <i className="fas fa-spinner fa-spin"></i>
                                            </div>
                                            :
                                            <IncidentsTable
                                                incidentes={incidentes}
                                                page={this.state.page}
                                                estado={this.state.incidentsHoy}
                                                reload={this.getIncidencias}
                                                filtrar={this.filtrar}
                                                escritura={this.state.escritura}
                                                pageLength={this.state.pageLength}
                                                sortBy={this.sortableTable.bind(this)}
                                                filterColumn={this.state.filterColumn}
                                            />
                                        }
                                        <div className="white-space-16"></div>
                                        <Paginador
                                            pages={Math.ceil(incidentes.length / this.state.pageLength)}
                                            setPage={this.setPage.bind(this)}
                                            pageLength={this.state.pageLength}
                                            paginatorChange={this.paginatorChange.bind(this)}
                                            showPageLenght={true}
                                        />

                                    </React.Fragment>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

export default Consumer(Incidents);