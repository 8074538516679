import React, { Component } from 'react';
import ReactModal from "react-modal";
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import moment from 'moment-timezone';
import Request from "../../core/httpClient";
import { AsYouType } from 'libphonenumber-js';

const request = new Request();
ReactModal.setAppElement("#root");

class IncidentsHistoryTable extends Component {
	constructor(props) {
		super(props);
		this.openModal = this.openModal.bind(this);
		this.state = {
			showModal: false,
			incidentes: [],
			incidenteDetalle: [],
			resolucion: 0,
			idQueja: 0,
			Rdescription: "",
			filtroValor: ''
		};
	};

	async openModal(idModal, idHistory) {
		this.setState({ Rdescription: '', id_queja_row: idHistory });
		await this.getIncidenciaDetalle(idHistory)
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		};
	};

	closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		};
	};

	async handleOpen(id) {
		await this.getIncidenciaDetalle(id);
	};

	handleClose() {
		this.setState({
			showModal: false,
			incidentes: [],
			incidenteDetalle: [],
			resolucion: 0,
			idQueja: 0,
			Rdescription: "",
			filtroValor: ''
		});
	};

	async getIncidenciaDetalle(idQueja) {
		try {
			const data = { idQueja: idQueja };
			const response = await request.post("/incidencias/get/detalle", data);

			if (response && !response.error) {
				if (response.incidenciaDetalle && !response.empty) {
					this.setState({
						showModal: true,
						incidenteDetalle: response.incidenciaDetalle,
						resolucion: response.incidenciaDetalle.resolucion,
						idQueja: response.incidenciaDetalle.id_queja,
						Rdescription: response.incidenciaDetalle.resolucion_descripcion
					});

				} else {
					this.setState({
						empty: true,
						message: response.message
					});
				}
			} else {
				this.setState({
					error: true,
					message: response.message
				});
			};
		} catch (error) {
			console.log(error);
		};
	};

	getNumberFormat(cell_phone) {
		try {
			if (cell_phone.includes('+'))
				return new AsYouType('MXN').input(cell_phone);

			return new AsYouType('MXN').input('+' + cell_phone);

		} catch (error) {
			return null;
		};
	};

	openImage() {
		this.setState({ showImage: !this.state.showImage })
	};

	render() {
		let incidentes = [];

		if (this.props.incidentes) {
			incidentes = this.props.incidentes;
		};

		return (
			<div className="column">
				<div className="card-table column" id="card-owners">

					<div className="table-responsiv">

						<table className="card-incidents full">
							<thead className="sticky-th">
								<tr className="text-left">
									<th className="type">
										<div className="row">
											Tipo &nbsp;
											<div className="align-center">
												{this.props.filterColumn.col === 'quejaDesripcion' ? this.props.filterColumn.filt === 'asc' ?
													<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'quejaDesripcion')}></i>
													:
													<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'quejaDesripcion')}></i>
													:
													<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'quejaDesripcion')}></i>
												}
											</div>
										</div>
									</th>
									<th className="name">
										<div className="row">
											Vivienda &nbsp;
											<div className="align-center">
												{this.props.filterColumn.col === 'numero_registro' ? this.props.filterColumn.filt === 'asc' ?
													<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
													:
													<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
													:
													<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
												}
											</div>
										</div>
									</th>
									<th className="date">
										<div className="row">
											Fecha &nbsp;
											<div className="align-center">
												{this.props.filterColumn.col === 'fecha' ? this.props.filterColumn.filt === 'asc' ?
													<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'fecha')}></i>
													:
													<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'fecha')}></i>
													:
													<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'fecha')}></i>
												}
											</div>
										</div>
									</th>
									<th className="description">
										<div className="row">
											Descripción &nbsp;
											<div className="align-center">
												{this.props.filterColumn.col === 'descripcion' ? this.props.filterColumn.filt === 'asc' ?
													<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'descripcion')}></i>
													:
													<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'descripcion')}></i>
													:
													<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'descripcion')}></i>
												}
											</div>
										</div>
									</th>
									<th className="resolution ">
										<div className="row">
											Resolución &nbsp;
											<div className="align-center">
												{this.props.filterColumn.col === 'resolucion' ? this.props.filterColumn.filt === 'asc' ?
													<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'resolucion')}></i>
													:
													<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'resolucion')}></i>
													:
													<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'resolucion')}></i>
												}
											</div>
										</div>
									</th>
									<th className="text-center th-options-small">Ver detalles</th>
								</tr>
							</thead>
							<tbody>
								{incidentes.slice(((this.props.page - 1) * this.props.pageLength), (this.props.page * this.props.pageLength)).map((incidente, key) => {
									return (

										<tr className={this.state.id_queja_row === incidente.id_queja && 'row-active tr'} key={key}>
											<td className="weight-semi">{incidente.quejaDesripcion}</td>
											<td >{incidente.numero_registro}</td>
											<td >
												{moment(incidente.fecha).format("DD/MM/YYYY")}
											</td>
											<td className="description text-justify">
												<p className="truncate-ellipsis">{incidente.descripcion}</p>
											</td>
											<td className="weight-semi">
												{incidente.resolucion === 0 ? ('Pendiente') : null || incidente.resolucion === 1 ? ('En revision') : null || incidente.resolucion === 2 ? ('Resuelto') : null}
											</td>

											<td className="text-center weight-semi">
												<button
													className="btn-full justify-center align-center font-small btn-secondary color-white"
													onClick={() => this.openModal("detailIncidentsHistory", incidente.id_queja)}
												>
													<i className="fas fa-info-circle"></i>
												</button>
											</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>

					<DinamicModal
						idModal={'detailIncidentsHistory'}
						sizeModalContainer={'big'}
						title={'Detalle de incidencia'}
						showBtnSuccess={false}
						showBtnCancel={false}
						loadingSave={this.state.loadingSave}
						message={this.state.message}
					>
						{this.renderHistoryTable()}
					</DinamicModal>
				</div>
			</div>
		);
	};

	renderHistoryTable() {
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-extra">
							<p><b>  Nombre completo:	</b></p>
						</div>
						<div className="column full">
							<input type="text" name="fullName" value={this.state.incidenteDetalle.nombre}
								onChange={event => this.setState({ pNombre: event.target.value })} className="input input-modals" placeholder="Nombre completo" required maxLength="64" disabled />
						</div>
					</div>
					<div className="white-space-16" />
					<div className="row full">
						<div className="column full">
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p><b>Tipo de incidencia:</b></p>
								</div>
								<div className="column full">
									<input type="text" name="numIdEddit" value={this.state.incidenteDetalle.quejaDesripcion}
										className="input input-modals" placeholder='Tipo' required maxLength="24" disabled />
								</div>
							</div>
							<div className="white-space-16"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p className="justify-start">
										<b>Fecha:</b>
									</p>
								</div>
								<div className="column full">
									<input type="date" name="numIdEddit" value={moment(this.state.incidenteDetalle.fecha).format("YYYY-MM-DD")}
										onChange={event => this.setState({ pNumId: event.target.value })} className="input input-modals" placeholder='Número ID' required maxLength="24" disabled />
								</div>
							</div>
							<div className="white-space-16" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p><b>  Teléfono:	</b></p>
								</div>
								<div className="column full">
									<input type="text" name="numIdEddit"
										value={this.getNumberFormat(this.state.incidenteDetalle.telefono)}
										className="input input-modals" placeholder='Número ID' required maxLength="24" disabled />
								</div>
							</div>
							<div className="white-space-16" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p className="justify-start" >
										<b>Resolución:</b>
									</p>
								</div>
								<div className="column full">
									<select
										name="incident-resolution"
										id="incident-resolution"
										className="input input-modals"
										value={this.state.resolucion}
										onChange={event => this.setState({ resolucion: event.target.value })}
										disabled
									>
										<option value="0">Sin resolver</option>
										<option value="1">En revisión</option>
										<option value="2">Resuelto</option>
									</select>
								</div>
							</div>
						</div>
						<div className="column full">
							<div className="responsive-img justify-end row">
								<div className="container-img justify-center">
									<img src={this.state.incidenteDetalle.imagen ? this.state.incidenteDetalle.imagen : require('../../img/empty_image.png')} className="img-duo-preview" id="previewAreaEdit" />
								</div>

							</div>
						</div>
					</div>
					<div className="white-space-16" />
					<div className="row full">
						<div className="column full">
							<div className="row full">
								<p><b> Descripción:	</b></p>
							</div>
							<div className="white-space-8"></div>
							<textarea
								type="text"
								name="fullName"
								value={this.state.incidenteDetalle.descripcion ? this.state.incidenteDetalle.descripcion : "N/A"}
								onChange={event => this.setState({ pNombre: event.target.value })}
								className="textarea input input-modals"
								cols="50"
								rows="3"
								maxLength="64"
								disabled
							/>
						</div>
						<div className="column full" style={{ paddingLeft: "20px" }}>
							<p>
								<b>Descripción de la resolución:</b>
							</p>
							<div className="white-space-8" />
							<textarea
								className="textarea input input-modals"
								value={this.state.Rdescription ? this.state.Rdescription : ''}
								onChange={event => this.setState({ Rdescription: event.target.value })}
								name="descriptionResolution"
								cols="50"
								rows="3"
								maxLength="600"
								minLength="20"
								id="desc"
								disabled
							/>

						</div>
					</div>

				</div>
			</div>
		)
	};
};

export default IncidentsHistoryTable;