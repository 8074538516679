import React, { Component } from "react";
import { Consumer } from "../../context";
import Paginador from "../paginador/paginador";

class DailyEvents extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loadingSave: false,
			user: {},
			eventPages: 1,
			authPages: 1,
		};
	}

	async componentDidMount() {
		await this.loadUser();
	};

	async loadUser() {
		const user = await this.props.context.loadUser();
		if (user) {
			this.setState({ user: user.id });
		};
	};

	render() {
		let dataAutorizados = [], dataEventos = [];

		if (this.props.dataAuth) {
			dataEventos = this.props.dataAuth.filter((auth) => auth.tipo === 1);
			dataAutorizados = this.props.dataAuth.filter((auth) => auth.tipo === 2);
		};

		return (
			<div className="daily-invitations row-responsive">
				<div className="card-table card-table-1 column">
					<div className="title">
						<h4>Autorizados del día</h4>
					</div>
					<div className="white-space-8" />
					{this.props.loadingEvents ? (
						<div className="row justify-center">
							<i className="fas fa-spinner fa-spin"></i>
						</div>
					) : dataAutorizados.length !== 0 ? (
						<div className="card-event-container">
							<div className="table-responsive">
								<table>
									<thead>
										<tr>
											<th className="text-left">Nombre</th>
											<th className="text-left">Vivienda</th>
										</tr>
									</thead>
									<tbody>
										{dataAutorizados.slice((this.state.authPages - 1) * 10, this.state.authPages * 10).map((auth, key) => (
											<tr key={key}>
												<td className="text-left">{auth.nombre}</td>
												<td className="text-left">{auth.unidad}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
							<Paginador pages={Math.ceil(dataAutorizados.length / 10)} setPage={(page) => this.setState({ authPages: page })} />
						</div>
					) : (
						<b>Sin Autorizados</b>
					)}
				</div>
				<div className="card-table card-table-1 column">
					<div className="title">
						<h4>Eventos del día</h4>
					</div>
					<div className="white-space-8" />
					{this.props.loadingEvents ? (
						<div className="row justify-center">
							<i className="fas fa-spinner fa-spin"></i>
						</div>
					) : dataEventos.length !== 0 ? (
						<div className="card-event-container">
							<div className="table-responsive">
								<table>
									<thead>
										<tr className="text-left">
											<th className="left">Evento</th>
											<th className="left">Nombre</th>
											<th className="left">Vivienda</th>
										</tr>
									</thead>
									<tbody>
										{dataEventos.slice((this.state.eventPages - 1) * 6, this.state.eventPages * 6).map((evento, key) => (
											<tr key={key}>
												<td className="text-left">{evento.nombre}</td>
												<td className="text-left">{evento.inquilino}</td>
												<td className="text-left">{evento.unidad}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
							<Paginador pages={Math.ceil(dataEventos.length / 6)} setPage={(page) => this.setState({ eventPages: page })} />
						</div>
					) : (
						<b>Sin eventos en el fraccionamiento</b>
					)}
				</div>
			</div>
		);
	}
}

export default Consumer(DailyEvents);
