import React, {Component} from 'react';

class DescriptorTable extends Component{
    constructor(props){
        super(props);
    }

    render() {
        return (
            <div className="card-table card-owners column" id="card-owners">
                <form className="column align-center" 
                onSubmit={event=>{this.props.updateDescriptores(event)}}
                >
                    <div className="white-space-24" />
                    <div className="container column">

                        <div className="edit-button justify-start">
                        <div className="column full align-start"> 
                            <h3>Descriptores</h3>
                        </div>
                        <div className="column full"> 
                            <div className="justify-end">
                                <button className="btn btn-primary btn-small color-white"
                                    type        ="button"
                                    id          ="btn-modal-edit"
                                    onClick     ={this.props.handleEditDescriptores.bind(this)}
                                    disabled    ={!this.props.escritura || this.props.generalLoading }>
                                    <i className="fas fa-pen"></i> Editar
                                </button>
                                
                                
                            </div>
                        </div>
                        </div>

                        <div className="white-space-24"></div>
                        <div>
                            
                        <div className="row full titles-descriptors">
                                <div className="column full"></div>
                                <div className="column justify-start align-center label-title-config">
                                    <h4>{'Prefijo'}</h4>
                                </div>
                                <div className="column justify-center align-center label-title-config">
                                    <h4>{'Número'}</h4>
                                </div>

                                <div className="column justify-center align-center label-title-config">
                                    <h4>{'Serie'}</h4>
                                </div>
                        </div>
                        <div className="white-space-8"></div>
                            {
                                this.props.loading ?
                                
                                    <div className="row justify-center full">
                                        <i className="fas fa-spinner fa-spin"></i>
                                    </div>
                                :
                                this.props.descriptores ? 
                                this.props.descriptores.map((data, key) => {
                                    return(
                                        <div className="billing-rows dinamic row full" key={key}>
                                            <div className="justify-start label-title-descriptors">
                                                <h4>{data.Nombre}:</h4>
                                            </div>
                                            <div className="justify-start align-center column options-descriptors">
                                            <input 
                                                type        =   "text"
                                                name        =   "PREFIJO"
                                                placeholder =   {"Prefijo"}
                                                className   =   {this.props.disabled ? "full input input-disabled font-regular " : "full input input-modals font-regular"}
                                                disabled    =   {this.props.disabled}
                                                value       =   {data.Prefijo}
                                                onChange    =   {(event)=>{this.props.changeDescriptor(event,   data.id_descriptor)}}
                                                required
                                            />
                                            </div>

                                           
                                            <div className="justify-start align-center column options-descriptors">
                                            <input 
                                                type        =   "number"
                                                name        =   "NUMERO"
                                                placeholder =   {"Número"}
                                                className   =   {this.props.disabled ? "full input input-disabled font-regular " : "full input input-modals font-regular"}
                                                disabled    =   {this.props.disabled}
                                                value       =   {data.Numero}
                                                onChange    =   {(event)=>{this.props.changeDescriptor(event,   data.id_descriptor)}}
                                                required
                                            />
                                            </div>

                                           
                                            <div className="justify-start align-center column options-descriptors">
                                            <input 
                                                type        =   "text"
                                                name        =   "SERIE"
                                                placeholder =   {"Serie"}
                                                className   =   {this.props.disabled ? "full input input-disabled font-regular " : "full input input-modals font-regular"}
                                                disabled    =   {this.props.disabled}
                                                value       =   {data.Serie}
                                                onChange    =   {(event)=>{this.props.changeDescriptor(event,   data.id_descriptor)}}
                                                required
                                            />
                                            </div>
                                        </div>
                                    )}
                                ) : null
                                
                            }

                        </div>

                        {this.props.disabled == false &&
                        <div className="btn-container justify-end">
                            <button type="button" className="btn-action-c color-white"  
                                onClick={this.props.handleEditDescriptores.bind(this)} 
                            >
                                CANCELAR
                            </button>
                            <button type="submit" className="btn-action-s color-white"  >
                                {this.props.loading ?
                                    <div className="row justify-center">
                                        <i className="fas fa-spinner fa-spin"></i>
                                    </div> :
                                    <div>
                                        ACTUALIZAR
                                    </div>
                                }
                            </button>
                        </div>
                        }
                    </div>
                    <div className="white-space-24" />
                    
                </form>
            </div>
        );
    }
}

export default DescriptorTable;