import React from 'react';
import SuperCore from './../../core/SuperCore';
import FooterDefault from './footer/Default';
import FooterScreens from './footer/Screens';
class Footer extends SuperCore{
    constructor(props){
        super(props);
        this.state = {
            defaultFooter: this.props.defaultFooter,
            lblSubmit:      this.props.lblSubmit,
            lblCancel:      this.props.lblCancel,
            lblBack:        this.props.lblBack,
            lblNext:        this.props.lblNext,
            id:             this.props.id,
            footer:         this.props.footer,
            nexFooter:      this.props.nexFooter,
            showSubmit:     this.props.showSubmit,
            showClose:      this.props.showClose
        }
    }

    async componentDidUpdate(prevProps, prevState){
        if(prevProps.defaultFooter != this.props.defaultFooter){
            await this.setState({defaultFooter: this.props.defaultFooter})
        }

        if(prevProps.lblSubmit != this.props.lblSubmit){
            await this.setState({lblSubmit: this.props.lblSubmit})
        }
        if(prevProps.lblCancel != this.props.lblCancel){
            await this.setState({lblCancel: this.props.lblCancel})
        }

        if(prevProps.id != this.props.id){
            await this.setState({id: this.props.id})
        }

        if(prevProps.footer != this.props.footer){
            await this.setState({footer: this.props.footer})
        }

        if(prevProps.nexFooter != this.props.nexFooter){
            await this.setState({nexFooter: this.props.nexFooter})
        }

        if(prevProps.lblBack != this.props.lblBack){
            await this.setState({lblBack: this.props.lblBack});
        }

        if(prevProps.lblNext != this.props.lblNext){
            await this.setState({lblNext: this.props.lblNext});
        }

        if(prevProps.showSubmit != this.props.showSubmit){
            await this.setState({showSubmit: this.props.showSubmit});
        }

        if(prevProps.showClose != this.props.showClose){
            await this.setState({showClose: this.props.showClose});
        }
        


    }
    render(){
        

        return(
            this.state.defaultFooter ? 
                <FooterDefault
                    id          =   {this.state.id}
                    labelSubmit =   {this.state.lblSubmit}
                    labelCancel =   {this.state.lblCancel}
					onSubmit    =   {this.props.onSubmit.bind(this)}
					loading		=	{this.props.loading}

                    triggerCloseModal   =   {this.props.triggerCloseModal.bind(this)}

                    showSubmit      ={this.state.showSubmit}
                    showClose       ={this.state.showClose}
                ></FooterDefault> 
            :
                this.state.nexFooter ? 
                    <FooterScreens
                        id_menu_activo  = {this.props.id_menu_activo}
                        labelBack       = {this.state.lblBack}
                        labelNext       = {this.state.lblNext}
                        backMenu        = {this.props.backMenu.bind(this)}
						nextMenu        = {this.props.nextMenu.bind(this)}
						loading			= {this.props.loading}
                    ></FooterScreens>
                :
                    this.props.footer ? this.props.footer: ''
        );
    }
}

export default Footer;