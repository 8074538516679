import React, { Component } from 'react';
import moment from "moment";
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import Request from "../../core/httpClient";
import cogoToast from 'cogo-toast';
const request = new Request();

class PropietarioEditarModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idPersona: 		this.props.propietario.id_persona,
            INombre: 		this.props.propietario.nombre,
            IDireccion: 	this.props.propietario.direccion,
            IMunicipio: 	this.props.propietario.municipio,
            IEstado: 		this.props.propietario.estado,
            ICp: 			this.props.propietario.cp,
            ITelefono: 		this.props.propietario.telefono,
            IPais: 			this.props.propietario.pais,
            IGenero: 		this.props.propietario.genero,
            IId: 			this.props.propietario.tipo_identificacion,
            INumId: 		this.props.propietario.num_identificacion,
            IVigencia: 		this.props.propietario.vigencia_identificacion,
            IFechaIngreso: 	moment(this.props.propietario.fecha_entrada).format("YYYY-MM-DD"),
            code: 			this.props.propietario.code,
            Iviv: 			this.props.propietario.numero_registro,
            IIdViv: 		this.props.propietario.id_vivienda,
			email: 			this.props.propietario.email,
			citye:			this.props.propietario.ciudad,
			comentarios:    this.props.propietario.comentarios,
			subLabel: 		this.props.subLabel,
			es_residente:   this.props.propietario.es_propietario
		}
		
		
	}
	
	componentDidUpdate(prevProps) {
		if(this.props.propietario !== prevProps.propietario) {
			
			this.setState({ 
				idPersona: 		this.props.propietario.id_persona,
				INombre: 		this.props.propietario.nombre,
				IDireccion: 	this.props.propietario.direccion,
				IMunicipio: 	this.props.propietario.municipio,
				IEstado: 		this.props.propietario.estado,
				ICp: 			this.props.propietario.cp,
				ITelefono: 		this.props.propietario.telefono,
				IPais: 			this.props.propietario.pais,
				IGenero: 		this.props.propietario.genero,
				IId: 			this.props.propietario.tipo_identificacion,
				INumId: 		this.props.propietario.num_identificacion,
				IVigencia: 		this.props.propietario.vigencia_identificacion,
				pFechaCompra: 	this.props.propietario.fecha_compra,
				pFechaVenta: 	this.props.propietario.fecha_venta,
				code: 			this.props.propietario.code,
				Iviv: 			this.props.propietario.numero_registro,
				IIdViv: 		this.props.propietario.id_vivienda,
				email: 			this.props.propietario.email,
				citye:			this.props.propietario.ciudad,
				comentarios:    this.props.propietario.comentarios,
				subLabel: 		this.props.subLabel,
				es_residente:   this.props.propietario.es_residente
			});
		}

		
	}

    render() {
        return (
            <div>
				<DinamicModal 
					idModal				=	{'editPropietario'}
					sizeModalContainer	=	{'big'}
					title				=	{'Editar propietario'} 
					success				=	{this.handleSubmit.bind(this)} 
					showBtnSuccess		=	{this.props.escritura}
					btnTextSuccess		=	{"GUARDAR"}
					showBtnCancel		=	{true}
					btnTextCancel		=	{"CANCELAR"}
					loadingSave			=	{this.state.loadingSave}
                    message				=	{this.state.message}
                    closeModal          =   {() => this.props.closeModal()}
				>
					<div className="full row align-center">
						
						<div className="column full">
						<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p>
										<b>{this.state.subLabel}:</b>
									</p>
								</div>
								<div className="column full">
									<input 
										type			=	"text" 
										name			=	"sub" 
										value			=	{this.props.propietario.subname}
										className		=	"input input-modals" 
										disabled
										autoComplete 	= 	'off'
									/>
								</div>
						</div>
						<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p><b> Vivienda:	</b></p>
								</div>
								<div className="column full">
									<input 
										className	="input input-modals" 
										type		="text" 
										name		="vivienda" 
										value		={this.state.Iviv}
										onChange	={event => this.setState({ Iviv: event.target.value })}
										disabled 
										required 
										autoComplete = 'off'
									/>
								</div>
							</div>
                            <div className="white-space-8" />
							<div className="input-form-content row full align-cente">
								<div className="column label-medium">
									<p><b>  Teléfono:	</b><b style={{color:'red'}}> * </b></p>
								</div>
								<div className="column full">
								<PhoneInput
									displayInitialValueAsLocalNumber
									name="phoneNumber"
									defaultCountry="MX"
									maxLength = "18"
									autoComplete = 'off'
									value={this.state.ITelefono}
									onChange={ value => this.setState({ ITelefono: value, telefonoError: undefined }) }
									error={ this.state.telefonoError }
									disabled={true}
                                    readOnly/>
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p><b>  Nombre completo:	</b><b style={{color:'red'}}> * </b></p>
								</div>
								<div className="column full">
									<input 
										type			=	"text" 
										name			=	"fullName" 
										value			=	{this.state.INombre}
										onChange		=	{event => this.setState({ INombre: event.target.value })} 
										className		=	"input input-modals" 
										autoComplete = 'off'
										required 
                                        disabled
									/>
								</div>
							</div>
                            <div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
								<p  className="justify-start">
										<b>Género:</b>
									</p>
								</div>
								<div className="column full">
									<select name="sex" id="" value={this.state.IGenero} required
										onChange={event => this.setState({ IGenero: event.target.value })} className="input input-modals">
										<option value="0">Hombre</option>
										<option value="1">Mujer</option>
										<option value="2">No especificado</option>
									</select>
								</div>
                                <div className="column label-duo-normal space">
									<p>
										<b>Correo:</b>
									</p>
								</div>
								<div className="column full">
									<input 
										className="input input-modals" 
										type="email" 
										name="fullName" 
										value={this.state.email}
										onChange={event => this.setState({ email: event.target.value })} 
										autoComplete = 'off'
									/>
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p >
										<b>Identificación:</b>
									</p>
								</div>
								<div className="column full">
									<select name="id" id="idEddit" className="input input-modals" value={this.state.IId} onChange={event => this.setState({ IId: event.target.value })}>
										<option value="0">INE</option>
										<option value="1">Pasaporte/Visa</option>
										<option value="2">Curp</option>
										<option value="3">Cartilla Militar</option>
									</select>
								</div>
								<div className="column label-duo-normal space">
									<p  className="justify-start" style={{  paddingLeft: '20px' }}>
										<b>ID:</b>
									</p>
								</div>
								<div className="column full">
									<input 
										className="input input-modals" 
										type="text" 
										name="numIdEddit" 
										value={this.state.INumId}
										onChange={event => this.setState({ INumId: event.target.value })} 
										autoComplete = 'off' 
										/* required  */
										 
									/>
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p>
										<b>Dirección:</b>
									</p>
								</div>
								<div className="column full">
									<input 
										className	=	"input input-modals"  
										type		=	"text" 
										name		=	"address" 
										value		=	{this.state.IDireccion}
										onChange	=	{event => this.setState({ IDireccion: event.target.value })} 
										/* required  */
										autoComplete = 'off'
									/>
								</div>
							</div>

							<div className="white-space-8" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Municipio:</b>
									</p>
								</div>
								<div className="column full">
									<input 
										className	=	"input input-modals"  
										type		=	"text" 
										name		=	"municipality" 
										value		=	{this.state.IMunicipio}
										onChange	=	{event => this.setState({ IMunicipio: event.target.value })}  
										/* required */ 
										autoComplete = 'off'
									/>
								</div>
								<div className="column label-duo-normal space">
								<p  className="justify-start">
										<b>Ciudad:</b>
									</p>
								</div>
								<div className="column full">
								    <input 
										className='input input-modals' 
										type="text" 
										value={this.state.citye}
										onChange={event => this.setState({ citye: event.target.value })} 
										id='city'
										autoComplete = 'off' 
									/>
								</div>
							</div>

							<div className="white-space-8" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>C.P:</b>
									</p>
								</div>
								<div className="column full">
								<input 
										className="input input-modals"  
										type="text" 
										value={this.state.ICp}
										onChange={event => this.setState({ ICp: event.target.value })} 
										name="zip" 
										/* required  */
										autoComplete = 'off'
									/>
								</div>
								<div className="column label-duo-normal space">
									<p className="justify-start">
										<b>Estado:</b>
									</p>
								</div>
								<div className="column full">
								    <input 
										className		=	"input input-modals"  
										type			=	"text" 
										name			=	"state" 
										value			=	{this.state.IEstado}
										onChange		=	{event => this.setState({ IEstado: event.target.value })} 
										autoComplete 	= 	'off'
										/* required  */
									/>
								</div>
							</div>
							<div className="white-space-8" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big ">
									<p>
										<b>País:</b>
									</p>
								</div>
								<div className="column full">
								    <input 
										className		= "input input-modals" 
										type			= "text" 
										value			= {this.state.IPais}
										onChange		= {event => this.setState({ IPais: event.target.value })} 
										name			= "country"  
										/* required  */
										autoComplete = 'off'
									/>
								</div>
								<div className="column label-duo-normal">
									
								</div>
								<div className="column full">
									
								</div>
							</div>
							<div className="white-space-8" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big ">
									<p>
										<b>Fecha de compra:</b><b style={{color:'red'}}> * </b>
									</p>
								</div>
								<div className="column full">
								<input type="date" value={this.state.pFechaCompra}
										onChange={event => this.setState({ pFechaCompra: event.target.value })} required className='input input-modals' id='fcompra' />
								
								</div>
								<div className="column label-duo-normal">
									<p>
										<b>Fecha de venta:</b>
									</p>
								</div>
								<div className="column full">
								<input type="date" value={this.state.pFechaVenta}
										onChange={event => this.setState({ pFechaVenta: event.target.value })} className='input input-modals' id='fventa' />
								</div>
							</div>
							<div className="white-space-8" />
							
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
							    <b>Comentarios:</b>
							</p>
						</div>
						<div className="column full">
						<textarea 
                            name="comentarios"
                            id="comentarios_inquilinos"
                            value={this.state.comentarios}
                            cols="30"
                            rows="3"
                            maxLength="500"
                            className='textarea font full'					
						 onChange={(event) => this.setState({comentarios: event.target.value})} >
                        </textarea>
						</div>
						{this.state.es_residente === 1 ? (
						<div className="column label-duo-normal space">
						    <div className="input-form-content row full align-center">
						        <div className="column">
							        <label className="container-check">
								        <input type="checkbox" name="esPropietario" id="esPropietario" disabled="disabled"  checked={this.state.es_residente ? true : false}  />
								        <span className="checkmark"></span>
							        </label>
						        </div>
						        <div className="column">
							        <h5>
								        {" "}
								        <b>Es residente </b>{" "}
							        </h5>
						        </div>
					        </div>
						</div> ) : <div className="column label-duo-normal"/> }
						<div className="column full"/>
					</div>
					<div className="white-space-8" />
					<div className="white-space-8" />
					{this.state.es_residente === 0 ? (
					<div className="input-form-content row full align-center">
						<div className="column">
							<label className="container-check">
								<input type="checkbox" name="residente" id="residente" checked={this.state.residente ? true : false} onChange={(event) => this.setState({residente: event.target.checked})} />
								<span className="checkmark"></span>
							</label>
						</div>
						<div className="column">
							<h5>
								{" "}
								<b>Añadir propietario como residente </b>{" "}
							</h5>
						</div>
					</div> ) : null} 
					<div className="white-space-8" />
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
							<b style={{color:'red'}}> * </b><small>Indica un campo obligatorio</small>
							</p>
						</div>
						
					</div>
										
						</div>
					</div>
				</DinamicModal>
            </div>
        );
    }

    handleClose() {
        this.setState({
            showModal: false,
        });
    }

    async handleSubmit(event) {
		event.preventDefault();

		this.setState({
			loadingSave: true
		});

		const form 	= event.target;
		/**Validar Telefono */
		if(!isValidPhoneNumber(this.state.ITelefono)) {
			this.setState({telefonoError: 'Número inválido', loadingSave: false});
			return;
		} else {
			this.setState({telefonoError: undefined});
		}
		let resident = document.getElementById("residente").checked ? 1 : 0;
		
        let data = {
            idVivienda: 		this.state.IIdViv,
            Nombre: 			this.state.INombre,
            Direccion: 			this.state.IDireccion,
            Municipio: 			this.state.IMunicipio,
            Estado: 			this.state.IEstado,
            cp: 				this.state.ICp,
            Telefono: 			this.state.ITelefono,
            Pais: 				this.state.IPais,
            Genero: 			this.state.IGenero,
            TipoId: 			this.state.IId,
            NumId: 				this.state.INumId,
            Vigencia: 			this.state.IVigencia,
			FechaCompra: 		this.state.pFechaCompra,
			FechaVenta: 		this.state.pFechaVenta,
            Code: 				this.state.code,
            IdPersona: 			this.state.idPersona,
			email: 				this.state.email,
			idFraccionamiento: 	localStorage.getItem('frac') || 0,
			city:				this.state.citye,
			comentarios:        this.state.comentarios,
			id_usuario_upd :    localStorage.getItem("user_id"),
			residente:          resident
        }

        const response = await request.post("/propietarios/update", data)
        
       
		if (response.updated && !response.empty) {
			this.props.reload();
			cogoToast.success('Datos del propietario actualizados.', {
				position: 'bottom-right',
			});
		} else {
			this.setState({
				empty: true,
				//message: "NO SE PUDO ACTUALIZAR PROPIETARIO",
				loading: false
			});
			cogoToast.error('No se pudieron actualizar datos del propietario.', {
				position: 'bottom-right',
			});
		}
		this.setState({loadingSave: false});
    }
}

export default PropietarioEditarModal;