import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PhoneInput from 'react-phone-number-input';
import Resizer from 'react-image-file-resizer';
import cogoToast from 'cogo-toast';
import moment from 'moment-timezone';
import Header from '../../components/header/header';
import SidebarOperacion from "../../components/header/sidebarOperacion";
import Controls from '../../components/controls/controls';
import ProvedoresTable from '../../components/invitadosFrecuentes/provedoresTable';
import Paginador from '../../components/paginador/paginador';
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import SelectHour from '../../components/invitadosFrecuentes/selectHour';
import { Consumer } from "../../context";
import Permissions from '../../middlewares/Permissions';

import Request from "../../core/httpClient";
const request = new Request();

const horariosInit = [
    { day: "Lunes", active: false, start: "00:00", end: "00:00" },
    { day: "Martes", active: false, start: "00:00", end: "00:00" },
    { day: "Miércoles", active: false, start: "00:00", end: "00:00" },
    { day: "Jueves", active: false, start: "00:00", end: "00:00" },
    { day: "Viernes", active: false, start: "00:00", end: "00:00" },
    { day: "Sábado", active: false, start: "00:00", end: "00:00" },
    { day: "Domingo", active: false, start: "00:00", end: "00:00" }
]

class proveedoresHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            invitados: [],
            filter: '',
            lectura: false,
            escritura: false,
            loading: false,
            page: 1,
            pageLength: 20,
            logo: require("../../img/logo-AppMosphera-r.png"),
            subfraccionamiento: localStorage.getItem('subfrac') || 0,
            subf: localStorage.getItem('subfrac') || 0,
            personas: [],
            viviendas: [],
            subfraccionamientos: [],
            message: null,
            filter: '',
            IidViv: 0,
            INombre: '',
            IEmpresa: '',
            iTelefono: '',
            telefonoError: '',
            acceso: '',
            tipo: 4,
            rotate1: 0,
            rotate2: 0,
            ine1: null,
            ine2: null,
            min: moment().format('YYYY-MM-DD'),
            fecha: moment().format('YYYY-MM-DD'),
            fecha2: moment().format('YYYY-MM-DD'),
            horarios: horariosInit || [],
            submenu: '',
            subLabel: ''
        };

        this.validarPermisos();
    };

    async validarPermisos() {

        let permisos = new Permissions();
        let permisosInfo = await permisos.getResult();

        this.setState({
            escritura: permisosInfo.escritura,
            lectura: permisosInfo.lectura
        });
    };

    componentDidMount() {
        this.getSubmenu();
        this.invitadosfrecuentes();
        this.getPersonas();
        this.getViviendas();
        this.getSubFraccionamientos();
    };

    componentDidUpdate(prevProps, prevState) {

        if (this.state.subfraccionamiento !== prevState.subfraccionamiento) {

            this.invitadosfrecuentes();
        };
    }

    async getSubmenu() {
        const submenu = await this.props.context.getSubmenuOpeName();
        if (submenu) {
            this.setState({ submenu });
        }

        const subLabel = window.localStorage.getItem('subLabel');
        if (subLabel) {
            this.setState({ subLabel });
        }
    }

    async invitadosfrecuentes() {

        this.setState({ loading: true, invitados: [] });

        const idSub = this.state.subfraccionamiento || 0;

        let data = {
            idfrac: localStorage.getItem('frac'),
            tipo: 4,
            idSub
        };

        const response = await request.post("/invitadosFrecuentes/get/invitados", data);

        if (response && !response.error) {

            if (response.invitadosfrec && !response.empty) {
                this.setState({ invitados: response.invitadosfrec })
            } else {
                this.setState({
                    error: true,
                    invitados: []
                });
            }

        } else {

            this.setState({
                error: true,
                invitados: []
            });
        };

        this.setState({ loading: false });

    };

    async getPersonas() {

        const response = await request.post("/personas/getbyfraccionamiento", { id_fraccionamiento: localStorage.getItem('frac') });

        if (Array.isArray(response.personas)) {
            this.setState({ personas: response.personas });
        } else {
            this.setState({ personas: [] });
        };
    };

    async getViviendas() {

        let data = {
            id_fraccionamiento: localStorage.getItem('frac'),
            id_subfraccionamiento: localStorage.getItem('subfrac')
        };

        const response = await request.post("/viviendas/getViviendaBySubFraccionamiento", data);

        if (Array.isArray(response.viviendas)) {

            this.setState({ viviendas: response.viviendas, subname: response.viviendas[0].nombre_subfrac });

        } else {
            this.setState({ viviendas: [] });
        };

    };

    async getSubFraccionamientos() {

        const fraccionamiento = localStorage.getItem('frac');

        if (!fraccionamiento) {
            return;
        };

        let data = { Idsub: fraccionamiento, filter: 1 };

        const response = await request.post("/admin/administracion/get/subfraccionamientos", data);

        if (response.subfraccionamiento) {
            this.setState({ subfraccionamientos: response.subfraccionamiento });
        } else {
            this.setState({ subfraccionamientos: [] });
        };
    };

    filter(invitadosFrecuentes) {

        invitadosFrecuentes = invitadosFrecuentes.filter(inv => {

            if ((inv.acceso || '').toLowerCase().indexOf(this.state.filter) !== -1) {

                return true;

            } else if ((inv.invitado || '').toLowerCase().indexOf(this.state.filter) !== -1) {

                return true;

            } else if (inv.numero_registro) {

                if (inv.numero_registro.toLowerCase().indexOf(this.state.filter) !== -1) {

                    return true;
                };

            } else {

                return false;

            };

        });

        return invitadosFrecuentes;

    };

    reload() {

        this.invitadosfrecuentes();
    };

    changeFrac() {

        this.invitadosfrecuentes();
    };

    async openModal(idModal) {

        this.setState({ message: null });
        const modal = document.getElementById(idModal);

        if (modal) {
            modal.classList.remove('hide-dinamic-modal');
            modal.classList.add('show-dinamic-modal');
        };

        document.getElementById('viv').focus();
    };

    closeModal(idModal) {

        const modal = document.getElementById(idModal);

        if (modal) {

            modal.classList.remove('show-dinamic-modal');
            modal.classList.add('hide-dinamic-modal');
        };

        this.setState({ rotate1: 0, rotate2: 0 });
    };

    setFilter(filter) {

        this.setState({ filter: filter.toLowerCase() });
    };

    subChange(sub) {

        this.setState({ subfraccionamiento: sub, subf: sub });
        this.getViviendas();
    };

    setPage(page) {

        this.setState({ page });
    };

    async paginatorChange(length) {

        await this.setState({ pageLength: length });
        this.setPage(1);
    };

    filtarViviendas() {

        let viviendas = this.state.viviendas;
        let vivs = [];

        if (this.state.subf == 0) {
            return [];
        };

        viviendas.map((viv) => {

            if (viv.id_subfraccionamiento == this.state.subf) {
                vivs.push(viv);
            };

        });

        return vivs;
    };

    async changeSubFrac(event) {

        let subf = event.target.value;
        this.setState({ subf: subf });
    };

    async rotateImg(id) {

        if (this.state.rotate1 == 360) {
            this.setState({ rotate1: 0 });
        };

        if (this.state.rotate2 == 360) {
            this.setState({ rotate2: 0 });
        };

        if (id == 1) {
            this.setState({ rotate1: (this.state.rotate1 + 90) });
        } else {
            this.setState({ rotate2: (this.state.rotate2 + 90) });
        };

        await this.changeImg(id);
    };

    async changeImg(id) {

        let rotate = 0;

        if (id == 1) {
            rotate = this.state.rotate1;
        } else {
            rotate = this.state.rotate2;
        };

        let file = document.getElementById(`ine${id}`);

        if (file) {

            if (file.files[0]) {

                Resizer.imageFileResizer(
                    file.files[0],
                    337,//337
                    180,//180
                    'JPEG',
                    100,
                    rotate,//270
                    async uri => {
                        if (id == 1) {
                            this.setState({ ine1: uri });
                        } else {
                            this.setState({ ine2: uri });
                        }

                    },
                    'base64'
                );
            };
        };
    };

    async handleSubmit(event) {
        try {
            event.preventDefault();

            this.setState({ loadingSave: true });

            if (parseInt(this.state.subf) === 0) {
				this.setState({ loadingSave: false });
				cogoToast.warn("Debes elegir un subfraccionamiento.", {
					position: "bottom-right",
				});
				return;
			}

			if (parseInt(this.state.IidViv) === 0) {
				this.setState({ loadingSave: false });
				cogoToast.warn("Debes elegir una vivienda.", {
					position: "bottom-right",
				});
				return;
			};

			if (!this.state.INombre) {
				this.setState({ loadingSave: false });
				cogoToast.warn("Debes ingresar el nombre.", {
					position: "bottom-right",
				});
				return;
			}

            if (!this.state.IEmpresa) {
				this.setState({ loadingSave: false });
				cogoToast.warn("Debes ingresar la empresa.", {
					position: "bottom-right",
				});
				return;
			}

			if (!this.state.iTelefono) {
				this.setState({ loadingSave: false });
				cogoToast.warn("Debes ingresar el teléfono.", {
					position: "bottom-right",
				});
				return;
			}

			if (this.state.acceso === 'CONFIRMADO') {
				if (!this.state.ine1 &&  this.state.ine2 || 
					 this.state.ine1 && !this.state.ine2 || 
				    !this.state.ine1 && !this.state.ine2) {
				    cogoToast.warn('Debes elegir las dos imágenes', {
					   position: 'bottom-right',
				    });
				   this.setState({ loadingSave: false });
				   return;
			    };
			}

            let user = this.props.context.user.id.usuario;

            let data = {
                nombre: this.state.INombre,
                tipo: 4,
                telefono: this.state.iTelefono,
                acceso: this.state.acceso || 'PENDIENTE',
                horario: JSON.stringify(this.state.horarios),
                vivienda: this.state.IidViv,
                fecha: this.state.fecha,
                fecha2: this.state.fecha2,
                id_fraccionamiento: localStorage.getItem('frac'),
                ine1: this.state.ine1,
                ine2: this.state.ine2,
                empresa: this.state.IEmpresa,
                usuario: user
            };

            const response = await request.post("/invitadosFrecuentes/invitados/agregar", data);

            if (response.error) {

                if (parseInt(response.registrado) == 0) {

                    cogoToast.error('La persona ya está agregada como un invitado frecuente.', {
                        position: 'bottom-right',
                    });

                } else {

                    if (parseInt(response.registrado) == -1) {

                        cogoToast.error('El límite de proveedores de esta vivienda se ha excedido.', {
                            position: 'bottom-right',
                        });

                    } else {

                        cogoToast.error('No se pudo crear proveedor.', {
                            position: 'bottom-right',
                        });
                    };

                };

            } else {

                if (response.created) {

                    this.invitadosfrecuentes();
                    this.closeModal('newProveedor');
                    this.resetValue();

                    cogoToast.success('Proveedor agregado.', {
                        position: 'bottom-right',
                    });

                } else {

                    cogoToast.error('No se pudo crear invitado.', {
                        position: 'bottom-right',
                    });
                };
            };

            this.setState({ loadingSave: false });
        } catch (error) {
            console.log(error);
        };
    };

    resetValue() {
        this.setState(
            {
                subf: 0,
                IidViv: 0,
                acceso: "",
                INombre: "",
                IEmpresa: "",
                iTelefono: "",
                ine1: null,
                ine2: null,
                fecha: moment().format("YYYY-MM-DD"),
                fecha2: moment().format("YYYY-MM-DD"),
                horarios: [
                    { day: "Lunes", active: false, start: "00:00", end: "00:00" },
                    { day: "Martes", active: false, start: "00:00", end: "00:00" },
                    { day: "Miércoles", active: false, start: "00:00", end: "00:00" },
                    { day: "Jueves", active: false, start: "00:00", end: "00:00" },
                    { day: "Viernes", active: false, start: "00:00", end: "00:00" },
                    { day: "Sábado", active: false, start: "00:00", end: "00:00" },
                    { day: "Domingo", active: false, start: "00:00", end: "00:00" },
                ],
            },
            () => {
                document.getElementById("ine1").value = "";
                document.getElementById("ine2").value = "";
            },
        );
    };

    async cambioFecha(event) {
        let inputDate = event.target;
        inputDate.id === 'fecha' ? this.setState({ fecha: inputDate.value, fecha2: inputDate.value }) : this.setState({ fecha2: inputDate.value });
    }

    render() {

        let invitadosFrecuentes = [];

        if (Array.isArray(this.state.invitados)) {

            invitadosFrecuentes = this.state.invitados;
            invitadosFrecuentes = this.filter(invitadosFrecuentes)
        };

        return (
            <div className="admin column">
                <Helmet>
                    <title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
                </Helmet>
                <Header
                    reload={this.reload.bind(this)}
                    change={this.changeFrac.bind(this)}
                    sidebar={true}
                    active={'historiala'}
                    nav={'operacion'}
                />
                <div className='row'>
                    <div className='column' id='sidebar'>
                        <SidebarOperacion />
                    </div>
                    <div className='column' id='content'>
                        <div className='justify-center'>
                            <div className='container column'>
                                {this.state.lectura ?
                                    <React.Fragment>
                                        <Controls
                                            title={this.state.submenu}
                                            nuevo={this.openModal.bind(this, "newProveedor")}
                                            dataSet={invitadosFrecuentes}
                                            newButton={this.state.escritura}
                                            setFilter={this.setFilter.bind(this)}
                                            subChange={this.subChange.bind(this)}
                                            selectSub={true}
                                        />
                                        <div className='card-table card-owners column' id='card-owners'>
                                            {this.state.loading ?
                                                <div className='row justify-center'>
                                                    <i className='fas fa-spinner fa-spin' />
                                                </div>
                                                :
                                                <ProvedoresTable
                                                    page={this.state.page}
                                                    invitados={invitadosFrecuentes}
                                                    reload={this.reload.bind(this)}
                                                    escritura={this.state.escritura}
                                                    pageLength={this.state.pageLength}
                                                />
                                            }
                                        </div>
                                        <div className='white-space-16' />
                                        <Paginador
                                            pages={Math.ceil(invitadosFrecuentes.length / this.state.pageLength)}
                                            setPage={this.setPage.bind(this)}
                                            pageLength={this.state.pageLength}
                                            paginatorChange={this.paginatorChange.bind(this)}
                                            showPageLenght={true}
                                        />
                                    </React.Fragment>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <DinamicModal
                    idModal={'newProveedor'}
                    sizeModalContainer={'medium'}
                    title={'Añadir proveedor'}
                    success={this.handleSubmit.bind(this)}
                    showBtnSuccess={true}
                    btnTextSuccess={"GUARDAR"}
                    showBtnCancel={true}
                    btnTextCancel={"CANCELAR"}
                    closeModal={this.resetValue.bind(this)}
                    loadingSave={this.state.loadingSave}
                    message={this.state.message}
                >
                    {this.renderNewProveedor()}
                </DinamicModal>
            </div>
        );
    };

    renderNewProveedor() {

        const { horarios } = this.state;

        let list_personas = [];
        let viviendas = [];
        let subfraccionamientos = [];

        if (Array.isArray(this.state.personas)) {
            list_personas = this.state.personas.map(persona => { return { label: persona.nombre, value: persona.id_persona } });
        };

        if (Array.isArray(this.state.subfraccionamientos)) {
            subfraccionamientos = this.state.subfraccionamientos;
        };

        if (Array.isArray(this.state.viviendas)) {
            viviendas = this.filtarViviendas();
        };

        return (
            <div className="full row align-start">
                <div className="column form-column">
                    <div className="input-form-content row full align-center">
                        <div className="column label-medium">
                            <p>
                                <b>{this.state.subLabel}:</b>
                            </p>
                        </div>
                        <div className="column full">
                            <select
                                className="input input-modals"
                                name="subfraccionamiento"
                                value={this.state.subf}
                                onChange={(event) => this.changeSubFrac(event)}
                                disabled={this.state.subfraccionamiento == 0 ? false : true}
                            >
                                <option value="0">SELECCIONAR {this.state.subLabel.toUpperCase()}</option>
                                {subfraccionamientos.map(viv =>
                                    <option key={viv.id_subfraccionamiento} value={viv.id_subfraccionamiento}>
                                        {viv.nombre}
                                    </option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="white-space-8"></div>
                    <div className="input-form-content row full align-center">
                        <div className="column label-medium">
                            <p><b>Vivienda:</b></p>
                        </div>
                        <div className="column full">
                            <select
                                className="input input-modals"
                                name="vivienda"
                                value={this.state.IidViv}
                                onChange={(event) => this.setState({ IidViv: event.target.value })}
                                id="viv"
                            >
                                <option value="0">SELECCIONAR VIVIENDA</option>
                                {viviendas.map(viv =>
                                    <option key={viv.id_vivienda} value={viv.id_vivienda}>
                                        {viv.numero_registro}
                                    </option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="white-space-8" />
                    <div className="input-form-content row full align-center">
                        <div className="column label-medium">
                            <p><b>  Nombre completo:	</b></p>
                        </div>
                        <div className="column full">
                            <input type="text" name="fullName" value={this.state.INombre}
                                onChange={event => this.setState({ INombre: event.target.value })}
                                className="input input-modals"
                                autoComplete='off'
                                maxLength="64" />
                        </div>
                    </div>
                    <div className="white-space-8" />
                    <div className="input-form-content row full align-center">
                        <div className="column label-medium">
                            <p><b>  Empresa:	</b></p>
                        </div>
                        <div className="column full">
                            <input type="text" name="company" value={this.state.IEmpresa}
                                onChange={event => this.setState({ IEmpresa: event.target.value })}
                                className="input input-modals"
                                autoComplete='off'
                                maxLength="64" />
                        </div>
                    </div>
                    <div className="white-space-8"></div>
                    <div className="input-form-content row full align-center">
                        <div className="column label-medium">
                            <p><b>  Teléfono:	</b></p>
                        </div>
                        <div className="column full">
                            <PhoneInput
                                displayInitialValueAsLocalNumber
                                name="phoneNumber"
                                defaultCountry="MX"
                                maxLength="18"
                                autoComplete='off'
                                value={this.state.iTelefono}
                                onChange={value => this.setState({ iTelefono: value, telefonoError: undefined })}
                                error={this.state.telefonoError}
                                className={'input-modals'}
                            />
                        </div>
                    </div>

                    <div className="white-space-8"></div>

                    <h4>Horarios</h4>
                    {horarios.map(hor =>
                        <>
                            <div className="white-space-8"></div>
                            <div className="input-form-content row full align-center">
                                <div className="row label-big" style={{ width: '30%' }}>
                                    <label className="container-check" >
                                        <input
                                            type="checkbox"
                                            name="active"
                                            checked={hor.active}
                                            onChange={(event) => {
                                                let { horarios } = this.state;
                                                horarios = horarios.map(hr => {
                                                    if (hr.day === hor.day) {
                                                        hr.active = event.target.checked
                                                    }
                                                    return hr;
                                                })
                                                this.setState({ horarios });
                                            }}
                                        />
                                        <span className="checkmark" ></span>
                                    </label>
                                    <p><b> {hor.day} </b></p>
                                </div>
                                <div className="column full" style={{ width: '35%' }}>
                                    <SelectHour
                                        id="lunes_start"
                                        className="input input-modals"
                                        value={hor.start}
                                        onChange={(horario) => {
                                            let { horarios } = this.state;
                                            horarios = horarios.map(hr => {
                                                if (hr.day === hor.day) {
                                                    hr.start = horario
                                                }
                                                return hr;
                                            })
                                            this.setState({ horarios });
                                        }} />
                                </div>
                                <div className="column full" style={{ width: '35%' }}>
                                    <SelectHour
                                        id="lunes_end"
                                        className="input input-modals"
                                        value={hor.end}
                                        onChange={(horario) => {
                                            let { horarios } = this.state;
                                            horarios = horarios.map(hr => {
                                                if (hr.day === hor.day) {
                                                    hr.end = horario
                                                }
                                                return hr;
                                            })
                                            this.setState({ horarios });
                                        }} />
                                </div>
                            </div>
                        </>
                    )}
                    <div className="white-space-8"></div>
                    <div className="input-form-content row full align-center">
                        <div className="column label-duo-big">
                            <p><b>  Tipo:	</b></p>
                        </div>
                        <div className="column full">
                            <input type="text" name="tipo" value="Proveedor"
                                onChange={event => this.setState({ tipo: event.target.value })} className="input input-modals" placeholder='Tipo' required maxLength="24" disabled />
                        </div>
                        <div className="column label-duo-normal space">
                        </div>
                        <div className="column full">
                        </div>
                    </div>
                    <div className="white-space-8" />
                    <div className="row justify-center">
                        {this.state.saveMessage}
                    </div>
                </div>
                <div className="column full images-ine align-end">
                    <div className="responsive-img column">
                        <div className="img-column">
                            {this.state.ine1 &&
                                <button type="button" onClick={this.rotateImg.bind(this, 1)} >
                                    <i className="fas fa-redo-alt"></i>
                                </button>
                            }
                            <div className="container-img">
                                <img src={this.state.ine1 ? this.state.ine1 : require('../../img/empty_image.png')} className="img-duo-preview" id="previewArea" />
                                <div className="middle-overlay">
                                    <label className="btn-upload-image column align-center justify-center">
                                        <i className="fas fa-file-upload"></i>
                                        <input type="file" id="ine1" name="AreaImage" accept="image/*" onChange={this.changeImg.bind(this, 1)} />
                                        <div className="white-space-8"></div>
                                        <p className="color-white">subir parte frontal de la INE</p>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="img-column">
                            {this.state.ine2 &&
                                <button type="button" onClick={this.rotateImg.bind(this, 2)} >
                                    <i className="fas fa-redo-alt"></i>
                                </button>
                            }
                            <div className="container-img">
                                <img
                                    src={this.state.ine2 ? this.state.ine2 : require('../../img/empty_image.png')}
                                    className="img-duo-preview"
                                    id="previewArea"
                                />
                                <div className="middle-overlay">
                                    <label className="btn-upload-image column align-center justify-center">
                                        <i className="fas fa-file-upload"></i>
                                        <input type="file" id="ine2" name="AreaImage" accept="image/*" onChange={this.changeImg.bind(this, 2)} />
                                        <div className="white-space-8"></div>
                                        <p className="color-white">subir parte trasera de la INE</p>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="white-space-24"></div>
                    <div className="input-form-content row full align-center space-24">
                        <div className="column label-medium ">
                            <p><b>Acceso:</b></p>
                        </div>
                        <div className="column full">
                            <select name="acceso" id="acceso" className="input input-modals" value={this.state.acceso} enable="true" required
                                onChange={event => this.setState({ acceso: event.target.value })}>
                                <option value="PENDIENTE">PENDIENTE</option>
                                <option value="CONFIRMADO">CONFIRMADO</option>
                            </select>
                        </div>
                    </div>
                    <div className="white-space-16"></div>
                    <div className="input-form-content row full align-center space-24">
                        <div className="column label-medium">
                            <p><b>Desde:</b></p>
                        </div>
                        <div className="column full">
                            <input
                                type="date"
                                name="fecha"
                                id="fecha"
                                className="input input-modals"
                                value={this.state.fecha}
                                onChange={this.cambioFecha.bind(this)}
                                min={this.state.min}
                            />
                        </div>
                    </div>
                    <div className="white-space-16"></div>
                    <div className="input-form-content row full align-center space-24">
                        <div className="column label-medium">
                            <p><b>Hasta:</b></p>
                        </div>
                        <div className="column full">
                            <input
                                type="date"
                                name="fecha2"
                                id="fecha2"
                                className="input input-modals"
                                value={this.state.fecha2}
                                onChange={this.cambioFecha.bind(this)}
                                min={this.state.fecha}
                            />
                        </div>
                    </div>
                    <div className="white-space-8"></div>
                </div>
            </div>
        );
    };
};

export default Consumer(proveedoresHistory);