import React, {Component} from "react";

import SH from "../../helpers/StringHelper";
import cloneDeep from "lodash/cloneDeep";

class Exclusions extends Component {
	
	changeData(data) {
		this.props.updateData(data);
	}

	filtrarViviendas(filter, viviendasCopy) {

		let STR = new SH();
		let string_limpio = STR.eliminarCaracteresRaros(filter.trim(), false);

		let viviendas = [...viviendasCopy];
		let viviendasFiltradas = [];

		viviendas.forEach((vv) => {

			if (STR.multiMatch(vv.numero_registro.toLowerCase(), string_limpio.toLowerCase())) {
				viviendasFiltradas.push(vv);
			};

		});

		if (string_limpio == "" || viviendasFiltradas.length == 0) {

			return cloneDeep(viviendas);
		};

		return viviendasFiltradas;
	};

	render() {

		const { filter_value, viviendas, viviendas_json, addVivienda, viviendasCheckAll } = this.props;

		const viviendasFiltradas = this.filtrarViviendas(filter_value, viviendas);

		return (
			<div className="container">
				<div className="column full">
					<div className="white-space-16"></div>
					<div className="input-form-content row  full align-center">
						<div className="column label-big ">
							<div className="row align-center">
								<p>
									<b>Viviendas a excluir:</b>
								</p>
								<label className="container-check space">
									<input type="checkbox" name="viviendas-check-all" onClick={() => viviendasCheckAll()} />
									<span className="checkmark"></span>
								</label>
							</div>
						</div>

						<div className="column full space align-end">
							<div className="new-search-container">
								<div className="sub-container">
									<div className="input-search">
										<input
											className="input input-filter"
											type="text"
											placeholder="Buscar"
											name="textFilter"
											value={filter_value}
											onChange={(event) => {
												this.changeData({filter_value: event.target.value});
											}}
										/>
										<i className="fas fa-search font-small"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="white-space-16"></div>
					<div className="viviendas-container-check">
						{viviendasFiltradas.map((vivienda) => {
							return (
								<div className="viviendas-render" key={vivienda.id_vivienda}>
									{/* id_vivienda */}
									<div className="check">
										<label className="container-check">
											<input
												type="checkbox"
												checked={viviendas_json.includes(vivienda.id_vivienda)}
												name="viviendas-check"
												onChange={(input) => {}}
												onClick={addVivienda.bind(this, vivienda.id_vivienda)}
											/>
											<span className="checkmark"></span>
										</label>
									</div>
									<label htmlFor="">{vivienda.numero_registro}</label>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
}

export default Exclusions;
