import React, {Component} from 'react';
import SuperCore from './../../../core/SuperCore';

class FooterScreens extends SuperCore{
    constructor(props){
        super(props);
        this.state = {
            labelBack: this.props.labelBack,
            labelNext:  this.props.labelNext
        }
    }
    async componentDidUpdate(prevProps, prevState){
        if(prevProps.labelBack != this.props.labelBack){
            await this.setState({labelBack: this.props.labelBack});
        }

        if(prevProps.labelNext != this.props.labelNext){
            await this.setState({labelNext: this.props.labelNext});
        }
    }

    render(){
        let labelBack = this.state.labelBack;
        let labelNext = this.state.labelNext;

        if(labelBack === null || typeof labelBack === 'undefined')
            labelBack = 'ATRÁS';
        
        if(labelNext === null || typeof labelNext === 'undefined')
            labelNext = 'SIGUIENTE';

		if (this.props.loading) {
			return (
				<div className="row full justify-center">
					<i className="fas fa-spinner fa-spin"></i>
				</div>
			)
		}

        return(
        <div className="row full justify-center">
            <div className="container">
                <div className ="next-container">
                    { parseInt(this.props.id_menu_activo) == 0 ? 
                        <span></span>
                        : 
                        <button
                            onClick = {(event)=>{this.props.backMenu()}}
                        >
                            {labelBack.toUpperCase()}
                        </button>
                    }
                    <button
                        onClick={(event)=>{this.props.nextMenu()}}
                    >
                        {labelNext.toUpperCase()}
                    </button>
                </div>
            </div>
        </div>
        );
    }
}

export default FooterScreens;