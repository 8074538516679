/**
 *  app.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Punto de Entrada de la aplicación
 * 	@process: 2
 */

import React, {Component} from 'react';
// import './sass/style.scss'
import Views from './core/views';
import'./sass/styleAppMosphera.scss';
class App extends Component{
    constructor(props){
        super(props)
        /*  process.env.REACT_APP_NAME === "iGate" ?  import('./sass/style.scss') :  import('./sass/styleAppMosphera.scss');*/
        
    }

    render(){
        return(
        <div className = "App">
            <Views> </Views>  
        </div >
        );
    };
}
// const App = () => ( 
//     <div className = "App">
//     <Views> </Views>  
//     </div >
// );

export default App;