/**
 *  cards.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio, alexis
 *  @description: Tarjetas del tablero
 */

import React from "react";

const CardQuick = (props) => (

    <div className="card-quick align-center">
        <div className="icon auto">
            <i className={`fas fa-${props.icon} fa-2x color-danger`}></i>
        </div>
        <div className="info column">
            <h5 className="weight-semi">
                {
                    props.loading ? <i className="fas fa-spinner fa-spin"></i> : 
                    props.value ? props.value : "0"
                }
            </h5>
            <p>
                { props.description }
            </p>
        </div>
    </div>
);

export default CardQuick;
