import React, {Component} from "react";
import {Helmet} from "react-helmet";
import {Consumer} from "../context";
import Header from "../components/header/header";
import moment from "moment";
import Request from "../core/httpClient";
import cogoToast from "cogo-toast";
import Paginador from "../components/paginador/paginador";
import useExcel from "../hooks/useExcel";
const req = new Request();

const ReportSheet = (props) => {
	return (
		<React.Fragment key={props.key}>
			<div className="reporter_sheet" style={{width: "100%", height: "100%"}}>
				<div className="reporter_content">{props.children}</div>
			</div>
		</React.Fragment>
	);
};

class Reports extends Component {
	state = {
		startDate: moment().add(-7, "day").format("YYYY-MM-DD"),
		endDate: moment().format("YYYY-MM-DD"),
		tipos: [],
		selected: {},
		data: [],
		page: 1,
		empty: true,
		registros: 0,
		filter: "",
		excelDisabled: true,
		pdfDisabled: true,
		print: true,
		pageLength: 20,
	};

	componentDidMount() {
		this.load();
	}

	async load() {
		let data = {
			id_fraccionamiento: parseInt(localStorage.getItem("frac")),
			tipo: 1, // 1 = reportes, 2 = dashboard facturación
		};
		const res = await req.post("/reportes/tipos", data);
		if (res.tipos) {
			this.setState({tipos: res.tipos, selected: res.tipos[0]});
		}
	}

	/*funcion del paginador*/
	setPage(page) {
		this.setState({page});
	}

	startChange(event) {
		this.setState({startDate: event.target.value});
	}

	endChange(event) {
		this.setState({endDate: event.target.value});
	}

	filter(dataSet) {
		let result = dataSet;
		const filtro = this.state.filter.toLowerCase();
		if (Array.isArray(result)) {
			const columns = Object.keys(dataSet[0]);
			if (columns.length > 0) {
				result = result.filter((row) => {
					let valid = false;
					columns.forEach((col) => {
						let item = row[col];
						item = item ? item.toString().toLowerCase() : "";
						if (item.indexOf(filtro) !== -1) {
							valid = true;
						}
					});
					return valid;
				});
			}
		}
		return result;
	}

	reportsChange(event) {
		// se obtiene el reporte
		let selected = this.state.tipos.filter((item) => parseInt(item.id_reporte) === parseInt(event.target.value));
		// se guarda en estado
		this.setState({selected: selected[0]});
	}

	async getReport(event) {
		event.preventDefault();

		this.setState({loading: true, page: 1});
		const reportSelected = this.state.selected;

		const startdate = event.target.startdate.value;
		const enddate = event.target.enddate.value;
		const query = reportSelected.consulta;
		const baseDatos = reportSelected.baseDatos;
		const frac = window.localStorage.getItem("frac");

		if (frac) {
			const data = {start: startdate, end: enddate, sp: query, id_frac: parseInt(frac), baseDatos: baseDatos};
			const res = await req.post("/reportes/get", data);
			if (res.reports) {
				this.setState({data: this.filter(res.reports), empty: false, loading: false, print: false, excelDisabled: false, pdfDisabled: false});
			} else {
				this.setState({data: [], empty: true, loading: false, print: true, excelDisabled: true, pdfDisabled: true});
			}
		}
	}

	async ExportPdf() {
		this.setState({pdfDisabled: true});
		let dataSet = this.state.data;
		let columns = "";
		let rows = "";
		// Definición de columnas
		let excelColumnsShow = dataSet[0].excelColumnsShow;
		if (excelColumnsShow) {
			//Si el store tiene formato de excel
			excelColumnsShow = excelColumnsShow.split(",");
			let excelColumnsName = dataSet[0].excelColumnsName;
			excelColumnsName = excelColumnsName.split(",");
			let excelColumnsType = dataSet[0].excelColumnsType;
			excelColumnsType = excelColumnsType.split(",");

			const cols = excelColumnsName;
			cols.forEach((col) => {
				columns = columns + `<th>${col}</th>`;
			});
			dataSet.forEach((row) => {
				let newRow = "";
				excelColumnsShow.forEach((col, colIndex) => {
					if (excelColumnsType[colIndex] === "image") {
						if (row[col].indexOf("data:image/") !== -1 || row[col].indexOf("http://") !== -1 || row[col].indexOf("https://") !== -1) {
							newRow = newRow + `<td><img src="${row[col]}" width="150"/></td>`;
						} else {
							newRow = newRow + `<td><img src="data:image/png;base64,${row[col]}" alt="reportImage" width="150"></img></td>`;
						}
					} else if (excelColumnsType[colIndex] === "date" || excelColumnsType[colIndex] === "datetime") {
						if (moment(row[col]).isValid()) {
							newRow = newRow + `<td>${moment(row[col]).format("DD/MM/YYYY hh:mm A")}</td>`;
						} else {
							newRow = newRow + `<td>-</td>`;
						}
					} else {
						newRow = newRow + `<td>${row[col] || ""}</td>`;
					}
				});
				rows = rows + `<tr>${newRow}</tr>`;
			});
		} else {
			const cols = Object.keys(dataSet[0]);
			cols.forEach((col) => {
				columns = columns + `<th>${col}</th>`;
			});

			dataSet.forEach((row) => {
				const newRow = cols.map((col) => `<td>${row[col] || ""}</td>`);
				rows = rows + `<tr>${newRow}</tr>`;
			});
		}

		const html = `<html>
            <head>
                <title>Appmosphera</title>
                <meta charset="utf8">
                <style>
                    body {
                        font-family: "Mukta", sans-serif;
                        font-size: 12px;
                    }
                    table {
                        width: 100%;
                        height: 100%;
                    }
                    th {
                        white-space: nowrap;
                        font-size: 11px;
                    }
                    td {
                        color: #4e4a4a;
                        font-size: 10px;
                    }
                </style>
            </head>
            <body>
                <table>
                    <thead>
                        <tr>
                            ${columns}
                        </tr>
                    </thead>
                    <tbody>
                        ${rows}
                    </tbody>
                </table>
            </body></html>`;
		const data = {
			info: dataSet,
			html: html,
		};
		const response = await req.post("/pdf/exportar/reporte", data);
		const {buffer} = response;
		if (buffer) {
			cogoToast.success("Generando archivo.", {
				position: "bottom-right",
			});
			let titulo = "Appmosphera";
			const select = document.getElementById("report-select");
			if (select) {
				titulo = select.options[select.selectedIndex].text;
			}
			const bytes = new Uint8Array(buffer.data);
			const blob = new Blob([bytes]);
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(blob);
			const fileName = `${titulo}_${moment().format("YYMMDDHHmm")}.pdf`;
			link.download = fileName;
			link.click();
		} else {
			cogoToast.error(response.message || "No se pudo crear el documento de excel.", {
				position: "bottom-right",
			});
		}
		this.setState({pdfDisabled: false});
	}

	async paginatorChange(length) {
		await this.setState({pageLength: length});
		this.setPage(1);
	}

	async ExportExcel() {
		this.setState({excelDisabled: true, print: true});
		let dataSet = this.state.data;
		// Definición de columnas
		if (!dataSet[0].excelColumnsShow) {
			cogoToast.error("No se encontraron definición de columnas en el reporte.", {
				position: "bottom-right",
			});
			this.setState({excelDisabled: false, print: false});
			return;
		}
		let excelColumnsShow = dataSet[0].excelColumnsShow;
		excelColumnsShow = excelColumnsShow.split(",");
		let excelColumnsName = dataSet[0].excelColumnsName;
		excelColumnsName = excelColumnsName.split(",");
		let excelColumnsType = dataSet[0].excelColumnsType;
		excelColumnsType = excelColumnsType.split(",");
		// Construir las filas
		let rows = [];
		dataSet.forEach((element) => {
			const object = {};
			excelColumnsShow.forEach((columnShow, index) => {
				const column = excelColumnsName[index];
				const row = element[columnShow];
				object[column] = row;
			});
			rows.push(object);
		});
		//Datos a enviar al API
		const {context} = this.props;
		let titulo = "Appmosphera";
		const select = document.getElementById("report-select");
		if (select) {
			titulo = select.options[select.selectedIndex].text;
		}
		dataSet = {
			columns: excelColumnsName,
			rows,
			excelColumnsType,
			titulo: titulo.toUpperCase(),
			fraccionamiento: context.fraccionamiento || "Appmosphera",
			usuario: context.usuario || "",
			fecha: moment().format("DD/MM/YYYY HH:mm"),
			tz: moment.tz.guess(),
		};

		const excelFunctions = useExcel();
        const { generateExcel } = excelFunctions;
        const excelData = generateExcel(dataSet);
		
		if (excelData) {
			cogoToast.success("Generando archivo.", {
				position: "bottom-right",
			});
			const bytes = new Uint8Array(excelData.buffer);
			const blob = new Blob([bytes]);
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(blob);
			const fileName = `${titulo}_${moment().format("YYMMDDHHmm")}.xlsx`;
			link.download = fileName;
			link.click();
		} else {
			cogoToast.error(excelData.message || "No se pudo crear el documento de excel.", {
				position: "bottom-right",
			});
		}
		this.setState({excelDisabled: false, print: false});
	}

	PrintElem() {
		let columns = "";
		let rows = "";
		let dataSet = this.state.data;
		// Definición de columnas
		let excelColumnsShow = dataSet[0].excelColumnsShow;
		if (excelColumnsShow) {
			//Si el store tiene formato de excel
			excelColumnsShow = excelColumnsShow.split(",");
			let excelColumnsName = dataSet[0].excelColumnsName;
			excelColumnsName = excelColumnsName.split(",");
			let excelColumnsType = dataSet[0].excelColumnsType;
			excelColumnsType = excelColumnsType.split(",");

			const cols = excelColumnsName;
			cols.forEach((col) => {
				columns = columns + `<th>${col}</th>`;
			});
			dataSet.forEach((row) => {
				let newRow = "";
				excelColumnsShow.forEach((col, colIndex) => {
					if (excelColumnsType[colIndex] === "image") {
						if (row[col].indexOf("data:image/") !== -1 || row[col].indexOf("http://") !== -1 || row[col].indexOf("https://") !== -1) {
							newRow = newRow + `<td><img src="${row[col]}" width="150"/></td>`;
						} else {
							newRow = newRow + `<td><img src="data:image/png;base64,${row[col]}" alt="reportImage" width="150"></img></td>`;
						}
					} else if (excelColumnsType[colIndex] === "date" || excelColumnsType[colIndex] === "datetime") {
						if (moment(row[col]).isValid()) {
							newRow = newRow + `<td>${moment(row[col]).format("DD/MM/YYYY HH:mm")}</td>`;
						} else {
							newRow = newRow + `<td>-</td>`;
						}
					} else {
						newRow = newRow + `<td>${row[col] || ""}</td>`;
					}
				});
				rows = rows + `<tr>${newRow}</tr>`;
			});
		} else {
			const cols = Object.keys(dataSet[0]);
			cols.forEach((col) => {
				columns = columns + `<th>${col}</th>`;
			});

			dataSet.forEach((row) => {
				const newRow = cols.map((col) => `<td>${row[col] || ""}</td>`);
				rows = rows + `<tr>${newRow}</tr>`;
			});
		}

		const html = `<html>
        <head>
            <title>Appmosphera</title>
            <style>
                body {
                    font-family: "Mukta", sans-serif;
                    font-size: 11px;
                }
                th {
                    white-space: nowrap;
                }
                td {
                    color: #4e4a4a;
                }
            </style>
        </head>
        <body>
            <table>
                <thead>
                    <tr>
                        ${columns}
                    </tr>
                </thead>
                <tbody>
                    ${rows}
                </tbody>
            </table>
        </body></html>`;
		const mywindow = window.open("", "PRINT", "height=400,width=600");
		mywindow.document.write(html);

		mywindow.document.close(); // necessary for IE >= 10
		mywindow.focus(); // necessary for IE >= 10*/

		mywindow.print();
		mywindow.close();

		return true;
	}

	render() {
		//Definición de hojas
		let report_sheets = [];
		//Data State
		let {data} = this.state;
		//DataSet
		let dataSet = [],
			columns = [];
		if (this.state.loading) {
			report_sheets.push(
				<div className="row justify-center align-center">
					<div>
						<div className="white-space-32"></div>
						<i className="fas fa-spinner fa-spin"></i>
					</div>
				</div>
			);
		} else if (Array.isArray(data)) {
			if (data.length <= 0) {
				report_sheets.push(
					<div className="row justify-center align-center">
						<div>
							<h1>Sin Datos</h1>
						</div>
					</div>
				);
			} else {
				report_sheets = [];
				dataSet = data;
				let rowsSheet = 30;
				//ExcelFormat Options
				if (dataSet[0].excelColumnsShow) {
					if (dataSet[0].rowSheet) {
						rowsSheet = dataSet[0].rowSheet;
					}
					// Definición de columnas
					let excelColumnsShow = dataSet[0].excelColumnsShow;
					excelColumnsShow = excelColumnsShow.split(",");
					let excelColumnsName = dataSet[0].excelColumnsName;
					excelColumnsName = excelColumnsName.split(",");
					let excelColumnsType = dataSet[0].excelColumnsType;
					excelColumnsType = excelColumnsType.split(",");

					columns = dataSet[0].excelColumnsName.split(",");
					// Reconstruir las filas
					let rows = [];
					dataSet.forEach((element) => {
						const object = {};
						excelColumnsShow.forEach((columnShow, colIndex) => {
							const column = excelColumnsName[colIndex];
							let row = element[columnShow] || "";
							const type = excelColumnsType[colIndex];
							if (type === "image") {
								if (row.indexOf("data:image/") !== -1 || row.indexOf("http://") !== -1 || row.indexOf("https://") !== -1) {
									row = <img src={row} alt="reportImage" width="150"></img>;
								} else {
									row = <img src={`data:image/png;base64,${row}`} alt="reportImage" width="150"></img>;
								}
								object[column] = row;
							} else if (type === "date" || type === "datetime") {
								if (moment(row).isValid()) {
									object[column] = moment(row).format("DD/MM/YYYY HH:mm");
								} else {
									object[column] = "-";
								}
							} else {
								object[column] = row;
							}
						});
						rows.push(object);
					});
					dataSet = rows;
				} else {
					columns = Object.keys(dataSet[0]);
				}

				//Construcción del reporte
				const Sheet = (
					<ReportSheet>
						<table style={{width: "100%"}}>
							<thead>
								<tr>
									{columns.map((column) => (
										<th>{column}</th>
									))}
								</tr>
							</thead>
							<tbody>
								{dataSet.map((row, index) => {
									return (
										<tr>
											{columns.map((column) => (
												<td style={{fontSize: "13px"}}>{row[column]}</td>
											))}
										</tr>
									);
								})}
								<tr>
									<td colSpan={columns.length}>
										<div className="column full justify-center align-center">
											<div className="white-space-32"></div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</ReportSheet>
				);
				report_sheets.push(Sheet);
			}
		} else {
			report_sheets.push(
				<div className="row justify-center align-center">
					<div>
						<h1>Sin Datos</h1>
					</div>
				</div>
			);
		}

		let tiposReportes = [];
		if (this.state.tipos) {
			tiposReportes = this.state.tipos;
		}

		return (
			<div className="admin column">
				<Helmet>
					<title>{process.env.REACT_APP_NAME} - Administración</title>
				</Helmet>
				<Header nav={"reportes"} active={"reportes"} parent={"reportes"} panel={"panel1"} />
				<div className="row justify-center">
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column text-justify">
								<div className="white-space-32"></div>
								<div className="navigation align-center">
									<div className="justify-start">
										<h3 className="weight-semi">Reportes</h3>
									</div>
								</div>
								<div className="white-space-16"></div>
								<div className="row-responsive">
									<div className="filter-date row-responsive">
										<form className="get-reports column" onSubmit={this.getReport.bind(this)}>
											<div className="full column">
												<h5 className="color-black">TIPO:</h5>
												<select id="report-select" name="reportSelect" className="input input-select" onChange={this.reportsChange.bind(this)}>
													{tiposReportes.map((tipo, index) => (
														<option key={index} value={tipo.id_reporte}>
															{tipo.nombre}
														</option>
													))}
												</select>
											</div>
											<div className="white-space-8" />
											<div className="full column">
												<h5 className="color-black">DESDE:</h5>
												{/*<div className="white-space-8" />*/}
												<input type="date" id="startdate" name="startdate" className="input input-text" placeholder="Desde" value={this.state.startDate} onChange={this.startChange.bind(this)} />
											</div>
											<div className="white-space-8" />
											<div className="full column">
												<h5 className="color-black">HASTA:</h5>
												{/*<div className="white-space-8" />*/}
												<input type="date" id="enddate" name="enddate" className="input input-text" placeholder="Hasta" value={this.state.endDate} onChange={this.endChange.bind(this)} />
											</div>
											<div className="white-space-8" />
											<div className="full column">
												<h5 className="color-black">FILTRAR:</h5>
												{/*<div className="white-space-8" />*/}
												<input
													type="text"
													id="filter"
													name="filter"
													className="input input-text"
													placeholder="Filtro"
													value={this.state.filter}
													onChange={(input) => this.setState({filter: input.target.value})}
												/>
											</div>
											<div className="white-space-16" />
											<div className="justify-start align-end">
												<button className="btn btn-primary color-white width-100">
													{!this.props.loading ? <i className="fas fa-search i-small" /> : <i className="fas fa-spinner fa-spin" />}
													&nbsp; Obtener
												</button>
											</div>
										</form>
									</div>
									<div className="white-space-24"></div>
									<div className="column full space-24">
										<div className="row justify-end">
											<div className="auto">
												<button
													className={`btn ${this.state.excelDisabled ? "btn-disable" : "btn-primary"}`}
													id="rptBtnExcel"
													onClick={this.ExportExcel.bind(this)}
													disabled={this.state.excelDisabled}
												>
													<i className="far fa-file-excel color-white" />
												</button>
											</div>
											<div className="auto space-8">
												<button className={`btn ${this.state.pdfDisabled ? "btn-disable" : "btn-primary"}`} id="rptBtnPdf" onClick={this.ExportPdf.bind(this)} disabled={this.state.pdfDisabled}>
													<i className="far fa-file-pdf color-white" />
												</button>
											</div>
											<div className="auto space-8">
												<button
													className={`btn color-white ${this.state.print ? "btn-disable" : "btn-primary"}`}
													id="rptBtnPrint"
													onClick={this.PrintElem.bind(this, "reporter_content")}
													disabled={this.state.print}
												>
													<i className="fas fa-print color-white" />
												</button>
											</div>
										</div>
										<div className="white-space-8"></div>
										<div className="column align-center space-24">
											{this.state.loading ? (
												<React.Fragment>
													<div className="white-space-32"></div>
													<div className="row justify-center">
														<i className="fas fa-spinner fa-spin"></i>
													</div>
												</React.Fragment>
											) : !this.state.empty ? (
												<React.Fragment>
													<div className="card-table card-owners column space-48" id="card-owners">
														<div className="table-responsive column">
															<table>
																<thead>
																	<tr className="text-left">
																		{columns.map((column, index) => (
																			<th key={index}>{column}</th>
																		))}
																	</tr>
																</thead>
																<tbody>
																	{dataSet.slice((this.state.page - 1) * this.state.pageLength, this.state.page * this.state.pageLength).map((row, index) => {
																		return (
																			<tr>
																				{columns.map((column) => (
																					<td style={{fontSize: "13px"}}>{row[column]}</td>
																				))}
																			</tr>
																		);
																	})}
																</tbody>
															</table>
														</div>
													</div>
													{Math.ceil(dataSet.length / this.state.pageLength) > 0 ? (
														<React.Fragment>
															<div className="white-space-16"></div>
															<Paginador
																pages={Math.ceil(dataSet.length / this.state.pageLength)}
																setPage={this.setPage.bind(this)}
																paginatorChange={this.paginatorChange.bind(this)}
																pageLength={this.state.pageLength}
																showPageLenght={true}
															/>
														</React.Fragment>
													) : null}
												</React.Fragment>
											) : (
												<React.Fragment>
													<div className="white-space-48"></div>
													<div className="justify-center">
														<h3>No se encontraron resultados.</h3>
													</div>
												</React.Fragment>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Consumer(Reports);
