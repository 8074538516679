import React, { Component } from 'react';
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import moment from "moment";
import Request from "../../core/httpClient";
import ModalBorrar from '../modals/modalDelete';
import FormNewContact from "../contactos/formNewContact";
import FormEditContact from "../contactos/formEditContact";
import cogoToast from 'cogo-toast';
import sortableData from '../../helpers/sortableDataTable';

const request = new Request();

class ContactoModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: 			false,
			startDate: 			new Date(),
			contactos: 			[],
			contacto: 			{},
			idViv: 				0,
			add: 				false,
			edditProp: 			false,
			search: 			false,
			pNombre: 			'',
			pDireccion: 		'',
			pMunicipio: 		'',
			pEstado: 			'',
			pCp: 				'',
			pTelefono: 			'',
			pPais: 				'',
			pGenero: 			0,
			pId: 				0,
			pNumId: 			'',
			pVigencia: 			'',
			pFechaCompra: 		'',
			pIdPersona: 		0,
			isChecked: 			false,
			isPrincipal: 		false,
			viewOwners: 		true,
			email:				'',
            id: 				0,
            phoneValidated: 	false,
            showNewModal:       false,
			direction: {
				nombre: 'desc',
				estatus: 'desc'
			},
			filterColumn: {
				col: '',
				filt: ''
			},
			subLabel: ''
		}
	}

	componentDidUpdate(prevProps) {
		if(this.props.id !== prevProps.id) {
			this.getPropietariosVivienda();
		}
	}

	openModal(idModal, cont) {
		this.setState({message: null, contacto: cont});
		const modal = document.getElementById(idModal);
		
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
            modal.classList.add('show-dinamic-modal');
		}
		//Refresca datos
        this.setState({showNewModal: true});

		let nameContact = document.getElementById('contact');
		if(nameContact){
			nameContact.focus();
		}
	}

	delete = (stateId, id, close) => {
		this.handleDelete(id, close)
	}

	propietario = data => {
		this.setState({
			pNombre: 			data.nombre,
			pDireccion: 		data.direccion,
			pMunicipio: 		data.municipio,
			pEstado: 			data.estado,
			pCp: 				data.cp,
			pTelefono: 			data.telefono,
			pPais: 				data.pais,
			pGenero: 			data.genero,
			pId: 				parseInt(data.tipo_identificacion),
			pNumId: 			data.num_identificacion,
			pVigencia: 			data.vigencia_identificacion,
			pFechaCompra: 		moment(data.fecha_compra).format("YYYY-MM-DD"),
			pIdPersona: 		data.id_persona,
			email: 				data.email
		});
	}

	componentDidMount() {
		this.getContactosVivienda();
		this.getSubLabel();
	}

	getSubLabel() {
		const subLabel = window.localStorage.getItem('subLabel');
		if (subLabel) {
			this.setState({ subLabel });
		}
	}

	setOwnersInfo(state) {
		this.setState({
			IdViv: 			this.props.id,
			pNombre: 		state.INombre,
			pDireccion: 	state.IDireccion,
			pMunicipio: 	state.IMunicipio,
			pEstado: 		state.IEstado,
			pCp: 			state.ICp,
			pTelefono: 		state.iTelefono,
			pPais: 			state.IPais,
			pGenero: 		state.IGenero,
			pId: 			state.IId,
			pNumId: 		state.iNumId,
			pVigencia: 		state.iVigencia || '',
			pFechaIngreso: 	state.IFechaIngreso,
			pFechaSalida: 	state.IFechaSalida,
			idPersona: 		state.IIdPersona,
			email: 			state.email
		});
	}

	sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, this.state.contactos, this.state.direction[key]);

		this.setState({
			contactos: items,
			direction: {
				[key]: direction
			},
			filterColumn: {
				col: key,
				filt: direction
			}
		});
	}

  	render() {
		let id = 0
		if (this.props.id) {
			id = this.props.id;
		}
    	return (
			<div className="full row align-center">
				<div className="column full">
					{/*<div className="">
						<div className="justify-end">
							{this.props.escritura &&
							<button className="btn btn-mini btn-primary color-white" type="button" id="btn-modal-edit" onClick={this.openModal.bind(this,"addOwner",id)} >
								<i className="fas fa-plus"/> Añadir
							</button>
							}
							
						</div>
					</div> */}
					<div className="column card-table">
						<div className="table-responsiv column">
							<table>
								<tbody>
									<tr className="pad-bot row-without-border">
										<th colSpan="3" className="text-left">
											<h4 className="weight-semi">Contactos</h4>
										</th>
										{this.props.escritura && <th>
											<button className="btn btn-mini btn-primary color-white" type="button" id="btn-modal-edit" onClick={this.openModal.bind(this, "addOwner", id)} >
												<i className="fas fa-plus" /> Añadir
											</button>
										</th>}
									</tr>
									<tr className="text-left">
										<th className="text-left">Nombre</th>
										<th className="text-left">Teléfono</th>
										{/* <th className="text-center th-options-small">Principal</th> */}
										<th className="text-center th-options-small">Editar</th>
										<th className="text-center th-options-small">Borrar</th>
									</tr>
								{	this.state.loadingContactos? 
									<tr>
										<td colSpan="4">
											<div className="row justify-center">
												<i className="fas fa-spinner fa-spin"></i>
											</div>
										</td>
									</tr>
									:
									this.state.contactos.map((contacto, key) => (
									<tr key={key}>
										<td className="text-left">
											{contacto.nombre} 
										</td>
										<td className="text-left">
											{this.props.getNumberFormat(contacto.telefono)}
										</td>
										{/* <td className="text-center">
											{contacto.principal?  <i className="font-small fas fa-check" /> : <div className="white-space-8"></div> }
										</td> */}
										<td className="text-center ">
											<div className="aling-center justify-center">
												<button type="button" className="btn-full justify-center btn-mini btn-secondary color-white" onClick={this.openModal.bind(this, 'editOwner', contacto)}>
													<i className="font-small fas fa-pen" />
												</button>
											</div>
										</td>
										<td className="text-center">
											<div className="aling-center justify-center">
												<button className="btn-full justify-center align-center btn-mini btn-secondary color-white" 
														type		=	'button' 
														onClick		=	{this.openModalDelete.bind(this, contacto.id_contacto)} 
														style		=	{{ padding: '5px' }} 
														disabled	=	{!this.props.escritura}
												>
													<i className="font-text fas fa-trash-alt " /> &nbsp; {this.props.icono ? "" : null}
												</button>
											</div>
										</td>    
									</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				{/* botones para enviar el formulario */}
				</div>

			<ModalBorrar 
				icono	=	{true}
				id		=	{this.state.id}
				delete	=	{this.delete.bind(this, this.state.id)} />

			<DinamicModal 
				idModal				=	{'addOwner'}
				sizeModalContainer	=	{'big'}
				title				=	{'Añadir contacto asociado a la vivienda'} 
				success				=	{this.handleSubmit.bind(this)} 
				showBtnSuccess		=	{true}
				btnTextSuccess		=	{"GUARDAR"}
				showBtnCancel		=	{true}
				btnTextCancel		=	{"CANCELAR"}
				loadingSave			=	{this.state.loadingSave}
				message				=	{this.state.message}
                disabledBtnSuccess  =   {!this.state.phoneValidated}
                closeModal          =   {() => this.setState({showNewModal: false})}>
					<FormNewContact 
						setInfo			=	{this.setOwnersInfo.bind(this)} 
						editVivienda	=	{this.props.editVivienda} 
						idVivienda		=	{this.props.id}
						direccion		=	{this.props.direccion}
                        subname			=	{this.props.subname}
                        validPhone      =   {(valid) => this.setState({phoneValidated: valid})}
                        showNewModal    =   {this.state.showNewModal}
						subLabel		=	{this.state.subLabel}
					/>
			</DinamicModal>

			<DinamicModal
				idModal				=	{'editOwner'}
				sizeModalContainer	=	{'big'}
				title				=	{'Editar contacto'} 
				success				=	{this.handleUpdate.bind(this)} 
				showBtnSuccess		=	{this.props.escritura}
				btnTextSuccess		=	{"GUARDAR"}
				showBtnCancel		=	{true}
				btnTextCancel		=	{"CANCELAR"}
				loadingSave			=	{this.state.loadingSave}
                message				=	{this.state.message}
                closeModal          =   {() => this.setState({contacto: {}})}
			>
				<FormEditContact 
					telefonoError	=	{this.state.telefonoError} 
					setInfo			=	{this.setOwnersInfo.bind(this)} 
					contacto		=	{this.state.contacto}
					subname			=	{this.props.subname}
					subLabel		=	{this.state.subLabel}
				/>
			</DinamicModal>
		</div>
		);
	}

	openModalDelete(id_contacto) {
		this.setState({id: id_contacto});
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

	limpiarState() {
		this.setState({
		pNombre: "",
		pDireccion: "",
		pMunicipio: "",
		pEstado: "",
		pCp: "",
		pTelefono: "",
		pPais: "",
		pGenero: 0,
		pId: 0,
		pNumId: "",
		pVigencia: 0,
		pFechaCompra: "",
		pIdPersona: 0,
		edditProp: false,
		search: false,
		isPrincipal: false,
		edditProp:false,
		email:''
		});
	}

	handleDateChange(date, event) {
		this.setState({ startDate: date });
	}

	handleChangeEddit(date) {
		this.setState({ pFechaCompra: document.getElementById("fecha").value });
	}

	handleClose() {
		this.setState({
		showModal: !this.state.showModal,
		pNombre: "",
		pDireccion: "",
		pMunicipio: "",
		pEstado: "",
		pCp: "",
		pTelefono: "",
		pPais: "",
		pGenero: 0,
		pId: 0,
		pNumId: "",
		pVigencia: 0,
		pFechaCompra: "",
		pIdPersona: 0,
		edditProp: false,
		search: false,
		viewOwners: true,
		add: false,
		email:''
		});
	}

	async handleDelete(id, close) {
		let data = {
			id_contacto: id
		};
		const response = await request.post("/viviendas/contacto/delete", data);
		if(response.deleted) {
			close();
			cogoToast.success('Contacto eliminado.', {
				position: 'bottom-right',
			});
		} else {
			cogoToast.error('No se pudo eliminar contacto.', {
				position: 'bottom-right',
			});
		}
		this.getContactosVivienda();
		this.props.reload();
	}

	// TRAE TODOS LOS CONTACTOS DE LA VIVIENDA
	async getContactosVivienda() {
		this.setState({loadingContactos: true});
    	let data = { idVivienda: this.props.id };

		const response = await request.post("/viviendas/vivienda/contactos", data);
		if (response.contactosVivienda && !response.empty) {
			this.setState({
				contactos: response.contactosVivienda,
				isPrincipal: response.contactosVivienda[0].principal
			});
			
		} else {
			this.setState({
				empty: true,
				message: response.message,
				contactos: []
			});
		}
		this.setState({loadingContactos: false});
	}

	async handleSubmit(event) {
    	event.preventDefault();
		this.setState({loadingSave: true});
		let ciudad = document.getElementById('city').value;
		let vivJson = [];
		vivJson.push(parseInt(this.props.id));
		let data = {
			// p_JSON
			viviendasJSON:		JSON.stringify(vivJson),
			Nombre: 			this.state.pNombre,
			Direccion: 			this.state.pDireccion,
			Municipio: 			this.state.pMunicipio,
			Estado: 			this.state.pEstado,
			Cp: 				this.state.pCp,
			Telefono: 			this.state.pTelefono,
			Pais: 				this.state.pPais,
			Genero: 			this.state.pGenero,
			TipoId: 			this.state.pId,
			NumId: 				this.state.pNumId,
			Vigencia: 			this.state.pVigencia,
			FechaCompra: 		this.state.pFechaCompra,
			Principal: 			this.state.pPrincpial,
			IdPersona: 			this.state.idPersona,
			principal: 			this.state.isPrincipal,
			email:				this.state.email,
			idFraccionamiento: 	localStorage.getItem('frac') || 0,
			city:				ciudad
		};
		const response = await request.post("/viviendas/contacto/crear", data);
		
		if(response.created){
			//this.setState({message: response.message});
			this.getContactosVivienda();
			this.props.reload();
			cogoToast.success('Contacto añadido.', {
				position: 'bottom-right',
			});
		}else{
			//this.setState({message: "NO SE PUDO CREAR CONTACTO"});
			cogoToast.error('No se pudo añadir contacto.', {
				position: 'bottom-right',
			});
		}
		this.setState({loadingSave: false});
	}
	
	async handleUpdate(event) {
		let principal = false;
		const checkPrincpial = document.getElementById('contacto-principal');
		if(checkPrincpial) {
			principal = checkPrincpial.checked;
		}
		event.preventDefault();
		this.setState({loadingSave: true});

		let ciudad = document.getElementById('citye').value;
		let data = {
			idVivienda: 		this.props.id,
			Nombre: 			this.state.pNombre,
			Direccion: 			this.state.pDireccion,
			Municipio: 			this.state.pMunicipio,
			Estado: 			this.state.pEstado,
			Cp: 				this.state.pCp,
			Telefono: 			this.state.pTelefono,
			Pais: 				this.state.pPais,
			Genero: 			this.state.pGenero,
			TipoId: 			this.state.pId,
			NumId: 				this.state.pNumId,
			Vigencia: 			this.state.pVigencia,
			FechaCompra: 		this.state.pFechaCompra,
			IdPersona: 			this.state.idPersona,
			Principal: 			principal,
			email:				this.state.email,
			idFraccionamiento: 	localStorage.getItem('frac'),
			city:				ciudad

		};
		const response 		= await request.post("/viviendas/contacto/update", data);
		if(response.updated){
			//this.setState({message: response.message});
			this.getContactosVivienda();
			this.props.reload();
			cogoToast.success('Datos de contacto actualizados.', {
				position: 'bottom-right',
			});
		}else{
			//this.setState({message: "NO SE PUDO ACTUALIZAR CONTACTO"});
			cogoToast.error('No se pudieron actualizar los datos de contacto.', {
				position: 'bottom-right',
			});
		}
		this.setState({loadingSave: false});
	}

}
export default ContactoModal;