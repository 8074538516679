import React, { Component } from "react";
import { Consumer } from "../../context";
import Request from "../../core/httpClient";
import PropietarioModal from './PropietarioModal';
import InquilinoModal from './inquilinoModal';
import ContactoModal from './contactoModal';
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import cogoToast from 'cogo-toast';

import ModalBorrar from '../modals/modalDelete';

const request = new Request();

class HousesTable extends Component {
	constructor(props) {
		super(props);
		this.focusAllValue = this.focusAllValue.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);
		this.state = {
			subfraccionamiento: this.props.sfrac_name,
			frac_name: this.props.frac_name,
			direccion: '',
			Nregistro: '',
			mts: '',
			pIndiviso: '',
			vacacional: '',
			comentarios: '',
			subfraccionamientos: this.props.subfraccionamientos,
			message: null,
			sub_id: this.props.sub_id,
			pago: false
		};
	};

	componentDidMount() {
		let data = window.localStorage.getItem('openRow');
		if (data) {
			data = JSON.parse(data);
			this.setState({ viviendaId: data.idVivienda, rowType: data.type, id_vivienda_row: data.idVivienda });
		};

		const subLabel = window.localStorage.getItem('subLabel');
		if (subLabel) {
			this.setState({ subLabel });
		};
	};

	getNumberFormat(cell_phone) {
		try {
			return `******${cell_phone.substring(cell_phone.length - 4)}`
		} catch (error) {
			return null;
		};
	};

	async openModal(idModal, idVivienda, vivienda) {

		this.setState({
			formMessage: null, viviendaId: idVivienda, vivienda: vivienda,
			subfraccionamientos: this.props.subfraccionamientos, comentarios: vivienda.comentarios, id_vivienda_row: idVivienda
		});

		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
			await this.getDetalleVivienda(idVivienda);
		};
	};

	openRow(type, idVivienda) {
		const data = { type, idVivienda };

		window.localStorage.setItem('openRow', JSON.stringify(data));

		if (this.state.viviendaId === idVivienda && type === this.state.rowType) {
			window.localStorage.removeItem('openRow');
			this.setState({ rowType: '', viviendaId: 0 });
			return;
		}

		this.setState({ rowType: type, viviendaId: idVivienda, id_vivienda_row: idVivienda });
	}

	setInfo(state) {
		this.setState({
			subfraccionamiento: state.subfraccionamiento,
			direccion: state.direccion,
			Nregistro: state.Nregistro,
			mts: state.mts,
			pIndiviso: state.pIndiviso,
			vacacional: state.vacacional,
			comentarios: state.comentarios
		});
	}

	async handledSubmit(event) {
		event.preventDefault();
		try {
			this.setState({ loadingSave: true });
		
			const form = event.target;
			let vacacional = this.state.vacacional;
	
			if (vacacional === true) {
				vacacional = 1
			} else {
				vacacional = 0
			};
	
			const data = {
				IdVivienda: this.state.IdViv,
				Direccion: form.houseAddress.value,
				Numero_Registro: form.houseNumber.value,
				UPrivativa: this.state.uPrivativa,
				mts: this.state.mts,
				proIn: this.state.pIndiviso,
				vacation: this.state.vacacional,
				comentarios: form.comentarios.value,
				subfrac: this.state.sub_fraccionamiento,
				pago: this.state.pago ? 1 : 0
			};
	
			const response = await request.post("/viviendas/vivienda/update", data);

			if (response.updated) {
				this.props.reload();
				cogoToast.success('Datos de vivienda actualizados correctamente.', {
					position: 'bottom-right'
				});
			} else {
				cogoToast.error('No se pudieron actualizar los datos de vivienda.', {
					position: 'bottom-right'
				});
			};
	
			this.setState({ loadingSave: false });

		} catch (error) {
			console.log(error);
		};
	};

	async editarPropietarios(idPropietario, id_vivienda) {
		try {
			const nombreProp = document.getElementById("fullNameEdit").value,
				direccionProp = document.getElementById("addressEdit").value,
				municipioProp = document.getElementById("municipalityEdit").value,
				estadoProp = document.getElementById("stateEdit").value

			if (nombreProp.length === 0 || direccionProp.length === 0 || municipioProp.length === 0 || estadoProp.length === 0) {
				return;
			} else {

				const data = {
					IdPropietario: idPropietario,
					Nombre: document.getElementById("fullNameEdit").value,
					Direccion: document.getElementById("addressEdit").value,
					Municipio: document.getElementById("municipalityEdit").value,
					Cp: document.getElementById("zipEDit").value,
					Estado: document.getElementById("stateEdit").value,
					Telefono: document.getElementById("phoneEdit").value

				};

				const response = await request.post("/propietarios/update", data);

				if (response && !response.error) {
					if (response.updated && !response.empty) {
						this.setState({
							reload: true,
							loading: false,
							edditProp: false
						});

						this.getPropietariosVivienda(id_vivienda);
					} else {
						this.setState({
							empty: true,
							message: response.message,
							loading: false
						});
					}
				} else {
					this.setState({
						error: true,
						message: response.message,
						loading: false
					});
				};
			};
		} catch (error) {
			console.log(error)
		};
	};

	handleOpen(idVivienda) {
		this.setState({ showModal: true });

		this.getDetalleVivienda(idVivienda);
	};

	openModalDelete(viv) {
		this.setState({ id_vivienda: viv.id_vivienda, id_vivienda_row: viv.id_vivienda, viviendaId: 0 });
		let modal = document.getElementById("deleteModal-house");
		if (modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		};
	};

	async handleInqDetalle(id_inquilino) {
		try {
			this.setState({ iNombre: "" });

			const data = { idInquilino: id_inquilino };
			const response = await request.post("/viviendas/vivienda/get/inquilino", data);

			if (response && !response.error) {
				if (response.inquilino && !response.empty) {
					this.setState({
						iNombre: response.inquilino[0].nombre,
						iTelefono: response.inquilino[0].telefono,
						ifechaNac: response.inquilino[0].fecha_nacimiento,
						isexo: response.inquilino[0].sexo,
						ipassword: response.inquilino[0].password,
						inquilinoId: response.inquilino[0].id_inquilino
					});
				} else {
					this.setState({ empty: true, message: response.message });
				}
			} else {
				this.setState({
					error: true,
					message: response.message
				});
			};
		} catch (error) {
			console.log(error);
		};
	};

	async handleDelete(id, close) {
		try {
			const data = { id_vivienda: id };
			const response = await request.post("/viviendas/vivienda/delete", data);

			if (response) {
				if (response.deleted) {
					this.props.reload();
					cogoToast.success('Vivienda eliminada.', {
						position: 'bottom-right',
					});
				} else {
					cogoToast.error('No se puede eliminar por que hay propietario, residente o contacto  asociado a la vivienda.', {
						position: 'bottom-right',
					});
				};
			};

			if (close) {
				close();
			};
		} catch (error) {
			console.log(error);
		};
	};

	handleClose() {
		this.setState({ showModal: false, edit: false, inquilinos: [] });
	};

	handleEdit() {
		this.setState({ edit: !this.state.edit });
	};

	handleAdd() {
		this.setState({ add: !this.state.add, edditInq: false });
	};

	handleEditInq(id_inquilino) {
		if (id_inquilino) {
			this.setState({ edditInq: !this.state.edditInq, add: false });
			this.handleInqDetalle(id_inquilino);
		} else {
			this.setState({ edditInq: !this.state.edditInq, add: false });
		};
	};

	handleEditProp(idPropietario) {
		if (idPropietario) {
			this.setState({ edditProp: !this.state.edditProp, add: false });
			this.handlePropietarioDetalle(idPropietario);
		} else {
			this.setState({ edditProp: !this.state.edditProp, add: false });
		};
	};

	handleDateChange(date, event) {
		this.setState({ startDate: date, ifechaNac: event.target.value });
	};

	// TRAE LOS DATOS DE LA VIVIENDA NO DEL PROPIETARIO
	async getDetalleVivienda(idVivienda) {
		try {
			this.setState({
				Nregistro: "",
				direccion: "",
				IdViv: 0,
				mts: 0,
				uPrivativa: '',
				pIndiviso: 0,
				vacacional: 0,
				loadingDetalle: true,
				pago: false
	
			});

			const data = { idVivienda: idVivienda };
			const response = await request.post("/propietarios/get/propietario", data);
	
			if (response && !response.error) {
				if (response.propietario && !response.empty) {
					this.setState({
						Nregistro: response.propietario[0].numero_registro,
						direccion: response.propietario[0].direccion,
						IdViv: response.propietario[0].id_vivienda,
						subfrac: response.propietario[0].nombre,
						mts: response.propietario[0].metros_cuadrados,
						uPrivativa: response.propietario[0].unidad_privativa,
						pIndiviso: response.propietario[0].pro_indiviso,
						vacacional: response.propietario[0].vacacional,
						pago: response.propietario[0].compartir_info_pago_app == 1 ? true : false,
						sub_fraccionamiento: response.propietario[0].id_subfraccionamiento
					});
	
				} else {
					this.setState({
						empty: true,
						message: response.message
					});
				};
			} else {
				this.setState({
					error: true,
					message: response.message
				});
			};

			this.setState({ loadingDetalle: false });	
		} catch (error) {
			console.log(error);
		};
	};

	focusAllValue(event) {
		const element = event.currentTarget;
		if (document.activeElement === element) {
			element.select();
		};
	};

	handleChange(event) {
		let valor = event.target.checked

		if (valor === true) {
			valor = 1
		} else {
			valor = 0
		}
		this.setState({ vacacional: valor })
	};

	render() {

		let houses = [];

		if (this.props.dataHouses) {
			houses = this.props.dataHouses;
		};

		return (
			<div className="card-table card-owners column" id="card-owners">
				<div className="table-responsiv column">
					<table>
						<thead className="sticky-th">
							<tr className="text-left">
								<th className="text-left">
									<div className="row">
										Vivienda &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'numero_registro' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
											}
										</div>
									</div></th>
								<th className="text-left description">
									<div className="row">
										Dirección &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'direccion' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'direccion')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'direccion')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'direccion')}></i>
											}
										</div>
									</div>
								</th>
								{this.props.sub_id == 0 &&
									<th className="text-left">
										<div className="row">
											{this.props.subLabel} &nbsp;
											<div className="align-center">
												{this.props.filterColumn.col === 'nombre_subfrac' ? this.props.filterColumn.filt === 'asc' ?
													<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'nombre_subfrac')}></i>
													:
													<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'nombre_subfrac')}></i>
													:
													<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'nombre_subfrac')}></i>
												}
											</div>
										</div>
									</th>
								}

								<th className="text-center th-options-small">Propietarios</th>
								<th className="text-center th-options-small">Residentes</th>
								<th className="text-center th-options-small">Contactos</th>
								<th className="text-center th-options-small">Editar</th>
								<th className="text-center th-options-small">Eliminar</th>
							</tr>
						</thead>
						<tbody>
							{houses.slice(((this.props.page - 1) * this.props.pageLength), (this.props.page * this.props.pageLength)).map((house, key) => {
								return (
									<React.Fragment key={house.id_vivienda}>
										<tr className={this.state.id_vivienda_row === house.id_vivienda ? 'row-active text-left' : ''}>
											<td className="text-left">{house.numero_registro}</td>
											<td className="text-left">{house.direccion}</td>
											{this.state.sub_id === 0 &&
												<td className="text-left">{house.nombre_subfrac}</td>
											}
											<td className="text-center">
												<button 
												    className="btn-full btn-mini btn-secondary color-white tooltip right"
												    data-tooltip={house.total_propietarios}
													onClick={this.openRow.bind(this, "propietarios", house.id_vivienda)}
												>
													&nbsp;
													<i className={`font-small fas fa-user-shield ${this.state.rowType === "propietarios" && this.state.id_vivienda_row === house.id_vivienda ? 'color-red' : ''}`} />
												</button>
											</td>
											<td className="text-center">
												<button 
												    className="btn-full btn-mini btn-secondary color-white tooltip right"
													data-tooltip={house.total_inquilinos}
													onClick={this.openRow.bind(this, "inquilinos", house.id_vivienda)} 
												>
													<i className={`font-small fas fa-users ${this.state.rowType === "inquilinos" && this.state.id_vivienda_row === house.id_vivienda ? 'color-red' : ''}`} />
												</button>
											</td>
											<td className="text-center">
												<button 
												    className="btn-full btn-mini btn-secondary color-white tooltip right" 
													data-tooltip={house.total_contactos}
													onClick={this.openRow.bind(this, "contactos", house.id_vivienda)} 
												>
													<i className={`font-small fas fa-address-book ${this.state.rowType === "contactos" && this.state.id_vivienda_row === house.id_vivienda ? 'color-red' : ''}`} />
												</button>
											</td>
											<td className="text-center">
												<button
													type="button"
													className="btn-full justify-center btn-mini btn-secondary color-white"
													onClick={this.openModal.bind(this, "editHouse", house.id_vivienda, house)}
												>
													<i className="font-small fas fa-pen" />
												</button>
											</td>
											<td className="text-center">
												<button className="btn-full justify-center btn-mini btn-secondary color-white"
													type='button'
													onClick={this.openModalDelete.bind(this, house)}
													style={{ padding: '5px' }}
													disabled={!this.props.escritura}
												>
													<i className="font-text fas fa-trash-alt " />
												</button>
											</td>

										</tr>

										{this.state.viviendaId === house.id_vivienda && this.state.rowType === 'propietarios' &&
											<tr className="acordeon-row">
												<td className="acordeon" colSpan="8">
													<div className="row full">

														<PropietarioModal
															reload={this.props.reload}
															id={this.state.viviendaId}
															id_vivienda={this.state.id_vivienda_row}
															direccion={house.direccion}
															editVivienda={false}
															subfraccionamientos={this.state.subfraccionamientos}
															subname={house.nombre_subfrac}
															escritura={this.props.escritura}
															total_propietarios={house.total_propietarios}
															getNumberFormat={this.getNumberFormat.bind(this)}
														></PropietarioModal>
													</div>
												</td>
											</tr>
										}
										{this.state.viviendaId === house.id_vivienda && this.state.rowType === 'inquilinos' &&
											<tr className="acordeon-row">
												<td className="acordeon" colSpan="8">
													<div className="row full">
														{/* RESIDENTES */}
														<InquilinoModal
															reload={this.props.reload}
															id={this.state.viviendaId}
															editVivienda={false}
															direccion={house.direccion}
															subname={house.nombre_subfrac}
															escritura={this.props.escritura}
															getNumberFormat={this.getNumberFormat.bind(this)}
														/>
													</div>
												</td>
											</tr>
										}
										{this.state.viviendaId === house.id_vivienda && this.state.rowType === 'contactos' &&
											<tr className="acordeon-row">
												<td className="acordeon" colSpan="8">
													<div className="row full">
														<ContactoModal
															reload={this.props.reload}
															id={this.state.viviendaId}
															editVivienda={false}
															direccion={house.direccion}
															subname={house.nombre_subfrac}
															escritura={this.props.escritura}
															getNumberFormat={this.getNumberFormat.bind(this)}
														/>
													</div>
												</td>
											</tr>
										}
									</React.Fragment>
								)
							})}
						</tbody>
					</table>
				</div>
				<DinamicModal
					idModal={'editHouse'}
					sizeModalContainer={'big'}
					title={'Editar vivienda'}
					success={this.handledSubmit.bind(this)}
					showBtnSuccess={this.props.escritura}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.formMessage}
					setInfo={this.setInfo.bind(this)}
				>
					{this.renderNewHouse()}
				</DinamicModal>
				<ModalBorrar
					name='deleteModal-house'
					id={this.state.id_vivienda}
					delete={this.handleDelete.bind(this)}
				/>
			</div>
		);
	};

	renderNewHouse() {
		let subfraccionamientos = [];

		if (Array.isArray(this.state.subfraccionamientos)) {
			subfraccionamientos = this.state.subfraccionamientos;
		};

		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b className="label-red">{this.props.subLabel}:</b></p>
						</div>
						<select className="input input-modals" name="subfraccionamiento"
							disabled={true}
							value={this.state.sub_fraccionamiento}
							id='subfrac'
						>
							{subfraccionamientos.map((sub, index) =>
								<option key={index} value={sub.id_subfraccionamiento}>{sub.nombre}</option>
							)}
						</select>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Vivienda:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="houseNumber"
								id="houseNum"
								className="input input-modals"
								required
								maxLength="25"
								value={this.state.Nregistro}
								onChange={event => this.setState({ Nregistro: event.target.value })}
								autoComplete={'off'}
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Dirección:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type='text'
								name='houseAddress'
								className='input input-modals'
								required={false}
								maxLength='150'
								value={this.state.direccion}
								onChange={event => this.setState({ direccion: event.target.value })}
								autoComplete='off'
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p >
								<b>Metros cuadrados:</b>
							</p>
						</div>
						<div className="column full">
							<input
								required={true}
								className="input input-modals text-center"
								type="number"
								name="mts"
								value={this.state.mts}
								onChange={event => this.setState({ mts: event.target.value })}
								step="any"
								autoComplete="off"
							/>
						</div>
						<div className="column label-duo-normal space">
							<p >
								<b>Indiviso:</b>
							</p>
						</div>
						<div className="column full space tooltip left" data-tooltip="El valor del indiviso es base 100">
							<input
								className="input input-modals text-center"
								min={0}
								max={100}
								step="any"
								type="number"
								name="proInd"
								value={this.state.pIndiviso}
								onChange={event => this.setState({ pIndiviso: event.target.value })}
								required
								autoComplete='off'
								onClick={this.focusAllValue}
							/>
						</div>
					</div>

					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column ">
							<label className="container-check" >
								<input
									type="checkbox"
									checked={this.state.vacacional === 1 ? (true) : (false)}
									name="vacation"
									onChange={this.handleChange.bind(this)}
								/>
								<span className="checkmark" ></span>
							</label>
						</div>
						<div className="column ">
							<p>
								<b>Esta vivienda es de renta vacacional</b>
							</p>
						</div>
					</div>
					{this.state.valida ? (<p className="color-primary">El porcentaje de Indiviso no puede ser mayor a 100%</p>) : (null)}

					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column ">
							<label className="container-check" >
								<input
									type="checkbox"
									checked={this.state.pago}
									name="pagos"
									onChange={(event) => { this.setState({ pago: !this.state.pago }) }}
								/>
								<span className="checkmark" ></span>
							</label>
						</div>
						<div className="column ">
							<p><b>No compartir información de pagos con la APP:</b></p>
						</div>
					</div>

					<div className="white-space-8" />
					<div className="row full justify-start">
						<p><b>Comentarios:</b></p>
					</div>
					<div className="white-space-8" />
					<div className="row full justify-start">
						<textarea name="comentarios" id="comentarios" value={this.state.comentarios} cols="30" rows="3" maxLength="500" className='textarea font-small full'
							onChange={(event) => this.setState({ comentarios: event.target.value })}></textarea>
					</div>
				</div>
			</div>
		);
	};
};

export default Consumer(HousesTable);
