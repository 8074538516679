import React, { useEffect, useState } from 'react';

const Reporte = ({reporte_resultado}) => {
	const [preguntas, setPreguntas] = useState([]);

	useEffect(() => {
		if (Array.isArray(reporte_resultado)) {
			setPreguntas(reporte_resultado);
		}
	}, [reporte_resultado])

	return (
		<div className="table-responsive table-asistencia-container" id="tablaReporteAsamblea">
			<table className="table-asistencia">
					{preguntas.map(preg =>
						<tbody>
							<tr>
								<td> <span className="weight-bold"> {preg.pregunta} </span> </td>
							</tr>
							<tr>
								<td>
									<table>
										<thead>
											<tr>
												<th className="text-center">Respuesta</th>
												<th className="text-center">Total votos</th>
												<th className="text-center">Indiviso</th>
											</tr>
										</thead>
										<tbody>
											{preg.respuestas.map(resp => 
												<tr>
													<td className="text-left">{resp.respuesta}</td>
													<td className="text-center">{resp.Total_Votos}</td>
													<td className="text-center">{resp.pro_indiviso}</td>
												</tr>
											)}
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
					)}
			</table>
		</div>
	)
}

export default Reporte;
