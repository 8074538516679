import React from "react";
import { Consumer } from "../../context";
import SidebarAdministracion from "../../components/header/sidebarAdministracion";
import { Helmet } from "react-helmet";
import Header from "../../components/header/header";
import Request from "../../core/httpClient";
import Controls from "../../components/controls/controls";
import Permissions from "../../middlewares/Permissions";
import Tabla_asamblea from "../../components/encuestas/asamblea_table";
import Tabla_encuesta from "../../components/encuestas/encuestas_table";
import Paginador from "../../components/paginador/paginador";
import DinamicModal from "../../components/dinamicModal/dinamicModal";
import DatePicker from "react-datepicker";
import Core from "../../components/core/SuperCore";
import cogoToast from "cogo-toast";
import TimeField from "react-simple-timefield";
import sortableData from '../../helpers/sortableDataTable';

const request = new Request();
const logo = require("../../img/logo-AppMosphera-r.png");

class Encuestas extends Core {
	constructor(props) {
		super(props);
		this.state = {
			id_tipo_encuesta: 0,
			activado: false,
			sub_fraccionamiento: 0,
			id_encuesta_aplicacion: 1,
			nombre: null,
			descripcion: null,
			desde: new Date(),
			hasta: new Date(),
			time1: "00:00",
			time2: "23:59",
			politicas: "",

			subfraccionamientos: [],
			encuestas: [],
			encuestas_tipo: [],
			preguntas_tipos: [],
			encuestas_aplicacion: [],

			lectura: false,
			escritura: false,
			loadingEncuetas: true,
			page: 1,
			loadingSave: false,
			filter: "",
			sub_filtro: 0,
			pageLength: 20,
			direction: {},
			filterColumn: {},
			submenu: '',
			subLabel: ''
		};
	};

	async validarPermisos() {
		let permisos = new Permissions();
		let permisosInfo = await permisos.getResult();
		this.setState({
			escritura: permisosInfo.escritura,
			lectura: permisosInfo.lectura,
		});
	};

	async componentDidMount() {
		await this.validarPermisos();
		await this.getSubmenu();
		await this.setType();
		await this.getSubFraccionamientos();
		await this.getEncuestasTipo();
		await this.getEncuestasAplicacion();
		await this.getPreguntasTipos();
		await this.getEncuestas();
	};

	async getSubmenu() {
		const submenu = await this.props.context.getSubmenuAdminName();
		if (submenu) {
			this.setState({ submenu });
		}

		const subLabel = window.localStorage.getItem('subLabel');
		if (subLabel) {
			this.setState({ subLabel });
		}
	}

	async setType() {
		let match = this.props.match;
		this.setState({ id_tipo_encuesta: 1 });
		if (match.path !== "/encuestas/encuestas") {
			this.setState({ id_tipo_encuesta: 2 });
		}
	}

	setFilter(filter) {
		this.setState({ filter: filter.toLowerCase() });
	}

	subChange(val) {
		this.setState({
			sub_filtro: parseInt(val),
		});
	}

	async getSubFraccionamientos() {
		try {
			const fraccionamiento = localStorage.getItem("frac");

			if (!fraccionamiento) {
				return;
			};

			const data = { Idsub: fraccionamiento };
			const response = await request.post("/admin/administracion/get/subfraccionamientos", data);

			if (response.subfraccionamiento) {
				this.setState({ subfraccionamientos: response.subfraccionamiento });
			} else {
				this.setState({ subfraccionamientos: [] });
			};

		} catch (error) {
			console.log(error);
		};
	};

	async getEncuestas() {
		this.setState({ loadingEncuetas: true });

		const response = await request.get("/encuestas/get/encuestas", {
			IdFraccionamiento: localStorage.getItem("frac"),
			IdEncuestaTipo: this.state.id_tipo_encuesta,
		});

		if (response.error || response.empty) {
			this.setState({ loadingEncuetas: false, encuestas: [] });
			return;
		}

		this.setState({ loadingEncuetas: false, encuestas: response });
	}

	async getEncuestasTipo() {
		try {
			const response = await request.get("/encuestas/get/encuestas/tipos");

			if (response.error || response.empty) {
				this.setState({ encuestas_tipo: [] });
				return false;
			};

			this.setState({
				encuestas_tipo: response,
			});
		} catch (error) {
			console.log(error);
		};
	};

	async getEncuestasAplicacion() {
		try {
			const response = await request.get("/encuestas/get/encuestas/aplicacion");

			if (response.error || response.empty) {
				this.setState({ encuestas_aplicacion: [] });
				return false;
			}

			this.setState({
				encuestas_aplicacion: response,
				id_encuesta_aplicacion: parseInt(response[0].id_encuesta_aplicacion),
			});
		} catch (error) {
			console.log(error);
		};
	};

	async getPreguntasTipos() {
		try {
			const response = await request.get("/encuestas/get/preguntas/tipos", {});

			if (response.empty || response.error) {
				this.setState({ preguntas_tipos: [] });
				return false;
			};

			this.setState({ preguntas_tipos: response });
		} catch (error) {
			console.log(error);
		};
	};

	async createEncuesta(e) {
		e.preventDefault();
		try {
			this.setState({ loadingSave: true });
			const usuario = await this.getUserInfo();

			let d = this.state.desde;
			let t1 = this.state.time1;
			let desde_format = this.getDateFormat(d.getFullYear(), d.getMonth(), d.getDate(), t1);
			let h = this.state.hasta;
			let t2 = this.state.time2;
			let hasta_format = this.getDateFormat(h.getFullYear(), h.getMonth(), h.getDate(), t2);

			if (desde_format === hasta_format) {
				cogoToast.warn("La hora de fecha hasta debe ser mayor a la hora de fecha desde.", {
					position: "bottom-right",
				});

				this.setState({ loadingSave: false });
				return;
			}

			if (hasta_format < desde_format) {
				cogoToast.warn("La hora de fecha hasta no puede ser mayor a la hora de fecha de desde.", {
					position: "bottom-right",
				});

				this.setState({ loadingSave: false });
				return;
			}

			const data = {
				IdEncuestaTipo: parseInt(this.state.id_tipo_encuesta),
				IdFraccionamiento: parseInt(localStorage.getItem("frac")),
				IdSubfraccionamiento: parseInt(this.state.sub_fraccionamiento),
				IdEncuestaAplicacion: this.state.id_tipo_encuesta == 1 ? parseInt(this.state.id_encuesta_aplicacion) : 2,
				Nombre: this.state.nombre,
				Descripcion: this.state.descripcion ? this.state.descripcion : "",
				Desde: desde_format,
				Hasta: hasta_format,
				Usuario: usuario.info.usuario,
				Activo: this.state.activado,
				Politicas: this.state.politicas ? this.state.politicas : "",
			};

			const response = await request.post("/encuestas/add/encuesta", data);

			if (response.error) {
				cogoToast.error("No se pudo crear encuesta.", {
					position: "bottom-right",
				});

				this.setState({ loadingSave: false });
				return false;
			};

			await this.getEncuestas();

			cogoToast.success("Encuesta creada con éxito.", {
				position: "bottom-right",
			});

			this.closeModalDinamic();
			this.setState({ loadingSave: false });
		} catch (error) {
			console.log(error);
		};
	};

	getDateFormat(year, month, date, time) {
		month++; //ajustar de 1-12
		let fecha = `${year.toString()}-`;

		if (month < 10) {
			fecha += "0";
		}

		fecha += `${month.toString()}-`;

		if (date < 10) {
			fecha += "0";
		}

		fecha += `${date.toString()}T`;
		fecha += `${time.toString()}:00`;

		return fecha;
	}

	closeModalDinamic() {
		this.closeModal("newEncuesta");

		this.setState({
			activado: false,
			id_encuesta_aplicacion: this.state.encuestas_aplicacion[0].id_encuesta_aplicacion,
			sub_fraccionamiento: 0,
			nombre: "",
			descripcion: "",
			desde: new Date(),
			hasta: new Date(),
			time1: "00:00",
			time2: "23:59",
		});
	};

	sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, this.state.encuestas, this.state.direction[key]);

		this.setState({
			encuestas: items,
			direction: {
				[key]: direction
			},
			filterColumn: {
				col: key,
				filt: direction
			}
		});
	}

	async changeDate(desde) {
		this.setState({ desde });

		if (this.state.hasta < desde) {
			this.setState({ hasta: desde });
		};
	};

	setPage(page) {
		this.setState({ page });
	};

	paginatorChange(length) {
		this.setState({ pageLength: length });
		this.setPage(1);
	};

	render() {
		return (
			<div className="owners column">
				<Helmet>
					<title>
						{process.env.REACT_APP_NAME} - {this.state.id_tipo_encuesta ? (this.state.id_tipo_encuesta == 1 ? " Encuestas Rápidas" : "Votaciones Asambleas") : ""}
					</title>
				</Helmet>
				<Header sidebar={true} nav={"administracion"} />

				<div className="row">
					<div className="column" id={"sidebar"}>
						<SidebarAdministracion />
					</div>
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								{this.state.lectura ? (
									<React.Fragment>
										<Controls
											title={this.state.submenu}
											nuevo={this.openModal.bind(this, "newEncuesta")}
											newButton={this.state.escritura}
											excelButton={false}
											setFilter={this.setFilter.bind(this)}
											subChange={this.subChange.bind(this)}
										/>

										{this.state.loadingEncuetas ? (
											<div className="row justify-center">
												<i className="fas fa-spinner fa-spin"></i>
											</div>
										) : !this.state.id_tipo_encuesta ? (
											""
										) : this.state.id_tipo_encuesta == 1 ? (
											<Tabla_encuesta
												page={this.state.page}
												escritura={this.state.escritura}
												subfraccionamientos={this.state.subfraccionamientos}
												encuestas_tipo={this.state.encuestas_tipo}
												encuestas_aplicacion={this.state.encuestas_aplicacion}
												preguntas_tipos={this.state.preguntas_tipos}
												encuestas={this.state.sub_filtro != 0 ? this.state.encuestas.filter((enc) => enc.id_subfraccionamiento == this.state.sub_filtro) : this.state.encuestas}
												getEncuestas={this.getEncuestas.bind(this)}
												filter={this.state.filter}
												pageLength={this.state.pageLength}
												sortBy={this.sortableTable.bind(this)}
												filterColumn={this.state.filterColumn}
												subLabel={this.state.subLabel}
											/>
										) : (
											<Tabla_asamblea
												page={this.state.page}
												escritura={this.state.escritura}
												subfraccionamientos={this.state.subfraccionamientos}
												encuestas_tipo={this.state.encuestas_tipo}
												encuestas_aplicacion={this.state.encuestas_aplicacion}
												preguntas_tipos={this.state.preguntas_tipos}
												encuestas={this.state.sub_filtro != 0 ? this.state.encuestas.filter((enc) => enc.id_subfraccionamiento == this.state.sub_filtro) : this.state.encuestas}
												getEncuestas={this.getEncuestas.bind(this)}
												filter={this.state.filter}
												pageLength={this.state.pageLength}
												sortBy={this.sortableTable.bind(this)}
												filterColumn={this.state.filterColumn}
												subLabel={this.state.subLabel}
											></Tabla_asamblea>
										)}
										<div className="white-space-16" />
										<Paginador
											pages={Math.ceil(this.state.encuestas.length / this.state.pageLength)}
											setPage={this.setPage.bind(this)}
											pageLength={this.state.pageLength}
											paginatorChange={this.paginatorChange.bind(this)}
											showPageLenght={true}
										/>
									</React.Fragment>
								) : (
									null
								)}
							</div>
						</div>
					</div>
				</div>
				<DinamicModal
					idModal={"newEncuesta"}
					enforceFocus={true}
					sizeModalContainer={"small"}
					title={this.state.id_tipo_encuesta ? (this.state.id_tipo_encuesta == 1 ? "Añadir encuesta" : "Añadir asamblea") : ""}
					success={this.createEncuesta.bind(this)}
					showBtnSuccess={true}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.saveMessage}
					closeModal={this.closeModalDinamic.bind(this)}
				>
					{this.renderEncuesta()}
				</DinamicModal>
			</div>
		);
	}

	renderEncuesta() {
		let encuestas_tipo = this.state.encuestas_tipo;
		let encuestas_aplicacion = this.state.encuestas_aplicacion;
		let sub_fraccionamientos = this.state.subfraccionamientos;

		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-duo-normal">
							<p>
								<b>Tipo de encuesta:</b>
							</p>
						</div>
						<div className="column full">
							<select
								name=""
								id=""
								className="input input-modals"
								onChange={(e) => {
									this.setState({ id_tipo_encuesta: e.target.value });
								}}
								required
								value={this.state.id_tipo_encuesta}
								disabled
							>
								{encuestas_tipo.map((et) => (
									<option value={et.id_encuesta_tipo}>{et.tipo}</option>
								))}
							</select>
						</div>

						<div className="column label-medium space align-end">
							<p>
								<b>Activar:</b>
							</p>
						</div>
						<div className="column space">
							<label className="container-check">
								<input type="checkbox" checked={this.state.activado} name="activar-check" onClick={(e) => this.setState({ activado: !this.state.activado })} />
								<span className="checkmark"></span>
							</label>
						</div>
					</div>

					<div className="white-space-16" />

					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Aplicación:</b>
							</p>
						</div>
						<div className="column full">
							{this.state.id_tipo_encuesta == 1 ? (
								<select
									name=""
									id=""
									className="input input-modals"
									onChange={(e) => {
										this.setState({ id_encuesta_aplicacion: e.target.value });
									}}
									required
									value={this.state.id_encuesta_aplicacion}
								>
									{encuestas_aplicacion.map((et) => (
										<option value={et.id_encuesta_aplicacion}>{et.aplicacion}</option>
									))}
								</select>
							) : (
								<select name="" id="" className="input input-modals" disabled value={2}>
									<option value="2">Propietarios</option>
								</select>
							)}
						</div>
					</div>

					<div className="white-space-16" />

					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>{this.state.subLabel}:</b>
							</p>
						</div>
						<div className="column full">
							<select
								className="input input-modals"
								name="subfraccionamiento"
								id="subfrac"
								value={this.state.sub_fraccionamiento}
								onChange={(event) => this.setState({ sub_fraccionamiento: event.target.value })}
							>
								<option value="0">{"Todos"}</option>
								{sub_fraccionamientos.map((sub, index) => (
									<option key={index} value={sub.id_subfraccionamiento}>
										{sub.nombre}
									</option>
								))}
							</select>
						</div>
					</div>

					<div className="white-space-16" />

					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Nombre:</b>
							</p>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								type="text"
								name=""
								id=""
								autoComplete="off"
								required
								value={this.state.nombre}
								onChange={(e) => {
									this.setState({ nombre: e.target.value });
								}}
							/>
						</div>
					</div>

					<div className="white-space-16" />

					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Descripción:</b>
							</p>
						</div>
						<div className="column full">
							<div className="column full">
								<textarea
									className=" input input-modals"
									autoComplete="off"
									rows="1"
									value={this.state.descripcion}
									onChange={(e) => {
										this.setState({ descripcion: e.target.value });
									}}
								/>
							</div>
						</div>
					</div>
					<div className="white-space-16" />

					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Políticas:</b>
							</p>
						</div>
						<div className="column full">
							<div className="column full">
								<textarea
									className=" input input-modals"
									autoComplete="off"
									rows="2"
									value={this.state.politicas}
									onChange={(e) => {
										this.setState({ politicas: e.target.value });
									}}
								/>
							</div>
						</div>
					</div>

					<div className="white-space-16" />

					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Desde:</b>
							</p>
						</div>
						<div className="column full">
							<DatePicker
								className="input input-modals"
								selected={this.state.desde}
								onChange={(event) => {
									this.changeDate(event);
								}}
								dateFormat="dd/MM/yyyy"
								locale="es"
							/>
						</div>

						<div className="column label-duo-normal align-end">
							<p>
								<b>Hora:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="time"
								className="input input-modals"
								placeholder="hrs:mins"
								value={this.state.time1}
								onChange={(event) => {
									this.setState({ time1: event.currentTarget.value });
								}}
							/>
						</div>
					</div>

					<div className="white-space-16" />

					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Hasta:</b>
							</p>
						</div>
						<div className="column full">
							<DatePicker
								className="input input-modals"
								selected={this.state.hasta}
								onChange={(event) => this.setState({ hasta: event })}
								dateFormat="dd/MM/yyyy"
								locale="es"
								minDate={this.state.desde}
							/>
						</div>

						<div className="column label-duo-normal align-end">
							<p>
								<b>Hora:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="time"
								className="input input-modals"
								placeholder="hrs:mins"
								value={this.state.time2}
								onChange={(event) => {
									this.setState({ time2: event.currentTarget.value });
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Consumer(Encuestas);
