import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from "../../components/header/header";
import SidebarOperacion from "../../components/header/sidebarOperacion";
import Controls from '../../components/controls/controls';
import Paginador from "../../components/paginador/paginador";
import Request from "../../core/httpClient";
import Permissions from '../../middlewares/Permissions';
import PedidosTable from '../../components/restaurantes/pedidos/table';
import moment from 'moment';
import cogoToast from 'cogo-toast';
import sortableData from '../../helpers/sortableDataTable';

import Socket from '../../core/socket';
const request = new Request();

let values = {};

class Pedidos extends Component {
	constructor(props){
		super(props);
		this.state = {
			showModal: 				false,
			restaurantes: 			[],
            categorias: 			[],
            pedidos:                [],
			filterData: 			[],
			keys: 					[],
			page: 					1,
			filter: 				'',
			subfraccionamiento: 	localStorage.getItem('subfrac') || 0,
            fraccionamiento: 		localStorage.getItem('frac') || 0,
            id_restaurante:         localStorage.getItem('pedIdRes' || 0),
            fecha_entrega:          moment().format('YYYY-MM-DD'),
            categoriaSelected:      '',
			escritura:				false,
			lectura:				false,
			logo:					require ("../../img/logo-AppMosphera-r.png"),
            menu:					null,
            isModalOpen:            false,
            loading:                false,
            direction: {
                vivienda: 'desc',
                fecha_entrega: 'desc',
                estado: 'desc',
                total: 'desc'
            },
            filterColumn: {
                col: '',
                filt: ''
            }
		}

		this.validarPermisos();
	}

    componentDidMount() {
		this.getRestaurantes();
		
		Socket.on('newPedidoRestaurante', (data) => {
			this.reload();
		});
	}

	async validarPermisos(){
		let permisos = new Permissions();
		//  permisos.getResult();
		let permisosInfo = await permisos.getResult()
		await this.setState({escritura: permisosInfo.escritura, lectura: permisosInfo.lectura});
	}

	componentDidUpdate(prevProps, prevState) {
		
	}

	async openModal(idModal) {
		await this.setState({ message: null, imageArea: ''});
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
        }
        this.setState({isModalOpen: true });
        const input = document.getElementById('name');
        if (input) {
            input.focus();
        }
    }

	closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
	}

	/*funcion del paginador*/
	setPage(page) {
		this.setState({ page });
	}

	reload() {
		this.getPedidos(this.state.id_restaurante, this.state.fecha_entrega)
	}

	changeFrac() {
		this.getRestaurantes()
	}

	subChange(sub) {
		this.setState({ subfraccionamiento: sub });
	}

	setFilter(filter) {
		this.setState({ filter: filter.toLowerCase() });
	}

	filter(Restaurantes) {
		Restaurantes = Restaurantes.filter(res => {
			if (res.nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		});
		return Restaurantes;
	}

    async getRestaurantes() {
        this.setState({loading: true});
        const data = {
            idFraccionamiento: this.state.fraccionamiento
        }
        const res = await request.post('/restaurantes/get', data);
        if (Array.isArray(res.restaurantes)) {
            this.setState({restaurantes: res.restaurantes});
            const id_restaurante = localStorage.getItem('pedIdRes');
            if (id_restaurante) {
                this.setState({id_restaurante});
            }
            this.getPedidos(id_restaurante);
        } else {
            this.setState({empty: true, restaurantes: [] });
        }
        this.setState({loading: false});
    }

    async getPedidos(id_restaurante, fecha) {
        this.setState({loading: true});
        const res = await request.post('/restaurantes/pedidos/get', {
			id_restaurante,
			id_fraccionamiento: this.state.fraccionamiento,
			fecha: moment(fecha).format()
		});
        if (res.pedidos) {
            this.setState({pedidos: res.pedidos});
        } else {
            this.setState({pedidos: []/*, message: res.message*/ });
        }
        this.setState({loading: false});
    }

    changeRestaurante(event) {
        const id_restaurante = event.target.value;
        this.setState({id_restaurante});
        localStorage.setItem('pedIdRes', id_restaurante);
    }

    changeFecha(event) {
        this.setState({fecha_entrega: event.target.value});
    }

    findPedidos() {
        this.getPedidos(this.state.id_restaurante, this.state.fecha_entrega);
    }

    sortableTable(key) {
        let sd = new sortableData();
        const { items, direction } = sd.sortBy(key, this.state.pedidos, this.state.direction[key]);

        this.setState({
            pedidos: items,
            direction: {
                [key]: direction
            },
            filterColumn: {
                col: key,
                filt: direction
            }
        });
    }

	render() {
        let restaurantes = [];

		if (Array.isArray(this.state.restaurantes)) {
			restaurantes = this.state.restaurantes;
        };

		let menu =  this.state.menu;
		
		return (
			<div className="admin column">
				<Helmet>
					<title>{process.env.REACT_APP_NAME} - Pedidos</title>
				</Helmet>
				<Header 
					reload	=	{this.reload.bind(this)} 
					change	=	{this.changeFrac.bind(this)} 
					sidebar	=	{true} 
					active	=	{'activas'} 
					parent	=	{'areas'} 
					panel	=	{'panel3'} 
					nav		=	{'operacion'} 

					menu = {this.state.menu != null ? this.state.menu : null}
					/>
					
				<div className="row">
					<div className="column" id="sidebar">
						<SidebarOperacion />
					</div>
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								{this.state.lectura?
								<React.Fragment>
									<Controls title="Pedidos"
                                        nuevo		=	{this.openModal.bind(this, "newPedido")}
                                        dataSet		=	{this.state.pedidos}
                                        setFilter	=	{this.setFilter.bind(this)}
                                        subChange	=	{this.subChange.bind(this)} 
                                        newButton	=	{false}
                                        selectSub	=	{false}
								    />
                                    <div className="row justify-start align-center">
                                        <div className="column justify-center">
                                            <p>Restaurante:</p>
                                            <select className="input input-modals" value={this.state.id_restaurante} onChange={this.changeRestaurante.bind(this)}>
                                                <option value="0">Todos</option>
                                                {restaurantes.map(res => <option key={res.id_restaurante} value={res.id_restaurante}>{res.nombre}</option>)}
                                            </select>
                                        </div>
                                        &nbsp;
                                        <div className="column justify-center">
                                            <p>Fecha Entrega:</p>
                                            <input
                                                type="date"
                                                className="input input-modals"
                                                value={this.state.fecha_entrega}
                                                onChange={this.changeFecha.bind(this)}/>
                                        </div>
                                        &nbsp;
                                        <div className="column justify-center" style={{width:'30%'}}>
                                            <div className="white-space-16"/>
                                            <button
                                                className="btn btn-primary btn-small color-white space-16"
                                                disabled={this.state.loading}
                                                onClick={this.findPedidos.bind(this)}>
                                                {!this.props.loading ?
                                                    <i className="fas fa-search" />
                                                    : <i className="fas fa-spinner fa-spin" />
                                                }
                                                &nbsp; OBTENER
                                            </button>
                                        </div>
                                    </div>
                                    <div className="white-space-16" />
                                    {this.state.loading ?
                                        <div className="row justify-center">
                                            <i className="fas fa-spinner fa-spin"></i>
                                        </div>
                                        :
                                        <PedidosTable
                                            id_restaurante  =   {this.state.id_restaurante}
                                            pedidos         =   {this.state.pedidos}
                                            page            =   {this.state.page}
                                            escritura       =   {this.state.escritura}
                                            reload          =   {this.reload.bind(this)}
                                            sortBy          =   {this.sortableTable.bind(this)}
                                            filterColumn    =   {this.state.filterColumn}
                                        />
                                    }
								    <div className="white-space-16"></div>
								    <Paginador pages={Math.ceil(restaurantes.length / 7)} setPage={this.setPage.bind(this)} />
								</React.Fragment>:
								null
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
    }

    getValues(data) {
        values = data;
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({loadingSave: true, message: null});
        const res = await request.post('/restaurantes/create', {...values, idFraccionamiento: this.state.fraccionamiento});
        if (res.created) {
            this.reload();
            this.closeModal('newRestaurante');
            cogoToast.success('Pedido agregado.', {
                position: 'bottom-right',
            });
        } else {
            //this.setState({message: res.message || 'No se pudo agregar el restaurante.'});
            cogoToast.error('No se pudo agregar pedido.', {
                position: 'bottom-right',
            });
        }
        this.setState({loadingSave: false});
    }
}

export default Pedidos;