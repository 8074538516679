import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import cogoToast from "cogo-toast";
import { Consumer } from "../../context";
import Request from "../../core/httpClient";
const request = new Request();

const BlackoutDates = ({ area, updateData, context }) => {
    const [descripcion, setDescripcion] = useState('');
    const [fechasBloqueadas, setFechasBloqueadas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [loadRemoving, setLoadRemoving] = useState(false);

    useEffect(() => {

        if (area.descripcion) {
            setDescripcion(area.descripcion);
        } else {
            setDescripcion('');
        };

        getFechasBloqueadas();

    }, [area]);

    async function colocaValor(id, valor) {
        const inputs = document.getElementsByName(id);
        if (inputs) {
            inputs.forEach((input) => {
                input.value = valor;
            });
        };
    };

    async function addDate(e) {
        e.preventDefault();

        setLoading(true);
        const form = e.target;
        let user = await context.loadUser();

        //Se construye el objeto para mostrar en pantalla si se agrega correctamente la fecha a bd.
        const date = {
            fecha: form.fecha.value,
            motivo: form.motivo.value
        };

        if (user) {

            const data = {
                id_espacio: area.id_espacio,
                fecha_bloqueada: form.fecha.value,
                motivo: form.motivo.value,
                usuario: user.id.nombre,
                fecha_upd: moment().format("YYYY-MM-DD HH:mm:ss"),
                tipo: 1
            };

            await saveData(data);
        };
    };

    async function removeDate(item) {
        setLoadRemoving(item.id);

        let user = await context.loadUser();
        if (user) {
            //Guardado de datos en bd.
            const data = {
                id_espacio: area.id_espacio,
                fecha_bloqueada: item.fecha,
                motivo: '',
                usuario: user.id.nombre,
                fecha_upd: moment().format("YYYY-MM-DD HH:mm:ss"),
                tipo: 2
            }

            await saveData(data);
        };
    };

    async function saveData(data) {
        try {
            const response = await request.post("/commonArea/bloquear/fecha", data);

            if (response) {
                if (response.error) {
                    cogoToast.error(response.message, {
                        position: "bottom-right",
                    });
                };

                if (response.updated) {

                    if (data.tipo === 1) {
                        cogoToast.success("Fecha bloqueada agregada correctamente.", {
                            position: "bottom-right"
                        });

                        await colocaValor("motivo", "");
                        await colocaValor("fecha", "");
                    } else {
                        cogoToast.success("Fecha bloqueada eliminada correctamente.", {
                            position: "bottom-right"
                        });
                    };

                    await getFechasBloqueadas();
                };

                if (response.existe) {
                    cogoToast.error(response.message, {
                        position: "bottom-right"
                    });

                    await colocaValor("motivo", "");
                    await colocaValor("fecha", "");
                };
            };

            setLoading(false);
            setLoadRemoving(false);
        } catch (error) {
            console.log(error);
        };
    };

    async function getFechasBloqueadas() {
        try {
            setLoadingData(true);

            const response = await request.post("/commonArea/get/fechasBloqueadas", { id_espacio: area.id_espacio });

            if (response) {
                if (response.error) {
                    cogoToast.error(response.message, {
                        position: "bottom-right",
                    });
                };
    
                if (response.empty) {
                    setFechasBloqueadas([]);
                };
    
                if (response.fechas_bloqueadas) {
                    console.log(response.fechas_bloqueadas);
                    let arrayBloqueos = [];
                    response.fechas_bloqueadas.map(row => {
                        arrayBloqueos = [...arrayBloqueos, { fecha: row.fecha_bloqueada, motivo: row.motivo, id: row.id }];
                    });
    
                    setFechasBloqueadas(arrayBloqueos);
                };
            };
    
            setLoadingData(false);

        } catch (error) {
            console.log(error);
        };
    };

    return (
        <div className="container">
            <div className="column full">
                <div className="white-space-4"></div>
                <div className="column">
                    <p>
                        <b>Políticas de uso:</b>
                    </p>
                    <div className="white-space-8"></div>
                    <div className="align-center row full">
                        <div className="column full">
                            <textarea
                                name="descripcion"
                                className="textarea input input-modals"
                                cols="50"
                                rows="5"
                                autoComplete="off"
                                value={descripcion}
                                onChange={(event) => {
                                    setDescripcion(event.target.value)
                                    updateData({ descripcion: event.target.value })
                                }}
                            ></textarea>
                        </div>
                    </div>
                </div>
                <div className="white-space-8"></div>
                <div className="column">
                    <p>
                        <b>Defina qué fechas quiere bloquear:</b>
                    </p>
                    <div className="white-space-8"></div>
                    <div className="align-center row full">
                        <div className="row full">
                            <form className="width-45" onSubmit={addDate.bind(this)}>
                                <div className="column">
                                    <p>Fecha a bloquear:</p>
                                    <input className="input" type="date" name="fecha" required />
                                </div>
                                <div className="white-space-8"></div>
                                <div className="column">
                                    <p>Motivo: </p>
                                    <input type="text" className="input" name="motivo" required />
                                </div>
                                <div className="white-space-8"></div>
                                <div>
                                    <button className="btn btn-primary color-white full" type="submit">
                                        {loading ? <i className="fas fa-spinner fa-spin"></i> : 'Agregar'}
                                    </button>
                                </div>
                            </form>
                            <div className="fechas-bloqueadas full">
                                <div className="card-table card-owners column" id="card-owners">
                                    <div className="table-responsiv column">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className="text-left">Fecha bloqueada</th>
                                                    <th className="text-left">Motivo</th>
                                                    <th className="text-center">Quitar</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loadingData ? <tr>
                                                    <td colSpan="3">
                                                        <div className="white-space-8"></div>
                                                        <div className="justify-center">
                                                            <i className="fas fa-spinner fa-spin"></i>
                                                        </div>
                                                        <div className="white-space-8"></div>
                                                    </td>
                                                </tr> :
                                                    fechasBloqueadas.length !== 0 ? fechasBloqueadas.map((item, i) => {
                                                        return <tr>
                                                            <td>{moment(item.fecha).format("DD/MM/YYYY")}</td>
                                                            <td>{item.motivo}</td>
                                                            <td className="text-center">{loadRemoving === item.id ? <i className="fas fa-spinner fa-spin"></i> : <i className="fas fa-times-circle color-red cursor-pointer space-4" onClick={removeDate.bind(this, item)}></i>}</td>
                                                        </tr>
                                                    }) :
                                                        <tr>
                                                            <td colSpan="3">
                                                                <div className="justify-center">
                                                                    <h4>No se encontraron resultados.</h4>
                                                                </div>
                                                            </td>
                                                        </tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Consumer(BlackoutDates);