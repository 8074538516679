/**
 *  daily-invitations.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Tablas de Visitas del día
 */

import React, { Component } from 'react';
import moment from 'moment';
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import Core from '../../components/core/SuperCore';
import Request from '../../core/httpClient';
import cogoToast from 'cogo-toast';
import { Consumer } from '../../context';
import Paginador from '../../components/paginador/paginador';

const req = new Request();

class DailyInvitations extends Core {
	constructor(props) {
		super(props);
		this.state = {
			loadingSave: false,
			user: {},
			dailyPage: 1,
			activesPage: 1
		}
	}

	async componentDidMount() {
		await this.loadUser();
	};

	async loadUser() {
		const user = await this.props.context.loadUser();
		if (user) {
			this.setState({ user: user.id });
		};
	};

	async handleSubmit(event) {
		event.preventDefault();
		try {

			this.setState({ loadingSave: true });

			const data = {
				invitacion: this.state.invitacion,
				usuario: this.state.user,
				id_fraccionamiento: localStorage.getItem('frac')
			};

			const res = await req.post('/dashboard/marcar_salida', data);

			if (res.checked) {
				cogoToast.success(res.message || 'Salida registrada.', {
					position: 'bottom-right',
				});
				this.closeModal('ConfirmExitCheck');
				this.props.reload();

			} else {
				cogoToast.error(res.message || 'Error al marcar salida.', {
					position: 'bottom-right',
				});
			};
			this.setState({ loadingSave: false });
		} catch (error) {
			console.log(error);
		};
	};

	render() {
		let dataDiaria = [], dataActiva = [];

		if (this.props.dataDiaria) {
			dataDiaria = this.props.dataDiaria;

		};

		if (this.props.dataActiva) {
			dataActiva = this.props.dataActiva;
		};

		return (
			<div className="daily-invitations row-responsive">
				<div className="card-table card-table-1 column">
					<div className="title">
						<h4>Invitados del día</h4>
					</div>
					<div className="white-space-8" />
					{this.props.loadingDiarios ?
						<div className="row justify-center">
							<i className="fas fa-spinner fa-spin"></i>
						</div>
						:
						this.props.dataDiaria.length !== 0 ? (
							<div className="card-container">
								<div className="table-responsive">
									<table>
										<thead>
											<tr>
												<th className="text-left">Nombre</th>
												<th className="text-left">Vivienda</th>
												<th className="text-left">Fecha y hora</th>
											</tr>
										</thead>
										<tbody>
											{dataDiaria.map((invitacion, key) => (
												<tr key={key}>
													<td className="text-left">{invitacion.nombre_invitado}</td>
													<td className="text-left">{invitacion.numero_registro}</td>
													<td className="text-left">
														{invitacion.Hora ? (
															moment(invitacion.Hora).format('DD/MM/YYYY [-] hh:mm A')
														) : (
															'N/A'
														)}
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
								<Paginador pages={Math.ceil(this.props.dataDiaria.length / 10)} setPage={(page) => this.setState({ dailyPage: page })} />
							</div>
						) : (
							<b>Sin invitados</b>
						)}
				</div>
				<div className="card-table card-table-1 column">
					<div className="title">
						<h4>Invitados en fraccionamiento</h4>
					</div>
					<div className="white-space-8" />
					{this.props.loadingActivos ?
						<div className="row justify-center">
							<i className="fas fa-spinner fa-spin"></i>
						</div>
						:
						this.props.dataActiva.length !== 0 ? (
							<div className="card-container">
								<div className="table-responsive">
									<table>
										<thead>
											<tr className="text-left">
												<th className="left">Nombre</th>
												<th className="left">Vivienda</th>
												<th className="text-left">Fecha y hora</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											{dataActiva.slice((this.state.activesPage - 1) * 10, this.state.activesPage * 10).map((invitacion, key) => (
												<tr key={key}>
													<td className="text-left">
														<i className="fas fa-circle color-success" />
														&nbsp;
														{invitacion.nombre_invitado}
													</td>
													<td className="text-left">{invitacion.numero_registro}</td>
													<td className="text-left" style={{ width: '165px' }}>
														{moment(invitacion.Hora).format('DD/MM/YYYY [-] hh:mm A')}
													</td>
													<td>
														<button
															className="btn btn-primary btn-extrasmall color-white"
															onClick={() => {
																this.openModal('ConfirmExitCheck');
																this.setState({ invitacion });
															}}>
															Salida
														</button>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
								<Paginador pages={Math.ceil(this.props.dataActiva.length / 10)} setPage={(page) => this.setState({ activesPage: page })} />
							</div>
						) : (
							<b>Sin invitados en el fraccionamiento</b>
						)}
				</div>
				<DinamicModal
					idModal={'ConfirmExitCheck'}
					sizeModalContainer={'small'}
					title={''}
					success={this.handleSubmit.bind(this)}
					btnTextSuccess={"ACEPTAR"}
					btnTextCancel={"CANCELAR"}
					showBtnSuccess={true}
					showBtnCancel={true}
					loadingSave={this.state.loadingSave}
					message={this.state.messageSave}>
					<div className="row justify-center align-center">
						<h3>¿Registrar salida del invitado?</h3>
					</div>
				</DinamicModal>
			</div>
		);
	};
};

export default Consumer(DailyInvitations);
