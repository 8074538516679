import React, { Component } from 'react';
import ModalEddit from './userEdditModal'
import Request from "../../core/httpClient";
import ModalBorrar from '../modals/modalDelete';
import cogoToast from 'cogo-toast';

const request = new Request();

class UsersTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id_usuario: '',
            dataUser: []
        };
    };

    async recargar() {
        await this.props.reload()
    };

    delete = id => {
        this.handleDelete(id)
    };

    openModalDelete(id) {
        this.setState({ id_usuario: id, id_usuario_row: id });
        let modal = document.getElementById("deleteModal");
        if (modal) {
            modal.classList.remove('hide-modal');
            modal.classList.add('show-modal');
        };
    };

    openModal(idModal, data) {
        this.setState({ mesagge: null, dataUser: data, id_usuario_row: data.id_usuario });
        const modal = document.getElementById(idModal);
        if (modal) {
            modal.classList.remove('hide-dinamic-modal');
            modal.classList.add('show-dinamic-modal');
        };
        this.getUser();
        document.getElementById('fname').focus();
    };

    closeModal(idModal) {
        this.setState({ mesagge: null });
        const modal = document.getElementById(idModal);
        if (modal) {
            modal.classList.add('hide-dinamic-modal');
            modal.classList.remove('show-dinamic-modal');
        };
    };

    async handleDelete(id) {
        try {
            const data = { idUsuario: id };
            const response = await request.post("/users/fraccionamiento/delete/user", data);

            if (response && !response.error) {
                if (response.deleted && !response.empty) {
                    this.setState({ showModal: false });
                    cogoToast.success('Usuario eliminado.', {
                        position: 'bottom-right',
                    });
                } else {
                    this.setState({ empty: true });
                    cogoToast.error('No se pudo eliminar usuario.', {
                        position: 'bottom-right',
                    });
                };
            } else {
                this.setState({ error: true });
                cogoToast.error('No se pudo eliminar usuario.', {
                    position: 'bottom-right',
                });
            };
            await this.props.reload();
        } catch (error) {
            console.log(error);
        };
    };

    async getUser() {
        try {
            const id = this.props.idUser;
            const data = { IdUser: id };

            const response = await request.post("/users/get/fraccionamiento/user", data);

            if (response && !response.error) {
                if (response.user && !response.empty) {
                    this.setState({
                        nombre: response.user[0].nombre,
                        usuario: response.user[0].usuario,
                        rol: response.user[0].id_perfil,
                        idFraccionamiento: response.user[0].id_fraccionamiento
                    });
                } else {
                    this.setState({
                        empty: true,
                        message: response.message
                    });
                };
            } else {
                this.setState({
                    error: true,
                    message: response.message
                });
            };
        } catch (error) {
            console.log(error);
        };
    };

    render() {
        let perfiles = [];

        if (this.props.perfiles) {
            perfiles = this.props.perfiles;
        };

        let users = [];

        if (this.props.users) {
            users = this.props.users;
        };

        let listSubfraccionamiento = [];

        if (this.props.listSubfraccionamiento) {
            listSubfraccionamiento = this.props.listSubfraccionamiento
        };

        return (
            <div className="card-table card-owners column" id="card-owners">
                <table className="full">
                    <thead className="sticky-th">
                        <tr className="text-left">
                            <th className="name">
                                <div className="row">
                                    Nombre &nbsp;
                                    <div className="align-center">
                                        {this.props.filterColumn.col === 'nombre' ? this.props.filterColumn.filt === 'asc' ?
                                            <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
                                            :
                                            <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
                                            :
                                            <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
                                        }
                                    </div>
                                </div>
                            </th>
                            <th className="">
                                <div className="row">
                                    Usuario &nbsp;
                                    <div className="align-center">
                                        {this.props.filterColumn.col === 'usuario' ? this.props.filterColumn.filt === 'asc' ?
                                            <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'usuario')}></i>
                                            :
                                            <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'usuario')}></i>
                                            :
                                            <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'usuario')}></i>
                                        }
                                    </div>
                                </div>
                            </th>
                            <th className="date">
                                <div className="row">
                                    Perfil &nbsp;
                                    <div className="align-center">
                                        {this.props.filterColumn.col === 'perfil' ? this.props.filterColumn.filt === 'asc' ?
                                            <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'perfil')}></i>
                                            :
                                            <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'perfil')}></i>
                                            :
                                            <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'perfil')}></i>
                                        }
                                    </div>
                                </div>
                            </th>
                            <th className="date text-center th-options-small">Editar</th>
                            <th className="date text-center th-options-small">Eliminar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            users.slice(((this.props.page - 1) * this.props.pageLength), (this.props.page * this.props.pageLength)).map((usuario, key) => {

                                return (
                                    <tr className={this.state.id_usuario_row === usuario.id_usuario ? 'row-active' : ''} key={key} >
                                        <td>{usuario.nombre}</td>
                                        <td>{usuario.usuario}</td>
                                        <td>{usuario.perfil}</td>
                                        <td className="text-center" >
                                            <button
                                                className="btn-full justify-center btn-mini btn-secondary color-white"
                                                type="button justify-center"
                                                onClick={this.openModal.bind(this, "editUsers", usuario)}
                                            >
                                                <i className="font-small fas fa-pen" />
                                            </button >
                                        </td>
                                        <td className="text-center" >
                                            <button className="btn-full justify-center align-center btn-mini btn-secondary color-white"
                                                type='button'
                                                onClick={this.openModalDelete.bind(this, usuario.id_usuario)}
                                                style={{ padding: '5px' }}
                                                disabled={!this.props.escritura}
                                            >
                                                <i className="font-text fas fa-trash-alt " />
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

                <ModalEddit
                    data={this.state.dataUser}
                    reload={this.recargar.bind(this)}
                    perfiles={perfiles}
                    escritura={this.props.escritura}
                    listSubfraccionamiento={listSubfraccionamiento} 
                />

                <ModalBorrar
                    id={this.state.id_usuario}
                    delete={this.delete.bind(this)}
                />

            </div>
        );
    };
};

export default UsersTable;