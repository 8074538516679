import React from 'react';
import { Helmet } from "react-helmet";
import Header from '../components/header/header';
import SidebarOperacion from '../components/header/sidebarOperacion';
import Permissions from '../middlewares/Permissions';
import PenalizationsTable from '../components/commonAreasPenalization/penalizationTable';
import Controls from '../components/controls/controls';
import Paginador from "../components/paginador/paginador";
import Core from './../components/core/SuperCore';
import DinamicModal from '../components/dinamicModal/dinamicModal';
import cogoToast from 'cogo-toast';
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { Consumer } from "../context";
import Request from "../core/httpClient";
import sortableData from '../helpers/sortableDataTable';

registerLocale('es', es);

const request = new Request();

class Penalizaciones extends Core {
	constructor(props) {
		super(props);
		this.state = {
			lectura: false,
			escritura: false,
			user: {},
			page: 1,
			penalizaciones: [],
			filter: '',
			subfraccionamientos: [],
			id_subfraccionamiento: 0,
			id_subfraccionamiento_traer_viviendas: localStorage.getItem('subfrac'),
			unidades: [],
			id_unidad: 0,
			areas: [],
			id_area: 0,
			desde: new Date(),
			hasta: new Date(),
			pageLength: 20,
			direction: {},
			filterColumn: {},
			submenu: '',
			subLabel: ''
		};
	};

	async componentDidMount() {
		await this.validarPermisos();
		await this.getSubmenu();
		await this.loadUser();
		await this.getPenalizaciones();
		await this.getSubfraccionamientos();
		await this.getUnidades();
		await this.getAreasComunes();
	};

	async getSubmenu() {
		const submenu = await this.props.context.getSubmenuOpeName();

		if (submenu) {
			this.setState({ submenu });
		};

		const subLabel = window.localStorage.getItem('subLabel');
		if (subLabel) {
			this.setState({ subLabel });
		};
	};

	async loadUser() {
		const user = await this.props.context.loadUser();
		if (user) {
			this.setState({
				user: {
					idUsuario: user.id.id_usuario,
					idPerfil: user.id.id_perfil,
					idFraccionamiento: user.id.id_fraccionamiento,
					nombre: user.id.nombre
				}
			});
		};
	};

	async validarPermisos() {
		let permisos = new Permissions();
		//  permisos.getResult();
		let permisosInfo = await permisos.getResult()
		this.setState({ escritura: permisosInfo.escritura, lectura: permisosInfo.lectura });
	}

	setFilter(filter) {
		this.setState({ filter: filter.toLowerCase() });
	}

	async subChange(sub) {
		this.setState({ id_subfraccionamiento: sub, id_subfraccionamiento_traer_viviendas: sub });
		await this.getPenalizaciones();
	}

	setPage(page) {
		this.setState({ page });
	};

	paginatorChange(length) {
		this.setState({ pageLength: length });
		this.setPage(1);
	};

	async reload() {
		await this.getPenalizaciones();
	};

	async getSubfraccionamientos() {
		try {
			const fraccionamiento = localStorage.getItem('frac');
			const subfraccionamiento = localStorage.getItem('subfrac');

			if (!fraccionamiento) {
				return;
			};

			const data = { Idsub: fraccionamiento, filter: 1 };
			const response = await request.post("/admin/administracion/get/subfraccionamientos", data);

			if (response.error || response.empty) {
				this.setState({ subfraccionamientos: [] });
				return;
			};

			if (response.subfraccionamiento) {
				this.setState({
					subfraccionamientos: response.subfraccionamiento,
					id_subfraccionamiento: subfraccionamiento
				});
			};

		} catch (error) {
			console.log(error);
		};
	};

	async getPenalizaciones() {
		try {
			this.setState({ loading: true });

			const data = {
				id_fraccionamiento: localStorage.getItem('frac'),
			};

			const response = await request.post('/commonArea/penalizaciones/obtener', data);

			if (response.penalizaciones) {
				this.setState({ penalizaciones: response.penalizaciones });
			} else {
				this.setState({ penalizaciones: [] });
			};

			this.setState({ loading: false });
		} catch (error) {
			console.log(error);
		};
	};

	filter(penalizaciones) {
		const filtro = this.state.filter.toLowerCase();
		return penalizaciones.filter(p => {
			if (p.nombre.toLowerCase().indexOf(filtro) !== -1) {
				return true;
			} else if (p.numero_registro.toLowerCase().indexOf(filtro) !== -1) {
				return true;
			} else if (p.desde.toLowerCase().indexOf(filtro) !== -1) {
				return true;
			} else if (p.hasta.toLowerCase().indexOf(filtro) !== -1) {
				return true;
			}
			return false;
		})
	};

	sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, this.state.penalizaciones, this.state.direction[key]);

		this.setState({
			penalizaciones: items,
			direction: {
				[key]: direction
			},
			filterColumn: {
				col: key,
				filt: direction
			}
		});
	};

	async changeSubfraccionamiento(event) {
		let id_sub = event.target.value;
		this.setState({ unidades: [], areas: [], id_unidad: 0, id_area: 0, horarios: [], id_horario: 0, fecha: null });
		this.setState({
			id_subfraccionamiento: id_sub,
			id_subfraccionamiento_traer_viviendas: id_sub
		})
		await this.getUnidades();
		await this.getAreasComunes();
	}

	async getUnidades() {
		try {
			this.setState({ unidades: [], id_unidad: 0 });

			const data = {
				id_fraccionamiento: localStorage.getItem('frac'),
				id_subfraccionamiento: this.state.id_subfraccionamiento_traer_viviendas
			};

			const response = await request.post("/viviendas/getViviendaBySubFraccionamiento", data);

			if (response.empty || response.error) {
				this.setState({ unidades: [] });
				cogoToast.info('No se encontraron viviendas en este fraccionamiento.', {
					position: 'bottom-right',
				});
				return;
			};

			if (response.viviendas) {
				this.setState({
					unidades: response.viviendas,
					id_unidad: response.viviendas[0].id_vivienda
				});
			};

		} catch (error) {
			console.log(error);
		};
	};

	async getAreasComunes() {
		try {
			this.setState({ id_area: 0, areas: [] });

			const data = {
				id: localStorage.getItem('frac'),
				idsubfrac: this.state.id_subfraccionamiento_traer_viviendas || 0
			};

			const response = await request.post("/commonArea/get/info", data);

			if (response.empty || response.error) {
				this.setState({ areas: [] });
				cogoToast.info('No se encontraron áreas comunes en este subfraccionamiento.', {
					position: 'bottom-right',
				});
			};

			if (response.info) {
				let primer_area = response.info[0];
				this.setState({
					areas: response.info,
					id_area: primer_area.id_espacio
				});
			};

		} catch (error) {
			console.log(error);
		};
	};

	async changeUnidad(event) {
		this.setState({ id_unidad: event.target.value });
	};

	async changeAreaComun(event) {
		this.setState({ id_area: event.target.value });
	};

	async handleSubmit(event) {
		event.preventDefault();
		try {
			this.setState({ loadingSave: true });

			if (this.state.desde > this.state.hasta) {
				cogoToast.warn(('El rango de fechas no es válido.'), {
					position: 'bottom-right',
				});

				this.setState({ loadingSave: false });
				return;
			};

			const data = {
				idUsuario: this.state.user.idUsuario,
				idArea: this.state.id_area,
				idVivienda: this.state.id_unidad,
				desde: moment(this.state.desde).format('YYYY-MM-DD'),
				hasta: moment(this.state.hasta).format('YYYY-MM-DD')
			};

			const response = await request.post('/commonArea/penalizaciones/agregar', data);

			if (response.created) {
				cogoToast.success('Penalización agregada.', {
					position: 'bottom-right',
				});
				this.closeModal('newPenalizacion')
				this.reload();
			} else {
				cogoToast.error(response.message || 'Error al agregar penalización.', {
					position: 'bottom-right',
				});
			};

			this.setState({ loadingSave: false });
		} catch (error) {
			console.log(error);
		};
	};

	render() {
		
		let penalizaciones = [];

		if (Array.isArray(this.state.penalizaciones)) {
			penalizaciones = this.state.penalizaciones;
			penalizaciones = this.filter(penalizaciones);
		};

		return (
			<div className="houses column">
				<Helmet>
					<title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
				</Helmet>
				<Header
					sidebar={true}
					active={'reservas'}
					parent={'areas'}
					panel={'panel3'}
					nav={"operacion"}
				/>
				<div className="row">
					<div className="column" id="sidebar">
						<SidebarOperacion />
					</div>
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								{this.state.lectura ?
									<React.Fragment>
										<Controls title={this.state.submenu}
											nuevo={this.openModal.bind(this, "newPenalizacion")}
											dataSet={penalizaciones}
											setFilter={this.setFilter.bind(this)}
											subChange={this.subChange.bind(this)}
											newButton={this.state.escritura}
										/>
										{this.state.loading ?
											<div className="row justify-center">
												<i className="fas fa-spinner fa-spin"></i>
											</div>
											:
											<PenalizationsTable
												penalizaciones={penalizaciones}
												page={this.state.page}
												user={this.state.usuario}
												reload={this.reload.bind(this)}
												escritura={this.state.escritura}
												pageLength={this.state.pageLength}
												sortBy={this.sortableTable.bind(this)}
												filterColumn={this.state.filterColumn}
												subLabel={this.state.subLabel}
											/>
										}
										<div className="white-space-16"></div>
										<Paginador
											pages={Math.ceil(penalizaciones.length / this.state.pageLength)}
											setPage={this.setPage.bind(this)}
											pageLength={this.state.pageLength}
											paginatorChange={this.paginatorChange.bind(this)}
											showPageLenght={true}
										/>

									</React.Fragment> :
									null
								}
							</div>
						</div>
					</div>
				</div>
				<DinamicModal
					idModal={'newPenalizacion'}
					sizeModalContainer={'small'}
					title={'Añadir penalización'}
					success={this.handleSubmit.bind(this)}
					btnTextSuccess={"GUARDAR"}
					btnTextCancel={"CANCELAR"}
					showBtnSuccess={true}
					showBtnCancel={true}
					loadingSave={this.state.loadingSave}
					message={this.state.messageSave}
				>
					{this.renderNewPenalizacion()}
				</DinamicModal>
			</div>
		)
	}

	renderNewPenalizacion() {
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-medium"><p><b>{this.state.subLabel}:</b></p></div>
						<div className="column full">
							<select
								className="input input-modals"
								value={this.state.id_subfraccionamiento}
								disabled={(localStorage.getItem('subfrac') || 0) == 0 ? false : true}
								onChange={this.changeSubfraccionamiento.bind(this)}
								required
							>
								{
									this.state.subfraccionamientos.map((subfraccionamiento, key) => (

										<option value={subfraccionamiento.id_subfraccionamiento} key={key}>
											{subfraccionamiento.nombre}
										</option>))
								}
							</select>
						</div>
					</div>
					<div className="white-space-16"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium"><p><b>Vivienda:</b></p></div>
						<div className="column full">
							<select
								className="input input-modals"
								value={this.state.id_unidad}
								onChange={this.changeUnidad.bind(this)}
								required
							>
								{
									this.state.unidades.map((unidad, key) => (

										<option value={unidad.id_vivienda} key={key}>
											{unidad.numero_registro}
										</option>))
								}
							</select>
						</div>
					</div>
					<div className="white-space-16"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium"><p><b>Áreas:</b></p></div>
						<div className="column full">
							<select
								className="input input-modals"
								value={this.state.id_area}
								onChange={this.changeAreaComun.bind(this)}
								required
							>
								{
									this.state.areas.map((area, key) => (

										<option value={area.id_espacio} key={key}>
											{area.nombre}
										</option>))
								}
							</select>
						</div>
					</div>
					<div className="white-space-16"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big"><p><b>Desde:</b></p></div>
						<div className="column full">
							<DatePicker
								className="input input-modals"
								selected={this.state.desde}
								onChange={(fecha) => this.setState({ desde: fecha })}
								dateFormat="dd/MM/yyyy"
								locale="es"
								required
							// disabled    =   {this.state.fecha_dia}
							// showMonthYearPicker
							/>
						</div>

						<div className="column label-duo-normal space"><p><b>Hasta:</b></p></div>
						<div className="column full">
							<DatePicker
								className="input input-modals"
								selected={this.state.hasta}
								onChange={(fecha) => this.setState({ hasta: fecha })}
								dateFormat="dd/MM/yyyy"
								locale="es"
								minDate={this.state.desde}
								required
							// disabled    =   {this.state.fecha_dia}
							// showMonthYearPicker
							/>
						</div>
					</div>
				</div>
			</div>
		);
	};
};

export default Consumer(Penalizaciones);
