import React, { Component } from 'react';
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import moment from "moment";
import Request from "../../core/httpClient";
import ModalBorrar from '../modals/modalDelete';
import { isValidPhoneNumber } from 'react-phone-number-input';
import FormNewInquilino from "../inquilinos/formNewInquilino";
import FormEditInquilino from "../inquilinos/formEditInquilino";
import cogoToast from 'cogo-toast';
import sortableData from '../../helpers/sortableDataTable';

const request = new Request();

class InquilinoModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: 			false,
			startDate: 			new Date(),
			inquilinos: 		[],
			inquilino: 			{},
			idViv: 				0,
			add: 				false,
			edditProp: 			false,
			search: 			false,
			pNombre: 			'',
			pDireccion: 		this.props.direccion,
			pMunicipio: 		'',
			pEstado: 			'',
			pCp: 				'',
			pTelefono: 			'',
			pPais: 				'',
			pGenero: 			0,
			pId: 				0,
			pNumId: 			'',
			pVigencia: 			'',
			pFechaCompra: 		'',
			pIdPersona: 		0,
			isChecked: 			false,
			isPrincipal: 		false,
			viewOwners: 		true,
			email:				'',
			comentarios:        '',
			id: 				0,
			residentes_status: 	[],
            residente_status:	0,
            phoneValidated:     false,
            showNewModal:       false,
			direction: {
				nombre: 'desc',
				estatus: 'desc'
			},
			filterColumn: {
				col: '',
				filt: ''
			},
			subLabel: ''
		}
	}

	componentDidMount() {
		this.getInquilinosVivienda();
		this.load();
	}

	async load() {
		const res = await request.get('/inquilinos/get/tipo_status');
		if(res.tipo_status) {
			this.setState({residentes_status: res.tipo_status});
		}

		const subLabel = window.localStorage.getItem('subLabel');
		if (subLabel) {
			this.setState({ subLabel });
		}
	}

	componentDidUpdate(prevProps) {
		if(this.props.id !== prevProps.id) {
			this.getInquilinosVivienda();
		}
	}

	openModal(idModal, inq) {
		this.setState({message: null, inquilino: inq});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
            modal.classList.add('show-dinamic-modal');
            //Refresca datos
            this.setState({showNewModal: true});
		}

		switch(idModal){
			case 'addOwner':
					document.getElementById('inqname').focus();
				break;
			case 'editOwner':
					document.getElementById('editinqname').focus();
				break;
		}
	}

	delete = (idState, id, close) => {
		this.handleDelete(id, close)
	}

	propietario = data => {
		this.setState({
			pNombre: 		data.nombre,
			pDireccion: 	data.direccion,
			pMunicipio: 	data.municipio,
			pEstado: 		data.estado,
			pCp: 			data.cp,
			pTelefono: 		data.telefono,
			pPais: 			data.pais,
			pGenero: 		data.genero,
			pId: 			parseInt(data.tipo_identificacion),
			pNumId: 		data.num_identificacion,
			pVigencia: 		data.vigencia_identificacion,
			pFechaCompra: 	moment(data.fecha_compra).format("YYYY-MM-DD"),
			pIdPersona: 	data.id_persona,
			email: 			data.email
		});
	}

	setOwnersInfo(state) {
		this.setState({
			IdViv: 				this.props.id,
			pNombre: 			state.INombre,
			pDireccion: 		state.IDireccion,
			pMunicipio: 		state.IMunicipio,
			pEstado: 			state.IEstado,
			pCp: 				state.ICp,
			pTelefono: 			state.iTelefono,
			pPais: 				state.IPais,
			pGenero: 			state.IGenero,
			pId: 				state.IId,
			pNumId: 			state.iNumId,
			pVigencia: 			state.iVigencia || '',
			pFechaIngreso: 		state.IFechaIngreso,
			pFechaSalida: 		state.IFechaSalida,
			idPersona: 			state.IIdPersona,
			principal: 			state.isPrincipal,
			email: 				state.email,
			comentarios:        state.comentarios,
			residente_status: 	state.residente_status
		});
	}

	sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, this.state.inquilinos, this.state.direction[key]);

		this.setState({
			inquilinos: items,
			direction: {
				[key]: direction
			},
			filterColumn: {
				col: key,
				filt: direction
			}
		});
	}

  	render() {
		let id = 0
		if (this.props.id) {
			id = this.props.id;
		}
    	return (
			<div className="full row align-center">
				<div className="column full">
					{/*<div className="">
						<div className="justify-end">
							{this.props.escritura && 
							<button className="btn btn-mini btn-primary color-white" type="button" id="btn-modal-edit" onClick={this.openModal.bind(this,"addOwner",id)} >
								<i className="fas fa-plus"/> Añadir
							</button>
							}
						</div>
					</div> */}
					<div className="column card-table">
						<div className="table-responsiv column">
							<table>
								<tbody>
									<tr className="pad-bot row-without-border">
										<th colSpan="4" className="text-left">
											<h4 className="weight-semi">Residentes</h4>
										</th>
										{this.props.escritura && <th>
											<button className="btn btn-mini btn-primary color-white" type="button" id="btn-modal-edit" onClick={this.openModal.bind(this, "addOwner", id)} >
												<i className="fas fa-plus" /> Añadir
											</button>
										</th>}
									</tr>
									<tr className="text-left">
										<th className="">Nombre</th>
										<th className="text-left">Teléfono</th>
										<th className="th-options-huge">Estado APP</th>
										<th className="text-center th-options-small">Editar</th>
										<th className="text-center th-options-small">Borrar</th>
									</tr>
								{	this.state.loadingInquilinos? 
									<tr>
										<td colSpan="4">
											<div className="row justify-center">
												<i className="fas fa-spinner fa-spin"></i>
											</div>
										</td>
									</tr>
									:
									this.state.inquilinos.map((inquilino, key) => (
									<tr key={key}>
										<td className="text-left">
												{inquilino.nombre} 
										</td>
										
										<td className="text-left">
												{this.props.getNumberFormat(inquilino.telefono)}
										</td>
										<td>
											{inquilino.estatus}
										</td>
										<td className="text-center ">
											<div className="aling-center justify-center">
												<button type="button" className="btn-full justify-center btn-mini btn-secondary color-white" onClick={this.openModal.bind(this, 'editOwner', inquilino)}>
													<i className="font-small fas fa-pen" />
												</button>
											</div>
										</td>
										<td className="text-center">
											<div className="aling-center justify-center">
												<button className="btn-full justify-center align-center btn-mini btn-secondary color-white" 
														type		=	'button' 
														onClick		=	{this.openModalDelete.bind(this, inquilino)} 
														style		=	{{ padding: '5px' }} 
														disabled	=	{!this.props.escritura}
												>
													<i className="font-text fas fa-trash-alt " /> &nbsp; {this.props.icono ? "" : null}
												</button>
											</div>
										</td>    
									</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				{/* botones para enviar el formulario */}
				</div>

				<ModalBorrar 
					icono={true}
					id={this.state.id}
					delete={this.delete.bind(this, this.state.id)} />

				<DinamicModal 
					idModal					=	{'addOwner'}
					sizeModalContainer		=	{'big'}
					title					=	{'Añadir residente'} 
					success					=	{this.handleSubmit.bind(this)} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}
					btnTextCancel			=	{"CANCELAR"}
					loadingSave				=	{this.state.loadingSave}
					message					=	{this.state.message}
                    disabledBtnSuccess      =   {!this.state.phoneValidated}
                    closeModal              =   {() => this.setState({showNewModal: false})}>
					<FormNewInquilino 
						setInfo			=	{this.setOwnersInfo.bind(this)} 
						editVivienda	=	{this.props.editVivienda} 
						idVivienda		=	{this.props.id}
						direccion		=   {this.props.direccion}
						subname			=	{this.props.subname}
                        estadosAPP		= 	{this.state.residentes_status}
                        validPhone      =   {(valid) => this.setState({phoneValidated: valid})}
                        showNewModal    =   {this.state.showNewModal}
						subLabel		=	{this.state.subLabel}
					/>
				</DinamicModal>

				<DinamicModal
					idModal					=	{'editOwner'}
					sizeModalContainer		=	{'big'}
					title					=	{'Editar residente'} 
					success					=	{this.handleUpdate.bind(this)} 
					showBtnSuccess			=	{this.props.escritura}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}
					btnTextCancel			=	{"CANCELAR"}
					loadingSave				=	{this.state.loadingSave}
					message					=	{this.state.message}
                    closeModal              =   {() => this.setState({inquilino: {}})}>
					<FormEditInquilino 
						telefonoError		=	{this.state.telefonoError} 
						setInfo				=	{this.setOwnersInfo.bind(this)} 
						inquilino			=	{this.state.inquilino}
						subname				=	{this.props.subname}
						estadosAPP			= 	{this.state.residentes_status}
						subLabel			=	{this.state.subLabel}
					/>	
				</DinamicModal>
			</div>
		);
	}

	openModalDelete(prop) {
		this.setState({id: prop.id_inquilino});
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

	handleChecked(id) {
		this.makePrincipal(id);
	}

	showSearch() {
		this.setState({ search: !this.state.search });
	}

	makePrincipal(id) {
		let check = document.getElementById("check");
	}

	handleOpen(id) {
		this.setState({ showModal: !this.state.showModal, viewOwners: true });
		this.getInquilinosVivienda(id);
	}

	handleAddProp() {
		this.setState({ add: !this.state.add, edditProp: false, viewOwners: false });
		this.limpiarState();
	}

	handleCancel() {
		this.setState({ add: false, viewOwners: true });
		this.limpiarState();
	}

	limpiarState() {
		this.setState({
		pNombre: "",
		pDireccion: "",
		pMunicipio: "",
		pEstado: "",
		pCp: "",
		pTelefono: "",
		pPais: "",
		pGenero: 0,
		pId: 0,
		pNumId: "",
		pVigencia: 0,
		pFechaCompra: "",
		pIdPersona: 0,
		edditProp: false,
		search: false,
		isPrincipal: false,
		edditProp:false,
		email:''
		});
	}

	handleDateChange(date, event) {
		this.setState({ startDate: date });
	}

	handleChangeEddit(date) {
		this.setState({ pFechaCompra: document.getElementById("fecha").value });
	}

	handleClose() {
		this.setState({
			showModal: !this.state.showModal,
			pNombre: "",
			pDireccion: "",
			pMunicipio: "",
			pEstado: "",
			pCp: "",
			pTelefono: "",
			pPais: "",
			pGenero: 0,
			pId: 0,
			pNumId: "",
			pVigencia: 0,
			pFechaCompra: "",
			pIdPersona: 0,
			edditProp: false,
			search: false,
			viewOwners: true,
			add: false,
			email:''
		});
	}

	async handleDelete(id, close) {
		let data = {
			id_inquilino: id,
			id_usuario_del: localStorage.getItem("user_id")
		};
		const response = await request.post("/viviendas/inquilino/delete", data);
		if(response.deleted) {
			close();
			cogoToast.success('Residente eliminado.', {
				position: 'bottom-right',
			});
		} else {
			cogoToast.error('No se pudo eliminar residente.', {
				position: 'bottom-right',
			});
		}
		this.getInquilinosVivienda();
		this.props.reload();
	}

	showSearch() {
		this.setState({ search: !this.state.search })
	}

	edditProp(idPersona) {
		this.setState({ edditProp: true, add: false, viewOwners: false });
		
		this.handlePropietarioDetalle(idPersona);
	}

	// TRAE TODOS LOS CONTACTOS DE LA VIVIENDA

	async getInquilinosVivienda() {
		this.setState({loadingInquilinos: true});
    	let data = { 
			idVivienda: this.props.id,
			idFraccionamiento: localStorage.getItem('frac')
		};

		const response = await request.post("/viviendas/vivienda/inquilinos", data);
    	if (response && !response.error) {
			if (response.inquilinoVivienda && !response.empty) {
				
				this.setState({
					inquilinos: response.inquilinoVivienda,
				});
			} else {
				this.setState({
					empty: true,
					message: response.message,
					inquilinos: []
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message
			});
		}
		this.setState({loadingInquilinos: false});
	}

	async handleSubmit(event) {
    	event.preventDefault();
		this.setState({loadingSave: true});
		// Si existe un propietario
		let city = document.getElementById('city');
		let checksJson = [];
		checksJson.push(this.props.id);
    	if (this.state.pIdPersona === 0) {
      		let data = {
				// idVivienda:				 this.props.id,
				viviendasJSON:		JSON.stringify(checksJson),
				Nombre:					 this.state.pNombre,
				Direccion:				 this.state.pDireccion,
				Municipio:				 this.state.pMunicipio,
				Estado:					 this.state.pEstado,
				cp:						 this.state.pCp,
				Telefono:				 this.state.pTelefono,
				pais:					 this.state.pPais,
				Genero:					 this.state.pGenero,
				Id:						 this.state.pId,
				NumId:					 this.state.pNumId,
				Vigencia:				 this.state.pVigencia,
				Fechaingreso:			 this.state.pFechaIngreso,
				FechaSalida:			 this.state.pFechaSalida,
				IdPersona:				 this.state.idPersona,
				principal:				 this.state.isPrincipal,
				email:					 this.state.email,
				comentarios:             this.state.comentarios,
				idFraccionamiento:		 localStorage.getItem('frac') || 0,
				city:					 city.value,
				id_usuario_add :    localStorage.getItem("user_id")
			  };
			  //console.log(data);
		  const response = await request.post("/viviendas/inquilino/crear", data);
		//console.log(response);
			if(response.created){
				//this.setState({message:"NUEVO INQUILINO AÑADIDO"});
				this.props.reload();
				this.getInquilinosVivienda();
				cogoToast.success('Residente añadido.', {
					position: 'bottom-right',
				});
			}else{
				//this.setState({message:"NO SE PUDO AÑADIR NUEVO INQUILINO"});
				cogoToast.error('No se pudo añadir residente.', {
					position: 'bottom-right',
				});
			}
		
		this.setState({loadingSave: false});
    }
  }

  async handleUpdate(event) {
	this.setState({loadingSave: true});
	event.preventDefault();
	/**Validar Telefono */
	if(!isValidPhoneNumber(this.state.pTelefono)) {
		this.setState({telefonoError: 'Número inválido', loadingSave: false});
		return;
	} else {
		this.setState({telefonoError: undefined});
	}
	let city = document.getElementById('citye').value;

	let data = {
		idVivienda: 		this.props.id,
		Nombre: 			this.state.pNombre,
		Direccion: 			this.state.pDireccion,
		Municipio: 			this.state.pMunicipio,
		Estado: 			this.state.pEstado,
		cp: 				this.state.pCp,
		Telefono: 			this.state.pTelefono,
		pais: 				this.state.pPais,
		Genero: 			this.state.pGenero,
		Id: 				this.state.pId,
		NumId: 				this.state.pNumId,
		Vigencia: 			this.state.pVigencia,
		Fechaingreso: 		moment(this.state.pFechaIngreso).format("YYYY-MM-DD"),
		FechaSalida: 		moment(this.state.pFechaSalida).format("YYYY-MM-DD"),
		Code: 				this.state.code,
		IdPersona: 			this.state.idPersona,
		email: 				this.state.email,
		comentarios:        this.state.comentarios,
		idFraccionamiento: 	localStorage.getItem('frac') || 0,
		city:				city,
		estadoAPP:			this.state.residente_status,
		id_usuario_upd :    localStorage.getItem("user_id")

	}


	const response = await request.post("/viviendas/inquilino/update", data);
	if(response.updated){
		this.props.reload();
		this.getInquilinosVivienda();
		cogoToast.success('Datos de residente actualizados.', {
			position: 'bottom-right',
		});
	}else{
		this.setState({message: "NO SE PUDO EDITAR INQUILINO"});
		cogoToast.error('No se pudieron actualizar datos de residente.', {
			position: 'bottom-right',
		});
	}
	this.setState({loadingSave: false});
}

  // MUESTRA LOS DATOS DE UN ÚNICO propietario EN FORMULARIO
  async handlePropietarioDetalle(idPersona) {

    let data = { idPersona: idPersona };

    const response = await request.post("/viviendas/vivienda/get/propietario", data);

    if (response && !response.error) {
      if (response.propietarioVivienda && !response.empty) {
        
        this.setState({
          pNombre: response.propietarioVivienda[0].nombre,
          pDireccion: response.propietarioVivienda[0].direccion,
          pMunicipio: response.propietarioVivienda[0].municipio,
          pEstado: response.propietarioVivienda[0].estado,
          pCp: response.propietarioVivienda[0].cp,
          pTelefono: response.propietarioVivienda[0].telefono,
          pPais: response.propietarioVivienda[0].pais,
          pGenero: response.propietarioVivienda[0].genero,
          pId: response.propietarioVivienda[0].tipo_identificacion,
          pNumId: response.propietarioVivienda[0].num_identificacion,
          pVigencia: response.propietarioVivienda[0].vigencia_identificacion,
          pFechaCompra: moment(response.propietarioVivienda[0].fecha_compra).format('MM-DD-YYYY'),
          pIdPersona: response.propietarioVivienda[0].id_persona,
          email: response.propietarioVivienda[0].email
        });

      } else {
        this.setState({ empty: true, message: response.message });
      }
    } else {
      this.setState({
        error: true,
        message: response.message
      });
    }
  }
}
export default InquilinoModal;