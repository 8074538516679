//Http
import Request from "../../core/httpClient";

const request = new Request();

export const getInquilinosBalance = async (id_Fraccionamiento, id_Subfraccionamiento) => {
    try {

        const data = { id_Fraccionamiento, id_Subfraccionamiento };

        const response = await request.get("/tarjetaVirtual/inquilinosBalance", data);

        if (!response || response.error || !response.balances)
            return { error: "Error al Cargar el Balance de Viviendas" };

        return { balances: response.balances };

    } catch (error) {
        console.log(error);
        return { error };
    }
};

export const getViviendas = async (idSub, idFrac) => {
    try {
        const data = { idSub, idFrac };

        const response = await request.post("/subfraccionamientos/get/viviendas", data);

        if (response.subTotalViviendas) {

            return { viviendas: response.subTotalViviendas };

        } else {

            return { viviendas: [], error: response.message }
        };

    } catch (error) {
        console.log(error);
        return { error };
    }
};

export const getCatalogos = async () => { 
    try {

        const response = await request.get("/tarjetaVirtual/catalogos");

        if (!response || response.error || !response.catalogos)
            return { error: "Error al cargar catalogos" };

        return { catalogos: response.catalogos };


    } catch (error) {
        console.log(error);
        return { error };
    }
};

export const getMovimientos = async (idFraccionamiento, idInquilino, idVivienda, idCatalogo, desde, hasta) => {
    try {
        
        const data = { idFraccionamiento, idInquilino, idVivienda, idCatalogo, desde, hasta };

        const response = await request.get("/tarjetaVirtual/movimientos", data);

        if (!response || response.error)
            return { error: "Error al cargar los Movimientos del fraccionamiento " + idFraccionamiento };

        if(response.empty) {
            return { error: response.message, movimientos: [] };
        }

        return { movimientos: response.movimientos };

    } catch (error) {
        console.log(error);
        return { error };
    }
};

export const getBalance = async (id_inquilino, id_Fraccionamiento) => { 
    try {
        const data = { id_inquilino, id_Fraccionamiento };

        const response = await request.get("/tarjetaVirtual/balance", data);

        if (!response || response.error || !response.balance)
        return { error: "Error al cargar el balance del inquilino" + id_inquilino };

    } catch (error) {
        console.log(error);
        return { error };
    }
};

export const setAbono = async ({ monto, id_inquilino, id_metodoPago }) => {
    try {
        
        const data = { monto, id_inquilino, id_metodoPago, id_catalogo: 1, descripcion: "Abono Administración" };

        const response = await request.post("/tarjetaVirtual/createMovimiento", data);

        if (!response || response.error)
            return { error: "Error al crear Movimiento" };

        if(response.created) return { created: true };

    } catch (error) {
        console.log(error);
        return { error };
    }
};

export const getMetodosPago = async () => {
    try {
        // Actualizar por metodos de pago de TarjetaVirtual
        const result = await request.get("/documentos/get/metodopago/recibo");

        if (result.empty || result.error) return { error: "No se encontraron metodos de pago" };

        return { metodosPago: result.metodos };

    } catch (error) {
        console.log(error);
        return { error };
    }
};
