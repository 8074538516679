import React from 'react';
import SuperCore from './../../core/SuperCore';

class Header extends SuperCore{
    constructor(props){
        super(props);
        this.state = {
            title:  this.props.title.toString(),
            id:     this.props.id
        }
    }

    async componentDidUpdate(prevProps, prevState){
        if(prevProps.title != this.props.title){
            await this.setState({title: this.props.title});
        }

        if(prevProps.id != this.props.id){
            await this.setState({id: this.props.id});
        }
    }
    

    render(){
        return(
            <React.Fragment>
                <div className="column modal-container justify-center">
                    <h3 className="title">{this.state.title}</h3>
                </div>
                <button className="btn-modal-close justify-center align-center" onClick={(event) => {
                    this.props.triggerCloseModal();
                    this.superCloseModal(this.props.id)
                }}>
                    <i  className = "fas fa-times" ></i>
                </button>
            </React.Fragment>
        )
    }
}

export default Header;