import React from "react";
import cogoToast from "cogo-toast";
import moment from "moment-timezone";
import { Consumer } from "../../context";
import { Helmet } from "react-helmet";
import Header from "../../components/header/header";
import Controls from "../../components/controls/controls";
import SidebarAdministracion from "../../components/header/sidebarAdministracion";
import Paginador from "../../components/paginador/paginador";
import SuperCore from "../../components/core/SuperCore";
import SuperModal from "../../components/coreModal/SuperModal";
import NewReceipment from "../../components/receipmentpayment/NewReceipment";
import ReceipmentTable from "../../components/receipmentpayment/receipmentTable";
import Request from "../../core/httpClient";
import Permissions from "../../middlewares/Permissions";
import sortableData from '../../helpers/sortableDataTable';

const request = new Request();

class receipmentpayment extends SuperCore {
	constructor(props) {
		super(props);
		const today = new Date();
		const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
		this.state = {
			docs: [],
			page: 0,
			loading: true,
			frac_name: null,
			id_fraccionamiento: localStorage.getItem("frac"),
			subfraccionamientos: [],
			subfraccionamiento: localStorage.getItem("subfrac") || '0',
			userName: "",
			userId: 0,
			viviendas: [],
			screenNew: [],
			isOpen: false,
			metodosPago: [],
			linesReceipment: [],
			receipts: [],
			dateReceipment: new Date(),
			idReceipment: 0,
			aplicado: true,
			filter: "",
			pageLength: 20,
			loadingDetalle: false,
			savingReceipment: false,
			direction: {
				Unidad: 'desc',
				Propietario: 'desc',
				Fecha_recibo: 'desc',
				Total_Cobrado: 'desc'
			},
			filterColumn: {
				col: '',
				filt: ''
			},
			submenu: '',
			startDate: lastMonth.toISOString().split('T')[0],
			endDate: today.toISOString().split('T')[0],      
			filterTipo: '',
		};

		Array.prototype.unique = (function (a) {

			return function () {
				return this.filter(a);
			};

		})(function (a, b, c) {
			return c.indexOf(a, b + 1) < 0;
		});

		this.validarPermisos();
	};

	componentDidMount() {
		this.getSubmenu();
		this.userData();
		this.getMetodoPagos();
		this.getSubFraccionamientos();
		this.getFraccionamiento();
		this.getReceipment();
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevState.startDate !== this.state.startDate ||
			prevState.endDate !== this.state.endDate ||
			prevState.filterTipo !== this.state.filterTipo) {
			this.getReceipment();
		}
	}

	async getSubmenu() {

		const submenu = await this.props.context.getSubmenuAdminName();

		if (submenu) {
			this.setState({ submenu });
		};

	};

	async validarPermisos() {

		let permisos = new Permissions();
		let permisosInfo = await permisos.getResult();

		await this.setState({
			escritura: permisosInfo.escritura,
			lectura: permisosInfo.lectura,
		});
	};

	async userData() {
		let user = await this.getUserInfo();
		this.setState({ userName: user.info.usuario, userId: user.info.id_usuario });
	};

	async setPage(page) {
		await this.setState({ page });
	};

	async paginatorChange(length) {
		await this.setState({ pageLength: length });
		this.setPage(1);
	};

	async getMetodoPagos() {

		const result = await request.get("/documentos/get/metodopago/recibo");

		if (result.empty || result.error) {
			this.setState({ metodosPago: [] });
		} else {
			this.setState({ metodosPago: result.metodos });
		};
	};
	
	async getReceipment() {
		this.setState({ loading: true, viviendas: [], receipts: [] });
	
		const dataVivienda = {
			id_fraccionamiento: this.state.id_fraccionamiento,
			id_subfraccionamiento: 0,
		};
	
		const responseViviendas = await request.post("/viviendas/getViviendaBySubFraccionamiento", dataVivienda);
	
		if (responseViviendas && !responseViviendas.error) {
			if (!responseViviendas.empty) {
				await this.setState({ viviendas: responseViviendas.viviendas });
			};
		};
	
		let dataprop = {
			idFrac: this.state.id_fraccionamiento,
			IdSub: 0,
		};
	
		const responseProps = await request.post("/fraccionamientos/get/propietarios", dataprop);
	
		if (responseProps && !responseProps.error) {
			if (!responseProps.empty) {
				await this.setState({ propietarios: responseProps.propietario });
			};
		};
	
		let data = {
			id_fraccionamiento: localStorage.getItem("frac"),
			id_subfraccionamiento: this.state.subfraccionamiento,
			desde: this.state.startDate,
			hasta: this.state.endDate,
		};
	
		const response = await request.get("/receipment/get/all", data);
	
		if (response && !response.error) {
			if (response.data && !response.empty) {
				let filteredData = response.data;
	
				if (this.state.filterTipo) {
					filteredData = filteredData.filter(receipt => receipt.FormaPago === this.state.filterTipo);
				}
	
				let receiptList = filteredData.map((receipt) => {
					let prop = this.state.propietarios.filter((p) => p.id_vivienda === receipt.Id_Vivienda);
					let unidad = this.state.viviendas.filter((viv) => viv.id_vivienda === receipt.Id_Vivienda);
	
					return {
						...receipt,
						Unidad: unidad.length > 0 ? unidad[0].numero_registro : "",
						Propietario: prop.length > 0 ? prop[0].nombre : "Por definir",
						excelColumnsShow: "Unidad,Propietario,Fecha_recibo,Total_Cobrado,FormaPago,BANCO,NO_MOVIMIENTO",
						excelColumnsName: "Unidad,Propietario,Fecha aplicado,Importe,Forma pago,Banco,N° Movimiento",
						excelColumnsType: "string,string,datetime,money,string,string",
					};
				});
	
				this.setState({ receipts: receiptList });
	
			} else {
				this.setState({ empty: true, message: response.message });
			};
	
		} else {
			this.setState({ error: true, message: response.message });
		};
	
		this.setState({ loading: false });
	};

	getNumeroRegistro(id_vivienda) {

		let viviendas = this.state.viviendas;

		if (viviendas.length > 0) {

			for (let i = 0; i < viviendas.length; i++) {

				if (parseInt(viviendas[i].id_vivienda) === parseInt(id_vivienda)) {
					return viviendas[i].numero_registro;
				};
			};
		};
	};

	async updateScreens() {

		await this.setState({
			screenNew: [
				<NewReceipment
					updateData={this.updateData.bind(this)}
					userName={this.state.userName}
					userId={this.state.userId}
					isOpen={this.state.isOpen}
					viviendas={this.state.viviendas}
					metodosPago={this.state.metodosPago}
					linesReceipment={this.state.linesReceipment}
					dateReceipment={this.state.dateReceipment}
					aplicado={this.state.aplicado}
					idReceipment={this.state.idReceipment}
					loadingDetalle={this.state.loadingDetalle}
				/>,
			],
		});
	};

	async getSubFraccionamientos() {

		const fraccionamiento = localStorage.getItem("frac");

		let data = {
			Idsub: fraccionamiento,
			filter: 1
		};

		const response = await request.post("/admin/administracion/get/subfraccionamientos", data);

		if (response && !response.error) {

			if (response.subfraccionamiento && !response.empty) {

				this.setState({ subfraccionamientos: response.subfraccionamiento, });
				this.updateScreens();

			} else {
				this.setState({ empty: true, message: response.message, subfraccionamientos: [], });
			};

		} else {
			this.setState({ error: true, message: response.message, });
		};
	};

	async getFraccionamiento() {

		const frac = await localStorage.getItem("frac");
		const idFraccionamiento = frac;
		const data = { IdFraccionamiento: idFraccionamiento };

		const response = await request.post("/admin/administracion/get/fraccionamiento", data);

		if (response && !response.error) {

			if (response.fraccionamiento && !response.empty) {
				await this.setState({ frac_name: response.fraccionamiento[0].nombre, });
			};
		};
	};

	async updateData(json) {
		await this.setState(json);
		await this.updateScreens();
	};

	async beforeOpenNewReceipment() {

		this.superOpenModal("newReceipment");

		await this.setState({ isOpen: true, dateReceipment: new Date(), aplicado: false, });

		const lines = [];

		for (let index = 0; index < 10; index++) {

			let line = {
				Id_Forma_Cobro: Math.ceil(Math.random() * 100) * Math.ceil(Math.random() * 100),
				Fecha_Pago: moment().format("YYYY-MM-DD"),
				Monto: 0,
				Id_FormaPago: 0,
				BANCO: "",
				NO_MOVIMIENTO: "",
				Id_Vivienda: 0,
				Edit: false,
				Text: "",
			};

			lines.push(line);
		};

		await this.setState({ linesReceipment: lines });

		this.updateScreens();
	};

	async submitNewReceipment() {

		let lineas = this.state.linesReceipment.filter((lin) => lin.Edit === true && lin.Monto > 0);

		this.setState({ savingReceipment: true });

		if (lineas.filter((ln) => ln.Id_Vivienda === 0).length > 0) {

			cogoToast.warn("Debe elegir vivienda en uno de los pagos.", { position: "bottom-right", });

		} else if (lineas.filter((ln) => ln.Id_FormaPago === 0).length > 0) {

			cogoToast.warn("Debe elegir forma de pago en uno de los pagos.", { position: "bottom-right", });

		} else if (lineas.length == 0) {

			cogoToast.warn("No se han agregado montos de pagos.", { position: "bottom-right", });

		} else {

			let data = {
				id_fraccionamiento: this.state.id_fraccionamiento,
				dateReceipment: this.state.dateReceipment,
				userId: this.state.userId,
				linespagos: JSON.stringify(lineas),
				userName: this.state.userName,
			};

			const res = await request.post("/receipment/create/new", data);

			if (res.error) {

				cogoToast.error("No fue posible crear el recibo.", { position: "bottom-right", });

			} else if (res.created) {

				cogoToast.success("Recibo guardado de manera correcta.", { position: "bottom-right", });

				this.clearNewReceipment();
				this.getReceipment();
				this.superCloseModal("newReceipment");
			};
		};

		this.setState({ savingReceipment: false });
	};

	async clearNewReceipment() {

		await this.updateData({
			isOpen: false,
			linesReceipment: [],
			dateReceipment: new Date(),
			aplicado: true,
		});

	};

	async openToEdit(reciptObject) {

		await this.clearNewReceipment();

		let fecha = moment(reciptObject.Fecha_recibo).format("YYYY-MM-DD HH:MM:ss");

		this.superOpenModal("editReceipment");

		await this.setState({
			isOpen: true,
			loadingDetalle: true,
			dateReceipment: new Date(fecha.toString()),
			aplicado: reciptObject.Estado_Cobro === 1,
			linesReceipment: [],
			idReceipment: reciptObject.Id_Recibo,
		});

		await this.updateScreens();

		let data = {
			Id_Recibo: reciptObject.Id_Recibo,
		};

		const response = await request.get("/receipment/get/detail/receipment", data);

		if (response && !response.error) {

			if (!response.empty) {

				let lista = response.data.map((rec) => {

					let fecha = moment(rec.Fecha_Pago).format("YYYY-MM-DD HH:MM:ss");
					rec.Fecha_Pago = new Date(fecha.toString());
					rec.Detalle = JSON.parse(rec.Detalle);

					return { ...rec, Edit: false };

				});

				if (reciptObject.Estado_Cobro === 0) {

					for (let index = 0; index < 15; index++) {

						let line = {
							Id_Forma_Cobro: Math.ceil(Math.random() * 100) * Math.ceil(Math.random() * 100),
							Fecha_Pago: new Date(),
							Monto: 0,
							Id_FormaPago: 0,
							BANCO: "",
							NO_MOVIMIENTO: "",
							Id_Vivienda: 0,
							Edit: false,
						};

						lista.push(line);
					};
				};

				await this.setState({ linesReceipment: lista });
			};
		}

		await this.setState({ loadingDetalle: false });

		this.updateScreens();
	};

	async cancelReceipment(reciptObject) {

		let data = {
			Id_Recibo: reciptObject.Id_Recibo,
			userName: this.state.userName,
		};

		const res = await request.post("/receipment/cancel", data);

		if (res.error) {

			cogoToast.error(res.message || "No fue posible cancelar el recibo.", { position: "bottom-right", });

		} else if (res.canceled) {

			cogoToast.success("Pago cancelado de manera correcta.", { position: "bottom-right", });

			this.getReceipment();
		};
	};

	setFilter(filter) {
		this.setState({ filter: filter.toLowerCase() });
	};

	async subChange(val) {

		await this.getReceipment();

		await this.setState({ subfraccionamiento: parseInt(val), });
	};

	async addLineas() {

		let lista = this.state.linesReceipment;

		let line = {
			Id_Forma_Cobro: Math.ceil(Math.random() * 100) * Math.ceil(Math.random() * 100),
			Fecha_Pago: new Date(),
			Monto: 0,
			Id_FormaPago: 0,
			BANCO: "",
			NO_MOVIMIENTO: "",
			Id_Vivienda: 0,
			Edit: false,
		};

		lista.push(line);

		await this.setState({ linesReceipment: lista });
		await this.updateScreens();
	};

	sortableTable(key) {

		let sd = new sortableData();

		const { items, direction } = sd.sortBy(key, this.state.receipts, this.state.direction[key]);

		this.setState({
			receipts: items,
			direction: { [key]: direction },
			filterColumn: { col: key, filt: direction }
		});

	};

	handleStartDateChange(event) {
		this.setState({ startDate: event.target.value });
	}
	
	handleEndDateChange(event) {
		this.setState({ endDate: event.target.value });
	}
	
	handleTipoChange(event) {
		this.setState({ filterTipo: event.target.value });
	}

	render() {
		return (
            <div className="admin column">
                <Helmet>
                    <title>
                        {process.env.REACT_APP_NAME} - {this.state.submenu}
                    </title>
                </Helmet>
                <Header
                    reload={this.getReceipment.bind(this)}
                    change={this.getReceipment.bind(this)}
                    sidebar={true}
                    active={"EstadosCuenta"}
                    parent={"EstCuenta"}
                    panel={"panel14"}
                    nav={"administracion"}
                />
                <div className="row">
                    <div className="column" id="sidebar">
                        <SidebarAdministracion />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">
                                <Controls
                                    title={this.state.submenu}
                                    nuevo={this.beforeOpenNewReceipment.bind(this)}
                                    dataSet={this.state.receipts}
                                    newButton={this.state.escritura}
                                    setFilter={this.setFilter.bind(this)}
                                    subChange={this.subChange.bind(this)}
                                />
                                <div
                                    id="second_controls"
                                    className="row-responsive full"
                                    style={{ backgroundColor: "white", marginBottom: "16px" }}
                                >
                                    <div className="data-from column full">
                                        <h5 className="color-black">DESDE:</h5>
                                        <input
                                            type="date"
                                            id="startdate"
                                            name="startdate"
                                            className="input input-text"
                                            placeholder="Desde"
                                            value={this.state.startDate}
                                            onChange={this.handleStartDateChange.bind(this)}
                                        />
                                    </div>
                                    <div style={{ width: "16px" }}></div>
                                    <div className="data-to column full">
                                        <h5 className="color-black">HASTA:</h5>
                                        <input
                                            type="date"
                                            id="enddate"
                                            name="enddate"
                                            className="input input-text"
                                            placeholder="Hasta"
                                            value={this.state.endDate}
                                            onChange={this.handleEndDateChange.bind(this)}
                                        />
                                    </div>
                                    <div style={{ width: "16px" }}></div>
                                    <div className="data-to column full">
                                        <h5 className="color-black">TIPO:</h5>
                                        <select
                                            className="input input-select"
                                            onChange={this.handleTipoChange.bind(this)}
                                        >
                                            <option value="">Todos</option>
                                            <option value="Cheque Nominativo">Cheque Nominativo</option>
                                            <option value="Efectivo">Efectivo</option>
                                            <option value="Tarjeta de crédito">	Tarjeta de Crédito</option>
                                        </select>
                                    </div>
                                </div>	
                                {this.state.loading ? (
                                    <div className="row justify-center">
                                        <i className="fas fa-spinner fa-spin"></i>
                                    </div>
                                ) : (
                                    <ReceipmentTable
                                        receipts={this.state.receipts}
                                        page={this.state.page}
                                        openToEdit={this.openToEdit.bind(this)}
                                        filter={this.state.filter}
                                        pageLength={this.state.pageLength}
                                        escritura={this.state.escritura}
                                        cancelReceipment={this.cancelReceipment.bind(this)}
                                        sortBy={this.sortableTable.bind(this)}
                                        filterColumn={this.state.filterColumn}
                                        idReceipment={this.state.idReceipment}
                                    />
                                )}
                                <div className="white-space-16"></div>
                                <Paginador
                                    pages={Math.ceil(this.state.receipts.length / this.state.pageLength)}
                                    setPage={this.setPage.bind(this)}
                                    pageLength={this.state.pageLength}
                                    paginatorChange={this.paginatorChange.bind(this)}
                                    showPageLenght={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <SuperModal
                    id={"newReceipment"}
                    size={"huge"}
                    title={"Aplicar pago"}
                    screens={this.state.screenNew}
                    onSubmit={this.submitNewReceipment.bind(this)}
                    lblSubmit={"Aplicar"}
                    showSubmit={true}
                    loading={this.state.savingReceipment}
                    triggerCloseModal={this.clearNewReceipment.bind(this)}
                    deshabilitarEsc={true}
                />
                <SuperModal
                    id={"editReceipment"}
                    size={"big"}
                    title={"Detalle pago"}
                    screens={this.state.screenNew}
                    onSubmit={() => {}} // edit?
                    lblSubmit={"Aplicar"}
                    showSubmit={false}
                    triggerCloseModal={this.clearNewReceipment.bind(this)}
                />
            </div>
        );
	};
};

export default Consumer(receipmentpayment);
