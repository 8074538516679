import React, { Component } from 'react';
import Draggable from 'react-draggable';

class NewModal extends Component {
    constructor(props) {
		super(props);
		this.state = {
			sizeModal: "column dinamic-modal-container dinamic-modal-normal"
		}
	}
	
	componentDidMount() {
		switch(this.props.sizeModalContainer) {
			case "big" : 
				this.setState({sizeModal: "column dinamic-modal-container dinamic-modal-big"});
				break;
			case "normal" : 
				this.setState({sizeModal: "column dinamic-modal-container dinamic-modal-normal"});
				break;
			case "small" : 
				this.setState({sizeModal: "column dinamic-modal-container dinamic-modal-small"});
				break;
			case "medium":
			this.setState({sizeModal: "column dinamic-modal-container dinamic-modal-medium"});
			break;
			case "super":
				this.setState({sizeModal: "column dinamic-modal-container dinamic-modal-super-big"});
				break;
			default: 
				this.setState({sizeModal: "column dinamic-modal-container dinamic-modal-normal"});
				break;
		}
	}

	async addEventESC() {
		let idd = this.props.idModal;
		//let triggerCloseModal = this.props.triggerCloseModal;

		document.addEventListener("keyup", async function (event) {
			event.preventDefault();
			if (event.keyCode != 27) {
				return;
			}

			try {
				let modal = document.getElementById(idd);

				if (modal) {
					if (modal.classList.contains('show-dinamic-modal')) {
						modal.classList.remove('show-dinamic-modal');
						modal.classList.add('hide-dinamic-modal');

						/*if (triggerCloseModal != null && typeof triggerCloseModal != 'undefined') {
							triggerCloseModal();
						}*/

					}
				}
			} catch (error) {
				//console.log(error);
			}
		})
	}

    render() {
		this.addEventESC();
        return (
			<div className= "overlay hide-dinamic-modal"  id={this.props.idModal}>
				<Draggable 
				handle = '.dinamic-header'
				// defaultPosition={{x: '50%', y: '50%'}}
				>
            <div className= {this.state.sizeModal} >
				<div className="dinamic-header row justify-center">
					<div className="column modal-container justify-center">
						<h3 className="title">{this.props.title}</h3>
					</div>
					{!this.props.noClose &&
					<button className="btn-modal-close justify-center align-center" onClick={this.closeModal.bind(this,this.props.idModal)} >
						<i className="fas fa-times"></i>
					</button>
					}
				</div>
				<div className="dinamic-body row justify-center">
					<div className="column modal-container">
						<form className="flex column justify-center" onSubmit={this.props.success} autoComplete="off">
							<div className="white-space-16"></div>
							{this.props.children}
							
							{
								this.props.message && 
								<div className="row full">
								<div className="column">
									<div className="white-space-32"></div>
									<p className="modal-message">
									<i className="fas fa-bookmark"></i> &nbsp;
									{this.props.message}
								</p>
								</div>
								
								</div>
							}
							<div className="white-space-24"></div>
							<div className="dinamic-footer row justify-center">
								{this.props.loadingSave ?
									<div className="row justify-center">
										<i className="fas fa-spinner fa-spin"></i>
									</div> :
									<div className="row justify-end">
									{this.props.footer}
									{this.props.showBtnCancel &&
										<button type="button" className="btn-action-cancel" onClick={this.closeModal.bind(this, this.props.idModal)}>
											{/*<i className="fas fa-times" />*/}  {this.props.btnTextCancel}
										</button>
									}
									{this.props.showBtnSuccess &&
										<button type="submit" className="btn-action-success" disabled={this.props.disabledBtnSuccess}>
											{/*<i className="fas fa-check" />*/}  {this.props.btnTextSuccess}
										</button>
									}
									</div>
								}
							</div>
							<div className="white-space-16"></div>
						</form>
					</div>
				</div>
            </div>
			</Draggable>
			</div>
        );
	}
	
	openModal() {
		let modal = document.getElementById(this.state.idModals);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}
   
	closeModal(idModal) {
		if(this.props.closeModal) {
			this.props.closeModal();
		}
		const modal = document.getElementById(idModal) 
		if(modal) {
			modal.classList.remove('show-dinamic-modal');
			modal.classList.add('hide-dinamic-modal');
		}
	}
}

export default NewModal;