import React, { Component } from 'react';
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import MapEmergency from '../../components/maps/map';
import cogoToast from 'cogo-toast';
import Request from "../../core/httpClient";
import { AsYouType } from 'libphonenumber-js'

const request = new Request();

class DetailEmergency extends Component {
    constructor(props) {
        super(props);
        this.state = {
			emergency: 		this.props.emergency,
			comentario: 	this.props.emergency.comentario ? this.props.emergency.comentario : '',
			loadingSave: 	false,
			userName:		'seguridadd'
		}
	}
	
	componentDidUpdate(prevProps) {
		if(this.props.emergency!== prevProps.emergency) {
			this.setState({
				emergency: 	this.props.emergency,
				comentario: this.props.emergency.comentario ? this.props.emergency.comentario : ''});
			//console.log(this.props.emergency);
		}
	}

	reset() {
		this.setState({comentario: ''});
	}
	
    render() {
		//console.log()
		// //console.log(new AsYouType('US').input()		);
        return ( 
		<DinamicModal 
			idModal				=	{'detailEmergency'}
			sizeModalContainer	=	{'big'}
			title				=	{'Detalle emergencia'} 
			success				=	{this.addComment.bind(this)} 
			showBtnSuccess		=	{this.props.escritura}
			btnTextSuccess		=	{"GUARDAR"}
			showBtnCancel		=	{true}
			btnTextCancel		=	{"CANCELAR"}
			closeModala			=	{this.reset.bind(this)}
			loadingSave			=	{this.state.loadingSave}
			message				=	{this.state.message}
			>
			<div className="full row align-center">
				<div className="column full">
					<div className="row full">
						<div className="column full">
						<div className="input-form-content row full align-center">
								<h3 className="color-danger">{this.state.emergency.subfraccionamiento}</h3>
						</div>
						<div className="white-space-16"></div>
						<div className="input-form-content row full align-center">
							<div className="column label-medium">
								<p><b> Nombre:	</b></p>
							</div>
							<div className="column full">
								<input className ="input input-modals"
									type="text" 
									disabled 
									value ={this.state.emergency.nombre}
								/>
								{/* <p className="label-gray">{}</p> */}
							</div>
						</div>
						<div className="white-space-8"></div>
						<div className="input-form-content row full align-center">
							<div className="column label-medium">
								<p><b> Teléfono:	</b></p>
							</div>
							<div className="column full">
								<input className ="input input-modals"
									type="text" 
									disabled 
									value ={this.state.emergency.telefono ? new AsYouType('MXN').input('+'+this.state.emergency.telefono) : this.state.emergency.telefono }
								/>
								
							</div>
						</div>
						<div className="white-space-8"></div>
						<div className="input-form-content row full align-center">
							<div className="column label-medium">
								<p><b>Vivienda:</b></p>
							</div>
							<div className="column full">
								<input className ="input input-modals"
									type="text" 
									disabled 
									value ={this.state.emergency.numero_vivienda}
								/>
							</div>
						</div>
						<div className="white-space-8"></div>
						<div className="input-form-content row full align-center">
							<div className="column label-medium">
								<p><b> Recibido:	</b></p>
							</div>
							<div className="column full">
								<input className ="input input-modals"
									type="text" 
									disabled 
									value ={this.state.emergency.seguridad ? this.state.emergency.seguridad : 'No recibido'}
								/>
							</div>
						</div>
						<div className="white-space-8"></div>
						<div className="input-form-content row full align-center">
								<p><b> Dirección:	</b></p>
						</div>
						<div className="white-space-8"></div>
						<div className="input-form-content row full align-center">
								<textarea className="text-area-gray" value={this.state.emergency.direccion} rows="2" disabled></textarea>
						</div>
						<div className="white-space-8"></div>
						<div className="input-form-content row full align-center">
								<p><b> Comentarios:	</b></p>
						</div>
						<div className="white-space-8"></div>
						<div className="input-form-content row full align-center">
								<textarea className="text-area-gray" 
									id ="comment"
									value={this.state.comentario} 
									onChange={event => this.setState({ comentario: event.target.value })} 
									rows="2"
								></textarea>
						</div>
						</div>
						<div className="column full"  style={{paddingLeft:"16px"}}>
						<MapEmergency data={this.state.emergency}></MapEmergency>
						</div>
					</div>
				</div>
			</div>
		</DinamicModal>
        );
	}
	
	async addComment(event) {
        event.preventDefault()
		this.setState({loadingSave: true});
        let data = {

            comentario: this.state.comentario,
            id: this.state.emergency.id_panico
        }
        const response = await request.post("/emergencys/update/emergency", data)

        
		if (response.updated) {
			this.setState({
				showModal: false
			});
			this.closeModal("detailEmergency");
			this.props.reload();
			cogoToast.success('Detalle de emergencia guardado.', {
				position: 'bottom-right',
			});
		} else {
			this.setState({
				empty: true,
				//message: "NO SE PUDO GUARDAR DETALLE DE LA EMERGENCIA"
			});
			cogoToast.error('No se pudo guardar detalle de la emergencia.', {
				position: 'bottom-right',
			});
		}
        
		this.setState({loadingSave: false});
		

	}
	
	  
	closeModal(idModal) {
		if(this.props.closeModal) {
			this.props.closeModal();
		}
		const modal = document.getElementById(idModal) 
		if(modal) {
			modal.classList.remove('show-dinamic-modal');
			modal.classList.add('hide-dinamic-modal');
		}
	}


}

export default DetailEmergency;