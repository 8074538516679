import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import moment from 'moment-timezone';
import SidebarOperacion from "../components/header/sidebarOperacion";
import Request from "../core/httpClient";
import BitacoraModal from '../components/bitacora/bitacoraModal';
import Controls from '../components/controls/controls';
import Paginador from "../components/paginador/paginador";
import Permissions from '../middlewares/Permissions';
import sortableData from '../helpers/sortableDataTable';
import { Consumer } from '../context';

const request = new Request();

Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}


class SecurityBitacora extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bitacora: [],
            filterData: [],
            keys: [],
            page: 1,
            filter: '',
            subfraccionamiento: localStorage.getItem('subfrac'),
            bitacoraModal: [],
            escritura: false,
            pageLength: 40,
            start: moment().format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD'),
            direction: {},
            filterColumn: {},
            submenu: ''
        };
    };

    async componentDidMount() {
        await this.validarPermisos();
        await this.getSubmenu();
        await this.getRondas();
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.loading !== prevState.loading) {
            this.stickyTablePosition('header', 'controls', '', 'table');
        };
    };

    stickyTablePosition(headerId, controlsId, secondControlsId, tableId) {
        const header_Heigh = document.getElementById(headerId);
        const height_controls = document.getElementById(controlsId);
        const second_controls = document.getElementById(secondControlsId);

        const table_height = document.getElementById(tableId);

        let limit_sticy_top = header_Heigh && height_controls ? header_Heigh.offsetHeight + height_controls.offsetHeight : 226;

        if (second_controls) {
            second_controls.style.position = 'sticky';
            second_controls.style.top = `${limit_sticy_top}px`;

            limit_sticy_top += second_controls.offsetHeight;
        };

        if (table_height) {
            table_height.style.position = 'sticky';
            table_height.style.top = `${limit_sticy_top}px`;
        };
    };

    async validarPermisos() {
        let permisos = new Permissions();
        let permisosInfo = await permisos.getResult()
        this.setState({ escritura: permisosInfo.escritura });
    };

    /*funcion del paginador*/
    setPage(page) {
        this.setState({ page });
    };

    paginatorChange(length) {
        this.setState({ pageLength: length });
        this.setPage(1);
    };

    /* funcion para el filtro generico */
    filterGeneric(e) {
        e.preventDefault();

        const form = e.target;

        const param1 = form.filterIncidents.value;
        const param2 = form.textFilter.value;

        let filterData = this.state.filterData;

        if (param1 === "todo") {
            this.setState({ bitacora: this.state.filterData });
            form.textFilter.value = "";
        } else {
            const key = param1;
            filterData = filterData.filter(product => product[key].toLowerCase().indexOf(param2.toLowerCase()) !== -1);
            this.setState({ bitacora: filterData });
        };
    };

    changeDate(newDate) {
        this.setState(newDate);
    };

    async reload() {
        await this.getRondas();
    };

    async changeFrac() {
        await this.getRondas();
    };

    subChange(sub) {
        this.setState({ subfraccionamiento: sub });
    };

    nuevaArea() {
        this.setState({ showModal: true });
    };

    setFilter(filter) {

        this.setState({ filter: filter.toLowerCase() });
    };

    filter(bitacora) {
        bitacora = bitacora.filter(bit => {
            if (bit.comentarios.toLowerCase().indexOf(this.state.filter) !== -1) {
                return true;
            } else if (bit.usuario) {
                if (bit.usuario.toLowerCase().indexOf(this.state.filter) !== -1) {
                    return true;
                };
            } else if (bit.fracNombre.toLowerCase().indexOf(this.state.filter) !== -1) {
                return true;
            } else {
                return false;
            };

        });

        return bitacora;
    };

    sortableTable(key) {
        let sd = new sortableData();
        const { items, direction } = sd.sortBy(key, this.state.bitacora, this.state.direction[key]);

        this.setState({
            bitacora: items,
            direction: {
                [key]: direction
            },
            filterColumn: {
                col: key,
                filt: direction
            }
        });
    };

    async getSubmenu() {
        const submenu = await this.props.context.getSubmenuOpeName();
        if (submenu) {
            this.setState({ submenu });
        };
    };

    async openModal(idModal, bit) {
        this.setState({ mesagge: null, bitacoraModal: bit });
        const modal = document.getElementById(idModal);
        if (modal) {
            modal.classList.remove('hide-dinamic-modal');
            modal.classList.add('show-dinamic-modal');
        };
    };

    async getRondas() {
        try {
            await this.setState({ loading: true, bitacora: []});

            const data = { 
                IdFraccionamiento: localStorage.getItem('frac'),
                inicio: this.state.start,
				fin: this.state.end
             };
            const response = await request.post("/incidencias/get/bitacora", data);

            if (response && !response.error) {
                if (response.data && !response.empty) {
                    console.log(response.bitacora)
                    this.setState({
                        bitacora: response.data,
                    });

                } else {
                    this.setState({
                        empty: true,
                        message: response.message,
                        loading: false,
                        bitacora: []
                    });
                };
            } else {
                this.setState({
                        error: true,
                        message: response.message,
                        loading: false
                    });
                };      
            await this.setState({ loading: false });
        } catch (error) {
            console.log(error);
        };
    };

    render() {
        let bitacora = [];

        if (Array.isArray(this.state.bitacora)) {
            bitacora = this.state.bitacora;
            bitacora = this.filter(bitacora);
        };

        return (
            <div className="admin column">
                <Helmet>
                    <title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
                </Helmet>
                <Header
                    id='header'
                    reload={this.reload.bind(this)}
                    change={this.changeFrac.bind(this)}
                    sidebar={true}
                    active={'historialb'}
                    nav={'operacion'}
                />
                <div className="row">
                    <div className="column" id="sidebar">
                        <SidebarOperacion />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column" >
                                <Controls
                                    id='controls'
                                    title={this.state.submenu}
                                    nuevo={this.nuevaArea.bind(this)}
                                    dataSet={bitacora}
                                    dateFormat='dd/mm/yyyy'
                                    newButton={false}
                                    setFilter={this.setFilter.bind(this)}
                                    subChange={this.subChange.bind(this)}
                                    selectSub={false}
                                    changeDate={this.changeDate.bind(this)}
                                />
                                    <div id='second_control' className="row-responsive full" style={{ backgroundColor: 'white' }} >
										<div className="data-from column full">
											<h5 className="color-black">DESDE:</h5>
											<input
												type="date"
												id="startdate"
												name="startdate"
												className="input input-text"
												placeholder="Desde"
												value={this.state.start}
												onChange={(event) => this.setState({start: event.target.value})}
											/>
										</div>
										<div style={{ width: '16px' }}></div>
										<div className="data-to column full">
											<h5 className="color-black">HASTA:</h5>
											<input
												type="date"
												id="enddate"
												name="enddate"
												className="input input-text"
												placeholder="Hasta"
												value={this.state.end}
												onChange={(event) => this.setState({end: event.target.value})}
											/>
										</div>
										<div style={{ width: '16px' }}></div>
										<div className="data-to column justify-end" style={{ maxWidth: '80px' }}>
											<div className="white-space-16" />
											<button 
											    type="button" 
												className="btn btn-primary btn-small color-white" 
												onClick={this.getRondas.bind(this)}
											>
												<i className="fas fa-search"/>
											</button>
										</div>
									</div>
                                <div className="card-table card-table-bitacora column" id="card-owners">
                                    <table>
                                        <thead id='table' className="sticky-th">
                                            <tr className="text-left">
                                                <th className="text-left description">
                                                    <div className="row">
                                                        Descripción &nbsp;
                                                        <div className="align-center">
                                                            {this.state.filterColumn.col === 'comentarios' ? this.state.filterColumn.filt === 'asc' ?
                                                                <i className="fas fa-sort-down" onClick={this.sortableTable.bind(this, 'comentarios')}></i>
                                                                :
                                                                <i className="fas fa-sort-up" onClick={this.sortableTable.bind(this, 'comentarios')}></i>
                                                                :
                                                                <i className="fas fa-sort" onClick={this.sortableTable.bind(this, 'comentarios')}></i>
                                                            }
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="text-center th-options">
                                                    <div className="row">
                                                        Prioridad &nbsp;
                                                        <div className="align-center">
                                                            {this.state.filterColumn.col === 'prioridad' ? this.state.filterColumn.filt === 'asc' ?
                                                                <i className="fas fa-sort-down" onClick={this.sortableTable.bind(this, 'prioridad')}></i>
                                                                :
                                                                <i className="fas fa-sort-up" onClick={this.sortableTable.bind(this, 'prioridad')}></i>
                                                                :
                                                                <i className="fas fa-sort" onClick={this.sortableTable.bind(this, 'prioridad')}></i>
                                                            }
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="text-left th-options">
                                                    <div className="row">
                                                        Fecha &nbsp;
                                                        <div className="align-center">
                                                            {this.state.filterColumn.col === 'fecha' ? this.state.filterColumn.filt === 'asc' ?
                                                                <i className="fas fa-sort-down" onClick={this.sortableTable.bind(this, 'fecha')}></i>
                                                                :
                                                                <i className="fas fa-sort-up" onClick={this.sortableTable.bind(this, 'fecha')}></i>
                                                                :
                                                                <i className="fas fa-sort" onClick={this.sortableTable.bind(this, 'fecha')}></i>
                                                            }
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="text-left th-options">
                                                    <div className="row">
                                                        Usuario &nbsp;
                                                        <div className="align-center">
                                                            {this.state.filterColumn.col === 'usuario' ? this.state.filterColumn.filt === 'asc' ?
                                                                <i className="fas fa-sort-down" onClick={this.sortableTable.bind(this, 'usuario')}></i>
                                                                :
                                                                <i className="fas fa-sort-up" onClick={this.sortableTable.bind(this, 'usuario')}></i>
                                                                :
                                                                <i className="fas fa-sort" onClick={this.sortableTable.bind(this, 'usuario')}></i>
                                                            }
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="text-center th-options-small">Ver</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.loading ?(
                                                <tr>
                                                    <td colSpan="5">
                                                        <div className="row justify-center">
                                                            <i className="fas fa-spinner fa-spin"></i>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) : bitacora.length === 0 ? (
                                                <tr>
									                <td colSpan="9" className="text-center">
										                Sin datos que mostrar.
									                </td>
								                </tr>
                                                ) : ( 
                                                bitacora
                                                    .slice(((this.state.page - 1) * this.state.pageLength), (this.state.page * this.state.pageLength)).map((bitacora, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td className="text-left "><p className="parrafo">{bitacora.comentarios}</p></td>
                                                            <td className="text-center">{bitacora.prioridad}</td>
                                                            <td className="text-left">{moment(bitacora.fecha).format("DD/MM/YYYY")}</td>
                                                            <td className="text-left">{bitacora.usuario}</td>
                                                            <td>
                                                                <button className="btn-full btn-mini btn-secondary color-white"
                                                                    type='button'
                                                                    onClick={this.openModal.bind(this, "bitacoraModal", bitacora)}
                                                                    style={{ padding: '5px' }} >
                                                                    <i className="fas fa-eye"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                            }))}
                                        </tbody>
                                    </table>

                                </div>
                                <div className="white-space-16"></div>
                                <Paginador
                                    pages={Math.ceil(bitacora.length / this.state.pageLength)}
                                    setPage={this.setPage.bind(this)}
                                    pageLength={this.state.pageLength}
                                    paginatorChange={this.paginatorChange.bind(this)}
                                    showPageLenght={true}
                                />

                            </div>
                        </div>
                    </div>
                </div>
                <BitacoraModal
                    data={this.state.bitacoraModal}

                />
            </div>
        );
    };
};

export default Consumer(SecurityBitacora);