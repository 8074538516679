import React, {Component} from 'react';

class ComponenteChido extends Component{
    constructor(props){
        super(props)
    }


    render(){
        return(
            <React.Fragment>
                <div className="form-group">
                    <div className="form-item">
                        <label for="name">Subfraccionamiento</label>
                        <select name="" id="">
                            <option value="1">Villa Magna</option>
                            <option value="2">Dwit</option>

                        </select>
                        {/* <span>El número debe ser mayor o igual a 8 caracteres</span> */}
                    </div>
                    <div className="form-item text-center">
                        <label for="name">Vivienda</label>
                        <select name="" id="">
                            <option value="1">Vivienda 1</option>
                            <option value="2">Vivienda 2</option>

                        </select>
                        {/* <span>El número debe ser mayor o igual a 8 caracteres</span> */}
                    </div>

                    <div className="form-item">
                        <label for="name">Nombre</label>
                        <input type="text" name="name" maxlength="100" autoComplete='off' spellcheck="false"
                        value ="José Andrés Romero Ramírez"/>
                        {/* <span>El número debe ser mayor o igual a 8 caracteres</span> */}
                    </div>

                    <div className="form-item error">
                        <label for="name">Teléfono</label>
                        <input type="text" name="name" maxlength="100" autoComplete='off'
                        value= "998"/>
                        <span>El número de teléfono no cumple con el formato deseado</span>
                    </div>

                    <div className="group-double">
                    <div className="form-item">
                        <label for="name">Acceso</label>
                        <input className="text-center" type="text" name="name" maxlength="100" autoComplete='off' disabled value ="PENDIENTE"/>
                        {/* <span>El número debe ser mayor o igual a 8 caracteres</span> */}
                    </div>
                

                
                    <div className="form-item">
                        <label for="name">TIPO</label>
                        <input className="text-center" type="text" name="name" maxlength="100" autoComplete='off' disabled value ="FAMILIAR"/>
                        {/* <span>El número debe ser mayor o igual a 8 caracteres</span> */}
                    </div>
                    </div>
                </div>
                {/* <div className="form-group-double"> */}
                    
                    
                {/* </div> */}
            </React.Fragment>
        )
    }
}

export default ComponenteChido;