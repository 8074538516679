import React from "react";
import { Helmet } from "react-helmet";
import Header from "../header/header";
import Controls from "../controls/controls";
import Sidebar from "../header/sidebar";
import SuperCore from "../core/SuperCore";
import RulesTable from "../../components/Billing/rulesTable";
import sortableData from "../../helpers/sortableDataTable";
import { Consumer } from "../../context";
import DinamicModal from '../dinamicModal/dinamicModal';
import HolidayDates from './HolidayDates';
import PaginadorParent from "../paginador/PaginadorParent";

class RulesRender extends SuperCore {
	constructor(props) {
		super(props);
		this.state = {
			page: 0,
			rules: this.props.rules,
			pagedRules: this.props.rules,
			loading: null,
			subfraccionamientos: this.props.subfraccionamientos,
			fracName: this.props.fracName,
			frac_name: this.props.frac_name,
			direction: {},
			filterColumn: {},
			submenu: "",
			subLabel: ''
		};
	};

	async componentDidMount() {
		await this.getSubmenu();
	};

	async getSubmenu() {

		const submenu = await this.props.context.getSubmenuName();

		if (submenu) {
			this.setState({ submenu });
		};

		const subLabel = window.localStorage.getItem('subLabel');

		if (subLabel) {
			this.setState({ subLabel });
		};

	};

	componentDidUpdate(prevProps, prevState) {

		if (prevProps.rules != this.props.rules) {
			this.setState({ rules: this.props.rules });
		}

		if (prevProps.loading != this.props.loading) {
			this.setState({ loading: this.props.loading });
		}

		if (this.props.subfraccionamientos != prevProps.subfraccionamientos) {
			this.setState({
				subfraccionamientos: this.props.subfraccionamientos,
			});
		}

		if (this.props.frac_name != prevProps.frac_name) {
			this.setState({
				frac_name: this.props.frac_name,
			});
		}

		if (this.props.subLabel != prevProps.subLabel) {
			this.setState({ subLabel: this.props.subLabel });
		}

	};

	async setPage(page) {
		this.setState({ page });
	};

	triggerOpenModal(id) {
		this.props.triggerOpenModal();
		this.superOpenModal(id);
	};

	sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, this.state.rules, this.state.direction[key]);

		this.setState({
			rules: items,
			direction: {
				[key]: direction,
			},
			filterColumn: {
				col: key,
				filt: direction,
			},
		});
	};

	async openDiasFeriados() {

		const modal = document.getElementById("diasFeriadosModal");

		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		};

	};

	render() {
		return (
			<div className="admin column">
				<Helmet>
					<title>
						{process.env.REACT_APP_NAME} - {this.state.submenu}
					</title>
				</Helmet>
				<Header
					reload={this.props.reload.bind(this)}
					change={this.props.changeFrac.bind(this)}
					sidebar={true}
					active={"historial"}
					nav={"catalogos"}
				/>
				<div className="row">
					<div className="column" id="sidebar">
						<Sidebar />
					</div>
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								<Controls
									title     = {this.state.submenu}
									nuevo     = {this.triggerOpenModal.bind(this, "newRule")}
									dataSet   = {this.state.rules}
									newButton = {this.props.escritura}
									setFilter = {this.props.setFilter.bind(this)}
									subChange = {this.props.subChange.bind(this)}
								/>
								<div className="navigation align-center justify-end">
									<button
										type="button"
										className="btn btn-primary btn-small color-white"
										onClick={this.openDiasFeriados}
									>
										<i className="fas fa-calendar-times font-text" />
										&nbsp; Días Feriados
									</button>
								</div>
								<div className="white-space-16"></div>
								<RulesTable
									rules={this.state.pagedRules}
									page={this.state.page}
									reload={this.props.reload.bind(this)}
									escritura={this.state.escritura}
									loading={this.props.loading}
									subfraccionamientos={this.state.subfraccionamientos}
									frac_name={this.state.frac_name}
									pageLength={this.props.pageLength}
									sortBy={this.sortableTable.bind(this)}
									filterColumn={this.state.filterColumn}
									subLabel={this.state.subLabel}
								/>
								<div className="white-space-16"></div>
								<PaginadorParent
									data={this.state.rules}
									onPagedDataChange={({ pagedData }) => this.setState({ pagedRules: pagedData })}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="row justify-between align-center">
					<div className="row">

						<DinamicModal
							idModal={'diasFeriadosModal'}
							sizeModalContainer={'big'}
							title={'Días feriados'}
						>
							<HolidayDates />
						</DinamicModal>

					</div>

				</div>
			</div>
		);
	};
};

export default Consumer(RulesRender);
