// React and third-parties
import React, { useState, useEffect, useLayoutEffect, useRef, forwardRef } from "react";
import { createPortal } from "react-dom";

// Hooks
import useMuliSelectFormat from "../../hooks/useMuliSelectFormat";

export default function MultiSelect(props) {

    const { options = [], disabled = false, onSelected, onRemoved, min = 0, style } = props;

    const elementRef = useRef();
    const modalRef = useRef();

    const { 
        filteredOptions, allFilteredSelectedOptions,
        searchFilter, setSearchFilter,
        setOptions, getFilteredOptionsInfo,
        selectOption, selectAllOptions 
    } = useMuliSelectFormat(options, min);

    const [openOptions, setOpenOptions] = useState(false);
    const [modalLayout, setModalLayout] = useState({ width: 0, top: 0, left: 0, top1: 0, left1: 0 })

    useLayoutEffect(() => {
      
        if(openOptions) {

            const inputElement = elementRef.current.getElementsByClassName('multi-select-input')[0];
            const bounds =  inputElement.getBoundingClientRect();

            setModalLayout({ 
                width: bounds.width, 
                height: bounds.height + 2, 
                top: bounds.top,
                left: bounds.left
            });
        }

    }, [openOptions]);

    useEffect(() => {

        if (!openOptions) return;

        const onPointerDown = (e) => {

            const containsChildren = modalRef.current.contains(e.target);

            if (!containsChildren) {
                setOpenOptions(false);
                setSearchFilter("");
            }
        };

        document.addEventListener('pointerdown', onPointerDown);

        return () => document.removeEventListener('pointerdown', onPointerDown);

    }, [openOptions, setSearchFilter]);

    useEffect(() => {

        if(options.length === 0) return;

        setOptions(options);

    }, [options, setOptions])

    const onSelectOption = (index) => {

        const checked = !filteredOptions[index].checked;
        const newOptionsSelected = selectOption(index);

        if(newOptionsSelected === null) return;

        checked ? onSelected(newOptionsSelected) : onRemoved(newOptionsSelected)
    };

    const onSelectAll = (all) => {

        const newOptionsSelected = selectAllOptions();

        all ? onSelected(newOptionsSelected) : onRemoved(newOptionsSelected)
    };

    const getSelectedOptionsText = (optionsChecked, optionsEnabled) => {

        if(options.length === 0) return "Sin opciones";

        return optionsChecked.length > 0 ?
            optionsChecked.length === optionsEnabled.length ?
            "Todos"
            : optionsChecked.join(", ")
            : "Seleccionar";
    };

    const onKeyDown = (e) => {

        if (e.key === "Escape") {
            setOpenOptions(false);
            setSearchFilter("");
        }
    };

    const { optionsChecked, optionsEnabled } = getFilteredOptionsInfo();

    const selectedOptionsText = getSelectedOptionsText(optionsChecked, optionsEnabled);

    return (
        <div
            ref={elementRef}
            className={'multi-select ' + (disabled ? 'disabled' : '')}
            tabIndex={"0"}
            style={{ zIndex: 1, ...style }}
            onKeyDown={onKeyDown}
            onPointerDown={!openOptions ? () => setOpenOptions(true) : undefined}
        >
            <div className='multi-select-input row full' >
                <input
                    name="multi-select-text-filter"
                    style={{ display: "inline-block", width: "95%" }}
                    placeholder={selectedOptionsText}
                    value={searchFilter}
                    disabled={disabled}
                    onChange={(e) => setSearchFilter(e.target.value)}
                />
            </div>
            <SelectModal
                ref={modalRef}
                open={openOptions && !disabled}
                options={filteredOptions}
                optionsHeader={optionsChecked.length + " seleccionado(s)"}
                allOptionsSelected={allFilteredSelectedOptions}
                modalLayout={modalLayout}
                onSelectOption={onSelectOption}
                onSelectAll={() => onSelectAll(!allFilteredSelectedOptions)}
            />
        </div>
    )
}

export const SelectModal = forwardRef((props, ref) => {

    const { options, open, optionsHeader, allOptionsSelected, modalLayout, onSelectOption, onSelectAll } = props;

    return createPortal(
        <div
            ref={ref}
            className='multi-select-modal column'
            style={{
                position: "absolute", zIndex: 2, top: modalLayout.top + modalLayout.height, left: modalLayout.left,
                width: modalLayout.width, display: open ? "flex" : "none"
            }}
        >
            <SelectOption
                option={optionsHeader}
                checked={allOptionsSelected}
                disabled={false}
                onSelect={() => onSelectAll(!allOptionsSelected)}
            />
            <div style={{ width: "100%", marginBlock: 2, height: 1, backgroundColor: "#555" }}></div>
            {options.length > 0 ?
                options.map(({ id, option, checked, disabled }, index) =>
                    <SelectOption
                        key={id}
                        option={option}
                        checked={checked}
                        disabled={disabled}
                        onSelect={() => onSelectOption(index)}
                    />
                )
                :
                <div
                    className='row align-center multiple-option'
                    style={{ fontStyle: "italic", pointerEvents: "none" }}
                >
                    <p>Sin opciones</p>
                </div>
            }
        </div>
    , document.body);
});

const SelectOption = (props) => {

    const { option, checked, disabled, onSelect, style } = props;

    const onPointerDown = (e) => {

        if(disabled) return;

        e.preventDefault();

        onSelect();
    };

    return (
        <div
            className='row align-center multiple-option'
            style={{ opacity: !disabled ? 1 : 0.5, pointerEvents: !disabled ? "all" : "none", ...style }}
            onPointerDown={onPointerDown}
        >
            <input
                type='checkbox'
                checked={checked}
                style={{ pointerEvents: "none" }}
                readOnly
            />
            <p>{option}</p>
        </div>
    )
};
