import { calculoTipos, aplicacionEstadoTipos } from './pagoTypes';

export const validateAplicacion = (documentos, aplicacionEstado, calculoTipo, { importe, saldoAFavorGenerado }) => {

    if(aplicacionEstado === aplicacionEstadoTipos.inicial)
        return { error: "No se han aplicado los pagos."};

    const totalAPagar = parseFloat(documentos.reduce(
        (suma, documento) => suma + Number(documento.Aplicado),
        0).toFixed(2));

    if(totalAPagar <= 0) return { error: "No hay datos que guardar." };

    if(calculoTipo === calculoTipos.manual) {

        if (totalAPagar + saldoAFavorGenerado !== importe)
            return {
                error: `El total de los pagos aplicados ${totalAPagar > importe ?
                    'es mayor' : 'es menor'} al importe de pago.`
            };
    }

    return { valid: true };
};

export const validatePagoInfo = (documentos, saldoAFavor, { fecha, idMetodoPago, importe }) => {

    if(!fecha) return { error: "Debe seleccionar una fecha." };

    if(idMetodoPago === 0) return { error: "Debe seleccionar un método de pago." };

    if(importe <= 0) return { error: "El importe a pagar debe ser mayor a 0." };

    const totalAPagar = documentos.reduce(
        (suma, documento) => suma + Number(documento.Saldo),
        0).toFixed(2);

    if(totalAPagar <= 0) return { error: "Ingresar cantidad a aplicar de al menos un documento." };

    if(idMetodoPago === -1) { // Saldo a favor

        if (importe > saldoAFavor) return { error: "El importe de saldo a favor es mayor al disponible." };
        
        if (importe > totalAPagar) return { error: "El importe de saldo a favor es mayor al total a pagar." };
    }

    const saldoAFavorGenerado = importe > totalAPagar ? importe - totalAPagar : undefined;

    return { valid: true, saldoAFavorGenerado };
};