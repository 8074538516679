import React, { Component } from 'react';
import ModalBorrar from '../modals/modalDelete';
import DinamicModal from '../dinamicModal/dinamicModal';
import PersonalForm from './form';
import Request from "../../core/httpClient";
import cogoToast from 'cogo-toast';
import moment from 'moment-timezone';

const request = new Request();

class PersonalTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
			personal: {},
			loadingSave: false
		}
    }

    render() {
        let personal = []
        if (this.props.personal) {
            personal = this.props.personal
        }
        return (
            <div className="card-table card-owners column" id="card-owners">
                <div className="row">
                    <table className="full">
                        <thead className="sticky-th">
                            <tr className="text-left">
                                <th className="th-options-huge">
									<div className="row">
										Nombre(s) &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'nombres' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'nombres')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'nombres')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'nombres')}></i>
											}
										</div>
									</div>
								</th>
								<th className="th-options-huge">
									<div className="row">
										Apellidos &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'apellidos' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'apellidos')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'apellidos')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'apellidos')}></i>
											}
										</div>
									</div>
								</th>
								<th className="th-options-huge">
									<div className="row">
										Departamento &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'departamento' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'departamento')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'departamento')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'departamento')}></i>
											}
										</div>
									</div>
								</th>
                                <th className="text-center th-options-small">Editar</th>
								<th className="text-center th-options-small">Borrar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {personal.slice(((this.props.page - 1) * this.props.pageLength), (this.props.page * this.props.pageLength)).map((personal, key) => (
                                <tr className={this.state.id_personal_row === personal.id_personal && 'row-active'} key={key}>
                                    <td>
                                        {personal.nombres}
                                    </td>
									<td>
                                        {personal.apellidos}
                                    </td>
									<td>
                                        {personal.departamento}
                                    </td>
									<td className="text-center">
                                        <button
											type        =   "button justify-center" 
											onClick     =   {this.openModal.bind(this, 'modal-edit-personal', personal)}
                                        >
											<i className="font-small fas fa-pen" />
										</button>
									</td>
									<td className="text-center">
										<div className="aling-center justify-center">
											<button 
													className="btn-full justify-center align-center btn-mini btn-secondary color-white" 
													type		=	'button' 
													onClick		=	{this.openModalDelete.bind(this, personal)} 
													style		=	{{ padding: '5px' }} 
													disabled	=	{!this.props.escritura}
											>
												<i className="font-text fas fa-trash-alt " /> &nbsp; {this.props.icono ? "" : null}
											</button>
										</div>
									</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
				</div>
				<DinamicModal 
					idModal				=	{'modal-edit-personal'}
					sizeModalContainer	=	{'normal'}
					title				=	{'Personal'} 
					success				=	{this.handleSubmit.bind(this)} 
					showBtnSuccess		=	{true}
					btnTextSuccess		=	{"GUARDAR"}
					showBtnCancel		=	{true}
					btnTextCancel		=	{"CANCELAR"}
					loadingSave			=	{this.state.loadingSave}
					message				=	{this.state.message}
					closeModal			=	{this.closeModal.bind(this)}
				>
					<PersonalForm
						personal		=	{this.state.personal}
						departamentos	=	{this.props.departamentos}
						posiciones		=	{this.props.posiciones}
						jornadas		=	{this.props.jornadas}
						turnos			=	{this.props.turnos}/>
				</DinamicModal>

				<ModalBorrar
					id          =   {this.state.id_personal}
					delete      =   {this.handleDelete.bind(this)}
                />
            </div>
        );
	}

	closeModal(idModal) {
		this.setState({personal: {}});

		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
	}

	openModal(idModal, data) {
        this.setState({personal: data, id_personal_row: data.id_personal});
        
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
        }
	}

	openModalDelete(personal) {
		this.setState({id_personal: personal.id_personal, id_personal_row: personal.id_personal});
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

	async handleSubmit(event) {
		event.preventDefault();

		this.setState({loadingSave: true});

		const form = event.target;
		const img = form.personal_imagen;

		let file = null;
		if (img.files) {
			file = img.files[0];
		}

		const data = {
			id_personal: this.state.personal.id_personal,
			id_fraccionamiento: localStorage.getItem('frac'),
			departamento: form.personal_departamento.value,
			posicion: form.personal_posicion.value,
			jornada: form.personal_jornada.value,
			turno: form.personal_turno.value,
			nombres: form.personal_nombres.value,
			apellidos: form.personal_apellidos.value,
			rfc: form.personal_rfc.value,
			curp: form.personal_curp.value,
			nss: form.personal_nss.value,
			activo: form.personal_activo.checked,
			fecha_inicio: form.personal_fecha_inicio.value,
			fecha_fin: form.personal_fecha_fin.value,
			user: this.props.user.id.nombre,
			fecha: moment().format()
		}

		let response = await request.new_postFile('/personal/update', [file], data);

		if (response.updated) {
			this.closeModal('modal-edit-personal');
            this.props.reload();
            cogoToast.success('Personal actualizado.', {
                position: 'bottom-right',
            });
        } else {
            cogoToast.error(response.message || 'No se pudo actualizar el personal.', {
                position: 'bottom-right',
            });
        }

		this.setState({loadingSave: false});
	}

	async handleDelete(id) {
        let data = {
            id_personal: id,
			user: this.props.user.id.nombre,
			fecha: moment().format()
        }
        const response = await request.post("/personal/delete", data);
        if (response && !response.error) {
            if (response.deleted && !response.empty) {
                //this.setState({ message: response.message });
                this.props.reload();
                cogoToast.success('Personal eliminado.', {
                    position: 'bottom-right',
                });
            } else {
                this.setState({
                    empty: true,
                    //message: response.message,
                    loading: false
                });
                cogoToast.error('No se pudo eliminar el personal.', {
                    position: 'bottom-right',
                });
            }
        } else {
            this.setState({
                empty: true,
                //message: response.message,
                loading: false
            });
            cogoToast.error('No se pudo eliminar el personal.', {
                position: 'bottom-right',
            });
        }
    }
}

export default PersonalTable;