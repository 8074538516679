import React, { useState, useEffect } from 'react';
import Resizer from 'react-image-file-resizer';

const PaqueteForm = ({ viviendas }) => {

	const [id_vivienda, setVivienda] = useState(0);
	const [foto_paquete_url, setfoto_paquete_url] = useState(null);

	async function changePaqueteImagen(event) {
		let file = event.target;
		if (file) {
			if (file.files[0]) {
				Resizer.imageFileResizer(
					file.files[0],
					400,//337
					200,//180
					'JPEG',
					30,
					0,
					uri => {
						setfoto_paquete_url(uri);
					},
					'base64'
				);
			}
		}
	}

	return (
		<div className="row justify-between full">
			<div className="column full">
				<div className="input-form-content row full align-center">
					<div className="column label-medium">
						<p><b>Vivienda:</b></p>
					</div>
					<input
						id="paquete-id_vivienda"
						name="paquete-id_vivienda"
						type="hidden"
						value={id_vivienda}
					/>
					<input
						type="text"
						className="input input-modals"
						autoComplete="off"
						id="paquete-unidad"
						name="paquete-unidad"
						list="lista-viviendas"
						placeholder="Vivienda"
						required
						onChange={(event) => {
							let valor = event.target.value;
							let viviendaSelect = viviendas.filter((viv) => viv.numero_registro === valor);

							if (viviendaSelect[0]) {
								setVivienda(viviendaSelect[0].id_vivienda);
							} else {
								setVivienda(0);
							}
						}}
					/>
					<datalist id="lista-viviendas">
						<select className="input input-modals" name="conceptos">
							{viviendas.map((vivienda, key) => (
								<option value={vivienda.numero_registro} key={key}></option>
							))}
						</select>
					</datalist>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
					<div className="column label-medium">
						<p><b>Paquetería:</b></p>
					</div>
					<div className="column full">
						<input
							className="input input-modals"
							type="text"
							name="paquete_empresa"
							id="paquete_empresa"
							autoComplete='off'
							required
						/>
					</div>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
					<div className="column label-medium">
						<p><b>Destinatario:</b></p>
					</div>
					<div className="column full">
						<input
							className="input input-modals"
							type="text"
							name="paquete_nombre_residente"
							id="paquete_nombre_residente"
							autoComplete='off'
							required
						/>
					</div>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
					<div className="column label-medium">
						<p>
							<b>Fecha llegada:</b>
						</p>
					</div>
					<div className="column full">
						<input
							id="paquete_fecha_llegada"
							className="input input-modals"
							type="date"
							name="paquete_fecha_llegada"
							autoComplete="off"
							required
						/>
					</div>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full">
					<div className="column label-medium">
						<p>
							<b>Comentarios:</b>
						</p>
					</div>
					<div className="column full">
						<textarea
							id="paquete_comentarios"
							name="comentarios"
							cols="30"
							rows="3"
							maxLength="500"
							className='textarea font-small full'
						>
						</textarea>
					</div>
				</div>
			</div>
			<div className="column">
				<div className="responsive-img column space align-start">
					<div className="img-width">
						<div className="img-personal-perfil container-img">
							<img src={foto_paquete_url ? foto_paquete_url : require('../../img/empty_image.png')} className="img-duo-preview" id="previewArea" />
							<div className="middle-overlay">
								<label className="btn-upload-image column align-center justify-center">
									<i className="fas fa-file-upload"></i>
									<input
										type="file"
										id="paquete_foto_llegada"
										name="paquete_foto_llegada"
										accept="image/*"
										onChange={changePaqueteImagen.bind(this)} />
									<div className="white-space-8"></div>
									<p className="color-white">Subir imagen</p>
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PaqueteForm;
