// React and third-parties
import React, { useEffect, useState, useRef } from 'react';
import Draggable from 'react-draggable';
import cogoToast from 'cogo-toast';

// Components
import SuperModal from '../../../components/coreModal/SuperModal';

// HTTP
import Request from '../../../core/httpClient';

const request = new Request();

export default function PrelacionModal(props) {

	const { productos, modalToggle, onSuccessfulSubmit } = props;

	const [sortedProductos, setSortedProductos] = useState(productos);
	const [loadingSetPrelaciones, setLoadingSetPrelaciones] = useState(false);

	useEffect(() => {
		setSortedProductos(productos);
	}, [modalToggle, productos]);
	
	const screen = [
		<PrelacionesScreen
			sortedProductos={sortedProductos}
			onPrelacionesChanged={(productos) => setSortedProductos(productos)}
		/>
	]
	
	const loadSetPrelaciones = async () => {

		const prelaciones = sortedProductos.map((producto, index) => {

			return { Id_Producto: producto.Id_Producto, Prelacion: index + 1 };
		});

		setLoadingSetPrelaciones(true);

		const setPrelaciones = async (prelaciones) => {
			try {
			
				const data = { 
					prelaciones : JSON.stringify(prelaciones)
				};
	
				const response = await request.post("/facturacion/productos/prelaciones/update", data);
	
				if(!response) return { error: "ERROR DE CONEXIÓN. No se pudieron actualizar las prelaciones." };

				if(response.error) return { error: response.message };

				if(response.updated) return { updated: true };
				
	
			} catch (error) {
				return { error };
			}
		}

		const { updated, error } = await setPrelaciones(prelaciones);

		if(updated) {
			cogoToast.success("Prelaciones actualizadas.", {
				position: "bottom-right",
				hideAfter: 5,
			});

			onSuccessfulSubmit();
		}

		if(error) {
			console.log("PrelacionesUpdate error: ", error);
			cogoToast.error(error, {
				position: "bottom-right",
				hideAfter: 5,
			});
		}

		setLoadingSetPrelaciones(false);
	}

	return (
		<SuperModal
			id={"modificarPrelaciones"}
			size={"static-nav"}
			title={"Prelaciones"}
			id_menu_activo={0}
			screens={screen}
			nexFooter={true}
			lblSubmit="Aceptar"
			lblCancel="Cancelar"
			lblBack="Anterior"
			lblNext="Siguiente"
			showSubmit={true}
			showClose={true}
			onSubmit={() => loadSetPrelaciones()}
			loading={loadingSetPrelaciones}
		/>
	)
}

const PrelacionesScreen = (props) => {

	const { sortedProductos, onPrelacionesChanged } = props;

	const prelacionesListRef = useRef();

	const [draggingIndex, setDraggingIndex] = useState(-1);
	const [draggingPositionIndex, setDraggingPositionIndex] = useState(-1);
	const [listItemHeight, setListItemHeight] = useState(0);
	const [moveTransition, setMoveTransition] = useState(false);

	const unorderedListClassName = 'dragAndSortList ' + (draggingIndex !== -1 ? "dragging" : "");

	useEffect(() => {

		if (sortedProductos.length > 0) {

			const firstElement = prelacionesListRef.current.getElementsByClassName("prelacionHandleItem")[0];
			setListItemHeight(firstElement.getClientRects()[0].height);
		}
	}, [sortedProductos, prelacionesListRef]);

	const handleDraggingOver = (e) => {

		const ULElementBounds = e.currentTarget.getBoundingClientRect();
		const scrollTopOffset = e.currentTarget.scrollTop;
		const elementSize = listItemHeight;

		let position = e.clientY - ULElementBounds.y + scrollTopOffset;
		let positionIndex = -1;

		while(position > 0){
			positionIndex++
			position -= elementSize
		}

		if(positionIndex !== draggingPositionIndex && positionIndex !== -1) {
			setDraggingPositionIndex(positionIndex);
		}
	}

	const onStartDrag = (index) => {
		setDraggingIndex(index);
		setDraggingPositionIndex(index);
	}

	const onEndDrag = (index) => {

		if(index !== draggingPositionIndex) {

			const productosCopy = [...sortedProductos];

			const item = productosCopy.splice(draggingIndex, 1)[0];
	
			productosCopy.splice(draggingPositionIndex, 0, item);
	
			onPrelacionesChanged(productosCopy);
		};

		setDraggingIndex(-1);
		setDraggingPositionIndex(-1);
	}

	const onMoveTransitionEnd = (e) => {

		if(e.propertyName !== 'transform') return;

		onEndDrag(-1);
		setMoveTransition(false);
	}

	const onMoveTabUp = (index) => {

		if(index === 0) return false;

		setMoveTransition(true);
		setDraggingIndex(index);
		setDraggingPositionIndex(index - 1);

		return true;
	}

	const onMoveTabDown = (index) => {

		if(index === sortedProductos.length - 1) return false;

		setMoveTransition(true);
		setDraggingIndex(index);
		setDraggingPositionIndex(index + 1);

		return true;
	}

	const onMouseMove = draggingIndex !== -1 && !moveTransition ? handleDraggingOver : undefined;
	const onTransitionEnd = moveTransition ? onMoveTransitionEnd : undefined;

	return (
		<div className="container" style={{ height: "100%", paddingTop: 16 }}>
			<div className="column align-center" style={{ flexGrow: 1, maxHeight: 470 }}>
				<div
					className='row justify-start full'
					style={{ paddingBlock: 6, paddingRight: 4, backgroundColor: "#EEE", borderTop: "1px solid #cfcfcf" }}
				>
					<div style={{ width: "15%", paddingInline: 4, paddingLeft: 12 }}>
						<b>Prelación</b>
					</div>
					<div style={{ width: "20%", paddingInline: 4  }}>
						<b>Código</b>
					</div>
					<div style={{ width: "65%", paddingInline: 4 }}>
						<b>Descripcion</b>
					</div>
				</div>
				<ul
					ref={prelacionesListRef}
					id={"prelacionesList"}
					onMouseMove={onMouseMove}
					onTransitionEnd={onTransitionEnd}
					className={unorderedListClassName}
					style={{ width: "100%", overflowY: "auto" }}
				>
					{sortedProductos &&
						sortedProductos.map((producto, index) => (
							<PrelacionTab
								key={producto.Id_Producto}
								id={"prelacion-" + producto.Id_Producto}
								producto={producto}
								index={index}
								itemHeight={listItemHeight}
								draggingIndex={draggingIndex}
								draggingPositionIndex={draggingPositionIndex}
								onStartDrag={onStartDrag}
								onEndDrag={onEndDrag}
								onMoveUp={onMoveTabUp}
								onMoveDown={onMoveTabDown}
							/>
						))
					}
				</ul>
			</div>
		</div>
	)
}

// Tabs tienen que ser del mismo tamaño
const PrelacionTab = (props) => {

	const { id, index, producto, itemHeight, draggingIndex, draggingPositionIndex, 
		onStartDrag, onEndDrag, onMoveUp, onMoveDown } = props;

	const [draggingOffset, setDraggingOffset] = useState(0);

	const isDragging = draggingIndex === index;

	const handleClassName = "tabBackground " +
		(draggingIndex === -1 ? "prelacionHandle"
			: isDragging ? "prelacionHandle dragging"
				: "");

	const tabTransformOffset = `translateY(${draggingOffset}px)`;

	useEffect(() => {
		
		if(draggingIndex === -1) setDraggingOffset(0);

	}, [draggingIndex]);
	
	useEffect(() => {
		
		if(draggingIndex !== index) {

			if(draggingOffset === 0) {
				
				if(draggingIndex < index && (draggingPositionIndex >= index && draggingOffset !== -itemHeight)) {
					setDraggingOffset(-itemHeight);
				}

				if(draggingIndex > index && (draggingPositionIndex <= index && draggingOffset !== itemHeight)) {
					setDraggingOffset(itemHeight);
				}

			} else {

				if(draggingOffset < 0 && draggingPositionIndex < index) {
					setDraggingOffset(0);
				}

				if(draggingOffset > 0 && draggingPositionIndex > index) {
					setDraggingOffset(0);
				}
			}
		}

	}, [draggingIndex, draggingPositionIndex, draggingOffset, index, itemHeight]);

	const onMoveTab = (e) => {

		if(e.target.classList.contains("prelacionUp")) {
			const canMoveUp = onMoveUp(index);
			if(canMoveUp) setDraggingOffset(-itemHeight);
		}

		if(e.target.classList.contains("prelacionDown")) {
			const canMoveDown = onMoveDown(index);
			if(canMoveDown) setDraggingOffset(itemHeight);
		}
	}

	return (
		<li
			onClick={onMoveTab}
			className={'prelacionHandleItem'}
			style={{
				zIndex: isDragging ? 5 : 1, position: "relative",
				transform: tabTransformOffset,
			}}
		>
			<Draggable
				handle='.prelacionHandle'
				axis='y'
				position={{ x: 0, y: 0 }}
				onStart={(e) => onStartDrag(index)}
				onStop={(e) => onEndDrag(index)}
			>
				<div
					id={id}
                    className={handleClassName}
					draggable={false}
					style={{ width: "100%", paddingBlock: 8, pointerEvents: isDragging ? "none" : "all" }}
				>
					<div className='full row justify-center align-center'>
						<div className='row align-center' style={{ width: "15%", height: "100%", paddingInline: 4 }}>
							<div className='row' onMouseDown={(e) => e.stopPropagation()} style={{ paddingInline: 8, paddingRight: 12 }}>
								<button className='flex prelacionUp' type="button">
									<i
										className="fas fa-angle-up"
										style={{ pointerEvents: "none", color: "#999", fontSize: 24, paddingInline: 4, paddingBottom: 4 }}
									/>
								</button>
								<button className='flex prelacionDown' type="button">
									<i
										className="fas fa-angle-down"
										style={{ pointerEvents: "none", color: "#999", fontSize: 24, paddingInline: 4, paddingTop: 4 }}
									/>
								</button>
							</div>
							<p className='font-regular weight-semi'>{index + 1}</p>
						</div>
						<p style={{ width: "20%", height: "100%", paddingInline: 8, alignItems: "center" }}>{producto.Clave}</p>
						<p style={{ whiteSpace: "nowrap", overflow: "hidden", wordWrap: "break-word", textOverflow: "ellipsis", width: "65%", paddingInline: 8 }}>{producto.Nombre}</p>
					</div>
				</div>
			</Draggable>
		</li>
	)
}