import React, { Component } from 'react';
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Request from "../../core/httpClient";
import cogoToast from 'cogo-toast';
import Resizer from "react-image-file-resizer";
const request = new Request();

class PersonaEditarModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idPersona: 			this.props.persona.id_persona,
            pNombre: 			this.props.persona.nombre || '',
            pTelefono: 			this.props.persona.telefono || '',
            phoneValidated:     false,
			code:               this.props.persona.code || '',
			activated:			false,
			rotate1: 			0,
			rotate2: 			0,
			rotate3:			0
		}
		
	}
	
	componentDidUpdate(prevProps) {
		if(this.props.persona !== prevProps.persona) {
			const phone = parsePhoneNumber(`+${this.props.persona.telefono}`) || '';
			this.setState({
                message:            null,
				idPersona: 			this.props.persona.id_persona,
				pNombre: 			this.props.persona.nombre || '',
				pTelefono: 			phone.number || '',
				code:               this.props.persona.code || '',
				activated:			this.props.persona.activated,
				ine1:				this.props.persona.identificacion1,
				ine2:				this.props.persona.identificacion2,
				avatar:			this.props.persona.avatar_img
			});

			if (this.props.persona.identificacion1) {
				if (this.props.persona.identificacion1.indexOf('data:image/') === 0) {
					const data = {
						ine: this.props.persona.identificacion1,
						idFI: this.props.persona.id_persona
					}
					this.base64ToUrl(data);
				}
			}

			if (this.props.persona.identificacion2) {
				if (this.props.persona.identificacion2.indexOf('data:image/') === 0) {
					const data = {
						ine: this.props.persona.identificacion2,
						idFI: this.props.persona.id_persona
					}
					this.base64ToUrl(data);
				}
			}

			if (this.props.persona.avatar_img) {
				if (this.props.persona.avatar_img.indexOf('data:image/') === 0) {
					const data = {
						ine: this.props.persona.avatar_img,
						idFI: this.props.persona.id_persona
					}
					this.base64ToUrl(data);
				}
			}
		}
	}

    async validarTelefono(telefono) {
        this.setState({loadingValidatePhone: true, phoneValidated: false, message: null});
        if (isValidPhoneNumber(telefono)) {
            const data = {
                telefono,
                id_persona: this.state.idPersona
            }
            const res = await request.post('/personas/phoneexists', data);
            if (res.exist) {
                this.setState({
                    persona_existente: res.exist,
                    phoneValidated: false,
                    //message: res.message
				});
				cogoToast.warn(res.message, {
					position: 'bottom-right',
				});
            } else {
                this.setState({
                    phoneValidated: true,
                    persona_existente: false
                });
            }
        } else {
			cogoToast.warn('El teléfono no es válido.', {
				position: 'bottom-right',
			});
            //alert('El teléfono no es válido');
        }
        this.setState({loadingValidatePhone: false});
    }

	async rotateImg(id) {
		if (this.state.rotate1 === 360) {
			await this.setState({ rotate1: 0 });
		}
		if (this.state.rotate2 === 360) {
			await this.setState({ rotate2: 0 });
		}
		if (this.state.rotate3 === 360) {
			await this.setState({ rotate3: 0 });
		}

		if (id === 1) {
			await this.setState({ rotate1: this.state.rotate1 + 90 });
		} else if (id === 2) {
			await this.setState({ rotate2: this.state.rotate2 + 90 });
		} else {
			await this.setState({ rotate3: this.state.rotate3 + 90 });
		}
		await this.changeImg(id);
	}

	async changeImg(id) {
		let rotate = 0;
		if (id === 1) {
			rotate = this.state.rotate1;
		} else if (id === 2) {
			rotate = this.state.rotate2;
		} else {
			rotate = this.state.rotate3;
		} 

		let file = document.getElementById(`imagen${id}`);
		if (file) {
			if (file.files[0]) {
				Resizer.imageFileResizer(
					file.files[0],
					337, //337
					180, //180
					"JPEG",
					100,
					rotate, //270
					async (uri) => {
						if (id === 1) {
							await this.setState({ ine1: uri });
						} else if (id === 2) {
							await this.setState({ ine2: uri });
						} else {
							await this.setState({ avatar: uri });
						}
					},
					"Blob"
				);
			}
		}
	}

    render() {
        return (
            <div>
				<DinamicModal 
					idModal					=	{'editPersona'}
					sizeModalContainer		=	{'big'}
					title					=	{'Editar persona'} 
					success					=	{this.handleSubmit.bind(this)} 
					showBtnSuccess			=	{this.props.escritura}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}
					btnTextCancel			=	{"CANCELAR"}
					loadingSave				=	{this.state.loadingSave}
                    message					=	{this.state.message}
                    disabledBtnSuccess      =   {!this.state.phoneValidated}
					closeModal				= 	{this.handleClose.bind(this)}
				>
					<div className="full row justify-center">
						<div className="column full">
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-cente">
								<div className="column label-medium">
									<p><b>  Teléfono:	</b></p>
								</div>
								<div className="row full">
									<PhoneInput
										displayInitialValueAsLocalNumber
										name="phoneNumber"
										maxLength = "18"
										defaultCountry="MX"
										autoComplete='off'
										value={this.state.pTelefono}
										onChange={value => this.setState({ pTelefono: value, telefonoError: undefined, phoneValidated: false })}
										error={this.state.telefonoError}
										className={'input-modals'}
										required
									/>
									<button disabled={this.state.loadingVlidatePhone} type="button" className="btn-small btn-primary color-white" onClick={this.validarTelefono.bind(this, this.state.pTelefono)}>
										{this.state.loadingValidatePhone ? <i className="fas fa-spinner fa-spin"></i> : 'Validar'}
									</button>
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p><b>  Nombre completo:	</b></p>
								</div>
								<div className="column full">
									<input
										type="text"
										name="fullName"
										value={this.state.pNombre}
										onChange={event => this.setState({ pNombre: event.target.value })}
										className="input input-modals"
										autoComplete='off'
										required
										maxLength="64"
									/>
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p><b>  Codigo de verificación:	</b></p>
								</div>
								<div className="column full text-left" style={{fontSize: '0.9rem', fontFamily: '"Mukta", sans-serif'}}>
								{this.state.code}
								</div>
							</div>
							<div className="white-space-16"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p><b>  Dispositivo activado:	</b></p>
								</div>
								<div className="column full">
									<div className="column full">
										{this.state.activated ?
											<label className="container-check">
												<input
													type="checkbox"
													name="activated"
													checked={this.state.activated ? true : false}
													onChange={() => this.setState({ activated: !this.state.activated })}
													className="input input-modals"
													autoComplete='off'
												/>
												<span className="checkmark"></span>
											</label> :
											<label className="container-check">
												<input
													type="checkbox"
													name="activated"
													checked={this.state.activated ? true : false}
													className="input input-modals"
													autoComplete='off'
												/>
												<span className="checkmark-disabled"></span>
											</label>
										}
									</div>
								</div>
							</div>
							<div className="white-space-24"></div>
							<div className="column full images-ine align-between">
								<div className="responsive-img row">
									<div className="img-column align-center full">
										{this.state.avatar && (
											<button type="button" onClick={this.rotateImg.bind(this, 3)}>
												<i className="fas fa-redo-alt"></i>
											</button>
										)}
										<div className="container-img">
											<img src={this.state.avatar ? this.state.avatar : require("../../img/empty_image.png")} className="img-duo-preview" id="previewArea" alt="" />

											<div className="middle-overlay">
												<label className="btn-upload-image column align-center justify-center">
													<i className="fas fa-file-upload"></i>
													<input type="file" id="imagen3" name="AreaImage" accept="image/*" onChange={this.changeImg.bind(this, 3)} />
													<div className="white-space-8"></div>
													<p className="color-white">Subir avatar</p>
												</label>
											</div>
										</div>
									</div>
									<div className="img-column align-center">
										{this.state.ine1 && (
											<button type="button" onClick={this.rotateImg.bind(this, 1)}>
												<i className="fas fa-redo-alt"></i>
											</button>
										)}
										<div className="container-img">
											<img src={this.state.ine1 ? this.state.ine1 : require("../../img/empty_image.png")} className="img-duo-preview" id="previewArea" alt="" />

											<div className="middle-overlay">
												<label className="btn-upload-image column align-center justify-center">
													<i className="fas fa-file-upload"></i>
													<input type="file" id="imagen1" name="AreaImage" accept="image/*" onChange={this.changeImg.bind(this, 1)} />
													<div className="white-space-8"></div>
													<p className="color-white">Subir parte frontal de la INE</p>
												</label>
											</div>
										</div>
									</div>
									<div className="img-column align-center">
										{this.state.ine2 && (
											<button type="button" onClick={this.rotateImg.bind(this, 2)}>
												<i className="fas fa-redo-alt"></i>
											</button>
										)}
										<div className="container-img">
											<img src={this.state.ine2 ? this.state.ine2 : require("../../img/empty_image.png")} className="img-duo-preview" id="previewArea" alt="" />

											<div className="middle-overlay">
												<label className="btn-upload-image column align-center justify-center">
													<i className="fas fa-file-upload"></i>
													<input type="file" id="imagen2" name="AreaImage" accept="image/*" onChange={this.changeImg.bind(this, 2)} />
													<div className="white-space-8"></div>
													<p className="color-white">Subir parte trasera de la INE</p>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</DinamicModal>
        	</div>
        );
    }

    handleClose() {
		const phone = parsePhoneNumber(`+${this.props.persona.telefono}`) || '';
		this.setState({
			message: null,
			idPersona: this.props.persona.id_persona,
			pNombre: this.props.persona.nombre || '',
			pTelefono: phone.number || '',
			code: this.props.persona.code || '',
			activated: this.props.persona.activated
		});

        const modal = document.getElementById('editPersona');
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
        }
    }

    async handleSubmit(event) {
		event.preventDefault();
		this.setState({loadingSave: true, message: null});
		/**Validar Telefono */
		
		if(!isValidPhoneNumber(this.state.pTelefono)) {
			this.setState({telefonoError: 'Número inválido', loadingSave: false});
			return;
		} else {
			this.setState({telefonoError: undefined});
        }

        let data = {
            nombre: 				    this.state.pNombre,
            telefono: 				    this.state.pTelefono,
            id_persona: 				this.state.idPersona,
			email: 					    this.state.email,
			id_fraccionamiento: 		localStorage.getItem('frac') || 0,
			ine1:						this.state.ine1,
			ine2:						this.state.ine2,
			avatar:						this.state.avatar,
			activated:					this.state.activated
        }

        if (this.state.persona_existente) {
            this.setState({loadingSave: false});
            return;
        }
        const response = await request.post("/personas/update", data)

        //this.setState({message: response.message});
		if(response.updated) {
            this.handleClose();
			this.props.reload();
			cogoToast.success('Datos de la persona actualizados.', {
				position: 'bottom-right',
			});
		} else {
			//this.setState({message: response.message || "NO SE PUDO ACTUALIZAR PERSONA"});
			cogoToast.error(response.message || 'No se pudieron actualizar datos de la persona.', {
				position: 'bottom-right',
			});
		}
		this.setState({loadingSave: false});  
    }

	async base64ToUrl(info) {
		const data = {
			img: info.ine,
			idfrac: localStorage.getItem('frac') || 0,
			id_persona: info.idFI,
			module: "personas"
		}

		const res = await request.post("/personas/base64ToUrl", data);
		if (res) {
			//console.log(res);
			if (!res.error) {
				//return res.url;
				if (info.type === 1) {
					this.setState({ ine1: res.url });
				}

				if (info.type === 2) {
					this.setState({ ine2: res.url });
				}

				if (info.type === 3) {
					this.setState({ avatar: res.url });
				}
			}
		}
	}
}

export default PersonaEditarModal;