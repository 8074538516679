
export const selectDoc = (id_doc, selected, allDocsSelected, docs, key) => {

    let newSelected = [...selected];
    const parsedId = parseInt(id_doc);

    if(parsedId !== 0) {
        const foundDoc = newSelected.find((doc => doc == parsedId));

        if(foundDoc) {
            newSelected = newSelected.filter(doc => doc != parsedId);
        } else{
            newSelected.push(id_doc);
        }
    }
    else {

        if(allDocsSelected) {

            newSelected = docs.reduce((prevSelected, currDoc) => {

                const foundIndex = prevSelected.indexOf(currDoc[key]);
                prevSelected.splice(foundIndex, 1);
                return prevSelected;

            }, newSelected);

        } else {
            newSelected = docs.reduce((prevSelected, currDoc) => { 

                if(!prevSelected.includes(currDoc[key])) {
                    prevSelected.push(currDoc[key]);
                }

                return prevSelected;

            }, newSelected);
        }
    }

    return newSelected;
};

export const getAllDocsSelected = (selectedDocs, docs, key) => {

    if(docs.length === 0 || selectedDocs.length === 0) return false;

    const selectedCopy = [...selectedDocs];

    for (let index = 0; index < docs.length; index++) {
        
        const foundIndex = selectedCopy.indexOf(docs[index][key]);

        if(foundIndex !== -1) {
            selectedCopy.splice(foundIndex, 1);
        }else{
            return false;
        }
    }

    return true;
};