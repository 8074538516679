import React from "react";
import Core from "./../core/SuperCore";
import Paginador from "./../paginador/paginador";
import DinamicModal from "./../dinamicModal/dinamicModal";
import cogoToast from "cogo-toast";
import Request from "./../../core/httpClient";
import ModalBorrar from "../modals/modalDelete";

const request = new Request();

class RespuestasTable extends Core {
	constructor(props) {
		super(props);
		this.state = {
			id_pregunta: this.props.id_pregunta,
			respuestas: this.props.respuestas,
			respuestas_page: 1,
			title: "Añadir respuesta",
			create: true,
			id_respuesta: 0,

			respuesta: "",
			explicacion: false,
		};
	}

	async componentDidUpdate(prevProps) {
		if (prevProps.id_pregunta != this.props.id_pregunta) {
			this.setState({id_pregunta: this.props.id_pregunta});
		}

		if (prevProps.respuestas != this.props.respuestas) {
			this.setState({respuestas: this.props.respuestas});
		}
	}

	renderRespuestas() {
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Respuesta:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								name=""
								id=""
								className="input input-modals"
								value={this.state.respuesta}
								onChange={(event) => this.setState({respuesta: event.target.value})}
								required
							/>
						</div>
					</div>
					<div className="white-space-8" />

					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Explicación:</b>
							</p>
						</div>
						<div className="column full">
							<div className="item">
								<div className="check">
									<label className="container-check">
										<input type="checkbox" checked={this.state.explicacion} onClick={(e) => this.setState({explicacion: !this.state.explicacion})} />
										<span className="checkmark"></span>
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		let {respuestas} = this.state;

		return (
			<React.Fragment>
				<tr>
					<td colSpan="6">
						<table className="full">
							<tbody>
								<tr>
									<th colSpan="3" className="text-center">
										<h6>Respuestas</h6>
									</th>
									<th>
										<button
											className="btn-full justify-center align-center btn-mini btn-primary color-white"
											type="button"
											onClick={(e) => {
												this.setState({title: "Añadir respuesta"});
												this.clearData();
												this.openModal("modal-respuestas");
											}}
											disabled={!this.props.escritura || this.props.cerrado}
										>
											Añadir Respuesta
										</button>
									</th>
								</tr>
								<tr>
									<th className="text-center th-options-huge">Respuesta</th>
									<th className="th-options text-center">Explicación</th>
									<th className="th-options">Editar</th>
									<th className="th-options">Eliminar</th>
								</tr>

								{respuestas.slice((this.state.respuestas_page - 1) * 5, this.state.respuestas_page * 5).map((res) => {
									return (
										<tr className="tr-selected-respuestas">
											<td className="text-left">{res.respuesta}</td>
											<td className="text-center">{res.explicacion ? "Si" : "No"}</td>
											<td>
												<button
													className="btn-full justify-center align-center btn-mini btn-secondary color-white"
													type="button"
													onClick={(event) => {
														this.openEditModal(res);
													}}
												>
													<i className="font-small fas fa-edit" />
												</button>
											</td>
											<td>
												<button
													className="btn-full justify-center align-center btn-mini btn-secondary color-white"
													type="button"
													onClick={(event) => {
														this.setState({id_respuesta: res.id_encuesta_respuesta});
														this.openModalDelete("deleteModal-respuestas");
													}}
													disabled={!this.props.escritura || this.props.cerrado}
												>
													<i className="font-small fas fa-trash" />
												</button>
											</td>
										</tr>
									);
								})}

								<tr className="separador">
									<td colSpan="4" className="text-center separador">
										<Paginador pages={Math.ceil(respuestas.length / 5)} setPage={this.setPage.bind(this)} />
									</td>
								</tr>
							</tbody>
						</table>
					</td>
				</tr>

				<DinamicModal
					idModal={"modal-respuestas"}
					enforceFocus={true}
					sizeModalContainer={"small"}
					title={this.state.title}
					success={this.handleSubmit.bind(this)}
					showBtnSuccess={this.props.escritura && !this.props.cerrado} //
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.saveMessage}
					closeModal={this.closeModal.bind(this)}
				>
					{this.renderRespuestas()}
				</DinamicModal>

				<ModalBorrar name="deleteModal-respuestas" id={this.state.id_respuesta} delete={this.handleDelete.bind(this)} />
			</React.Fragment>
		);
	}

	async handleDelete() {
		let usuario = await this.getUserInfo();

		let data = {
			IdEncuestaRespuesta: this.state.id_respuesta,
			Usuario: usuario.info.usuario,
		};

		const response = await request.put("/encuestas/delete/respuesta", data);

		if (!response.error) {
			await this.props.getRespuestas();

			cogoToast.success("Respuesta eliminada.", {
				position: "bottom-right",
			});

			this.closeModalDelete("deleteModal-respuestas");
		} else {
			cogoToast.error("No se pudo eliminar respuesta.", {
				position: "bottom-right",
			});
		}

		this.closeModalDelete("deleteModal-respuestas");
		this.props.getRespuestas();
	}

	async openEditModal(res) {
		await this.setState({
			title: "Editar respuesta",
			id_respuesta: res.id_encuesta_respuesta,
			respuesta: res.respuesta,
			explicacion: res.explicacion,
			create: false,
		});

		this.openModal("modal-respuestas");
	}

	async createRespuesta() {
		this.setState({loadingSave: true});

		let usuario = await this.getUserInfo();

		let data = {
			IdEncuestaPregunta: this.state.id_pregunta,
			Respuesta: this.state.respuesta,
			Explicacion: this.state.explicacion ? 1 : 0,
			Usuario: usuario.info.usuario,
		};

		const response = await request.post("/encuestas/add/respuesta", data);

		if (response.error) {
			let msj = "No se pudo añadir respuesta.";

			cogoToast.error(msj, {position: "bottom-right"});
			await this.setState({loadingSave: false});
			return false;
		}

		await this.props.getRespuestas();
		this.closeModal("modal-respuestas");
		this.clearData();
		cogoToast.success("Respuesta añadida.", {position: "bottom-right"});

		this.setState({loadingSave: false});
	}

	async editRespuesta() {
		this.setState({loadingSave: true});

		let usuario = await this.getUserInfo();

		let data = {
			IdEncuestaRespuesta: parseInt(this.state.id_respuesta),
			Respuesta: this.state.respuesta,
			Explicacion: this.state.explicacion ? 1 : 0,
			Usuario: usuario.infousuario,
		};
		const response = await request.put("/encuestas/update/respuesta", data);

		if (response.error) {
			let msj = "No se pudo editar respuesta.";
			if (response.Cerrado) msj = "No se pudo editar respuesta, la encuesta está cerrada.";
			cogoToast.error(msj, {position: "bottom-right"});
			this.setState({loadingSave: false});
			return false;
		}
		await this.props.getRespuestas();
		this.closeModal("modal-respuestas");
		this.clearData();

		cogoToast.success("Respuesta editada.", {position: "bottom-right"});

		this.setState({loadingSave: false});
	}

	async handleSubmit(event) {
		event.preventDefault();

		if (this.state.create) {
			await this.createRespuesta();
		} else {
			await this.editRespuesta();
			await this.setState({title: "Añadir pregunta", create: true});
		}
	}

	async clearData() {
		await this.setState({
			respuesta: "",
			explicacion: false,
		});
	}

	setPage(page) {
		this.setState({respuestas_page: page});
	}
}

export default RespuestasTable;
