import React, { useEffect, useState } from 'react';
import Resizer from 'react-image-file-resizer';
import moment from 'moment-timezone';

const PersonalForm = ({ personal, departamentos, posiciones, jornadas, turnos }) => {
	const [imagen, setImagen] = useState();

	useEffect(() => {
		//console.log("Entré");
		if (Object.keys(personal).length !== 0) {
			colocaValor('personal_nombres', personal.nombres);
			colocaValor('personal_apellidos', personal.apellidos);
			colocaValor('personal_curp', personal.curp);
			colocaValor('personal_departamento', personal.id_personal_departamento);
			colocaValor('personal_jornada', personal.id_personal_jornada);
			colocaValor('personal_posicion', personal.id_personal_posicion);
			colocaValor('personal_turno', personal.id_personal_turno);
			colocaValor('personal_nss', personal.nss);
			colocaValor('personal_rfc', personal.rfc);

			const check = document.getElementById('personal_activo');
			if (check) {
				check.checked = personal.activo || false;
			}

			//console.log('DATEVALID', personal.fecha_fin, moment(personal.fecha_fin, "YYYY-MM-DD").isValid());

			colocaValor('personal_fecha_fin', moment(personal.fecha_fin, "YYYY-MM-DD").isValid() === true ? moment(personal.fecha_fin).format('YYYY-MM-DD') : '');
			colocaValor('personal_fecha_inicio', moment(personal.fecha_inicio, "YYYY-MM-DD").isValid() === true ? moment(personal.fecha_inicio).format('YYYY-MM-DD') : '');

			setImagen(personal.foto_url);
		} else {
			colocaValor('personal_nombres', '');
			colocaValor('personal_apellidos', '');
			colocaValor('personal_curp', '');
			colocaValor('personal_departamento', '');
			colocaValor('personal_jornada', '');
			colocaValor('personal_posicion', '');
			colocaValor('personal_turno', '');
			colocaValor('personal_nss', '');
			colocaValor('personal_rfc', '');

			const checks = document.getElementsByName('personal_activo');
			if (checks) {
				checks.forEach(check => {
					check.checked = false;
				});
			}

			colocaValor('personal_fecha_fin', moment());
			colocaValor('personal_fecha_inicio', moment());

			setImagen();
		}
	}, [personal]);

	async function colocaValor(id, valor) {
		const inputs = document.getElementsByName(id);
		if (inputs) {
			inputs.forEach(input => {
				input.value = valor;
			});
		}
	}

	async function changeImg(event) {
        let file = event.target;
        if(file) {
            if(file.files[0]) {
                Resizer.imageFileResizer (
                    file.files[0],
                    400,//337
                    200,//180
                    'JPEG',
                    30,
                    0,
                    async uri => {
                        await setImagen(uri);
                    },
                    'base64'
                );
            }
        }
	}

	return (
		<div className="full row align-center">
			<div className="column full">
				<div className="responsive-img column space align-start">
					<div className="space">
						<div className="img-personal-perfil container-img">
							<img src={imagen ? imagen : require('../../img/empty_image.png')} className="img-duo-preview" id="previewArea" alt="" />
							<div className="middle-overlay">
								<label className="btn-upload-image column align-center justify-center">
									<i className="fas fa-file-upload"></i>
									<input type="file" id="personal_imagen" name="personal_imagen" accept="image/*" onChange={changeImg.bind(this)} />
									<div className="white-space-8"></div>
									<p className="color-white">subir imagen</p>
								</label>
							</div>
						</div>
					</div>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
					<div className="column label-duo-normal">
						<p>
							<b>Departamento:</b>
						</p>
					</div>
					<div className="column full">
						<select
							id			=	"personal_departamento"
							name		=	"personal_departamento"
							className	=	"input input-modals"
							required
						>
							<option value=""></option>
							{Array.isArray(departamentos) &&
								departamentos.map(dep => 
								<option
									key={dep.id_personal_departamento}
									value={dep.id_personal_departamento}>
										{dep.departamento}
								</option>)
							}
						</select>
					</div>
					<div className="column column label-medium space">
						<p>
							<b>Posición:</b>
						</p>
					</div>
					<div className="column full">
						<select
							id			=	"personal_posicion"
							name		=	"personal_posicion" 
							className	=	"input input-modals"
							required
						>
							<option value=""></option>
							{Array.isArray(posiciones) &&
								posiciones.map(pos => 
									<option
										key={pos.id_personal_posicion}
										value={pos.id_personal_posicion}>
										{pos.posicion}
									</option>)
							}
						</select>
					</div>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
					<div className="column label-duo-normal">
						<p>
							<b>Jornada:</b>
						</p>
					</div>
					<div className="column full">
						<select
							id			=	"personal_jornada"
							name		=	"personal_jornada"
							className	=	"input input-modals"
							required
						>
							<option value=""></option>
							{Array.isArray(jornadas) &&
								jornadas.map(jor =>
									<option
										key={jor.id_personal_jornada}
										value={jor.id_personal_jornada}>
										{jor.jornada}
									</option>)
							}
						</select>
					</div>
					<div className="column column label-medium space">
						<p>
							<b>Turno:</b>
						</p>
					</div>
					<div className="column full">
						<select
							id			=	"personal_turno"
							name		=	"personal_turno" 
							className	=	"input input-modals"
							required
						>
							<option value=""></option>
							{Array.isArray(turnos) &&
								turnos.map(tur =>
									<option
										key={tur.id_personal_turno}
										value={tur.id_personal_turno}>
										{tur.turno}
									</option>)
							}
						</select>
					</div>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
					<div className="column label-duo-small">
						<p><b>Nombre(s):</b></p>
					</div>
					<div className="column full">
						<input
							id				=	"personal_nombres"
							className		=	"input input-modals"  
							type			=	"text" 
							name			=	"personal_nombres" 
							autoComplete 	=	'off'
							required
						/>
					</div>
					<div className="column label-medium space">
						<p><b>Apellidos:</b></p>
					</div>
					<div className="column full">
						<input
							id				=	"personal_apellidos"
							className		=	"input input-modals"  
							type			=	"text" 
							name			=	"personal_apellidos" 
							autoComplete 	=	'off'
							required
						/>
					</div>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
					<div className="column label-duo-small">
						<p><b>CURP:</b></p>
					</div>
					<div className="column full">
						<input 
							className		=	"input input-modals"  
							type			=	"text" 
							name			=	"personal_curp"
							id				=	"personal_curp" 
							autoComplete 	=	'off'
						/>
					</div>
					<div className="column label-medium space">
						<p><b>RFC:</b></p>
					</div>
					<div className="column full">
						<input 
							className		=	"input input-modals"  
							type			=	"text" 
							name			=	"personal_rfc"
							id				=	"personal_rfc" 
							autoComplete 	=	'off'
						/>
					</div>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
					<div className="column label-duo-small">
						<p><b>NSS:</b></p>
					</div>
					<div className="column full">
						<input 
							className		=	"input input-modals"  
							type			=	"text" 
							name			=	"personal_nss"
							id				=	"personal_nss" 
							autoComplete 	=	'off'
						/>
					</div>
					<div className="column label-medium space">
						<p>
							<b>Activo:</b>
						</p>
					</div>
					<div className="column full">
						<label className="container-check" >
							<input
								id			=	"personal_activo"
								type		=	"checkbox" 
								name		=	"personal_activo"
							/>
							<span className="checkmark" ></span>
						</label>
					</div>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
					<div className="column label-duo-small">
						<p>
							<b>Inicio:</b>
						</p>
					</div>
					<div className="column full">
						<input
							id				=	"personal_fecha_inicio"
							className		=	"input input-modals"
							type			=	"date" 
							name			=	"personal_fecha_inicio"
							autoComplete 	=	"off"
						/>
					</div>
					<div className="column label-medium space">
						<p><b>Término:</b></p>
					</div>
					<div className="column full">
						<input
							id				=	"personal_fecha_fin" 
							className		=	"input input-modals"  
							type			=	"date" 
							name			=	"personal_fecha_fin" 
							autoComplete 	=	"off"
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PersonalForm;
