import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import ReactModal from "react-modal";
import SidebarOperacion from "../components/header/sidebarOperacion";
import NotificationTable from "../components/notications/notificationTable";
import DinamicModal from '../components/dinamicModal/dinamicModal';
import Request from "../core/httpClient";
import Controls from '../components/controls/controls';
import Paginador from "../components/paginador/paginador";
import Permissions from '../middlewares/Permissions';
import moment from "moment";
import { Consumer } from "../context";
import cogoToast from 'cogo-toast';
import sortableData from '../helpers/sortableDataTable';

const request = new Request();


class NotificacionDefinition extends Component {
    constructor(props){
        super(props);
        this.state = {
            showModal:                  false,
            notificaciones:             [],
            /* Estados para el filtro generico : filterData y keys */
            filterData:                 [],
            keys:                       [],
            page:                       1,
            notificacionestipe:         [],
            tipe:                       0,
            filter:                     '',
            subfraccionamientoNombre:   '',
            subfraccionamiento:         localStorage.getItem('subfrac') || 0,
            subfraccionamientos:        [],
            escritura:                  false,
            viviendasList:              [],
            message:                    '',
            subIdSelect:                0,
            vivIdSelect:                0,
            msj:                        '',
            fraccionamiento:            localStorage.getItem('frac') || 0,
            user: {
                idUsuario: 0,
                idPerfil: 0,
            },
            logo:					require ("../img/logo-AppMosphera-r.png"),
            lectura:                false,
            pageLength: 			20, 
            direction: {
                tipo: 'desc',
                texto: 'desc'
            },
            filterColumn: {
                col: '',
                filt: ''
            },
            submenu: '',
            subLabel: ''
        }

        this.validarPermisos();
    }

    async validarPermisos(){
		let permisos = new Permissions();
		//  permisos.getResult();
		let permisosInfo = await permisos.getResult()
		await this.setState({escritura: permisosInfo.escritura, lectura: permisosInfo.lectura});
		 //console.log(permisosInfo);

	}

	componentDidUpdate(prevProps, prevState) {
		if(this.state.subfraccionamiento !== prevState.subfraccionamiento) {
			this.getNotificaciones();
        }
        
        // this.refs['input' + this.props.line ].focus();
	}

    /*funcion del paginador*/
    setPage(page) {
        this.setState({ page });
    }
    
    async paginatorChange( length ){
		await this.setState({pageLength:length});
		this.setPage(1);
	}

    /* funcion para el filtro generico */
    filterGeneric(e) {
        e.preventDefault();

        const form = e.target;

        const param1 = form.filterIncidents.value;
        const param2 = form.textFilter.value;

        let filterData = this.state.filterData;

        //

        if (param1 === "todo") {
            this.setState({ notificaciones: this.state.filterData });
            form.textFilter.value = "";
        }
        else {
            const key = param1;
            filterData = filterData.filter(product => product[key].toLowerCase().indexOf(param2.toLowerCase()) !== -1);
            this.setState({ notificaciones: filterData });
        }

    }

    reload() {
        this.getNotificaciones()

        // this.getHouses();
        // this.getNombreSubFraccionamiento()
	}

    changeFrac() {
		this.setState({
			subfraccionamientos: [],
			idSub: 0,
        })
		this.getNotificaciones();
	}

    updated() {
        this.getNotificaciones();
	}

    subChange(sub) {
		this.setState({subfraccionamiento: sub});
	}

	nuevaDefinicion() {
		this.setState({showModal: true});
	}

	setFilter(filter) {
		this.setState({filter: filter.toLowerCase()});
	}

	filter(notificaciones) {
		notificaciones = notificaciones.filter(not => {
			if(not.texto.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if (not.tipo.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		})
		return notificaciones;
	}

    openModalFast(){
        this.openModal('fastSend');
	}

    async load() {
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({
                user: {
                    idUsuario: user.id.id_usuario,
                    idPerfil: user.id.id_perfil,
                }
			});
        }
	}

    sortableTable(key) {
        let sd = new sortableData();
        const { items, direction } = sd.sortBy(key, this.state.notificaciones, this.state.direction[key]);

        this.setState({
            notificaciones: items,
            direction: {
                [key]: direction
            },
            filterColumn: {
                col: key,
                filt: direction
            }
        });
    }

    render() {
		let notificaciones = [];

        if (this.state.notificaciones) {
			notificaciones = this.state.notificaciones;
			notificaciones = this.filter(notificaciones);
        }
        

        return (
            <div className="admin column">
                <Helmet>
                    <title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
                </Helmet>
                <Header 
                    reload={this.reload.bind(this)} 
                    change={this.changeFrac.bind(this)} 
                    sidebar={true} 
                    active={'definicion'}  
                    parent={'notificaciones'} 
                    panel={'panel2'} 
                    nav={'operacion'} 
                />
                <div className="row">
                    <div className="column" id="sidebar">
                        <SidebarOperacion />
                    </div>
                    <div className="column" id="content">
                        <div className="justify-center">
                            <div className="container column">
                                <div className="justify-center">
                                    <div className="column full">
                                        {this.state.lectura?
                                        <React.Fragment>
                                            <Controls title={this.state.submenu}
                                                nuevo           =   {this.openModal.bind(this,"addNotificationsDef")}
                                                dataSet         =   {notificaciones}
                                                newButton       =   {this.state.escritura}
                                                setFilter       =   {this.setFilter.bind(this)}
                                                subChange       =   {this.subChange.bind(this)}
                                                selectSub       =   {false}
                                                btnAux          =   {true}
                                                fastSend        =   {this.openModalFast.bind(this)}
                                                excelButton     =   {false}
                                            />


										{this.state.loading?
											<div className="row justify-center">
												<i className="fas fa-spinner fa-spin"></i>
											</div>
											:
                                            <NotificationTable 
                                                notificaciones          =   {notificaciones} 
                                                page                    =   {this.state.page} 
                                                updated                 =   {this.updated.bind(this)} 
                                                subfraccionamientos     =   {this.state.subfraccionamientos}
                                                escritura               =   {this.state.escritura}
                                                pageLength		        =   {this.state.pageLength}
                                                sortBy                  =   {this.sortableTable.bind(this)}
                                                filterColumn            =   {this.state.filterColumn}
                                                subLabel                =   {this.state.subLabel}
                                            />
										}
										<div className="white-space-16"></div>
                                        <Paginador 
                                            pages={Math.ceil(notificaciones.length / this.state.pageLength)} 
                                            setPage={this.setPage.bind(this)} 
                                            pageLength = {this.state.pageLength} 
                                            paginatorChange = {this.paginatorChange.bind(this)} 
                                            showPageLenght = {true}
                                        />
										<div className="white-space-16"></div>

                                        </React.Fragment>:
                                        null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

				<DinamicModal 
					idModal             =   {'addNotificationsDef'}
					sizeModalContainer  =   {'small'}
					title               =   {'Añadir notificación'} 
					success             =   {this.handleSubmit.bind(this)} 
					showBtnSuccess      =   {true}
					btnTextSuccess      =   {"GUARDAR"}
					showBtnCancel       =   {true}
					btnTextCancel       =   {"CANCELAR"}
					closeModal          =   {this.resetValue.bind(this)}
					loadingSave         =   {this.state.loadingSave}
					message             =   {this.state.message}
				>
				{this.renderNotification()}
				</DinamicModal>

                <DinamicModal 
					idModal             =   {'fastSend'}
					sizeModalContainer  =   {'small'}
					title               =   {'Notificación rápida'} 
					success             =   {this.sedFastNotification.bind(this)} 
					showBtnSuccess      =   {true}
					btnTextSuccess      =   {"ENVIAR"}
					showBtnCancel       =   {true}
					btnTextCancel       =   {"CANCELAR"}
					// closeModal          =   {this.resetValue.bind(this)}
					loadingSave         =   {this.state.loadingSave}
					message             =   {this.state.message}
				>
				{this.renderFastNotification()}
				</DinamicModal>
            </div>
        );
    }
    async sedFastNotification(event){
        event.preventDefault();
        await this.setState({loadingSave: true});
        let data =  {
            idFraccionamiento:      this.state.fraccionamiento,
            idSub:                  this.state.subIdSelect,
            idViv:                  this.state.vivIdSelect,
            texto:                  this.state.msj,
            idUser:                 this.state.user.idUsuario,
            fecha:                  moment(new Date()).format("YYYY-MM-DD"),
            tipo:                   "Rapida",
        }
        const response = await request.post('/notificaciones/send/notification',data);
        // let response = false;
        if(response){
            this.closeModal('fastSend');
            this.getNotificaciones();
            //console.log(response);
            await this.setState({msj: ''});
            cogoToast.success('Notificación enviada.', {
                position: 'bottom-right',
            });
        }else{
            //await this.setState({message: 'No se pudo enviar notificación'});
            cogoToast.error('No se pudo enviar notificación.', {
                position: 'bottom-right',
            });
        }
        //console.log(data);
        await this.setState({loadingSave: false});
	}

    renderFastNotification(){
        
        return(
            <div className="full row align-center">
			<div className="column full">
				<div className="input-form-content row full align-center">
					<div className="column label-medium">
						<p><b>  Tipo de notificación:	</b></p>
					</div>
                    <div className="column full">
                        <input className="input input-modals"
                            type		=	"text" 
                            name		=	"tipo" 
                            value		=	{"Rápida"}
                            // onChange	=	{event => this.setState({ INombre: event.target.value })}
                            // className	=	"input input-modals" placeholder="Nombre completo del contacto"
                            maxLength	=	"64" 
                            disabled
						/>
                    </div>
                    
				</div>

                <div className="white-space-16"/>
                <div className="input-form-content row full align-center">
                    <div className="column label-medium">
                        <p><b> {this.state.subLabel}:	</b></p>
                    </div>

                    <div className="column full">
                        <select name="subfraccionamientoId" id="subfraccionamientoId" className="input input-modals"
                            value={this.state.subIdSelect}
                            onChange={this.subfracChange.bind(this)}
                        >
                            <option value="0">TODOS</option>
                            {  
                                this.state.subfraccionamientos.map((subfraccionamiento, key) => (
                                <option value={subfraccionamiento.id_subfraccionamiento} key={key}>{subfraccionamiento.nombre}</option>)) 
                            }
                        </select> 
                    </div>
                </div>
				<div className="white-space-16"/>
                <div className="input-form-content row full align-center">
                    <div className="column label-medium">
                        <p><b> Vivienda:	</b></p>
                    </div>

                    <div className="column full">
                        {this.state.loadingViviendas? 
                            <div className="row justify-center align-center">
                                <i className="fas fa-spinner fa-spin"></i>
                            </div>
                            :
                            <select name="viviendaId" id="viviendaId" className="input input-modals"
                                value={this.state.vivIdSelect}
                                onChange={this.viviendaChange.bind(this)}
                            >
                                <option value="0">TODAS</option>
                                {  
                                    this.state.viviendasList.map((vivienda, key) => (
                                    <option value={vivienda.id_vivienda} key={key}>{vivienda.numero_registro}</option>)) 
                                }
                            </select>
                        }
                    </div>
                </div>
                <div className="white-space-16"/>
				<div className="white-space-16"></div>
				<p>
					<b>Mensaje:</b>
				</p>
				<div className="white-space-4" />
                <textarea 
                    className   =   "textarea input input-modals" 
                    name        =   "descriptionMessage" 
					cols        =   "50"
					rows		=	"8"
					maxLength	=	"1500"
					id			=	"message"
					required
                    value       =   {this.state.msj}
                    onChange={(event) => this.setState({msj: event.target.value})}
                    
                ></textarea>
		
			</div>
		</div>
        );
    }

    async subfracChange(event) {
		let idSub = event.target.value;
		await this.setState({subIdSelect: idSub});
		this.getViviendas(idSub);
	}

    viviendaChange(event) {
		let idViv = event.target.value;
		this.setState({vivIdSelect: idViv});
	}
    

	async resetValue() {
		document.getElementById('message').value = '';
		this.setState({tipe: ''});
    }
    async getViviendas(idSubfrac) {
		const frac = localStorage.getItem('frac');
		this.setState({loadingViviendas: true});
		const response = await request.post("/viviendas/getViviendaBySubFraccionamiento", 
		{	id_fraccionamiento: frac,
			id_subfraccionamiento: idSubfrac}
		);
		if(Array.isArray(response.viviendas)) {
			
			this.setState({viviendasList: response.viviendas});
		} else {
			this.setState({viviendasList: []});
		}
		this.setState({loadingViviendas: false});
	}
	
	renderNotification() {
		return (
			<div className="full row align-center">
			<div className="column full">
				<div className="input-form-content row full align-center">
					<div className="column label-big">
						<p><b>  Tipo de notificación:	</b></p>
					</div>
                    <div className="column full">
                        <select 
                            name        =   "tipo" 
                            id          =   "notification_type" 
                            className   =   "input input-modals" 
                            value       =   {this.state.tipe} onChange={event => this.setState({ tipe: event.target.value })}
                            
                        >
                            {this.state.notificacionestipe.map((value, key) => (
                                <option key={key} value={value.Descripcion}>{value.Descripcion}</option>
                            ))}
                        </select>
                    </div>
                    
				</div>
            
				<div className="white-space-16"></div>
				<p>
					<b>Mensaje:</b>
				</p>
				<div className="white-space-4" />
                <textarea 
                    className   =   "textarea input input-modals" 
                    name        =   "descriptionMessage" 
					cols        =   "50"
					rows		=	"8" 	
					maxLength	=	"1500"
					id			=	"message"
					required
                    ref			=	{ 'input' + this.props.line }
					autoFocus	=	{ this.props.isAutoFocus }
                    
                ></textarea>
		
			</div>
		</div>
		)
	}

	async openModal(idModal) {
		await this.setState({message: ''});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
        }
        document.getElementById('notification_type').focus();
	}


    handleOpen() {
        this.setState({ showModal: !this.state.showModal })
        this.getTipeNotification()
	}

    async getTipeNotification() {
        this.setState({ notificacionestipe: [] })
        let data = {
            id: localStorage.getItem('frac'),
            subfrac: localStorage.getItem('subfrac')
        }
        const response = await request.post("/notificaciones/get/tipe", data);

        if (response && !response.error) {
            if (response.notifications && !response.empty) {
                this.setState({
                    notificacionestipe: response.notifications
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message,
                    loading: false,
                    showModal: false
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message,
                loading: false,
                showModal: false
            });
        }
    }

    async handleSubmit(event) {
		this.setState({loadingSave: true});
        event.preventDefault();
        const form = event.target;

        let data = {
            texto:              form.descriptionMessage.value,
            tipo:               form.notification_type.value,
			idFraccionamiento:  localStorage.getItem('frac'),
			idSub:              this.state.subfraccionamiento
        }

        const response = await request.post("/notificaciones/create/new/notification", data)
        
        if (response.created) {
            this.setState({
                reload: true,
                showModal: false
            });
            this.setState({loadingSave: false});
            form.descriptionMessage.value = '';
            form.notification_type.value = '';
            this.getNotificaciones();
            this.closeModal('addNotificationsDef');
            cogoToast.success('Notificación creada.', {
                position: 'bottom-right',
            });
        } else {
            this.setState({
                empty:      true,
                //message:    "NO SE PUDO CREAR NOTIFICACIÓN",
                showModal:  false
            });
            cogoToast.error('No se pudo crear notificación.', {
                position: 'bottom-right',
            });
        }
        this.setState({loadingSave: false});
		
	}
	
	closeModal(idModal) {

		const modal = document.getElementById(idModal) 
		if(modal) {
			modal.classList.remove('show-dinamic-modal');
			modal.classList.add('hide-dinamic-modal');
		}
	}

    componentDidMount() {
        this.getSubmenu();
        this.load();
		this.getTipeNotification();
		this.getNotificaciones();
        this.getSubFraccionamientos();
        this.getViviendas();
    }

    async getSubmenu() {
        const submenu = await this.props.context.getSubmenuOpeName();
        if (submenu) {
            this.setState({ submenu });
        }

        const subLabel = window.localStorage.getItem('subLabel');
        if (subLabel) {
            this.setState({ subLabel });
        }
    }

    async getNotificaciones() {
        this.setState({loading: true});
        let data = {
			idFraccionamiento: localStorage.getItem('frac'),
			idSub: this.state.subfraccionamiento
        }

        const response = await request.post("/notificaciones/get/all/notifications", data)
        if (response && !response.error) {
            if (response.notifications && !response.empty) {
                this.setState({
                    notificaciones:     response.notifications,
                    filterData:         response.notifications,
                    keys:               Object.keys(response.notifications[0]).filter(key => key.indexOf('id') === -1),
                });
            } else {
                this.setState({
                    empty:          true,
                    message:        response.message,
                    loading:        false,
                    showModal:      false,
                    notificaciones: [],
                    filterData:     []
                });
            }
        } else {
            this.setState({
                error:      true,
                message:    response.message,
                loading:    false,
                showModal:  false
            });
        }
		this.setState({loading: false});
	}
	
	// traer subfraccionamientos
    async getSubFraccionamientos() {
		this.setState({loadingSub: true});
		const fraccionamiento = localStorage.getItem('frac');
		if(!fraccionamiento) {
			return;
		}
        let data = { Idsub: fraccionamiento, filter:1 }
        const response = await request.post("/admin/administracion/get/subfraccionamientos", data);
        if (response && !response.error) {
            if (response.subfraccionamiento && !response.empty) {
                this.setState({
                    subfraccionamientos:    response.subfraccionamiento,
                    filterData:             response.subfraccionamiento
				});
				
            } else {
                this.setState({
                    empty:                  true,
                    message:                response.message,
                    subfraccionamientos:    []
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });
		}
		this.setState({loadingSub: false});
    }
}

export default Consumer(NotificacionDefinition);