import React, { Component } from 'react';
import moment from 'moment-timezone';
import { Consumer } from "../../context";
import ModalBorrar from '../modals/modalDelete'
import Request from "../../core/httpClient";
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import cogoToast from 'cogo-toast';
import DatePicker from "react-datepicker";

const request = new Request();

class PenalizationTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: {},
			id_penalizacion: 0,
			subfraccionamientos: [],
			id_subfraccionamiento: 0,
			id_subfraccionamiento_traer_viviendas: localStorage.getItem('subfrac'),
			unidades: [],
			id_unidad: 0,
			areas: [],
		};
	};

	async componentDidMount() {
		await this.getCommonArea();
		await this.load();
		await this.getSubfraccionamientos();
		await this.getAreasComunes();
	};

	async componentDidUpdate(prevProps, prevState) {
		if (prevState.id_subfraccionamiento !== this.state.id_subfraccionamiento) {
			this.getUnidades();
		};
	};

	async reservas() {
		await this.props.reserva()
	};

	async load() {
		const user = await this.props.context.loadUser();
		if (user) {
			this.setState({
				user: {
					idUsuario: user.id.id_usuario,
					idPerfil: user.id.id_perfil,
					idFraccionamiento: user.id.id_fraccionamiento,
					nombre: user.id.nombre,
					desde: null,
					hasta: null
				}
			});
		}
	};

	async getSubfraccionamientos() {
		try {
			const fraccionamiento = localStorage.getItem('frac');
			if (!fraccionamiento) {
				return;
			};

			const data = { Idsub: fraccionamiento, filter: 1 }
			const response = await request.post("/admin/administracion/get/subfraccionamientos", data);

			if (response.error || response.empty) {
				this.setState({ subfraccionamientos: [] });
				return;
			};

			if (response.subfraccionamiento) {
				this.setState({
					subfraccionamientos: response.subfraccionamiento,
					id_subfraccionamiento: response.subfraccionamiento[0].id_subfraccionamiento
				});
			};

		} catch (error) {
			console.log(error);
		};
	};

	async getUnidades() {
		try {
			this.setState({ unidades: [], id_unidad: 0 });

			const data = {
				id_fraccionamiento: localStorage.getItem('frac'),
				id_subfraccionamiento: this.state.id_subfraccionamiento
			};

			const response = await request.post("/viviendas/getViviendaBySubFraccionamiento", data);

			if (response.empty || response.error) {
				this.setState({ unidades: [] });
				cogoToast.info('No se encontraron unidades en este fraccionamiento.', {
					position: 'bottom-right',
				});
				return;
			};

			if (response.viviendas) {
				this.setState({
					unidades: response.viviendas,
					id_unidad: response.viviendas[0].id_vivienda
				});
			};

		} catch (error) {
			console.log(error);
		};
	};

	async getAreasComunes() {
		try {
			this.setState({ id_area: 0, areas: [] });
			const data = {
				id: localStorage.getItem('frac'),
				idsubfrac: 0
			};

			const response = await request.post("/commonArea/get/info", data);

			if (response.empty || response.error) {
				this.setState({ areas: [] });
				cogoToast.info('No se encontraron áreas comunes en este subfraccionamiento.', {
					position: 'bottom-right',
				});
			};

			if (response.info) {
				let primer_area = response.info[0];
				this.setState({
					areas: response.info,
					id_area: primer_area.id_espacio
				});
			};

		} catch (error) {
			console.log(error);
		};
	}

	openModal(idModal, data) {
		this.setState({
			idPenalizacion: data.id_penalizacion,
			id_subfraccionamiento: data.id_subfraccionamiento,
			id_unidad: data.id_vivienda,
			id_area: data.id_espacio,
			desde: moment(data.desde, 'YYYY/MM/DD').toDate(),
			hasta: moment(data.hasta, 'YYYY/MM/DD').toDate(),
			id_penalizacion_row: data.id_penalizacion
		});

		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		};
	};

	async updateInfo(data) {
		this.setState({
			message: null,
			dataReservation: data,
			nombreDevolucion: data.nombre_devolucion,
			fecha: moment(data.fecha_reserva).tz('GMT').format("YYYY/MM/DD"),
			viv: data.numero_registro,
			fianza: data.fianza_cobrada,
			nombreRecibido: data.nombre_recibido,
			fianzaDev: data.fianza_devolucion,
			comentarios: data.comentarios,
			idReserva: data.id_reserva,
			inicial: data.id_reserva,
			nombre: data.nombre
		});
	};

	async getSchedule({ id_espacio, fecha }) {
		try {
			const data = {
				id: id_espacio,
				fecha: fecha,
				dif: 1
			};

			const response = await request.post("/commonArea/get/available/schedule", data);

			if (response && !response.error) {
				if (response.commonSchedules && !response.empty) {
					this.setState({
						horarios: response.commonSchedules
					});

				} else {
					this.setState({
						horarios: []
					});
				};
			} else {
				this.setState({
					horarios: []
				});
			};
		} catch (error) {
			console.log(error);
		};
	};

	openModalDelete(penalizacion) {
		this.setState({ id_penalizacion: penalizacion.id_penalizacion, id_penalizacion_row: penalizacion.id_penalizacion });
		let modal = document.getElementById("deleteModal");
		if (modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		};
	};

	async closeModal(idModal) {
		let modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.add('hide-modal');
			modal.classList.remove('show-modal');
		};
	};

	getMoneyFormat(number) {
		return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(number)
	};

	async resetData() {
		this.setState({
			idPenalizacion: undefined,
			id_unidad: 0,
			id_subfraccionamiento: 0,
			id_area: 0
		});
	};

	async handleSubmit(event) {
		event.preventDefault();
		try {
			this.setState({ loadingSave: true });

			if (this.state.desde > this.state.hasta) {
				cogoToast.warn(('El rango de fechas no es válido.'), {
					position: 'bottom-right',
				});

				this.setState({ loadingSave: false });
				return;
			};

			const data = {
				idPenalizacion: this.state.idPenalizacion,
				idUsuario: this.state.user.idUsuario,
				idArea: this.state.id_area,
				idVivienda: this.state.id_unidad,
				desde: moment(this.state.desde).format('YYYY-MM-DD'),
				hasta: moment(this.state.hasta).format('YYYY-MM-DD')
			};

			const response = await request.post('/commonArea/penalizaciones/editar', data);

			if (response.updated) {
				cogoToast.success('Penalización actualizada.', {
					position: 'bottom-right',
				});
				await this.closeModal('editPenalizacion');
				await this.props.reload();
			} else {
				cogoToast.error(response.message || 'Error al actualizar penalización.', {
					position: 'bottom-right',
				});
			};

			this.setState({ loadingSave: false });
		} catch (error) {
			console.log(error);
		};
	}

	async handleDelete(id, close) {
		try {
			const data = {
				idPenalizacion: id,
				idUsuario: this.state.user.idUsuario
			};

			const response = await request.post("/commonArea/penalizaciones/eliminar", data);
			if (response.deleted) {
				if (close) {
					close();
				};
				cogoToast.success('Penalización eliminada.', {
					position: 'bottom-right',
				});
				await this.props.reload();
			} else {
				cogoToast.error(response.message || 'Error al eliminar penalización.', {
					position: 'bottom-right',
				});
			}
		} catch (error) {
			console.log(error);
		};
	};

	async getCommonArea() {
		try {
			const data = { id: localStorage.getItem('frac') };
			const response = await request.post("/commonArea/get/info", data);

			if (response && !response.error) {
				if (response.info && !response.empty) {
					this.setState({ info: response.info })
				};
			} else {
				this.setState({
					error: true,
					message: ""
				});
			};
		} catch (error) {
			console.log(error);
		};
	};

	render() {
		let penalizaciones = [];

		if (Array.isArray(this.props.penalizaciones)) {
			penalizaciones = this.props.penalizaciones
		};

		return (
			<div className="card-table card-owners column" id="card-owners">
				<div className="table-responsiv column">
					<table>
						<thead className="sticky-th">
							<tr className="text-left">
								<th className="text-left">
									<div className="row">
										Vivienda &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'numero_registro' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
											}
										</div>
									</div>
								</th>
								<th className="text-left">
									<div className="row">
										Área Común &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'nombre' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
											}
										</div>
									</div>
								</th>
								<th className="">
									<div className="row">
										Desde &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'desde' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'desde')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'desde')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'desde')}></i>
											}
										</div>
									</div>
								</th>
								<th className="">
									<div className="row">
										Hasta &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'hasta' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'hasta')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'hasta')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'hasta')}></i>
											}
										</div>
									</div>
								</th>
								<th className="text-center th-options-small">Editar</th>
								<th className="text-center th-options-small">Eliminar</th>

							</tr>
						</thead>
						<tbody>
							{penalizaciones.slice((this.props.page - 1) * this.props.pageLength, this.props.page * this.props.pageLength).map((penalizacion, key) => (
								<tr className={this.state.id_penalizacion_row === penalizacion.id_penalizacion && 'row-active'} key={key}>
									<td>{penalizacion.numero_registro}</td>
									<td>{penalizacion.nombre}</td>
									<td>{moment(penalizacion.desde).tz('GMT').format("DD/MM/YYYY")}</td>
									<td>{moment(penalizacion.hasta).tz('GMT').format("DD/MM/YYYY")}</td>
									<td className="text-center">
										<button className='btn-full justify-center btn-mini btn-secondary color-white' type="button" onClick={this.openModal.bind(this, 'editPenalizacion', penalizacion)} >
											<i className="font-small fas fa-pen" />
										</button>
									</td>
									<td>
										<button className="btn-full justify-center align-center btn-mini btn-secondary color-white"
											type='button' onClick={this.openModalDelete.bind(this, penalizacion)}
											style={{ padding: '5px' }}
											disabled={!this.props.escritura}
										>
											<i className="font-text fas fa-trash-alt " />
										</button>
									</td>
								</tr>
							))}

						</tbody>
					</table>
				</div>
				<DinamicModal
					idModal={'editPenalizacion'}
					sizeModalContainer={'small'}
					title={'Editar penalización'}
					success={this.handleSubmit.bind(this)}
					closeModal={this.resetData.bind(this)}
					showBtnSuccess={this.props.escritura}
					btnTextSuccess={"ACTUALIZAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.message}
				>
					{this.renderEdit()}
				</DinamicModal>

				<ModalBorrar
					id={this.state.id_penalizacion}
					delete={this.handleDelete.bind(this)}
				/>
			</div>
		);
	};

	renderEdit() {
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-medium"><p><b>{this.props.subLabel}:</b></p></div>
						<div className="column full">
							<select
								className="input input-modals"
								value={this.state.id_subfraccionamiento}
								onChange={(event) => this.setState({ id_subfraccionamiento: event.target.value })}
								disabled
								required
							>
								{
									this.state.subfraccionamientos.map((subfraccionamiento, key) => (
										<option value={subfraccionamiento.id_subfraccionamiento} key={key}>
											{subfraccionamiento.nombre}
										</option>
									))
								}
							</select>
						</div>
					</div>
					<div className="white-space-16"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>vivienda:</b></p>
						</div>
						<div className="column full">
							<select
								className="input input-modals"
								value={this.state.id_unidad}
								onChange={(event) => this.setState({ id_unidad: event.target.value })}
								disabled
								required
							>
								{
									this.state.unidades.map((unidad, key) => (
										<option value={unidad.id_vivienda} key={key}>
											{unidad.numero_registro}
										</option>
									))
								}
							</select>
						</div>
					</div>
					<div className="white-space-16"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium"><p><b>Áreas:</b></p></div>
						<div className="column full">
							<select
								className="input input-modals"
								value={this.state.id_area}
								onChange={(event) => this.setState({ id_area: event.target.value })}
								disabled
								required
							>
								{
									this.state.areas.map((area, key) => (
										<option value={area.id_espacio} key={key}>
											{area.nombre}
										</option>
									))
								}
							</select>
						</div>
					</div>
					<div className="white-space-16"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big"><p><b>Desde:</b></p></div>
						<div className="column full">
							<DatePicker
								className="input input-modals"
								selected={this.state.desde}
								onChange={(fecha) => this.setState({ desde: fecha })}
								dateFormat="dd/MM/yyyy"
								locale="es"
								required
							// disabled    =   {this.state.fecha_dia}
							// showMonthYearPicker
							/>
						</div>

						<div className="column label-duo-normal space"><p><b>Hasta:</b></p></div>
						<div className="column full">
							<DatePicker
								className="input input-modals"
								selected={this.state.hasta}
								onChange={(fecha) => this.setState({ hasta: fecha })}
								dateFormat="dd/MM/yyyy"
								locale="es"
								minDate={this.state.desde}
								required
							// disabled    =   {this.state.fecha_dia}
							// showMonthYearPicker
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Consumer(PenalizationTable);