import React, { Component } from 'react';

import Header from '../components/header/header'
import Sidebar from '../components/header/sidebar'
import { Helmet } from "react-helmet";
class Catalogos extends Component {
    constructor(props) {
        super(props);
        this.state = {}

    }

    render() {
        return (
            <div className="catalogos">
                <Helmet>
                    <title>{process.env.REACT_APP_NAME} - Viviendas</title>
                </Helmet>
                <Header sidebar={true} nav={"catalogos"} />
                <div className="row justify-center">
                    <div className="column" id="sidebar">
                        <Sidebar/>
                    </div>
                    <div className="container column" id="content">
                        <div className="container column align-center">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio fuga voluptas, in minus veniam neque cum dolorum sunt excepturi.
                             Cum adipisci quis pariatur saepe quaerat repellat, quisquam totam nemo nihil.
                             Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sequi officiis non, nostrum nobis numquam quasi laboriosam vel facere
                             excepturi quia sunt tempora harum est ipsa repudiandae ab asperiores placeat maxime!
                        </div>


                    </div>

                </div>
            </div >);
    }

}

export default Catalogos;