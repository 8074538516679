import React, { Component } from 'react';
import moment from "moment";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Request from "../../core/httpClient";
const request = new Request();

class InquilinoEditarModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            IIdPersona: 	this.props.inquilino.id_persona,
            INombre: 		this.props.inquilino.nombre,
            IDireccion: 	this.props.inquilino.direccion,
            IMunicipio: 	this.props.inquilino.municipio,
            IEstado: 		this.props.inquilino.estado,
            ICp: 			this.props.inquilino.cp,
            iTelefono:		this.props.inquilino.telefono,
            IPais: 			this.props.inquilino.pais,
            IGenero: 		this.props.inquilino.genero,
            IId: 			this.props.inquilino.tipo_identificacion,
            iNumId: 		this.props.inquilino.num_identificacion,
            IVigencia: 		this.props.inquilino.vigencia_identificacion,
            IFechaIngreso: 	moment(this.props.inquilino.fecha_entrada).format("DD-MM-YYYY"),
            code: 			this.props.inquilino.code,
            Iviv: 			this.props.inquilino.numero_registro,
            IIdViv: 		this.props.inquilino.id_vivienda,
			email: 			this.props.inquilino.email,
			citye:			this.props.inquilino.ciudad,
			residente_status: 	this.props.inquilino.residente_status || 0,
			comentarios:        this.props.inquilino.comentarios,
		}
		
	}
	
	componentDidUpdate(prevProps, prevState) {
		if(this.state !== prevState) {
			this.props.setInfo(this.state);
		}
		if(this.props.telefonoError !== prevProps.telefonoError) {
			this.setState({telefonoError: this.props.telefonoError});
		}
		if(this.props.inquilino !== prevProps.inquilino) {
			let phone = '';
			let es_Propietario = false;
			if(this.props.inquilino.telefono) {
				phone = parsePhoneNumber(`+${this.props.inquilino.telefono}`) || '';
				phone = phone.number;				
			}
			
			this.setState({ 
				IIdPersona: 		this.props.inquilino.id_persona,
				INombre: 			this.props.inquilino.nombre || '',
				IDireccion: 		this.props.inquilino.direccion || '',
				IMunicipio: 		this.props.inquilino.municipio || '',
				IEstado: 			this.props.inquilino.estado || '',
				ICp: 				this.props.inquilino.cp || '',
				iTelefono: 			phone || '',
				IPais: 				this.props.inquilino.pais || '',
				IGenero: 			this.props.inquilino.genero || 0,
				IId: 				this.props.inquilino.tipo_identificacion || 0,
				iNumId: 			this.props.inquilino.num_identificacion || '',
				IVigencia: 			this.props.inquilino.vigencia_identificacion || '',
				IFechaIngreso: 		moment(this.props.inquilino.fecha_entrada).format("YYYY-MM-DD"),
				IFechaSalida: 		moment(this.props.inquilino.fecha_salida).format("YYYY-MM-DD"),
				code: 				this.props.inquilino.code || '',
				Iviv: 				this.props.inquilino.numero_registro || '',
				IdViv: 				this.props.inquilino.id_vivienda || 0,
				email: 				this.props.inquilino.email || '',
				citye:				this.props.inquilino.ciudad || '',
				residente_status: 	this.props.inquilino.residente_status || 0,
				comentarios: 	    this.props.inquilino.comentarios || '',
				es_propietario:     this.props.inquilino.es_propietario||false
			});
		}
	}

    render() {
		let estdosApp = [];
		if(Array.isArray(this.props.estadosAPP))
			estdosApp = this.props.estadosAPP;

        return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>{this.props.subLabel}:</b></p>
						</div>
						<div className="column full">
							<input 
								className	=	"input input-modals"  
								type		=	"text" 
								name		=	"sub" 
								value		=	{this.props.subname}
								disabled
								autoComplete 	= 	'off'
							/>
						
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>Vivienda:</b></p>
						</div>
						<div className="column full">
							<input 
								className	=	"input input-modals"  
								type		=	"text" 
								name		=	"vivienda" 
								value		=	{this.state.Iviv}
								onChange	=	{event => this.setState({ Iviv: event.target.value })}
								maxLength	=	"64" 
								disabled 
								required 
								autoComplete 	= 	'off'
							/>
						</div>
					</div>
                    <div className="white-space-8" />
					<div className="input-form-content row full align-cente">
						<div className="column label-medium">
							<p><b>Teléfono:</b><b style={{color:'red'}}> * </b></p>
						</div>
						<div className="column full">
							<PhoneInput
								name		=	"phoneNumber"
								defaultCountry		=	"MX"
								maxLength = "18"
								value		=	{this.state.iTelefono}
								onChange	=	{ value => this.setState({ iTelefono: value, telefonoError: undefined }) }
								error		=	{ this.state.telefonoError }
                                disabled 	=	{true}
								autoComplete 	= 	'off'
								required
                                readOnly
								displayInitialValueAsLocalNumber
							/>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>  Nombre completo:	</b><b style={{color:'red'}}> * </b></p>
						</div>
						<div className="column full">
							<input 
								className	=	"input input-modals"
								type		=	"text" 
								name		=	"fullName" 
								value		=	{this.state.INombre}
								id			=	"editinqname"
								onChange	=	{event => this.setState({ INombre: event.target.value })} 
                                maxLength	=	"64"
                                required
								disabled
								autoComplete 	= 	'off'
							/>
						</div>
					</div>
                    <div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
						    <p>
								<b>Género:</b>
							</p>
						</div>
						<div className="column full">
							<select 
								name		=	"sex"  
								value		=	{this.state.IGenero} 
								onChange	=	{event => this.setState({ IGenero: event.target.value })} 
								className	=	"input input-modals"
								required
							>
								<option value="0">Hombre</option>
								<option value="1">Mujer</option>
								<option value="2">No especificado</option>
							</select>
						</div>
                        <div className="column label-duo-normal space-16">
							<p className="justify-start">
								<b>Correo :</b>
							</p>
						</div>
						<div className="column full">
							<input 
								className	=	"input input-modals" 
								type		=	"email" 
								name		=	"fullName" 
								value		=	{this.state.email}
								onChange	=	{event => this.setState({ email: event.target.value })}  
								maxLength	="64"
								autoComplete 	= 	'off' 
							/>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Identificación:</b>
							</p>
						</div>
						<div className="column full">
							<select 
								className	=	"input input-modals" 
								name		=	"id"
								id			=	"idEddit" 
								value		=	{this.state.IId} 
								onChange	=	{event => this.setState({ IId: event.target.value })}
							>
								<option value="0">INE</option>
								<option value="1">Pasaporte/Visa</option>
								<option value="2">Curp</option>
								<option value="3">Cartilla Militar</option>
							</select>
						</div>
						<div className="column label-duo-normal space">
							<p  className="justify-start">
								<b>ID:</b>
							</p>
						</div>
						<div className="column full">
							<input 
								className	="input input-modals"  
								type		="text" 
								name		="numIdEddit" 
								value		={this.state.iNumId}
								onChange	={event => this.setState({ iNumId: event.target.value })} 
								maxLength="24" 
							//	required 
								autoComplete 	= 	'off'
							/>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Dirección:</b>
							</p>
						</div>
						<div className="column full">
							<input 
								className	=	"input input-modals"  
								type		=	"text"
								name		=	"address" 
								value		=	{this.state.IDireccion}
								onChange	=	{event => this.setState({ IDireccion: event.target.value })} 
								maxLength	=	"150" 
							//	required 
								autoComplete 	= 	'off'
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Municipio:</b>
							</p>
						</div>
						<div className="column full">
							<input 
								className	=	"input input-modals"  
								type		=	"text" 
								name		=	"municipality" 
								value		=	{this.state.IMunicipio}
								onChange	=	{event => this.setState({ IMunicipio: event.target.value })} 
								maxLength	=	"80" 
							//	required 
								autoComplete 	= 	'off'
							/>
						</div>
						<div className="column label-duo-normal space">
						<p  className="justify-start">
								<b>Ciudad</b>
							</p>
						</div>
						<div className="column full">
						   <input 
								className	=	'input input-modals' 
								id			=	'citye' 
								type		=	"text" 
								value		=	{this.state.citye}
								onChange	=	{event => this.setState({ citye: event.target.value })} 
								//required
								autoComplete 	= 	'off'
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>C.P:</b>
							</p>
						</div>
						<div className="column full">
						    <input 
								className	=	"input input-modals" 
								type		=	"text" 
								value		=	{this.state.ICp}
								onChange	=	{event => this.setState({ ICp: event.target.value })} 
								name		=	"zip"  
								maxLength	=	"10" 
								//required 
							/>
						</div>
						<div className="column label-duo-normal space">
						<p  className="justify-start">
								<b>Estado:</b>
							</p>
						</div>
						<div className="column full">
						    <input 
								type		=	"text" 
								name		=	"state" 
								value		=	{this.state.IEstado}
								onChange	=	{event => this.setState({ IEstado: event.target.value })} 
								className	=	"input input-modals"  
								maxLength	=	"24" 
							//	required 
								autoComplete = 	'off'
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">

						<div className="column label-duo-big ">
							<p>
								<b>País:</b>
							</p>
						</div>
						<div className="column full">
						    <input 
								className		=	"input input-modals"  
								type			=	"text"
								value			=	{this.state.IPais}
								onChange		=	{event => this.setState({ IPais: event.target.value })} 
								name			=	"country" 
								maxLength		=	"10" 
							//	required 
								autoComplete 	= 	'off'
							/>
						</div>

						<div className="column label-duo-normal">
							<p>
								<b>Estado APP:</b>
							</p>
						</div>
						<div className="column full">
						<select 
								className		=	'input input-modals' 
								type			=	"date" 
								value			=	{this.state.residente_status}
								onChange		=	{event => this.setState({ residente_status: event.target.value })} 
								id				=	'estadoAPP'
								required 
							>
									{estdosApp.map(sta => <option key={sta.id} value={sta.id}>{sta.estatus}</option>)}
							</select>
						</div>
					</div>

					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Fecha de ingreso:</b><b style={{color:'red'}}> * </b>
							</p>
						</div>
						<div className="column full">
							<input 
								className	=	'input input-modals' 
								type		=	"date" 
								value		=	{this.state.IFechaIngreso}
								onChange	=	{event => this.setState({ IFechaIngreso: event.target.value })} 
								id			=	'fcompra' 
								required 
							/>
						</div>
						<div className="column label-duo-normal">
							<p>
								<b>Fecha de salida:</b>
							</p>
						</div>
						<div className="column full">
						   <input 
								className	=	'input input-modals' 
								type		=	"date" 
								id			=	'fcompra' 
								value		=	{this.state.IFechaSalida}
								onChange	=	{event => this.setState({ IFechaSalida: event.target.value })} 
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
							    <b>Comentarios:</b>
							</p>
						</div>
						<div className="column full">
						<textarea 
                            name="comentarios"
                            id="comentarios_inquilinos"
                            value={this.state.comentarios}
                            cols="30"
                            rows="3"
                            maxLength="500"
                            className='textarea font full'					
						 onChange={(event) => this.setState({comentarios: event.target.value})} >
                        </textarea>
						</div>
						<div className="column label-duo-normal space">
						<div className="input-form-content row full align-center">
						<div className="column">
							<label className="container-check">
								<input type="checkbox" name="esPropietario" id="esPropietario" disabled="disabled"  checked={this.state.es_propietario ? true : false}  />
								<span className="checkmark"></span>
							</label>
						</div>
						<div className="column">
							<h5>
								{" "}
								<b>Es propietario </b>{" "}
							</h5>
						</div>
					</div>
						</div>
						<div className="column full">
						
						</div>
					</div>
					<div className="white-space-8" />
					<div className="white-space-8" />
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
							<b style={{color:'red'}}> * </b><small>Indica un campo obligatorio</small>
							</p>
						</div>
						
					</div>
				</div>
			</div>
        );
    }

    handleClose() {
        this.setState({
            showModal: false,
        });
    }
}

export default InquilinoEditarModal;