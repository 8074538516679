import React, { Component } from 'react';
import Request from "../../core/httpClient";
import ModalBorrar from '../modals/modalDelete';
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import cogoToast from 'cogo-toast';
import PDFPreview from './../PDFPreview/pdfpreview';

const request = new Request();

class DocumentsTableSub extends Component{
    constructor(props){
        super(props);
        this.state =   {
            escritura:              this.props.escritura,
            id_carpeta:             0,
            id_carpetaActivo:       0,
            documentos:             this.props.documentos ,          
            link_pdf:               '',
            on:                     false,
            id_document:            0
        }
        
        
    }

    async componentDidUpdate(prevProps, prevState){
        if(prevProps.documentos != this.props.documentos){
            await this.setState({documentos: this.props.documentos});
        }
    }

    

    render(){
        return(this.renderTable());
    }


    renderTable(){
        let documentos = this.state.documentos;
        return(
            <React.Fragment>
            <div className="full row align-center">
                <div className="column full">
                    <div className="column card-table">
                        <div className="table-responsiv column">
                            <table>
                                <tbody>
                                    <tr>
                                        <th className="th-options-hug text-left" colSpan="4">Concepto</th>
                                        <th className="th-options-hug"></th>
                                        <th className="th-options-hug">Ver</th>
                                        <th className="th-options-hug">Eliminar</th>

                                    </tr>
                                    
                                    {documentos.map((doc, key) => {
                                        //console.log(doc)
                                        return (
                                            <tr key= {doc.id}>
                                                <td className="text-start" colSpan="4">{doc.nombre}</td>
                                                <td className="text-center"></td>
                                                <td className="text-center">
                                                    <button 
                                                        className="btn-full justify-center align-center btn-mini btn-secondary color-white"
                                                        onClick={(event) => { 
                                                            this.generateLink(doc) 
                                                        }}
                                                    >
                                                        <i className="fas fa-file-pdf"></i>
                                                    </button>
                                                </td>
                                                <td>
                                                    <button className="btn-full justify-center align-center btn-mini btn-secondary color-white" 
                                                        type        =   'button' 
                                                        onClick     =   {this.openModalDelete.bind(this, doc)} 
                                                        style       =   {{ padding: '5px' }}
                                                        disabled    =   {!this.props.escritura} 
                                                >
                                                    <i className="font-text fas fa-trash-alt " />
                                                </button> 
                                                </td>
                                                
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/*<PDFPreview
                id      = {'preview-pdf'}
                link    = {this.state.link_pdf} 
                on      = {this.state.on}
            ></PDFPreview>*/}

            <ModalBorrar
                name		= 	'deleteModal-document'
                id          =   {this.state.id_document}
                delete      =   {this.handleDelete.bind(this)}
            />

    </React.Fragment>
        

        );
    }
async handleDelete(close) {
    let data = {
        id_document: this.state.id_document,
    }
    const response = await request.post("/subfraccionamientos/delete/documentos", data);
    if (response) {
        if (response.deleted) {
            this.closeModalDelete();
            cogoToast.success('Documento eliminado.', {
                position: 'bottom-right',
            });
            this.props.reload();
        } else {
            cogoToast.error('No se pudo eliminar documento.', {
                position: 'bottom-right',
            });
            //alert(response.message);
        }
    }
    
    }
async closeModalDelete(){
    await this.setState({id_document: 0});
    let modal = document.getElementById("deleteModal-document");
    if(modal) {
        modal.classList.remove('show-modal');
        modal.classList.add('hide-modal');
    }
}

async openModalDelete(documento) {
    //console.log(documento);
    await this.setState({id_document: documento.id_documento});
    let modal = document.getElementById("deleteModal-document");
    if(modal) {
        modal.classList.remove('hide-modal');
        modal.classList.add('show-modal');
    }
}

async popPreviewPdf(id){
    let preview = document.getElementById(id);
    if(preview){
        let ver     = 'view-pdf';
        let ocultar = 'hide-pdf';

        if(preview.classList.contains(ocultar)){
            preview.classList.remove(ocultar);
            preview.classList.add(ver);
            await this.setState({on: true});
        }else{
            preview.classList.remove(ver);
            preview.classList.add(ocultar);
            await this.setState({on: false});
        }


    }
}

async generateLink(doc){
    //console.log(doc);
    const response = await request.post('/documents/generar/link/pdf',{
        link_pdf: doc.path
    });
    let url = '';
    if(response.token){
        url = doc.host + response.token;
        // url = 'http://localhost:8080/' + response.token;
    }
    await this.setState({link_pdf: url});
    window.open(url, '_blank');
    //this.popPreviewPdf('preview-pdf');
}



}

export default DocumentsTableSub;
