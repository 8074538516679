import React, { memo } from "react";
import ModalBorrar from "../modals/modalDelete";

import SuperCore from "../../components/core/SuperCore";
import SuperModal from "../../components/coreModal/SuperModal";
import General from "../../components/auxiliar/general";
import Amounts from "../../components/auxiliar/amounts";
import Exclusions from "../../components/auxiliar/exclusions";

import { generalOk as generalCheck } from "../../components/auxiliar/general";
import { importesOk as importesCheck, cargosOk as cargosCheck } from "../../components/auxiliar/amounts";

import Request from "../../core/httpClient";
import cogoToast from "cogo-toast";
import moment from "moment-timezone";

const request = new Request();

const conceptoInit = {
	// General
	general: {
		id_concept: 0,
		monthStart: new Date(),
		monthsNumber: 1,
		id_frecuency: 0,
		redondear: false,
		notificacion_pago_dias: 0,
		notificar: false,
	},
	// Importes
	importes: {
		importe_variable: true,
		fecha_dia: 1,
		valor_anual: 0,
		valor_vivienda: 0,
		dia1: 0,
		percent1: 0,
		money1: 0,
		dia2: 0,
		percent2: 0,
		money2: 0,
		percent4: 0,
		money4: 0,
		fecha_exacta_cargos: new Date(),
		dia_mes_cargos:  1,
		id_recargo: 0,
		recargo_aplica_id: 3, // Sin recargo
		id_frecuencia: 0,
	},
	// Exclusiones
	exclusiones: {
		viviendas_json: [],
		filter_value: ""
	}
}

class ConceptosTable extends SuperCore {
	constructor(props) {
		super(props);
		this.state = {
			conceptos: this.props.conceptos,
			menus: ["General", "Importes", "Exclusiones"],
			id_menu_activo: null,
			monthNames: ["ene","feb","mar","abr","may","jun","jul","ago","sep","oct","nov","dic"],
			viviendas: [],
			recargosList: [],
			conceptsList: [],
			frecListReglas: [],
			frecList: [],
			id_subfrac: this.props.id_subfrac,
			id_frac: this.props.id_frac,
			id_regla: this.props.id_regla,
			id_regla_producto: 0,
			editForm: false,
			saving: false,
			fecha_exacta: new Date(),

			// Concepto Modal
			conceptoEdit: { ...conceptoInit },
			conceptoGeneral: { ...conceptoInit.general },
			conceptoImportes: { ...conceptoInit.importes },
			conceptoExclusiones: { ...conceptoInit.exclusiones },
			conceptoIndex: -1,
		};

		this.updateConceptoGeneral = this.updateData.bind(this, "conceptoGeneral");
		this.updateConceptoImportes = this.updateData.bind(this, "conceptoImportes");
		this.updateConceptoExclusiones = this.updateData.bind(this, "conceptoExclusiones");

		this.addViviendaCallback = this.addVivienda.bind(this);
		this.viviendasCheckAllCallback = this.viviendasCheckAll.bind(this);

		this.onSubmitCallback = this.onSubmit.bind(this);
		this.onMenuChangedCallback = this.onMenuChanged.bind(this);
	};

	componentDidMount() {
		this.checkEndDateRule();
		this.getViviendas();
	};

	async getViviendas() {

		let data = {
			id_fraccionamiento: this.state.id_frac,
			id_subfraccionamiento: this.state.id_subfrac,
		};

		const response = await request.post("/viviendas/getViviendaBySubFraccionamiento", data);

		if (response.error || response.empty) {

			this.setState({ viviendas: [] });

		} else if (response.viviendas) {

			this.setState({ viviendas: response.viviendas });
		};
	};

	async deleteRuleProduct(id) {

		let data = { id_regla_producto: id };

		const response = await request.post("/facturacion/reglas/productos/delete", data);

		if (!response || response.error) {
			cogoToast.error("No fue posible eliminar registro.", {
				position: "bottom-center",
			});
		};

		if (response.updated) {

			cogoToast.success("Registro eliminado con éxito.", {
				position: "bottom-center",
			});

			this.props.getProductRules();
		};

		this.closeModalDelete("deleteModal");
	};

	async editRule(data) {

		try {

			const response = await request.post("/facturacion/reglas/productos/update", data);

			if (response.error) {

				cogoToast.error("No se pudo editar el concepto a la regla.", {
					position: "bottom-center"
				});

			} else {

				cogoToast.success("Concepto editado correctamente.", {
					position: "bottom-center"
				});

				this.props.getProductRules();
				this.setState({ id_menu_activo: 0 });

				this.superCloseModal("agregarProducto");
			};

		} catch (error) {
			console.log(error);
		};
	};

	async createRule(data) {

		try {

			const response = await request.post("/facturacion/productos/reglas/create", data);

			if (response.error) {

				cogoToast.error("No se pudo agregar concepto a la regla.", {
					position: "bottom-center"
				});

			} else {

				cogoToast.success("Concepto agregado correctamente.", {
					position: "bottom-center"
				});

				this.props.getProductRules();
				this.setState({ id_menu_activo: 0 });

				this.superCloseModal("agregarProducto");
			};

		} catch (error) {
			console.log(error);
		};
	};

	checkEndDateRule(fechaFin) {

		let newMonth = moment(fechaFin).format("MM");
		let newYear = moment(fechaFin).format("YYYY");
		let monthNames = this.state.monthNames;

		let text = `${monthNames[newMonth - 1]}/${newYear}`;
		return text;
	};

	updateData(tipo, json) {

		const prevConceptoTipoCopy = { ...this.state[tipo] };

		Object.assign(prevConceptoTipoCopy, json);

		const newConcepto = { [tipo]: prevConceptoTipoCopy };

		this.setState(newConcepto);
	};

	addVivienda(id_vivienda) {

		let viviendas_json = [...this.state.conceptoExclusiones.viviendas_json];

		if(viviendas_json.find(viv => viv === id_vivienda)){
			viviendas_json = viviendas_json.filter((id) => id !== id_vivienda);
		} else {
			viviendas_json.push(id_vivienda);
		}

		this.updateData("conceptoExclusiones", { viviendas_json });
	};

	viviendasCheckAll() {

		const viviendas = this.state.viviendas;

		let viviendas_json = [...this.state.conceptoExclusiones.viviendas_json];

		if (Array.isArray(viviendas)) {

			for (let key in viviendas) {

				const { id_vivienda } = viviendas[key];

				if(viviendas_json.find(viv => viv === id_vivienda)){
					viviendas_json = viviendas_json.filter((id) => id !== id_vivienda);
				} else {
					viviendas_json.push(id_vivienda);
				}

			};
		};

		this.updateData("conceptoExclusiones", { viviendas_json });
	};

	beforeOpenEdit(concepto, id) {

		const conceptoIndex = this.props.conceptos.findIndex(con => con.Id_ReglaProducto == concepto.Id_ReglaProducto);

		this.setState({
			id_menu_activo: conceptoIndex !== this.state.conceptoIndex ? 0 : this.state.id_menu_activo,
			id_frac: concepto.Id_Fraccionamiento,
			id_subfrac: concepto.Id_Subfraccionamiento,
			id_regla_producto: concepto.Id_ReglaProducto,
			conceptoIndex,
			conceptoGeneral: { ...getConceptoGeneralProps(concepto)},
			conceptoImportes: { ...getConceptoImportesProps(concepto)},
			conceptoExclusiones: { ...getConceptoExclusionesProps(concepto)},
			editForm: true,
		});

		this.getViviendas();

		this.superOpenModal(id);
	};

	beforeOpenModal(id) {

		this.setState({
			id_menu_activo: 0,
			conceptoGeneral: { ...conceptoInit.general },
			conceptoImportes: { ...conceptoInit.importes },
			conceptoExclusiones: { ...conceptoInit.exclusiones },
			conceptoIndex: -1,
			editForm: false
		});

		this.superOpenModal(id);
	};

	beforeOpenModalDelete(idModal, ruleProduct) {

		this.setState({ id_regla_producto: ruleProduct.Id_ReglaProducto });

		this.openModalDelete(idModal);
	};

	async onSubmit(event) {

		event.preventDefault();

		let user = await this.getUserInfo();

		let formato = "YYYY-MM-DD";

		const generalOK = generalCheck(this.state.conceptoGeneral);
		const importesOk = importesCheck(this.state.conceptoImportes);
		const cargosOk = cargosCheck(this.state.conceptoImportes, this.state.conceptoGeneral.monthStart);

		if (!generalOK) {
			this.setState({ id_menu_activo: 0 });
			return;
		};

		if (!importesOk || !cargosOk) {
			this.setState({ id_menu_activo: 1 });
			return;
		};

		const { 
			id_concept, monthStart, monthsNumber, id_frecuency, 
			redondear, notificacion_pago_dias
		} = this.state.conceptoGeneral;

		const { 
			importe_variable, valor_vivienda, valor_anual, fecha_dia,
			dia1, percent1, money1, dia2, percent2, money2, percent3, money3,
			recargo_aplica_id, fecha_exacta_cargos, dia_mes_cargos, id_recargo,
			id_frecuencia, percent4, money4
		} = this.state.conceptoImportes;

		const { viviendas_json } = this.state.conceptoExclusiones;

		let data = {
			id_regla_producto: this.state.id_regla_producto,
			id_concepto: parseInt(id_concept),
			mes: parseInt(monthStart.getMonth()) + 1,
			anio: parseInt(monthStart.getFullYear()),
			num_meses: parseInt(monthsNumber),
			id_frecuencia: parseInt(id_frecuency),

			monto_fijo: !importe_variable ? valor_vivienda : null,
			monto_variable: importe_variable ? valor_anual : null,

			desc_dia1: fecha_dia ? parseInt(dia1) : null,
			desc_prc1: fecha_dia ? parseFloat(percent1) : null,
			desc_monto1: fecha_dia ? parseFloat(money1) : null,
			desc_dia2: fecha_dia ? parseInt(dia2) : null,
			desc_prc2: fecha_dia ? parseFloat(percent2) : null,
			desc_monto2: fecha_dia ? parseFloat(money2) : null,

			desc_fecha: !fecha_dia ? moment(this.state.fecha_exacta).format(formato) : null,
			desc_prc3: !fecha_dia ? parseFloat(percent3) : null,
			desc_monto3: !fecha_dia ? parseFloat(money3) : null,
			redondear: redondear ? 1 : 0,

			notificacion_dias: parseInt(notificacion_pago_dias),

			id_recargo_aplica: parseInt(recargo_aplica_id),
			fecha_recargo: parseInt(recargo_aplica_id) === 4 ? moment(fecha_exacta_cargos).format(formato) : null,
			dia_recargo: parseInt(recargo_aplica_id) === 5 ? parseInt(dia_mes_cargos) : null,
			id_recargo_producto: parseInt(recargo_aplica_id) !== 3 ? parseInt(id_recargo) : null,
			id_recargo_frecuencia: parseInt(recargo_aplica_id) !== 3 ? parseInt(id_frecuencia) : null,
			recargo_prc: parseInt(recargo_aplica_id) !== 3 ? parseFloat(percent4) : null,
			recargo_monto: parseInt(recargo_aplica_id) !== 3 ? parseFloat(money4) : null,

			viviendas_json: JSON.stringify(viviendas_json),

			id_regla: this.state.id_regla,
			usuario: user.info.usuario,
			fecha_add: moment().format("YYYY-MM-DD HH:mm:ss"),
			fecha_update: moment().format("YYYY-MM-DD HH:mm:ss"),
		};

		data.desc_prc1 = data.desc_prc1 === 0 ? null : data.desc_prc1;
		data.desc_monto1 = data.desc_monto1 === 0 ? null : data.desc_monto1;

		data.desc_prc2 = data.desc_prc2 === 0 ? null : data.desc_prc2;
		data.desc_monto2 = data.desc_monto2 === 0 ? null : data.desc_monto2;

		data.desc_prc3 = data.desc_prc3 === 0 ? null : data.desc_prc3;
		data.desc_monto3 = data.desc_monto3 === 0 ? null : data.desc_monto3;

		data.recargo_prc = data.recargo_prc === 0 ? null : data.recargo_prc;
		data.recargo_monto = data.recargo_monto === 0 ? null : data.recargo_monto;

		this.setState({ saving: true });

		if (this.state.editForm) {
			await this.editRule(data);
		} else {
			await this.createRule(data);
		};

		this.setState({ saving: false });
	};

	onMenuChanged(id) {
		this.setState({ id_menu_activo: id });
	}

	render() {
		let conceptos = this.props.conceptos;
		let monthNames = this.state.monthNames;

		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="column card-table">
						<div className="table-responsiv column">
							<table>
								<tbody>
									<tr className="pad-bot row-without-border">
										<th colSpan="5" className="text-left">
											<h4 className="weight-semi">Conceptos</h4>
										</th>
										<th className="text-right">
											<button
												className="btn btn-mini btn-primary color-white"
												type="button"
												id="btn-modal-edit"
												onClick={(event) => this.beforeOpenModal("agregarProducto")}
											>
												<i className="fas fa-plus" /> Añadir
											</button>
										</th>
									</tr>
									<tr className="text-left">
										<th className="text-start">Concepto</th>
										<th className="text-center">Inicio</th>
										<th className="text-center">Fin</th>
										<th className="text-center ">Frecuencia</th>
										<th className="text-center ">Editar</th>
										<th className="text-center ">Eliminar</th>
									</tr>
									{this.props.loading ? (
										<tr>
											<td colSpan="6">
												<div className="row">
													<div className="white-space-8"></div>
												</div>
												<div className="row justify-center">
													<i className="fas fa-spinner fa-spin"></i>
												</div>
												<div className="row">
													<div className="white-space-8"></div>
												</div>
											</td>
										</tr>
									) : conceptos.length == 0 ? (
										<tr>
											<td colSpan="6">
												<div className="row">
													<div className="white-space-8"></div>
												</div>
												<div className="row justify-center">Sin datos que mostrar</div>
												<div className="row">
													<div className="white-space-8"></div>
												</div>
											</td>
										</tr>
									) : (
										conceptos.map((concepto, key) => {
											return (
												<tr key={key}>
													<td className="text-start">{concepto.producto_nombre}</td>
													<td className="text-center">
														{monthNames[concepto.Inicio_Mes - 1]}-
														{moment(concepto.Inicio_Anyo, "YYYY").locale('es').format("YY")}
													</td>
													<td className="text-center">
														{moment(concepto.fechaFin).locale('es').format("MMMYY").toLowerCase().replace(".", "-")}
													</td>
													<td className="text-center">{concepto.nombre_reglasFrec}</td>
													<td className="text-center">
														<button
															className="btn-full justify-center align-center btn-mini btn-secondary color-white"
															onClick={(event) => {
																this.beforeOpenEdit(concepto, "agregarProducto");
															}}
														>
															<i className="fas fa-edit"></i>
														</button>
													</td>
													<td className="text-center">
														<button
															className="btn-full justify-center align-center btn-mini btn-secondary color-white"
															onClick={this.beforeOpenModalDelete.bind(this, "deleteModal", concepto)}
														>
															<i className="fas fa-trash-alt"></i>
														</button>
													</td>
												</tr>
											);
										})
									)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<ConceptoModal
					editForm={this.state.editForm}
					id_menu_activo={this.state.id_menu_activo}
					// General
					conceptoGeneralProps={this.state.conceptoGeneral}
					conceptsList={this.props.conceptsList}
					frecListReglas={this.props.frecListReglas}
					updateConceptoGeneral={this.updateConceptoGeneral}
					// Importes
					conceptoImportesProps={this.state.conceptoImportes}
					frecList={this.props.frecList}
					recargosList={this.props.recargosList}
					recargos_aplica={this.props.recargos_aplica}
					updateConceptoImportes={this.updateConceptoImportes}
					// Exclusiones
					conceptoExclusionesProps={this.state.conceptoExclusiones}
					viviendas={this.state.viviendas}
					addVivienda={this.addViviendaCallback}
					viviendasCheckAll={this.viviendasCheckAllCallback}
					updateConceptoExclusiones={this.updateConceptoExclusiones}
					loading={this.state.saving}
					onSubmit={this.onSubmitCallback}
					onMenuChanged={this.onMenuChangedCallback}
				/>
				<ModalBorrar id={"deleteModal"} delete={this.deleteRuleProduct.bind(this, this.state.id_regla_producto)} />
			</div>
		);
	};
}

export default ConceptosTable;

const ConceptoModal = memo((props) => {

	const { 
		editForm, id_menu_activo, loading, onSubmit, onMenuChanged, // SuperModal
		conceptoGeneralProps, conceptsList, frecListReglas, updateConceptoGeneral, // General
		conceptoImportesProps, frecList, recargosList, recargos_aplica, updateConceptoImportes, // Importes
		conceptoExclusionesProps, viviendas, addVivienda, viviendasCheckAll, updateConceptoExclusiones // Exclusiones
	} = props;

	const title = editForm ? "Editar concepto" : "Añadir concepto";

	const conceptoScreens = [
		<General
			updateData={updateConceptoGeneral}
			concepts={conceptsList}
			FrecList={frecListReglas}
			{...conceptoGeneralProps}
		/>,
		<Amounts
			updateData={updateConceptoImportes}
			productos={recargosList}
			frecuencias={frecList}
			recargos_aplica={recargos_aplica}
			{...conceptoImportesProps}
		/>,
		<Exclusions
			updateData={updateConceptoExclusiones}
			viviendas={viviendas}
			addVivienda={addVivienda}
			viviendasCheckAll={viviendasCheckAll}
			{...conceptoExclusionesProps}
		/>
	]

	return (
		<SuperModal
			id={"agregarProducto"}
			size={"static-nav"}
			title={title}
			menus={["General", "Importes", "Exclusiones"]}
			id_menu_activo={id_menu_activo}
			screens={conceptoScreens}
			nexFooter={true}
			lblSubmit="Aceptar"
			lblCancel="Cancelar"
			lblBack="Anterior"
			lblNext="Siguiente"
			onSubmit={onSubmit}
			loading={loading}
			onMenuChanged={onMenuChanged}
		/>
	)
});

const getConceptoGeneralProps = (concepto) => {

	const date = new Date(concepto.Inicio_Anyo, concepto.Inicio_Mes - 1);

	return {
		id_concept: concepto.Id_Producto || 0,
		monthStart: date,
		monthsNumber: concepto.Num_Meses,
		id_frecuency: concepto.Id_ReglaFrecuencia,
		redondear: concepto.Redondear,
		notificacion_pago_dias: concepto.Notificacion_Dias,
		notificar: concepto.Notificacion_Dias ? 1 : 0,
	}
}

const getConceptoImportesProps = (concepto) => {

	const fechaRecargo = moment(concepto.RecargoAplica_Fecha).utc().format("YYYY-MM-DD HH:MM:ss");

	return {
		importe_variable: concepto.Monto_Variable ? 1 : 0,
		fecha_dia: 1,
		valor_anual: concepto.Monto_Variable || 0,
		valor_vivienda: concepto.Monto_Fijo || 0,
		dia1: concepto.DtoPP1_Dias || 0,
		percent1: concepto.DtoPP1_Prc || 0,
		money1: concepto.DtoPP1_Monto || 0,
		dia2: concepto.DtoPP2_Dias || 0,
		percent2: concepto.DtoPP2_Prc || 0,
		money2: concepto.DtoPP2_Monto || 0,
		percent4: concepto.RecargoPrc || 0,
		money4: concepto.Recargo_Monto || 0,
		fecha_exacta_cargos: concepto.RecargoAplica_Fecha ? new Date(fechaRecargo) : new Date(),
		dia_mes_cargos: concepto.RecargoAplica_Dia || 1,
		id_recargo: concepto.Id_RecargoProducto || 0,
		recargo_aplica_id: concepto.Id_RecargoAplica || 0,
		id_frecuencia: concepto.Id_RecargoFrecuencia || 0,
	}
}

const getConceptoExclusionesProps = (concepto) => {
	
	const viviendas_values = concepto.viviendas ? JSON.parse(concepto.viviendas).map((viv) => viv.Id_vivienda) : [];

	return { filter_value: "", viviendas_json: viviendas_values }
}