import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { Consumer } from "../../context";
import Request from "../../core/httpClient";
import {Catalogos ,Administracion, Reportes} from '../profiles/permissions';
import cogoToast from 'cogo-toast';

const request = new Request();

class ProfileDetailTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menus: [],
            user: {
                idUsuario: 0,
                idPerfil: 0,
                idFraccionamiento: 0
            },
            idPerfil: this.props.id,
            optionShowMenu: -1,
			listaMenus: [],
			menus_catalogo: [],
			menus_administracion: [],
			menus_reportes: []
        }
	}

	reload() { this.props.reload() }

	componentDidMount() {
        this.load();
        this.getMenus();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.optionShowMenu !== prevState.optionShowMenu) {
			const lectura = document.getElementById('lecturaAll');
			if( lectura ) {
				lectura.checked = false;
			}
			const escritura = document.getElementById('escrituraAll');
			if( escritura ) {
				escritura.checked = false;
			}
		}
	}

	async load() {
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({
                user: {
                    idUsuario: user.id.id_usuario,
                    idPerfil: user.id.id_perfil,
                    idFraccionamiento: user.id.id_fraccionamiento
                }
            });
        }
    }

    orderMenus(menus) {
        let menuOrdenado = [];
        let id_menu;
        if(Array.isArray(menus) && menus.length > 0){

            menus.map( (menu)=>{
                
                if(menu.parent == 0){
                    menuOrdenado.push(menu);
                    id_menu =   menu.id_menu;
                    menus.map( (menu2)=>{
                        if(menu2.parent === id_menu){
                            menuOrdenado.push(menu2);
                        }
                    });
                }

            });

        }
        
		return menuOrdenado;
    }

	async getMenus() {
        let data = {
            IdFraccionamiento: parseInt(localStorage.getItem('frac')),
            IdPerfil: this.state.idPerfil
        }

        const response = await request.post("/profiles/get/menus/user", data);
		let menus_catalogo = [];
		let menus_administracion = [];
		let menus_reportes = [];
        if (response) {
            if (response.menus) {
				menus_catalogo          = response.menus.filter(menu => menu.pantalla === -1)
				menus_administracion    = response.menus.filter(menu => menu.pantalla === -2)
				menus_reportes          = response.menus.filter(menu => menu.pantalla === -3)
                this.setState({
					menus: response.menus,
					menus_catalogo: this.orderMenus(menus_catalogo),
					menus_administracion: this.orderMenus(menus_administracion),
					menus_reportes:         this.orderMenus(menus_reportes)
                });
                // //console.log(menus_reportes);
                
                
            } else {
                this.setState({
                    empty: true,
					message: response.message,
					menus: [],
					menus_catalogo,
					menus_administracion,
					menus_reportes
                });
            }
        }
    }

	selectAll(option, event) {
		const checkbox = event.target;
		if(checkbox) {
			let { menus_catalogo, menus_administracion, menus_reportes } = this.state;
			switch(this.state.optionShowMenu) {
				case -1: 
					menus_catalogo = menus_catalogo.map(menu => {
						menu[option] = checkbox.checked;
						return menu;
					});
				break;
				case -2: 
                    menus_administracion = menus_administracion.map(menu => {
						menu[option] = checkbox.checked;
						return menu;
					});
				break;
				case -3:
					menus_reportes = menus_reportes.map(menu => {
						menu[option] = checkbox.checked;
						return menu;
					});
				break;
				default: break;
			}

			this.setState({menus_catalogo, menus_administracion, menus_reportes});
		}
	}

	async savePermissions() {
        await this.setState({loading: true});
		const menus = [];
		menus.push(...this.state.menus_catalogo, ...this.state.menus_administracion, ...this.state.menus_reportes)
		const data = {
			permissions: menus,
			user: this.state.user,
			id_perfil: this.state.idPerfil
		}
		const response = await request.post('/profiles/permissions/user', data);
        //console.log(response);
        if(response.error){
            cogoToast.error('No se pudieron actualizar los permisos.',{
                position: 'bottom-right',
            });
        }
        if(response.updated){
            cogoToast.success('Permisos actualizados.',{
                position: 'bottom-right',
            });
        }
		this.reload();
        await this.setState({loading: false});
	}

	render() {
		return (
			<div className="profile-detail">
				<div className="full column container">
					<div className="column tab content">
						<div className="row justify-between align-center">
							<div>
								<h4 className="weight-bold">Editar Permisos</h4>
							</div>
							<div>
								<button className="btn-small btn-primary color-white" onClick={this.savePermissions.bind(this)} >
									<i className="fas fa-check" />
									&nbsp; GUARDAR
								</button>
							</div>
						</div>
						<div className="white-space-8"></div>

                        {/*  */}
						<div className="row menus">
							<div className={`column container align-center justify-center ${this.state.optionShowMenu === -1? 'option' : 'no-select'}`} id="option1" onClick={() => this.setState({ optionShowMenu: -1 })}>
								<button type='button' className="butn">
									Catálogos
								</button>
							</div>
							<div className={`column container align-center justify-center ${this.state.optionShowMenu === -2? 'option' : 'no-select'}`} id="option2" onClick={() => this.setState({ optionShowMenu: -2 })}>
								<button type='button' className="butn" >
									Administración
								</button>
							</div>
							<div className={`column container align-center justify-center ${this.state.optionShowMenu === -3? 'option' : 'no-select'}`} onClick={() => this.setState({ optionShowMenu: -3 })}>
								<button type='button' className="butn" >
									Operación
								</button>
							</div>
						</div>
						<div className="white-space-16"></div>
						<table className="perimissions_table">
							<thead>
								<tr>
									<th style={{width: '40%'}}>
										<h4>Menú</h4>
									</th>
									<th style={{width: '10%'}}>
										<div className="row justify-end">
											<h4 className="">Lectura</h4> 
											&nbsp; 
											<label className="container-check">
												<input type="checkbox" id="lecturaAll" onChange={this.selectAll.bind(this, 'lectura')}/>
												<span className="checkmark"></span>
											</label>
										</div>
									</th>
									<th style={{width: '10%'}}>
										<div className="row justify-end">
											<h4 className="">Escritura</h4>
											&nbsp; 
											<label className="container-check" >
												<input type="checkbox" id="escrituraAll" onChange={this.selectAll.bind(this, 'escritura')}/>
												<span className="checkmark"></span>
											</label>
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
                                {this.state.loading ? 
                                    <React.Fragment>
                                        <div className="white-space-16"></div>
                                        <div className="row justify-center">
                                                <i className="fas fa-spinner fa-spin"></i>
                                        </div>
                                    </React.Fragment>
                                        :
                                    
                                    <React.Fragment>
                                        {this.state.optionShowMenu === -1 && <Catalogos menus={this.state.menus_catalogo}/>}
                                        {this.state.optionShowMenu === -2 && <Administracion menus={this.state.menus_administracion}/>}
                                        {this.state.optionShowMenu === -3 && <Reportes menus={this.state.menus_reportes}/>}
                                    </React.Fragment>
                                }
                        </tbody>
						</table>
					</div>
				</div>
			</div>
		)
	}

}

export default Consumer(ProfileDetailTable);