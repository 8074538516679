import React, { Component } from 'react';
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import Request from "../../core/httpClient";
import moment from 'moment-timezone';
import Resizer from 'react-image-file-resizer';
import cogoToast from 'cogo-toast';
import SelectHour from './selectHour';
import { Consumer } from "../../context";

const request = new Request();

const horariosInit = [
	{ day: "Lunes", active: false, start: "00:00", end: "00:00" },
	{ day: "Martes", active: false, start: "00:00", end: "00:00" },
	{ day: "Miércoles", active: false, start: "00:00", end: "00:00" },
	{ day: "Jueves", active: false, start: "00:00", end: "00:00" },
	{ day: "Viernes", active: false, start: "00:00", end: "00:00" },
	{ day: "Sábado", active: false, start: "00:00", end: "00:00" },
	{ day: "Domingo", active: false, start: "00:00", end: "00:00" }
]

class PersonalDomesticoTableEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			personalTipo: '',
			nombre: '',
			viv: '',
			tipo: '',
			acceso: '',
			id_frecuente_invitacion: 0,
			horarios: horariosInit || [],
			idfrac: localStorage.getItem('frac') || 0,
			fecha: moment().format('YYYY-MM-DD'),
			fecha2: moment().format('YYYY-MM-DD'),
			eine1: null,
			eine2: null,
			erotate1: 0,
			erotate2: 0,
			message: '',
			activated: true,
			code: ''
		};
	};

	async componentDidUpdate(prevProps) {
		if (this.props.invitado !== prevProps.invitado) {
			let horarios = [
				{ day: "Lunes", active: false, start: "00:00", end: "00:00" },
				{ day: "Martes", active: false, start: "00:00", end: "00:00" },
				{ day: "Miércoles", active: false, start: "00:00", end: "00:00" },
				{ day: "Jueves", active: false, start: "00:00", end: "00:00" },
				{ day: "Viernes", active: false, start: "00:00", end: "00:00" },
				{ day: "Sábado", active: false, start: "00:00", end: "00:00" },
				{ day: "Domingo", active: false, start: "00:00", end: "00:00" }
			];
			if (this.props.invitado.horarios_dia) {
				horarios = JSON.parse(this.props.invitado.horarios_dia);
			}
			this.setState({
				nombre: this.props.invitado.invitado,
				telefono: this.props.invitado.telefono,
				viv: this.props.invitado.numero_registro,
				tipo: this.props.invitado.tipo,
				acceso: this.props.invitado.acceso,
				id_frecuente_invitacion: this.props.invitado.id_frecuente_invitacion,
				eine1: this.props.invitado.ine1,
				eine2: this.props.invitado.ine2,
				erotate1: 0,
				erotate2: 0,
				message: '',
				horarios,
				activated: this.props.invitado.activated,
				personalTipo: this.props.invitado.personal_tipo || '',
				code: this.props.invitado.code || ''
			});

			if (this.props.invitado.ine1) {
				if (this.props.invitado.ine1.indexOf('data:image/') === 0) {
					const data = {
						ine: this.props.invitado.ine1,
						idFI: this.props.invitado.id_frecuente_invitacion,
						type: 1
					}
					this.base64ToUrl(data);
				}
			}

			if (this.props.invitado.ine2) {
				if (this.props.invitado.ine2.indexOf('data:image/') === 0) {
					const data = {
						ine: this.props.invitado.ine2,
						idFI: this.props.invitado.id_frecuente_invitacion,
						type: 2
					}
					this.base64ToUrl(data);
				}
			}
		}
	};

	async rotateImg2(id) {
		if (this.state.erotate1 === 360) {
			this.setState({ erotate1: 0 });
		}
		if (this.state.erotate2 === 360) {
			this.setState({ erotate2: 0 });
		}

		if (id === 1) {
			this.setState({ erotate1: (this.state.erotate1 + 90) })
		} else {
			this.setState({ erotate2: (this.state.erotate2 + 90) })
		}
		await this.changeImg2(id);
	};

	async changeImg2(id) {
		let rotate = 0;
		if (id === 1) {
			rotate = this.state.erotate1;
		} else {
			rotate = this.state.erotate2;
		}

		let file = document.getElementById(`eine${id}`);
		if (file) {
			if (file.files[0]) {

				Resizer.imageFileResizer(
					file.files[0],
					337,//337
					180,//180
					'JPEG',
					100,
					rotate,//270
					async uri => {
						if (id === 1) {

							await this.setState({ eine1: uri });
						} else {
							await this.setState({ eine2: uri });
						}

					},
					'base64'
				);
			}
		}
	};

	setHorarios(option, date, dia, mañana, tarde) {
		switch (option) {
			case 'dia':
				dia[date] = true;
				break;
			case 'mañana':
				mañana[date] = true;
				break;
			case 'tarde':
				tarde[date] = true;
				break;
			default: break;
		}
	};

	async handleSubmit(event) {
		event.preventDefault();
		try {
			this.setState({ loadingSave: true });
			
			if (this.state.acceso === 'CONFIRMADO') {
				if (!this.state.eine1 &&  this.state.eine2 || 
					 this.state.eine1 && !this.state.eine2 || 
				    !this.state.eine1 && !this.state.eine2) {
				    cogoToast.warn('Debes elegir las dos imágenes', {
					   position: 'bottom-right',
				    });
				   this.setState({ loadingSave: false });
				   return;
			    };
			}

			let user = this.props.context.user.id.usuario;

			let data = {
				acceso: this.state.acceso,
				idfrac: this.state.idfrac,
				id_frecuente_invitacion: this.state.id_frecuente_invitacion,
				horario: JSON.stringify(this.state.horarios),
				nombre: this.state.nombre,
				fecha: this.state.fecha,
				fecha2: this.state.fecha2,
				ine1: this.state.eine1,
				ine2: this.state.eine2,
				activated: this.state.activated,
				usuario: user,
				personalTipo: this.state.personalTipo || null
			}
			// console.log('This is data edit table->',data);
			const response = await request.post("/invitadosFrecuentes/invitados/update", data);


			if (response.updated && !response.empty) {
				this.closeModal("editDomestico");
				this.props.reload();
				cogoToast.success('Datos de invitado actualizados.', {
					position: 'bottom-right',
				});
			} else {
				// this.setState({
				// 	updated: 	response.updated,
				// 	acceso: 	response.updated.acceso,
				// 	idViv: 		response.updated.numero_registro,
				// 	nombre: 	response.updated.nombre,
				// 	tipo: 		response.updated.tipo
				// });
				//this.setState({message: "NO SE PUDO EDITAR PERSONAL"});
				cogoToast.error('No se pudieron actualizar datos de invitado.', {
					position: 'bottom-right',
				});
			}

			this.setState({ loadingSave: false });
		} catch (error) {

		}
	};

	closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
	};

	onlyOneMonday(checkbox) {
		var checkboxes = document.getElementsByName("lunes")
		checkboxes.forEach((item) => {
			if (item !== checkbox) item.checked = true;
		})
	};

	uncheck(id) {
		let check = document.getElementById(id);
		if (check.value === "0") {
			check.checked = true;
			check.value = "1";
			// alert("0");
		}
		if (check.value === "1") {
			check.checked = false;
			check.value = "0";
			// alert("1");
		}
		else {
			check.checked = true;
			check.value = "0";
			// alert("2");
		}
	};

	async base64ToUrl(info) {
		try {
			const data = {
				type: info.type,
				img: info.ine,
				idfrac: localStorage.getItem('frac') || 0,
				id_frecuente_invitacion: info.idFI,
				module: "frecuentes"
			}

			const res = await request.post("/invitadosFrecuentes/invitados/base64ToUrl", data);
			if (res) {
				if (!res.error) {
					if (info.type === 1) {
						this.setState({ eine1: res.url });
					}

					if (info.type === 2) {
						this.setState({ eine2: res.url });
					}
				}
			}
		} catch (error) {
			console.log(error);
		};
	};

	async clearState() {
		await this.setState({ message: '' });
	};

	closeModal() {
		let horarios = [
			{ day: "Lunes", active: false, start: "00:00", end: "00:00" },
			{ day: "Martes", active: false, start: "00:00", end: "00:00" },
			{ day: "Miércoles", active: false, start: "00:00", end: "00:00" },
			{ day: "Jueves", active: false, start: "00:00", end: "00:00" },
			{ day: "Viernes", active: false, start: "00:00", end: "00:00" },
			{ day: "Sábado", active: false, start: "00:00", end: "00:00" },
			{ day: "Domingo", active: false, start: "00:00", end: "00:00" }
		];
		if (this.props.invitado.horarios_dia) {
			horarios = JSON.parse(this.props.invitado.horarios_dia);
		}
		this.setState({
			nombre: this.props.invitado.invitado,
			telefono: this.props.invitado.telefono,
			viv: this.props.invitado.numero_registro,
			tipo: this.props.invitado.tipo,
			acceso: this.props.invitado.acceso,
			id_frecuente_invitacion: this.props.invitado.id_frecuente_invitacion,
			eine1: this.props.invitado.ine1,
			eine2: this.props.invitado.ine2,
			erotate1: 0,
			erotate2: 0,
			message: '',
			horarios,
			activated: this.props.invitado.activated,
			personalTipo: this.props.invitado.personal_tipo || '',
			code: this.props.invitado.code || ''
		});
	};

	render() {
		return (
			<div>
				<DinamicModal
					idModal={'editDomestico'}
					sizeModalContainer={'big'}
					title={'Editar personal'}
					success={this.handleSubmit.bind(this)}
					showBtnSuccess={this.props.escritura}
					btnTextSuccess={"ACTUALIZAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.message}
					closeModal={this.closeModal.bind(this)}
				>
					{this.rendereditDomestico()}
				</DinamicModal>
			</div>
		);
	};

	rendereditDomestico() {
		const { horarios } = this.state;
		//console.log(horarios);

		return (
			<div className="full row align-start">
				<div className="column full">
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>  Nombre:	</b></p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="nombre"
								value={this.state.nombre}
								enable="true"
								onChange={event => this.setState({ nombre: event.target.value })}
								className="input input-modals"
								placeholder="Nombre"
								required
								maxLength="64"
								id="nom"
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>  Teléfono:	</b></p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="phone"
								value={this.props.getNumberFormat(this.state.telefono)}
								className="input input-modals"
								required
								disabled
							/>
						</div>

					</div>

					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p className="justify-start" >
								<b>Acceso:</b>
							</p>
						</div>
						<div className="column full">
							<select
								name="acceso"
								id="acceso"
								className="input input-modals"
								value={this.state.acceso} enable="true"
								onChange={event => this.setState({ acceso: event.target.value })}>
								<option value="PENDIENTE">PENDIENTE</option>
								<option value="CONFIRMADO">CONFIRMADO</option>
							</select>
						</div>


					</div>
					{/*  */}
					<div className="white-space-8" />

					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>  Tipo:	</b></p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="tipo"
								value={this.state.personalTipo}
								placeholder='Limpieza, Jardinero, Chofer...'
								onChange={event => this.setState({ personalTipo: event.target.value })}
								className="input input-modals"
								required={false}
								maxLength="24"
								disabled={false}
							/>
						</div>

					</div>


					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p className="justify-start">
								<b>Vivienda:</b>
							</p>
						</div>
						<div className="column full">
							<div className="column full">
								<input type="text" name="vivienda" value={this.state.viv}
									onChange={event => this.setState({ viv: event.target.value })}
									className="input input-modals" required maxLength="24" disabled />
							</div>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>  Código de verificación:	</b></p>
						</div>
						<div className="column full text-left" style={{fontSize: '0.9rem', fontFamily: '"Mukta", sans-serif'}}>
						    {this.state.code}
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p className="justify-start">
								<b>Dispositivo activado:</b>
							</p>
						</div>
						<div className="column full">
							<div className="column full">
								{this.state.activated ?
									<label className="container-check">
										<input
											type="checkbox"
											name="activated"
											checked={this.state.activated ? true : false}
											onChange={event => this.setState({ activated: event.target.checked })}
											className="input input-modals"
										/>
										<span className="checkmark"></span>
									</label> :
									<label className="container-check">
										<input
											type="checkbox"
											name="activated"
											checked={this.state.activated ? true : false}
											className="input input-modals"
											disabled
										/>
										<span className="checkmark-disabled"></span>
									</label>
								}
							</div>
						</div>
					</div>
					<div className="white-space-8"></div>
					<h4>Horarios</h4>
					{horarios.map(hor =>
						<>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="row label-big" style={{ width: '30%' }}>
									<label className="container-check" >
										<input
											type="checkbox"
											name="active"
											checked={hor.active}
											onChange={(event) => {
												let { horarios } = this.state;
												horarios = horarios.map(hr => {
													if (hr.day === hor.day) {
														hr.active = event.target.checked
													}
													return hr;
												})
												this.setState({ horarios });
											}}
										/>
										<span className="checkmark" ></span>
									</label>
									<p><b> {hor.day} </b></p>
								</div>
								<div className="column full" style={{ width: '35%' }}>
									<SelectHour
										id="lunes_start"
										className="input input-modals"
										value={hor.start}
										onChange={(horario) => {
											let { horarios } = this.state;
											horarios = horarios.map(hr => {
												if (hr.day === hor.day) {
													hr.start = horario
												}
												return hr;
											})
											this.setState({ horarios });
										}} />
								</div>
								<div className="column full" style={{ width: '35%' }}>
									<SelectHour
										id="lunes_end"
										className="input input-modals"
										value={hor.end}
										onChange={(horario) => {
											let { horarios } = this.state;
											horarios = horarios.map(hr => {
												if (hr.day === hor.day) {
													hr.end = horario
												}
												return hr;
											})
											this.setState({ horarios });
										}} />
								</div>
							</div>
						</>
					)}
				</div>
				<div className="column auto images-ine align-end">
					<div className="responsive-img column">
						<div className="img-column img-width-small align-center">
							{this.state.eine1 &&
								<button type="button" onClick={this.rotateImg2.bind(this, 1)} >
									<i className="fas fa-redo-alt"></i>
								</button>
							}
							<div className="container-img">
								<img src={this.state.eine1 ? this.state.eine1 : require('../../img/empty_image.png')} className="img-duo-preview" id="previewArea" />

								<div className="middle-overlay">
									<label className="btn-upload-image column align-center justify-center">
										<i className="fas fa-file-upload"></i>
										<input type="file" id="eine1" name="AreaImage" accept="image/*" onChange={this.changeImg2.bind(this, 1)} />
										<div className="white-space-8"></div>
										<p className="color-white">Subir parte frontal de la INE</p>

									</label>
								</div>
							</div>
						</div>
						<div className="img-column img-width-small align-center">
							{this.state.eine2 &&
								<button type="button" onClick={this.rotateImg2.bind(this, 2)} >
									<i className="fas fa-redo-alt"></i>
								</button>
							}
							<div className="container-img">
								<img
									src={this.state.eine2 ? this.state.eine2 : require('../../img/empty_image.png')}
									className="img-duo-preview"
									id="epreviewArea"
								/>
								<div className="middle-overlay">
									<label className="btn-upload-image column align-center justify-center">
										<i className="fas fa-file-upload"></i>
										<input type="file" id="eine2" name="AreaImage" accept="image/*" onChange={this.changeImg2.bind(this, 2)} />
										<div className="white-space-8"></div>
										<p className="color-white">Subir parte trasera de la INE</p>
									</label>
								</div>
							</div>

						</div>

					</div>
				</div>
			</div>
		)
	};
}
export default Consumer(PersonalDomesticoTableEdit);