import React, { Component } from 'react';
import { Consumer } from "../../context";

import Request from "../../core/httpClient";
import Socket from '../../core/socket';
import DinamicModal from '../dinamicModal/dinamicModal';

const request = new Request();

let timer;

class Sidebar extends Component {
    state = {
		fraccionamientos: [],
		subfraccionamientos: [],
		fraccionamiento: "0",
		subfraccionamiento: "0"
    }

	componentDidMount() {
		this.getFraccionamientos();
		Socket.on('saludo', () =>{
			alert('Me saluda el api')
		})
	}

	componentWillUnmount() {
		clearTimeout(timer);
	}

	async getFraccionamientos() {

		const user = await this.props.context.loadUser();
		this.setState({user});
		let data = { 
			IdAdministracion: 	user.id.id_administracion,
			IdFraccionamiento: 	user.id.id_fraccionamiento,
			perfil:				user.id_perfil
		}
					 
		const response = await request.post("/admin/administracion/get/fraccionamientos", data);
		if (response && !response.error) {
			if (response.fraccionamientos && !response.empty) {
				this.setState({
					fraccionamientos: response.fraccionamientos
				});
				if(response.fraccionamientos.length > 0) {
					const fracc = localStorage.getItem('frac') || response.fraccionamientos[0].id_fraccionamiento;
					const fraccionamiento = this.state.fraccionamientos.find((fraccionamiento) => 
															fraccionamiento.id_fraccionamiento.toString() === fracc.toString())
					if(fraccionamiento) {
						this.props.context.setNombreFraccionamiento(fraccionamiento.nombre);
					}
					if(!localStorage.getItem('frac')) {
						localStorage.setItem('frac', response.fraccionamientos[0].id_fraccionamiento);
						this.props.context.getMenus(response.fraccionamientos[0].id_fraccionamiento, user.id.id_perfil);
						this.setState({fraccionamiento: response.fraccionamientos[0].id_fraccionamiento});
					} else {
						this.setState({fraccionamiento: fracc || 0});
					}
				}
			} else {
				this.setState({
					empty: true,
					//message: response.message
				});
			}
		} else {
			this.setState({
				error: true,
				//message: response.message
			});

		}
	}

	async applyChanges() {
		/** Asigna el ID de Fraccionamiento en localStorage */
		const user = await this.props.context.loadUser();
		
		localStorage.removeItem('subfrac');
		localStorage.removeItem('menusInfo');
		localStorage.setItem('frac', this.state.fraccionamiento);
		
		const fracc = this.state.fraccionamientos.find(
							(fraccionamiento) => 
							fraccionamiento.id_fraccionamiento.toString() === this.state.fraccionamiento.toString());
							await this.props.context.setFraccionamiento(this.state.fraccionamiento);
		await this.props.context.setNombreFraccionamiento(fracc.nombre);
		await this.props.context.getMenus(this.state.fraccionamiento,user.id.id_perfil)
		// //console.log(men);

		const leftbar = document.getElementById('rightsidebar');
		if(leftbar) {
			leftbar.classList.toggle('open');
		}
		timer = setTimeout(() => {
			window.location.reload();
		}, 500);
		// window.location.reload();
	}

	probarSocket() {
		Socket.emit('saludo', {...this.state})
	}

	changeFraccionamiento(event) {
        //Borrar Ids Restaurantes
        window.localStorage.removeItem('pedIdRes');
        window.localStorage.removeItem('plaIdRes');
        window.localStorage.removeItem('plaIdCat');

		this.setState({fraccionamiento: event.target.value});
    }

	handleLogout() {
		this.props.context.logout();
		window.location = "/login";
	}

	async toChangePassword() {
		const modal = document.getElementById('expiry-change-password');
		if (modal) {
			this.props.context.setExpiratePassword(false);
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

    render() {
		let user = {}
		if(this.state.user) {
			user = this.state.user.id;
		}
        return (
            <div id="rightsidebar" className="rightsidebar row justify-center">
				<div className="column container">
					<h4 className="accordion color-white text-center">{user.nombre}</h4>
					<div className="white-space-16"></div>
					<span className="accordion color-white">Fraccionamiento:</span>
					<div className="white-space-8"></div>
					<select name="fraccionamiento" id="fraccionamiento" className="input input-select full"
						value={this.state.fraccionamiento}
						onChange={this.changeFraccionamiento.bind(this)}>
						{  
							this.state.fraccionamientos.map((fraccionamiento, key) => (
							<option value={fraccionamiento.id_fraccionamiento} key={key}>{fraccionamiento.nombre}</option>)) 
						}
					</select>
					<div className="white-space-16"></div>
					<button className="btn btn-primary btn-small color-white" onClick={this.applyChanges.bind(this)}>
						Aplicar
					</button>
					<div className="white-space-64"></div>
					<div className="white-space-64"></div>
					<div className="white-space-64"></div>
					<div className="justify-center">
						<a className="font-regular color-white" onClick={() => this.toChangePassword()}>Cambiar contraseña</a>
					</div>
					<div className="white-space-16"></div>
					<div className="accordion justify-center ">
						<a
							href="#one"
							className="font-regular color-white nav-exit"
							onClick={this.handleLogout.bind(
								this
							)}>
							<i className="fas fa-sign-out-alt font-text " />{" "}
							Cerrar sesión
						</a>
					</div>
				</div>
            </div>
        );
	}
}

export default Consumer(Sidebar);