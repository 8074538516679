import React, { Component } from 'react';
import ModalBorrar from '../modals/modalDelete';
import DinamicModal from '../dinamicModal/dinamicModal';
import PosicionForm from './formPosicion';
import Request from "../../core/httpClient";
import cogoToast from 'cogo-toast';

const request = new Request();


class PosicionesTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
			poscion: {},
			loadingSave: false
		}
    }

    delete = id => {
        this.handleDelete(id)
    }

    render() {

        let posiciones = []
        if (this.props.posiciones) {
            posiciones = this.props.posiciones
        }
        return (
            <div className="card-table card-owners column" id="card-owners">
                <div className="row">
                    <table className="full">
                        <thead className="sticky-th">
                            <tr className="text-left">
                                <th className="th-options-huge">
									<div className="row">
										Posición &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'posicion' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'posicion')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'posicion')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'posicion')}></i>
											}
										</div>
									</div>
								</th>
                                <th className="text-center th-options-small">Editar</th>
								<th className="text-center th-options-small">Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {posiciones.slice(((this.props.page - 1) * this.props.pageLength), (this.props.page * this.props.pageLength)).map((posicion, key) => (
                                <tr className={this.state.id_posicion_row === posicion.id_personal_posicion && 'row-active'} key={key}>
                                    <td>
                                        {posicion.posicion}
                                    </td>
									<td className="text-center">
                                        <button
											type        =   "button justify-center" 
											onClick     =   {this.openModal.bind(this, 'modal-edit-posicion', posicion)}
                                        >
											<i className="font-small fas fa-pen" />
										</button>
									</td>
									<td className="text-center">
										<button 
											className = "btn-full justify-center align-center btn-mini btn-secondary color-white"
											type = 'button' 
											onClick = {this.openModalDelete.bind(this, posicion)}
											style = {{ padding: '5px' }}
										>
											<i className="font-text fas fa-trash-alt " />
										</button>
									</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
				</div>
				<DinamicModal 
					idModal				=	{'modal-edit-posicion'}
					sizeModalContainer	=	{'small'}
					title				=	{'Editar posición'} 
					success				=	{this.handleSubmit.bind(this)} 
					showBtnSuccess		=	{true}
					btnTextSuccess		=	{"GUARDAR"}
					showBtnCancel		=	{true}
					btnTextCancel		=	{"CANCELAR"}
					loadingSave			=	{this.state.loadingSave}
					message				=	{this.state.message}
					closeModal			=	{this.closeModal.bind(this)}
				>
					<PosicionForm posicion={this.state.posicion} posicionInput={this.props.posicion} setPosicion={this.props.setPosicion} />
				</DinamicModal>
				<ModalBorrar
				    id = {this.state.id_personal_posicion}
				    delete = {this.handleDelete.bind(this)}
				/>
            </div>
        );
	}

	closeModal(idModal) {
		this.setState({posicion: {}});

		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}

		this.props.setPosicion('');
	}

	openModal(idModal, data) {
		this.setState({ posicion: data, id_posicion_row: data.id_personal_posicion });
        
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
        }
	}

	openModalDelete(posicion) {
		this.setState({ id_personal_posicion: posicion.id_personal_posicion, id_posicion_row: posicion.id_personal_posicion});
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

	async handleSubmit(event) {
		event.preventDefault();
		this.setState({loadingSave: true});
		const form = event.target;
		const data = {
			id_personal_posicion: this.state.posicion.id_personal_posicion,
			posicion: form.posicion_title.value
		}

		const response = await request.post('/personal/posiciones/update', data);

		if (response.updated) {
            this.props.reload();
            cogoToast.success('Posición actualizado.', {
                position: 'bottom-right',
            });
        } else {
            cogoToast.error(response.message || 'No se pudo actualizar la posición.', {
                position: 'bottom-right',
            });
        }

		this.props.setPosicion('');
		this.setState({loadingSave: false});
	}

	async handleDelete(id) {
		this.setState({loadingSave: true});

		let data = {
			id_personal_posicion: id,
		}

		const response = await request.post('/personal/posiciones/delete', data);

		if (response.delete) {
            cogoToast.success('Posición eliminada.', {
                position: 'bottom-right',
            });
			this.props.reload();
        } else {
            cogoToast.error('No se pudo eliminar la posición.', {
                position: 'bottom-right',
            });
        }
		this.props.setPosicion('');
		this.setState({loadingSave: false});
	}
}

export default PosicionesTable;