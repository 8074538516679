import React, {Component} from 'react';
import Resizer from 'react-image-file-resizer';
class ComponenteChido extends Component{
    constructor(props){
        super(props);
        this.state = {
            ine1: null
        }
    }

    async rotateImg(id){
		if(this.state.rotate1 === 360){
			await this.setState({rotate1:0});
		}
		if(this.state.rotate2 === 360){
			await this.setState({rotate2:0});
		}

		if(id === 1){
			await this.setState({rotate1: (this.state.rotate1 +90)})
		}else{
			await this.setState({rotate2: (this.state.rotate2 +90)})
		}
		await this.changeImg(id);

    }
    
    async changeImg(id){
		let rotate = 0;
		if(id === 1){
			rotate = this.state.rotate1;
		}else{
			rotate = this.state.rotate2;
		}

		let file = document.getElementById(`ine${id}`);
		if(file){
			if(file.files[0]) {
            
				Resizer.imageFileResizer(
				   file.files[0],
				   337,//337
				   180,//180
				   'JPEG',
				   100,
				   rotate,//270
				   async uri => {
					   if(id == 1){

						   await this.setState({ine1: uri});
					   }else{
						await this.setState({ine2: uri});
					   }
					
				   },
				   'base64'
			   );
		   }
		}
	}


    render(){
        return(
            <React.Fragment>
                <div className="form-group">
                    {/* <div className="form-item"> */}
                        <img  src="https://www.rcg.com.mx/wp-content/uploads/2019/08/Captura-de-pantalla-2019-08-26-a-las-10.41.53.png" alt=""/>
                        <img  src="https://i.blogs.es/7dc539/emlpawtxuaa_d9j/450_1000.jpg" alt=""/>
                    {/* </div> */}
                    {/* <div className="container-img">
                        <img src={this.state.ine1 ? this.state.ine1 : require('../../img/empty_image.png')} className="img-duo-preview" id="previewArea" />
                        <div className="middle-overlay">
                            <label className="btn-upload-image column align-center justify-center">
                                <i className="fas fa-file-upload"></i>
                                <input type="file" id="ine1" name="AreaImage" accept="image/*" onChange={this.changeImg.bind(this,1)} />
                                <div className="white-space-8"></div>
                                <p className="color-white">Subir parte frontal de la INE</p>
                            </label>
                        </div>
                    </div>

                    <div className="container-img">
                        <img src={this.state.ine1 ? this.state.ine1 : require('../../img/empty_image.png')} className="img-duo-preview" id="previewArea" />
                        <div className="middle-overlay">
                            <label className="btn-upload-image column align-center justify-center">
                                <i className="fas fa-file-upload"></i>
                                <input type="file" id="ine1" name="AreaImage" accept="image/*" onChange={this.changeImg.bind(this,1)} />
                                <div className="white-space-8"></div>
                                <p className="color-white">Subir parte trasera de la INE</p>
                            </label>
                        </div>
                    </div> */}
                
                </div>
                
            </React.Fragment>
        )
    }
}

export default ComponenteChido;