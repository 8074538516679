import React, { useEffect, useState } from 'react';
import ModalBorrar from '../modals/modalDelete';
import DinamicModal from '../dinamicModal/dinamicModal';
import EntregaForm from './formEntrega';
import Request from "../../core/httpClient";
import moment from 'moment-timezone';
import cogoToast from 'cogo-toast';
import { Consumer } from '../../context';

const request = new Request();

const PaqueteriaTable = (props) => {
	const [loadingSave, setloadingSave] = useState(false);
	const [paquetes, setPaquetes] = useState([]);
	const [paquete, setPaquete] = useState({});
	const [id_paquete, setId_Paquete] = useState(0);

	useEffect(() => {
		setPaquetes(props.paquetes);
	}, [props.paquetes]);

	function openModal(idModal, data) {
		setPaquete(data);

		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		};
	};

	function closeModal(idModal) {
		setPaquete({});

		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		};
	};

	function openModalDelete(paquete) {
		setId_Paquete(paquete.id_paqueteria);

		let modal = document.getElementById("deleteModal");
		if (modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		};
	};

	async function handleDelete(id) {
		try {
			const data = { id_paqueteria: id };

			const response = await request.post("/paqueteria/delete", data);

			if (response && !response.error) {
				if (response.deleted && !response.empty) {
					//this.setState({ message: response.message });
					this.props.reload();
					cogoToast.success('Paquete eliminado.', {
						position: 'bottom-right',
					});
				} else {
					this.setState({
						empty: true,
						loading: false
					});
					cogoToast.error('No se pudo eliminar el paquete.', {
						position: 'bottom-right',
					});
				}
			} else {
				this.setState({
					empty: true,
					loading: false
				});
				cogoToast.error('No se pudo eliminar el paquete.', {
					position: 'bottom-right',
				});
			};
		} catch (error) {
			console.log(error);
		};
	};

	async function handleSubmit(event) {

		event.preventDefault();

		try {

			setloadingSave(true);

			const form = event.target;
			const foto_entrega = form.entrega_foto_entrega;

			let file_entrega = null;

			if (foto_entrega.files) {
				file_entrega = foto_entrega.files[0];
			};

			const user = await props.context.loadUser();

			const data = {
				id_paqueteria: paquete.id_paqueteria,
				nombre_recibe: form.entrega_nombre_recibe.value,
				fecha_entrega: moment(form.entrega_fecha_entrega.value).format(),
				id_usuario: user.id.id_usuario,
				comentarios: form.entrega_comentarios.value,
			};

			const response = await request.new_postFile('/paqueteria/update', [file_entrega], data);

			if (response.updated) {
				closeModal('modal-edit-paquete');
				props.reload();
				cogoToast.success('Paquete actualizado.', {
					position: 'bottom-right',
				});
			} else {
				cogoToast.error(response.message || 'No se pudo actualizar el paquete.', {
					position: 'bottom-right',
				});
			};

			setloadingSave(false);
		} catch (error) {
			console.log(error);
		};
	};

	return (
		<div className="card-table card-owners column" id="card-owners">
			<div className="row">
				<table className="full">
					<thead id={props.id ? props.id : ''} className="sticky-th">
						<tr className="text-left">
							<th className="th-options-huge">
								<div className="row">
									Vivienda &nbsp;
									<div className="align-center">
										{props.filterColumn.col === 'numero_registro' ? props.filterColumn.filt === 'asc' ?
											<i className="fas fa-sort-down" onClick={props.sortBy.bind(this, 'numero_registro')}></i>
											:
											<i className="fas fa-sort-up" onClick={props.sortBy.bind(this, 'numero_registro')}></i>
											:
											<i className="fas fa-sort" onClick={props.sortBy.bind(this, 'numero_registro')}></i>
										}
									</div>
								</div>
							</th>
							<th className="th-options-huge">
								<div className="row">
									Destinatario &nbsp;
									<div className="align-center">
										{props.filterColumn.col === 'nombre_residente' ? props.filterColumn.filt === 'asc' ?
											<i className="fas fa-sort-down" onClick={props.sortBy.bind(this, 'nombre_residente')}></i>
											:
											<i className="fas fa-sort-up" onClick={props.sortBy.bind(this, 'nombre_residente')}></i>
											:
											<i className="fas fa-sort" onClick={props.sortBy.bind(this, 'nombre_residente')}></i>
										}
									</div>
								</div>
							</th>
							<th className="th-options-huge">
								<div className="row">
									Fecha Llegada &nbsp;
									<div className="align-center">
										{props.filterColumn.col === 'fecha_llegada' ? props.filterColumn.filt === 'asc' ?
											<i className="fas fa-sort-down" onClick={props.sortBy.bind(this, 'fecha_llegada')}></i>
											:
											<i className="fas fa-sort-up" onClick={props.sortBy.bind(this, 'fecha_llegada')}></i>
											:
											<i className="fas fa-sort" onClick={props.sortBy.bind(this, 'fecha_llegada')}></i>
										}
									</div>
								</div>
							</th>
							<th className="th-options-huge">
								<div className="row">
									Fecha Entrega &nbsp;
									<div className="align-center">
										{props.filterColumn.col === 'fecha_entrega' ? props.filterColumn.filt === 'asc' ?
											<i className="fas fa-sort-down" onClick={props.sortBy.bind(this, 'fecha_entrega')}></i>
											:
											<i className="fas fa-sort-up" onClick={props.sortBy.bind(this, 'fecha_entrega')}></i>
											:
											<i className="fas fa-sort" onClick={props.sortBy.bind(this, 'fecha_entrega')}></i>
										}
									</div>
								</div>
							</th>
							<th className="th-options-huge">Estado</th>
							<th className="text-center th-options-small">Editar</th>
							<th className="text-center th-options-small">Borrar</th>
						</tr>
					</thead>
					<tbody>
						{paquetes.slice(((props.page - 1) * props.pageLength), (props.page * props.pageLength)).map((paquete, key) => (
							<tr key={key}>
								<td>
									{paquete.numero_registro}
								</td>
								<td>
									{paquete.nombre_residente}
								</td>
								<td>
									{moment(paquete.fecha_llegada).format('DD/MM/YYYY [-] hh:mm A')}
								</td>
								<td>
									{paquete.fecha_entrega ? moment(paquete.fecha_entrega).format('DD/MM/YYYY [-] hh:mm A') : null}
								</td>
								<td>
									{paquete.fecha_entrega ? 'Entregado' : 'Pendiente'}
								</td>
								<td className="text-center">
								<button
										type="button justify-center"
										onClick={openModal.bind(this, 'modal-entrega-paquete', paquete)}
									>
										{!paquete.fecha_entrega ? 
										    <i className="font-small fas fa-pen" />
										:
										    <i className="fas fa-eye"/>
									}
									</button>
								</td>
								<td className="text-center">
									<button
									    type='button'
										onClick={openModalDelete.bind(this, paquete)}
										style={{ padding: '5px' }}
										disabled={!props.escritura || paquete.fecha_entrega}
									>
										<i className="font-text fas fa-trash-alt " /> &nbsp; {props.icono ? "" : null}
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<DinamicModal
				idModal={'modal-entrega-paquete'}
				sizeModalContainer={'big'}
				title={'Entrega paquete'}
				success={handleSubmit.bind(this)}
				showBtnSuccess={paquete.fecha_entrega ? false : true}
				btnTextSuccess={"GUARDAR"}
				showBtnCancel={paquete.fecha_entrega ? false : true}
				btnTextCancel={"CANCELAR"}
				loadingSave={loadingSave}
				closeModal={closeModal.bind(this)}
			>
				<EntregaForm
					paquete={paquete}
					viviendas={props.viviendas || []} 
				/>
			</DinamicModal>

			<ModalBorrar
				id={id_paquete}
				delete={handleDelete.bind(this)}
			/>
		</div>
	);
};

export default Consumer(PaqueteriaTable);