/**
 *  Environment.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de entornos
 */

import React, { Component } from "react";
import { Consumer } from "../context";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import Sidebar from "../components/header/sidebar";
import EnvironmentTable from "../components/entorno/environmentTable";
import Request from "../core/httpClient";
import Permissions from "../middlewares/Permissions";
import cogoToast from "cogo-toast";

const request = new Request();

class Environment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: {
				idUsuario: 0,
				idPerfil: 0,
				idFraccionamiento: 0,
				idAdministracion: 0,
			},
			// config
			id_config: 0,
			t_invitaciones1: 0,
			t_invitaciones2: 0,
			foto: false,
			comentario: false,
			num_invitados: 0,
			num_empleados: 0,
			vigencia: 0,
			num_vacacionales: 0,
			escritura: false,
			lectura: false,
			logo: require("../img/logo-AppMosphera-r.png"),
			openpayId: "",
			openpayLlavePrivada: "",
			openpayLlavePublica: "",
			clipLlaveApi: "",
			clipLlaveSecreta: "",
			clipComision: 0,
			clipIvaComision: 0,
			salida_automatica: "",
			desactivar_notificaciones: "",
			hora_desactivacion: "",
			hora_activacion: "",
			submenu: '',
			appmospheraLlaveApi: "",
			appmospheraLlaveSecreta: "",
		};

		this.validarPermisos();
	}
	async validarPermisos() {
		let permisos = new Permissions();
		let permisosInfo = await permisos.getResult();
		await this.setState({ escritura: permisosInfo.escritura, lectura: permisosInfo.lectura });
	}

	componentDidMount() {
		cogoToast.info("Buscando configuración...", {
			position: "bottom-right",
		});
		this.getConfig();
		this.load();
	}

	async load() {
		const user = await this.props.context.loadUser();
		if (user) {
			this.setState({
				user: {
					idUsuario: user.id.id_usuario,
					idPerfil: user.id.id_perfil,
					idFraccionamiento: user.id.id_fraccionamiento,
					idAdministracion: user.id.id_administracion,
				},
			});
		}

		const submenu = await this.props.context.getSubmenuName();
		if (submenu) {
			this.setState({ submenu });
		}
	}

	async getConfig() {
		let data = { IdFraccionamiento: parseInt(localStorage.getItem("frac")) };

		const response = await request.post("/environments/get/configuration", data);

		if (response && !response.error) {
			if (response.configuracion && !response.empty) {
				this.setState({
					id_config: response.configuracion[0].id_configuracion,
					t_invitaciones1: response.configuracion[0].tolerancia_hr, //t hr1
					t_invitaciones2: response.configuracion[0].numero_registros, // t hr2
					foto: response.configuracion[0].obligatorio_foto,
					comentario: response.configuracion[0].obligatorio_comentario,
					num_invitados: response.configuracion[0].num_invitados,
					num_empleados: response.configuracion[0].empleados_domesticos,
					num_vacacionales: response.configuracion[0].num_vacacionales,
					vigencia: response.configuracion[0].vigencia,
					openpayId: response.configuracion[0].id_openpay,
					openpayLlavePrivada: response.configuracion[0].llave_privada,
					openpayLlavePublica: response.configuracion[0].llave_publica,
					clipLlaveApi: response.configuracion[0].clip_api_key,
					clipLlaveSecreta: response.configuracion[0].clip_secret_key,
					clipComision: response.configuracion[0].clip_comision,
					clipIvaComision: response.configuracion[0].clip_comision_iva,
					salida_automatica: response.configuracion[0].salida_automatica,
					desactivar_notificaciones: response.configuracion[0].desactivar_notificaciones,
					hora_desactivacion: response.configuracion[0].hora_desactivacion,
					hora_activacion: response.configuracion[0].hora_activacion,
					appmospheraLlaveApi: response.configuracion[0].appmosphera_api_key,
					appmospheraLlaveSecreta: response.configuracion[0].appmosphera_secret_key
				});
			}
		} else {
		}
	}

	limpiarvalores() {
		this.setState({
			config: {
				tInvitaciones: 0,
				nRegistros: 0,
				comentario: true,
				foto: true,
				nInvitadosVip: 0,
				IdConfig: 0,
				numEmpleados: "",
				numVacacionales: 0,
			},
		});
	}

	change() {
		this.load();
	}
	/*personal y familiares */
	async updateVlues(json) {
		await this.setState(json);
	}

	enviar() {
		this.handleSubmit();
	}

	render() {
		return (
			<div className="owners column">
				<Helmet>
					<title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
				</Helmet>
				<Header sidebar={true} active={"entorno"} parent={"config"} panel={"panel3"} nav={"catalogos"} change={this.change.bind(this)} reload={() => { }} />

				<div className="row">
					<div className="column" id="sidebar">
						<Sidebar />
					</div>
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								<div className="white-space-32" />
								{this.state.lectura ? (
									<React.Fragment>
										<div className="navigation align-center">
											<div className="justify-start">
												<h3 className="weight-semi">{this.state.submenu}</h3>
											</div>
										</div>
										<div className="white-space-16" />
										<EnvironmentTable
											id={this.state.user.idUsuario}
											// config
											id_config={this.state.id_config}
											t_invitaciones1={this.state.t_invitaciones1}
											t_invitaciones2={this.state.t_invitaciones2}
											foto={this.state.foto}
											comentario={this.state.comentario}
											num_invitados={this.state.num_invitados}
											num_empleados={this.state.num_empleados}
											num_vacacionales={this.state.num_vacacionales}
											vigencia={this.state.vigencia}
											user={this.state.user}
											actualizar={this.updateVlues.bind(this)}
											enviar={this.enviar.bind(this)}
											escritura={this.state.escritura}
											getConfig={this.getConfig.bind(this)}
											openpayId={this.state.openpayId}
											openpayLlavePrivada={this.state.openpayLlavePrivada}
											openpayLlavePublica={this.state.openpayLlavePublica}
											clipLlaveApi={this.state.clipLlaveApi}
											clipLlaveSecreta={this.state.clipLlaveSecreta}
											clipComision={this.state.clipComision}
											clipIvaComision={this.state.clipIvaComision}
											salida_automatica={this.state.salida_automatica}
											desactivar_notificaciones={this.state.desactivar_notificaciones}
											hora_desactivacion={this.state.hora_desactivacion}
											hora_activacion={this.state.hora_activacion}
											appmospheraLlaveApi={this.state.appmospheraLlaveApi}
											appmospheraLlaveSecreta={this.state.appmospheraLlaveSecreta}
										/>
									</React.Fragment>
								) : null}

								<div className="white-space-48"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Consumer(Environment);
