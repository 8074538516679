// React and Third-party
import React, { useRef, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import cogoToast from 'cogo-toast';

// Requests
import { getCatalogos as fetchCatalogos, getViviendas as fetchViviendas } from '../requests';

const desdeFechaDefault = moment().add(-1, "month").format("YYYY-MM-DD");
const hastaFechaDefault = moment().format("YYYY-MM-DD");

export default function HistorialFilterBar(props) {

    const { parameters, onParameterChanged, onSearch } = props;

    const [loadingCatalogos, setLoadingCatalogos] = useState(false);
    const [loadingViviendas, setLoadingViviendas] = useState(false);
    const [catalogos, setCatalogos] = useState([]);
    const [viviendas, setViviendas] = useState([]);

    useEffect(() => {
      loadCatalogos();
      loadViviendas();
    }, []);

    const loadViviendas = async () => {

        const getViviendas = async () => {

            const response = await fetchViviendas(0, localStorage.getItem('frac'));

            if(response.error) return { error: response.error };

            return { viviendas: response.viviendas };
        }

        setLoadingViviendas(true);

        const response = await getViviendas();

        if(response.error) { 
            cogoToast.error(response.error, {
                position: "bottom-right",
                hideAfter: 5,
            });
        }

        if(response.viviendas) setViviendas(response.viviendas);

        setLoadingViviendas(false);
    }

    const loadCatalogos = async () => {

        const getCatalogos = async () => {
            try {
                
                const response = await fetchCatalogos();

                if(response.error) return { error: response.error };

                return { catalogos: response.catalogos };

            } catch (error) {
                return { error };
            }
        }

        setLoadingCatalogos(true);

        const response = await getCatalogos();

        if(response.catalogos) {
            setCatalogos(response.catalogos)
        } else {
            cogoToast.error(response.error, {
                position: "bottom-right",
                hideAfter: 5,
            });
        }

        setLoadingCatalogos(false);
    }
    
    const parametersCopy = useRef(null);

    const handleSearch = () => {
        
        parametersCopy.current = { ...parameters };

        onSearch();
    }

    const checkChanges = () => {

        if(parametersCopy.current === null) return false;

        const keys = Object.keys(parameters);

        for (let index = 0; index < keys.length; index++) {
            const key = keys[index];

            if(parameters[key] !== parametersCopy.current[key])
                return true;
        }

        return false;
    }

    let cambiosRegistrados = false;

    if(parametersCopy.current === null) {

        parametersCopy.current = { ...parameters };
    }
    else {

        cambiosRegistrados = checkChanges();
    }

    return (
        <div className='column'>
            <div className="row-responsive full align-center" style={{ paddingBlock: 10, paddingInline: 8 }}>
                <div className="data-from row align-center" style={{ width: "auto" }}>
                    <h5 className="color-black" style={{ marginRight: 8, whiteSpace: "nowrap" }}>Por Balances:</h5>
                    <label className="container-check">
                        <input
                            type="checkbox"
                            id="byBalances"
                            name="byBalances"
                            value={parameters.byBalances}
                            onChange={(e) => onParameterChanged(e.target.name, e.target.checked)}
                        />
                        <span className="checkmark" ></span>
                    </label>
                </div>
                <div className="data-from row align-center" style={{ flex: 2 }}>
                    <h5 className="color-black" style={{ marginRight: 8 }}>Viviendas:</h5>
                    <select
                        id="vivienda"
                        name="vivienda"
                        className="input input-select full"
                        disabled={loadingViviendas}
                        onChange={(e) => onParameterChanged(e.target.name, +e.target.value)}
                    >
                        <option value="0">Todas</option>
                        {
                            viviendas.map((vivienda) => (
                                <option key={vivienda.id_vivienda} value={vivienda.id_vivienda}>
                                    {vivienda.numero_registro}
                                </option>
                            ))
                        }
                    </select>
                </div>
                <div style={{ width: "16px" }}></div>
                <div className="data-from row align-center" style={{ flex: 2 }}>
                    <h5 className="color-black" style={{ marginRight: 8 }}>Servicio:</h5>
                    <select
                        id="catalogo"
                        name="catalogo"
                        className="input input-select full"
                        disabled={loadingCatalogos}
                        onChange={(e) => onParameterChanged(e.target.name, +e.target.value)}
                    >
                        <option value="0">Todos</option>
                        {
                            catalogos.map((catalogo) => (
                                <option key={catalogo.id_catalogo} value={catalogo.id_catalogo}>
                                    {catalogo.tipo}
                                </option>
                            ))
                        }
                    </select>
                </div>
                <div style={{ width: "16px" }}></div>
                <div className="data-from row align-center" style={{ flex: 1 }}>
                    <h5 className="color-black" style={{ marginRight: 8 }}>Desde:</h5>
                    <input
                        type="date"
                        id="desdeFecha"
                        name="desdeFecha"
                        className="input input-select full"
                        defaultValue={desdeFechaDefault}
                        onChange={(e) => onParameterChanged(e.target.name, e.target.value)}
                    />
                </div>
                <div style={{ width: "16px" }}></div>
                <div className="data-to row align-center" style={{ flex: 1 }}>
                    <h5 className="color-black" style={{ marginRight: 8 }}>Hasta:</h5>
                    <input
                        type="date"
                        id="hastaFecha"
                        name="hastaFecha"
                        className="input input-select full"
                        defaultValue={hastaFechaDefault}
                        onChange={(e) => onParameterChanged(e.target.name, e.target.value)}
                    />
                </div>
                <div style={{ width: "16px" }}></div>
                <button
                    className="btn btn-primary btn-red-table color-white"
                    style={!cambiosRegistrados ? { backgroundColor: "#AAA" } : undefined}
                    disabled={!cambiosRegistrados}
                    onClick={handleSearch}
                >
                    Buscar
                </button>
            </div>
        </div>
    )
}
