import React from 'react';
import Core from './../../core/SuperCore';

class Body extends Core{
    constructor(props){
        super(props);
        this.state ={
            screens:        this.props.screens,
            id_menu_activo: this.props.id_menu_activo
        }
    }
    componentDidUpdate(prevProps, prevState){

        if(prevProps.screens != this.props.screens){
            this.setState({screens: this.props.screens});
        }

        if(prevProps.id_menu_activo != this.props.id_menu_activo){
            this.setState({id_menu_activo: this.props.id_menu_activo});
        }
    }

    render(){

        return(
            this.state.screens ? 
            this.state.screens[this.state.id_menu_activo] ? 
            this.state.screens[this.state.id_menu_activo] 
            : 
            <div className="center-all center">
                <div className="row justify-center full">
                    <i className="fas fa-sync fa-spin"></i>
                </div>
            </div>
        :
        <div className="center-all center">
            <div className="row justify-center full">
                <i className="fas fa-sync fa-spin"></i>
            </div>
        </div>
        );
    }
}

export default Body;
