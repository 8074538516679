import { Cell, CellTemplate, Compatible, getCellProperty, inNumericKey, isAllowedOnNumberTypingKey, isNavigationKey, isNumpadNumericKey, keyCodes, Uncertain, UncertainCompatible } from '@silevis/reactgrid';
import * as React from 'react';

// NOTE: all modules imported below may be imported from '@silevis/reactgrid'


const NumberCustomCell = {
    type: 'number_custom',
    value: 0,
    format: Intl.NumberFormat,
    nanToZero: true,
    hideZero: false,
    errorMessage: ''
}

export class NumberCustomCellTemplate {

    getCompatibleCell(uncertainCell) {
        let value;
        try {
            value = getCellProperty(uncertainCell, 'value', 'number');
        } catch (error) {
            value = NaN;
        }
        const numberFormat = uncertainCell.format || new Intl.NumberFormat(window.navigator.language);
        const displayValue = (uncertainCell.nanToZero && Number.isNaN(value)) ? 0 : value;
        const text = (Number.isNaN(displayValue) || (uncertainCell.hideZero && displayValue === 0)) ? '' : numberFormat.format(displayValue);
        return { ...uncertainCell, value: displayValue, text }
    }

    handleKeyDown(cell, keyCode, ctrl, shift, alt) {
        if (isNumpadNumericKey(keyCode)) keyCode -= 48;
        const char = String.fromCharCode(keyCode);
        if (!ctrl && !alt && !shift && (inNumericKey(keyCode) || isAllowedOnNumberTypingKey(keyCode))) {
            const value = Number(char);
            if (Number.isNaN(value) && isAllowedOnNumberTypingKey(keyCode))
                return { cell: { ...this.getCompatibleCell({ ...cell, value }), text: char }, enableEditMode: true }
            return { cell: this.getCompatibleCell({ ...cell, value }), enableEditMode: true }
        }
        return { cell, enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER || keyCode === keyCodes.F2 }
    }

    update(cell, cellToMerge) {
        return this.getCompatibleCell({ ...cell, value: cellToMerge.value });
    }

    getTextFromCharCode = (cellText) => {
        switch (cellText.charCodeAt(0)) {
            case keyCodes.DASH:
            case keyCodes.FIREFOX_DASH:
            case keyCodes.SUBTRACT:
                return '-';
            case keyCodes.COMMA:
                return ','
            case keyCodes.PERIOD:
            case keyCodes.DECIMAL:
                return '.';
            default:
                return cellText;
        }
    }

    getClassName(cell, isInEditMode) {
        const isValid = cell.validator?.(cell.value) ?? true;
        const className = cell.className || '';
        return `${!isValid ? 'rg-invalid' : ''} ${className}`;
    }

    render(cell, isInEditMode, onCellChanged) {
        if (!isInEditMode) {
            const isValid = cell.validator?.(cell.value) ?? true;
            const textToDisplay = !isValid && cell.errorMessage ? cell.errorMessage : cell.text;
            return textToDisplay;
        }

        const locale = cell.format ? cell.format.resolvedOptions().locale : window.navigator.languages[0];
        const format = new Intl.NumberFormat(locale, { useGrouping: false, maximumFractionDigits: 20 });

        return <input
            inputMode='decimal'
            ref={input => {
                if (input) {
                    input.focus();
                    input.setSelectionRange(input.value.length, input.value.length);
                }
            }}
            defaultValue={Number.isNaN(cell.value) ? this.getTextFromCharCode(cell.text) : format.format(cell.value)}
            onChange={e => onCellChanged(this.getCompatibleCell({ ...cell, value: parseFloat(e.currentTarget.value.replace(/,/g, '.')) }), false)}
            onBlur={e => onCellChanged(this.getCompatibleCell({ ...cell, value: parseFloat(e.currentTarget.value.replace(/,/g, '.')) }), (e).view?.event?.keyCode !== keyCodes.ESCAPE)}
            onKeyDown={e => {
                if (inNumericKey(e.keyCode) || isNavigationKey(e.keyCode) || isAllowedOnNumberTypingKey(e.keyCode)) e.stopPropagation();
                if ((!inNumericKey(e.keyCode) && !isNavigationKey(e.keyCode) && !isAllowedOnNumberTypingKey(e.keyCode)) || e.shiftKey) e.preventDefault();
            }}
            onCopy={e => e.stopPropagation()}
            onCut={e => e.stopPropagation()}
            onPaste={e => e.stopPropagation()}
            onPointerDown={e => e.stopPropagation()}
        />
    }
}