import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import CardQuick from "../components/dashboard/CardQuick";
import DailyInvitations from "../components/dashboard/daily-invitations";
import DailyEvents from "../components/dashboard/daily-events";
import Incidents from "../components/dashboard/incidents";
import { Consumer } from "../context";
import moment from "moment";
import 'moment/min/locales'
import DinamicModal from '../components/dinamicModal/dinamicModal';
import Request from "../core/httpClient";
import Paginador from "../components/paginador/paginador";
import Map from '../components/maps/map';

const request = new Request();

class DashBoard extends Component {
	constructor(props) {
		super(props);
		this.changeFrac = this.changeFrac.bind(this);
		this.reload = this.reload.bind(this);
		this.openBitacora = this.openBitacora.bind(this);
		this.getAutorizaciones = this.getAutorizaciones.bind(this);
		this.getActivos = this.getActivos.bind(this);
		this.state = {
			user: {},
			noInvitaciones: 0,
			noActivas: 0,
			noIncidentes: 0,
			invitacionesDiarias: [],
			invitacionesActivas: [],
			incidenciasDIa: 0,
			totalViviendas: 0,
			direccion: 0,
			fraccionamiento: [{ id_fraccionamiento: 0, p_latitude: "", p_longitude: "" }],
			preMap: [{ id_fraccionamiento: 0, p_latitude: "19.4284700", p_longitude: "-99.1276600" }],
			loadViewMap: false,
			bitacora: [],
			page: 0,
			pageAut: 1,
			autType: 0,
			autorizacionesFecha: moment(Date.now()).format("YYYY-MM-DD"),
			loadingEvents: false,
		};
	};

	async componentDidMount() {
		await this.changeFrac();
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.state.autType !== prevState.autType) {
			this.getAutorizaciones();
		};
	};

	async getFraccionamiento() {
		try {
			const user = await this.props.context.loadUser();
			const idFraccionamiento = localStorage.getItem('frac');

			if (user) {
				const data = { IdFraccionamiento: idFraccionamiento };

				const response = await request.post("/admin/administracion/get/fraccionamiento", data);

				if (response && !response.error) {
					if (response.fraccionamiento && !response.empty) {
						if (response.fraccionamiento[0].p_latitude !== '' || response.fraccionamiento[0].p_longitude !== '') {
							this.setState({ fraccionamiento: response.fraccionamiento });
						};
					};
				};
			};

		} catch (error) {
			console.log(error);
		};
	};

	setPage(page) {
		this.setState({ page });
	};

	async changeFrac() {
		await this.load();
		await this.reload();
	};

	async reload() {
		await this.getFraccionamiento();
		setTimeout(
			function () {
				this.setState({ loadViewMap: true });
			}
				.bind(this),
			1000
		);
	};

	async openBitacora() {
		this.setState({ mesagge: null });
		const modal = document.getElementById("bitacoraModal");
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
		await this.getRondas();
	};

	async openAutorizaciones() {
		this.setState({ mesagge: null });
		const modal = document.getElementById("autorizacionesModal");
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
		await this.getAutorizaciones();
	};

	async getRondas() {
		try {
			this.setState({ loading: true });

			const data = { IdFraccionamiento: localStorage.getItem('frac') };
			const response = await request.post("/incidencias/get/bitacora", data);

			if (response && !response.error) {
				if (response.bitacora && !response.empty) {
					this.setState({
						bitacora: response.bitacora,
						message: ""
					});
				} else {
					this.setState({
						empty: true,
						message: "NO HAY REGISTROS EN LA BITÁCORA",
						loading: false,
						bitacora: []
					});
				};
			} else {
				this.setState({
					error: true,
					message: "NO HAY REGISTROS EN LA BITÁCORA",
					loading: false
				});
			};

			this.setState({ loading: false });
		} catch (error) {
			console.log(error);
		};
	};

	findAutorizaciones(event) {
		event.preventDefault();
		this.getAutorizaciones();
	};

	async getAutorizaciones(event) {
		try {
			this.setState({ loadingEvents: true });

			const data = {
				IdFraccionamiento: localStorage.getItem('frac'),
				fecha: this.state.autorizacionesFecha,
				tipo: this.state.autType
			};

			const response = await request.post("/autorizaciones/get/dashboard", data);

			if (response && !response.error) {
				if (response.autorizaciones && !response.empty) {
					this.setState({
						autorizaciones: response.autorizaciones,
						message: ""
					});
				} else {
					this.setState({
						empty: true,
						message: response.message,
						loading: false,
						autorizaciones: []
					});
				};
			} else {
				this.setState({
					error: true,
					message: "NO SE ENCONTRARON AUTORIZACIONES",
					loading: false
				});
			};
			this.setState({ loadingEvents: false });
		} catch (error) {
			console.log(error);
		};
	};

	async load() {
		const user = await this.props.context.loadUser();
		if (user) {
			this.setState({
				user: {
					idUsuario: user.id.id_usuario,
					idPerfil: user.id.id_perfil,
					idFraccionamiento: user.id.id_fraccionamiento,
					nombre: user.id.nombre
				}
			});
		}
		setTimeout(
			function () {
				this.getDashboard();
			}
				.bind(this),
			1000
		);
	};

	async getDashboard() {
		await this.getInvitacionesDiarias();
		await this.getActivos();
		await this.getIncidencias();
		await this.getCards();
		await this.getDireccion();
		await this.getAutorizaciones();
	};

	//   traer invitaciones del día
	async getInvitacionesDiarias() {
		try {
			this.setState({ loadingDiarios: true, invitacionesDiarias: [] });
			const data = {
				IdFraccionamiento: localStorage.getItem('frac'),
				Fecha: moment(Date.now()).format("YYYY-MM-DD")
			};

			const response = await request.post("/invitaciones/get/panelentradas", data);

			if (response && !response.error) {
				this.setState({
					invitacionesDiarias: response.invitaciones,
					loadingDiarios: false
				});

			} else {
				this.setState({
					error: true,
					message: response.message,
					loadingDiarios: false
				});
			};
		} catch (error) {
			console.log(error);
		};
	};

	//   traer invitaciones pendientes de salida
	async getActivos() {
		try {
			this.setState({ loadingActivos: true, invitacionesActivas: [] });

			const data = {
				IdFraccionamiento: localStorage.getItem('frac'),
				Fecha: moment(Date.now()).format("YYYY-MM-DD")
			};

			const response = await request.post("/invitaciones/get/panelsalidas", data);

			if (response && !response.error) {
				this.setState({
					invitacionesActivas: response.activas,
					loadingActivos: false
				});
			} else {
				this.setState({
					error: true,
					message: response.message,
					loadingActivos: false
				});
			};
		} catch (error) {
			console.log(error);
		};
	};

	//   traer incidencias del dia
	async getIncidencias() {
		try {
			this.setState({ loadingIncidencias: true, incidenciasDIa: [] });

			const data = {
				Fecha: moment(Date.now()).format("MM-DD-YYYY"),
				IdFraccionamiento: localStorage.getItem('frac')
			};

			const response = await request.post("/incidencias/get/panelincidentes", data);

			if (response && !response.error) {
				this.setState({
					incidenciasDIa: response.incidentes,
					loadingIncidencias: false
				});

			} else {
				this.setState({
					error: true,
					message: response.message,
					loadingIncidencias: false
				});
			};
		} catch (error) {
			console.log(error);
		};
	};

	// get info de cards
	async getCards() {
		try {
			const data = {
				Fecha: moment(Date.now()).format("YYYY-MM-DD"),
				IdFraccionamiento: localStorage.getItem('frac')
			};

			this.setState({ loadingViviendas: true });

			const response = await request.post("/invitaciones/get/panelcards", data);

			if (response && !response.error) {
				this.setState({
					noInvitaciones: response[0].cantidad,
					noActivas: response[1].cantidad,
					totalViviendas: response[2].cantidad,
					noIncidentes: response[3].cantidad,
					loadingDiarios: false,
					loadingIncidencias: false,
					loadingViviendas: false,
					loadingActivos: false
				});
			} else {
				this.setState({
					error: true,
					message: response.message,
					loadingIncidencias: false
				});
			};
		} catch (error) {
			console.log(error);
		};
	};

	//   traer direccion fraccionaiento
	async getDireccion() {
		try {
			this.setState({ loadingDireccion: true });
			const data = parseInt(this.state.user.idFraccionamiento);

			const response = await request.get(`/fraccionamientos/direccion/${data}`);

			if (response && !response.error) {
				if (response.fraccionamientoDireccion && !response.empty) {
					this.setState({
						direccion: response.fraccionamientoDireccion,
						loadingDireccion: false
					});
				} else {
					this.setState({
						empty: true,
						message: response.message,
						loadingDireccion: false
					});
				};
			} else {
				this.setState({
					error: true,
					message: response.message,
					loadingDireccion: false
				});
			};
		} catch (error) {
			console.log(error);
		};
	};

	render() {
		return (
			<div className="dashboard column">
				<Helmet>
					<title>{process.env.REACT_APP_NAME} - Tablero</title>
				</Helmet>
				<Header change={this.changeFrac} reload={this.reload} />
				<div className="row">
					<div className="justify-center">
						<div className="container column">
							<div className="white-space-16" />
							<div className="navigation align-center justify-end">
								<button
									type="button"
									className="btn btn-primary btn-small color-white"
									onClick={this.openBitacora}
								>
									<i className="fas fa-clipboard-list font-text" />
									&nbsp; Bitácora
								</button>
							</div>
							<div className="white-space-16" />
							<div className="quick row">
								<CardQuick
									icon="warehouse"
									value={this.state.totalViviendas}
									loading={this.state.loadingViviendas ? true : false}
									description="N° de viviendas"
								/>
								<CardQuick
									icon="exclamation-triangle"
									value={this.state.noIncidentes}
									loading={this.state.loadingIncidencias ? true : false}
									description="N° de incidencias del día"
								/>
								<CardQuick
									icon="id-card"
									value={this.state.noInvitaciones}
									loading={this.state.loadingDiarios ? true : false}
									description="N° de invitaciones pendientes"
								/>
								<CardQuick
									icon="user-friends"
									value={this.state.noActivas}
									loading={this.state.loadingActivos ? true : false}
									description="Invitados en el fraccionamiento"
								/>
							</div>
							<div className="white-space-24" />
							<DailyInvitations
								dataDiaria={this.state.invitacionesDiarias}
								dataActiva={this.state.invitacionesActivas}
								reload={this.getActivos}
								loadingActivos={this.state.loadingActivos}
							/>
							<div className="white-space-24" />
							<DailyEvents
								dataAuth={this.state.autorizaciones}
								reload={this.getAutorizaciones}
								loadingEvents={this.state.loadingEvents}
							/>
							<div className="row justify-between align-center">
								<div className="row">

									<DinamicModal
										idModal={'bitacoraModal'}
										sizeModalContainer={'big'}
										title={'BITÁCORA DE SEGURIDAD'}
										loadingSave={this.state.loading}
									>
										{this.renderBitacora()}
									</DinamicModal>
									
								</div>

							</div>
							<div className="white-space-24" />

							<Incidents incidentesHoy={this.state.incidenciasDIa} />

							<div className="white-space-24" />
							<h3 className="weight-semi">Información General</h3>
							<div className="white-space-8" />

							<p className="font-small">
								{this.state.direccion.complete}
							</p>

							<div className="white-space-16" />
							{
								this.state.loadViewMap && this.state.fraccionamiento[0].p_latitude ?
									<Map data={this.state.fraccionamiento[0]}></Map>
									:
									<Map data={this.state.preMap[0]}></Map>

							}
							<div className="white-space-24" />
						</div>
					</div>
				</div>
			</div>
		);
	};

	renderBitacora() {
		let bita = this.state.bitacora;

		return (
			<div className="card-table card-owners column" id="card-owners">
				<div className="row">
					<table className="full">
						<thead>
							<tr className="text-left">
								<th className="text-left">Usuario</th>
								<th className="text-left">Fecha</th>
								<th className="text-left">Descripción</th>
							</tr>
						</thead>
						<tbody>
							{bita.slice((this.state.page - 1) * 7, this.state.page * 7).map((bit, key) => (
								<React.Fragment key={key}>
									<tr>
										<td className="text-left">{bit.usuario}</td>
										<td className="text-left">{moment(bit.fecha).tz('GMT').format('DD/MM/YYYY')}</td>
										<td className="text-left"><p className="">{bit.comentarios}</p></td>
									</tr>
								</React.Fragment>
							))}

						</tbody>
					</table>
				</div>
				<div className="white-space-24" />
				<Paginador pages={Math.ceil(bita.length / 7)} setPage={this.setPage.bind(this)} />
			</div>
		);
	};
};

export default Consumer(DashBoard);
