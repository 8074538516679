import React from "react";
import Paginador from "./../paginador/paginador";
import DinamicModal from "./../dinamicModal/dinamicModal";
import Core from "./../core/SuperCore";
import cogoToast from "cogo-toast";
import Request from "./../../core/httpClient";
import ModalBorrar from "../modals/modalDelete";
import Respuestas from "./respuestas_table";
import Chart from "chart.js";
import NumberFormat from "react-number-format";

const request = new Request();

class PreguntasTable extends Core {
	constructor(props) {
		super(props);
		this.state = {
			preguntas: this.props.preguntas,
			id_encuesta: this.props.id_encuesta,
			preguntas_tipos: this.props.preguntas_tipos,

			id_pregunta_tipo: this.props.preguntas_tipos.length > 0 ? this.props.preguntas_tipos[0].id_encuesta_pregunta_tipo : 0,
			pregunta: "",

			title: "Añadir Pregunta",
			createAction: true,

			respuestas: [],
			cerrado: this.props.cerrado,
		};
	}

	async componentDidUpdate(prevProps) {
		if (this.props.preguntas != prevProps.preguntas) {
			this.setState({preguntas: this.props.preguntas});
		}

		if (this.props.id_encuesta != prevProps.id_encuesta) {
			this.setState({id_encuesta: this.props.id_encuesta});
		}

		if (this.props.preguntas_tipos != prevProps.preguntas_tipos) {
			this.setState({
				preguntas_tipos: this.props.preguntas_tipos,
				id_pregunta_tipo: this.props.preguntas_tipos.length > 0 ? this.props.preguntas_tipos[0].id_encuesta_pregunta_tipo : 0,
			});
		}

		if (this.props.cerrado != prevProps.cerrado) {
			this.setState({cerrado: this.props.cerrado});
		}
	}

	async openEditModal(pregunta) {
		this.setState({
			title: "Editar pregunta",
			id_pregunta_tipo: pregunta.id_encuesta_pregunta_tipo,
			pregunta: pregunta.pregunta,
			id_pregunta: pregunta.id_encuesta_pregunta,
		});

		this.setState({
			createAction: false,
		});

		this.openModal("createPregunta");
	}

	render() {
		let {preguntas} = this.state;
		//console.log(preguntas);

		return (
			<React.Fragment>
				<div className="full row align-center">
					<div className="column full">
						<div className="column card-table">
							<div className="table-responsiv column">
								<table>
									<tbody>
										<tr className="row-without-border">
											<th colSpan="5" className="text-left">
												<h4 className="weight-semi">Tipo de preguntas</h4>
											</th>
											<th>
												<button
													className="btn-full justify-center align-center btn-mini btn-primary color-white"
													type="button"
													onClick={(e) => {
														this.setState({title: "Añadir pregunta"});
														this.clearData();
														this.openModal("createPregunta");
													}}
													disabled={!this.props.escritura || this.props.cerrado}
												>
													<i className="fas fa-plus mini-i"></i> &nbsp; Añadir pregunta
												</button>
											</th>
										</tr>
										<tr>
											<th className="text-left th-options-huge">Pregunta</th>
											<th className="text-left">Tipo</th>
											<th className="th-options">Respuestas</th>
											<th className="th-options">Resultado</th>
											<th className="th-options">Editar</th>
											<th className="th-options">Eliminar</th>
										</tr>
										{preguntas.map((preg) => {
											return (
												<React.Fragment>
													<tr className={this.state.id_pregunta_activa === preg.id_encuesta_pregunta ? "tr-selected-pregunta" : null}>
														<td title={preg.pregunta} className="td-no-desborder">
															{preg.pregunta}
														</td>
														<td>{preg.pregunta_tipo}</td>
														<td>
															<button
																className="btn-full justify-center align-center btn-mini btn-secondary color-white"
																type="button"
																onClick={(event) => {
																	this.popRow(preg.id_encuesta_pregunta);
																}}
															>
																<i className="font-small fas fa-bars" />
															</button>
														</td>
														<td>
															<button
																className="btn-full justify-center align-center btn-mini btn-secondary color-white"
																type="button"
																onClick={(event) => {
																	this.openResultModal(preg);
																}}
															>
																<i className="font-small fas fa-poll" />
															</button>
														</td>
														<td>
															<button
																className="btn-full justify-center align-center btn-mini btn-secondary color-white"
																type="button"
																onClick={(event) => {
																	this.openEditModal(preg);
																}}
															>
																<i className="font-small fas fa-pen" />
															</button>
														</td>
														<td>
															<button
																className="btn-full justify-center align-center btn-mini btn-secondary color-white"
																type="button"
																onClick={(event) => {
																	this.setState({id_pregunta: preg.id_encuesta_pregunta});
																	this.openModalDelete("deleteModal-preguntas");
																}}
																disabled={!this.props.escritura || this.props.cerrado}
															>
																<i className="font-small fas fa-trash-alt" />
															</button>
														</td>
													</tr>
													{this.state.id_pregunta_activa === preg.id_encuesta_pregunta && preg.pregunta_tipo !== "Abierta" ? (
														<Respuestas
															getRespuestas={this.getRespuestas.bind(this)}
															id_pregunta={preg.id_encuesta_pregunta}
															respuestas={this.state.respuestas}
															escritura={this.props.escritura}
															cerrado={this.props.cerrado}
														></Respuestas>
													) : null}
												</React.Fragment>
											);
										})}
										{/*<tr>
											<td className="text-center" colSpan="7">
												<Paginador pages={Math.ceil(this.state.preguntas.length / 5)} setPage={this.setPage.bind(this)} />
											</td>
										</tr>*/}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>

				<DinamicModal
					idModal={"createPregunta"}
					enforceFocus={true}
					sizeModalContainer={"small"}
					title={this.state.title}
					success={this.handleSubmit.bind(this)}
					showBtnSuccess={this.props.escritura && !this.props.cerrado}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.saveMessage}
					closeModal={this.closeModal.bind(this)}
				>
					{this.renderPregunta()}
				</DinamicModal>
				<DinamicModal
					idModal={"resultPregunta"}
					enforceFocus={true}
					sizeModalContainer={"small"}
					title={this.state.title}
					success={() => {}}
					showBtnSuccess={false}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.saveMessage}
					closeModal={this.closeResultados.bind(this)}
				>
					{this.renderResultados()}
				</DinamicModal>
				<ModalBorrar name="deleteModal-preguntas" id={this.state.id_pregunta} delete={this.handleDelete.bind(this)} />
			</React.Fragment>
		);
	}

	async getRespuestas() {
		let data = {
			IdEncuestaPregunta: this.state.id_pregunta_activa,
		};

		const response = await request.get("/encuestas/get/respuestas", data);

		if (response.error || response.empty) {
			this.setState({respuestas: []});
			return false;
		}

		this.setState({respuestas: response});
	}

	async popRow(id_pregunta) {
		if (id_pregunta === this.state.id_pregunta_activa) {
			await this.setState({id_pregunta_activa: null, respuestas: []});
		} else {
			await this.setState({id_pregunta_activa: id_pregunta});
			await this.getRespuestas();
		}
	}

	async handleDelete() {
		let usuario = await this.getUserInfo();

		let data = {
			IdEncuestaPregunta: this.state.id_pregunta,
			Usuario: usuario.info.usuario,
		};

		const response = await request.put("/encuestas/delete/pregunta", data);

		if (!response.error) {
			await this.props.getPreguntas();
			cogoToast.success("Pregunta eliminada.", {
				position: "bottom-right",
			});

			this.closeModalDelete("deleteModal-preguntas");
		} else {
			cogoToast.error("No se pudo eliminar pregunta.", {
				position: "bottom-right",
			});
		}

		this.closeModalDelete("deleteModal-preguntas");
		this.props.getPreguntas();
	}

	async editPregunta() {
		this.setState({loadingSave: true});

		let usuario = await this.getUserInfo();

		let data = {
			IdEncuestaPregunta: parseInt(this.state.id_pregunta),
			IdEncuestaPreguntaTipo: parseInt(this.state.id_pregunta_tipo),
			Pregunta: this.state.pregunta,
			Usuario: usuario.info.usuario,
		};

		const response = await request.put("/encuestas/update/pregunta", data);

		if (response.error) {
			let msj = "No se pudo actualizar la pregunta.";

			if (response.Cerrado) msj = "No se puede editar pregunta, la encuesta está cerrada.";

			cogoToast.error(msj, {
				position: "bottom-right",
			});

			this.setState({loadingSave: false});
			return false;
		}

		cogoToast.success("Pregunta editada correctamente.", {
			position: "bottom-right",
		});
		this.closeModal("createPregunta");
		await this.clearData();
		await this.props.getPreguntas();

		this.setState({loadingSave: false});
	}

	async handleSubmit(event) {
		event.preventDefault();

		if (this.state.createAction) {
			await this.createPregunta();
			await this.clearData();
		} else {
			await this.editPregunta();
			this.setState({createAction: true});
		}
	}

	async createPregunta() {
		this.setState({loadingSave: true});

		let usuario = await this.getUserInfo();

		let data = {
			IdEncuesta: parseInt(this.state.id_encuesta),
			IdEncuestaPreguntaTipo: parseInt(this.state.id_pregunta_tipo),
			Pregunta: this.state.pregunta,
			Usuario: usuario.info.usuario,
		};

		const response = await request.post("/encuestas/add/pregunta", data);

		if (response.error) {
			this.setState({loadingSave: false});

			cogoToast.error("No se pudo añadir pregunta.", {
				position: "bottom-right",
			});

			return false;
		}

		cogoToast.success("Pregunta añadida.", {
			position: "bottom-right",
		});

		await this.clearData();
		this.props.getPreguntas();
		this.closeModal("createPregunta");

		this.setState({loadingSave: false});
	}

	async openResultModal(pregunta) {
		this.openModal("resultPregunta");
		await this.setState({
			title: "Resultados",
			pregunta: pregunta.pregunta,
		});

		let data = {
			IdEncuestaPregunta: pregunta.id_encuesta_pregunta,
		};

		const response = await request.get("/encuestas/get/preguntas/resultados", data);

		if (!response.error && !response.empty) {
			await this.setState({resultados: response.resultados});
		} else {
			await this.setState({resultados: []});
		}

		this.renderChart();
	}

	async clearData() {
		this.setState({
			id_pregunta_tipo: this.state.preguntas_tipos.length > 0 ? this.state.preguntas_tipos[0].id_encuesta_pregunta_tipo : 0,
			pregunta: "",
		});
	}

	renderPregunta() {
		let {preguntas_tipos} = this.state;

		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Tipo de pregunta:</b>
							</p>
						</div>
						<div className="column full">
							<select
								name=""
								id=""
								className="input input-modals"
								value={this.state.id_pregunta_tipo}
								onChange={(event) => this.setState({id_pregunta_tipo: event.target.value})}
								required
							>
								{preguntas_tipos.map((pt) => (
									<option value={pt.id_encuesta_pregunta_tipo}>{pt.pregunta_tipo}</option>
								))}
							</select>
						</div>
					</div>
					<div className="white-space-8" />

					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Pregunta:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								className="input input-modals"
								name=""
								id=""
								value={this.state.pregunta}
								onChange={(event) => this.setState({pregunta: event.target.value})}
								required
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderResultados() {
		let result = this.state.resultados;
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="justify-start">
						<h3 className="weight-semi">{this.state.pregunta}</h3>
					</div>
					<div className="white-space-16"></div>
					<div className="card-table card-owners column" id="card-owners">
						<div className="table-responsive">
							<table>
								<thead>
									<tr className="text-left">
										<th className="th-options-huge">Respuestas</th>
										<th className="th-options text-center">Resultados</th>
										<th className="th-options text-center">Valor de voto</th>
										<th className="th-options text-center">Indiviso</th>
									</tr>
								</thead>
								<tbody>
									{result
										? result.map((res) => {
												return (
													<tr>
														<td>{res.respuesta}</td>
														<td className="text-center">{res.Total_Votos}</td>
														<td className="text-center">
															<NumberFormat value={res.Valor_Votos} displayType={"text"} thousandSeparator={true} decimalScale={4} fixedDecimalScale={true} />
														</td>
														<td className="text-center">
															<NumberFormat value={res.pro_indiviso_vivienda} displayType={"text"} thousandSeparator={true} decimalScale={4} fixedDecimalScale={true} />
														</td>
													</tr>
												);
										  })
										: ""}
								</tbody>
							</table>
						</div>
					</div>
					<div className="white-space-16"></div>
					<div className="column full">
						<canvas className="line" id="resultChart" width="50%" height="20%"></canvas>
					</div>
				</div>
			</div>
		);
	}

	async closeResultados() {
		this.closeModal.bind(this);
		await this.setState({resultados: []});

		let cont = document.getElementById("resultChart");
		let lineChart = new Chart(cont, {});
		lineChart.clear();
		lineChart.destroy();
	}

	renderChart() {
		let result = this.state.resultados;
		let labels = [];
		let data = [];
		result.map((res) => {
			labels.push(res.respuesta);
			data.push(res.pro_indiviso_vivienda);
			// data.push(res.Total_Votos);
		});

		let cont = document.getElementById("resultChart");
		let lineChart = new Chart(cont, {
			type: "pie",
			data: {
				labels: labels,
				datasets: [
					{
						data: data,
						borderColor: ["#d8eefeeb", "#ef4565eb", "#3da9fceb", "#5f6c7beb", "#e53170eb", "#ff8906eb"],
						backgroundColor: ["#d8eefeeb", "#ef4565eb", "#3da9fceb", "#5f6c7beb", "#e53170eb", "#ff8906eb"],
						borderWidth: 2,
						pointHitRadius: 3,
						pointBorderWidth: 3,
					},
				],
			},
			options: {
				legend: {
					display: false,
				},
				title: {
					display: true,
					text: this.state.pregunta,
				},
				// scales: {
				//     yAxes: [{
				//         stacked: true
				//     }]
				// }
			},
		});
	}

	setPage(page) {
		this.setState({page_preguntas: page});
	}
}

export default PreguntasTable;
