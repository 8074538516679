import React, { Component }from 'react';
import Request from "../core/httpClient";
const request = new Request();

class Permissions extends Component  {
    constructor(props){
        super(props); 
        this.state = {
            URL:                    window.location.pathname,
            userInfoEncoded:        JSON.parse( localStorage.getItem('iGateAdmin')  ),
        }
    }
    async getResult(){

        let menusInfoEncoded = localStorage.getItem('menusInfo');

        if(menusInfoEncoded == null){
            //console.log("Obteneindo los menus");
            await this.getMenusEncoded();
        }
        
        menusInfoEncoded = localStorage.getItem('menusInfo');
        // //console.log(menusInfoEncoded);
        let menusRes = await this.decodeMaster(menusInfoEncoded);
        if(menusRes.decoded){
            let menus = menusRes.info;
            for (let i = 0; i < menus.length; i++) {
                let men = menus[i];
                if(men.ruta == this.state.URL){
                    return{
                        lectura: men.lectura == 1 ? true: false,
                        escritura: men.escritura == 1 ? true: false,
                        url: this.state.URL
                    }
                }
                
            }
        }
        
        return {lectura: false, escritura: false, url: this.state.URL}
    }
    async decodeMaster(code){
        let data = {
            token: code
        }

        const response = await request.post("/admin/administracion/get/decodetoken", data);
        if(response.decoded) {
            return response;
        }
        return {decoded: false, info: null};
    }

    async getMenusEncoded(){
        let {id_perfil} = await this.decodeToken();

        const res = await request.post('/profiles/get/menus/perfil',{
            IdFraccionamiento: parseInt(localStorage.getItem('frac')),
            IdPerfil:           id_perfil
        });

        if(res.menusEncoded && res.menus){
            localStorage.setItem('menusInfo',res.menusEncoded);
        }
    }

    async decodeToken(){
        let userInfo = [];
        let data = {
            token: this.state.userInfoEncoded.id
        }

        const response = await request.post("/admin/administracion/get/decodetoken", data);
        if(response.decoded) {
            
            return{
                    id_user:     response.info.id_usuario,
                    id_perfil:  response.info.id_perfil,
            };
        }
        
    }





















    
//    async getResult(){
//         let permiso = await  this.getPermissions();
        
//         if(!permiso.lectura){
//             window.location = '/acceso/denegado';
//         }
        
//         return permiso;

//     }

//     async decodeToken(){
//         let userInfo = [];
//         let data = {
//             token: this.userInfoEncoded.id
//         }

//         const response = await request.post("/admin/administracion/get/decodetoken", data);
//         if(response.decoded) {
//             return {
//                     idUser:     response.info.id_usuario,
//                     userName:   response.info.usuario,
//                     idProfile:  response.info.id_perfil,
//                     idFrac:     response.info.id_fraccionamiento,
//                     idAdmin:    response.info.id_administracion
//             }
//         }
        
//     }

//     async getPermissions(){
//         try {
//             let userInfo = await  this.decodeToken();
//             //console.log(userInfo);
//             let menus    = null;
//             const data = { 
//                 idFraccionamiento:  userInfo.idFrac, 
//                 perfil:             userInfo.idProfile 
//             };
//             let PERMISOS = {lectura: false, escritura: false, url: this.URL};
            
        
//             const response = await request.post("/fraccionamientos/get/menus/parents", data);
            
//             if (response.parents) {
//                 // //console.log(response.parents);
//                 menus = response.parents;
//                 PERMISOS = this.validatePermissions(  menus  );
//             }
            
//             // //console.log(this.URL);
//             const resp = await request.post('/fraccionamientos/get/menus/info',{URL: this.URL});
//             // //console.log(resp.info[0]);

//             if(data.perfil <= 3){
//                 return {lectura: true, escritura: true, url: this.URL, menu: resp.info[0]};
//             }
            
//             return {...PERMISOS, menu: resp.info[0]};
//         } catch (error) {
//             // //console.log(error);
//             return {lectura: false, escritura: false, url: this.URL}
//         }
        
//     }

//     validatePermissions(menusParents){
//         let permisos = {
//             url:        this.URL,
//             lectura:    false,
//             escritura:  false
//         }
//         // //console.log(menusParents);
//         if(Array.isArray(menusParents)){
//             menusParents.map( (parent)=>{
//                parent.recordset.map( (submenu)=>{
//                     if(submenu.ruta === this.URL && submenu.lectura == 1){
                        
//                         permisos = {
//                             url:        submenu.ruta,
//                             lectura:    submenu.lectura     == 1 ? true: false,
//                             escritura:  submenu.escritura   == 1 ? true: false
//                         };
//                     }
//                });
//             });
//         }
//         return permisos;
//     }





        
}
    







export default  Permissions;