import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ReactModal from "react-modal";

import Request from "../../core/httpClient";
import Header from '../header/header';
import DinamicModal from '../dinamicModal/dinamicModal';
import cogoToast from 'cogo-toast';
const request = new Request();


class BudgetModalEddit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            id_presupuesto: this.props.presupuesto.id_presupuesto,
            nombre: this.props.presupuesto.nombre,
            mes: this.props.presupuesto.mes,
            anyo: this.props.presupuesto.anyo,
            mostrar_app:this.props.presupuesto.activo,
            comentarios:this.props.presupuesto.comentarios,
			nivel:this.props.presupuesto.nivel,
            niveles:[{id:1},{id:2},{id:3}],
            meses:[{id:1,nombre:'Enero'},{id:2,nombre:'Febrero'},{id:3,nombre:'Marzo'},{id:4,nombre:'Abril'},
			{id:5,nombre:'Mayo'},{id:6,nombre:'Junio'},{id:7,nombre:'Julio'},{id:8,nombre:'Agosto'},{id:9,nombre:'Septiembre'},{id:10,nombre:'Octubre'},
			{id:11,nombre:'Noviembre'},{id:12,nombre:'Diciembre'}],
        }
    }

    componentDidUpdate(prevProps) {
		if (this.props.presupuesto !== prevProps.presupuesto) {
			
			this.setState({
				id_presupuesto: this.props.presupuesto.id_presupuesto,
            nombre: this.props.presupuesto.nombre,
            mes: this.props.presupuesto.mes,
            anyo: this.props.presupuesto.anyo,
			nivel:this.props.presupuesto.nivel,
            mostrar_app:this.props.presupuesto.activo,
            comentarios:this.props.presupuesto.comentarios,
			});
		}
	}
    render() {

        let niveles = [];
        let meses = [];

        if (Array.isArray(this.state.niveles)) {
			niveles = this.state.niveles;
		}

        if (Array.isArray(this.state.meses)) {
			meses = this.state.meses;
		}

        return (
            <div>
            <DinamicModal
                idModal					=	{'editPresupuesto'}
                sizeModalContainer		=	{'big'}
                title					=	{'Editar presupuesto'} 
                success					=	{this.handleSubmit.bind(this)} 
                showBtnSuccess			=	{true}
                btnTextSuccess			=	{"GUARDAR"}
                showBtnCancel			=	{true}
                btnTextCancel			=	{"CANCELAR"}
                loadingSave				=	{this.state.loadingSave}
                message					=	{this.state.message}
                closeModal              =   {() => this.props.closeModal()}
            >
                <div className="full row align-center">
						<div className="column full">
							<div className="white-space-16" />
							<div className="input-form-content row full align-center ">
					<div className="column label-duo-big">
						<p><b>Nombre:	</b></p>
					</div>
					
					<div className="column full">
					   <input 
							type			=	"text" 
							name			=	"nombre" 
							id				=	"nombre" 
							className		=	"input input-modals" 
							autoComplete = 'off' 
							maxLength		=	"64"
							required 
                            value={this.state.nombre}
							onChange={event => this.setState({ nombre: event.target.value })} 
						/>	
					</div>
					<div className="column label-duo-normal align-end">
					<label className="container-check">
								<input
									type="checkbox"
									id="mostrar_app"
									checked={this.state.mostrar_app === 1 ? true : false}
									name="mostrar_app"
									onChange={(event) => {
										//console.log(this.state.residente);
										this.setState({mostrar_app: event.target.checked === true?1:0});
									}}
								/>
								<span className="checkmark"></span>
							</label>
					</div>
					<div className="column full">
					<h5>
								{" "}
								<b>Mostrar en la App</b>{" "}
							</h5>
					</div>
				</div>
							<div className="white-space-16" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Mes:</b>
									</p>
								</div>
								<div className="column full">
									<select className="input input-modals"  name="mes" value={this.state.mes} onChange 	=	{(event) => this.setState({mes: event.target.value}) }>
										{meses.map(mes => <option key={mes.id} value={mes.id}>{mes.nombre}</option>)}
									</select>
								</div>
								<div className="column label-duo-normal align-end">
					
					           </div>
					           <div className="column full">
					
					           </div>
							</div>
							<div className="white-space-16" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Año:</b>
									</p>
								</div>
								<div className="column full">
									<input 
										type		=	"number" 
										name		=	"year"
										className	=	"input input-modals" 
										placeholder	=	"AAAA" 
										maxLength='4' required minLength='2'
										value		=	{this.state.anyo}
										onChange 	=	{(event) => this.setState({anyo: event.target.value}) }
									/>
								</div>
								<div className="column label-duo-normal align-end">
					
					           </div>
					           <div className="column full">
					
					           </div>
							</div>
							<div className="white-space-16" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Nivel:</b>
									</p>
								</div>
								<div className="column full">
									<select className="input input-modals"  name="nivel" value={this.state.nivel} onChange 	=	{(event) => this.setState({nivel: event.target.value}) }>
										{niveles.map(nivel => <option key={nivel.id} value={nivel.id}>{nivel.id}</option>)}
									</select>
								</div>
								<div className="column label-duo-normal align-end">
					
					           </div>
					           <div className="column full">
					
					           </div>
							</div>
							<div className="white-space-16" />
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p>
										<b>Comentarios:</b>
									</p>
								</div>
								<div className="white-space-8" />
								<div className="align-center row full">
									<div className="column full">
										<textarea className='textarea font-small full' name="comentarios" 
                                        id="comentarios" cols="5" rows="5" value={this.state.comentarios}
										onChange={event => this.setState({ comentarios: event.target.value })}>

										</textarea>
									</div>
								</div>
							</div>
						</div>
					</div>
            </DinamicModal>
        </div>

        );
    }
    handleOpen() {
        this.setState({ showModal: true })
    }
    handleClose() {
        this.setState({ showModal: false })
    }

    async handleSubmit(event) {
        event.preventDefault();
        const form = event.target;

        let data = {
            id_presupuesto: this.state.id_presupuesto,
			nombre:         this.state.nombre,
			mes: 		    this.state.mes,
			anyo: 			this.state.anyo,
			comentarios: 	this.state.comentarios,
			nivel:          this.state.nivel,
			activo:         this.state.mostrar_app

        }
        const response = await request.post("/presupuestos/presupuesto/update", data);
		if (response.updated && !response.empty) {
			this.props.reload();
			cogoToast.success('Presupuesto actualizado.', {
				position: 'bottom-right',
			});
		} else {
			this.setState({
				empty: 		true,
				//message: "NO SE PUDO ACTUALIZAR CONCEPTO"
			});
			cogoToast.error('No se pudo actualizar el presupuesto.', {
				position: 'bottom-right',
			});
		}
    }
}

export default BudgetModalEddit;