import React, { Component } from 'react';
import ModalBorrar from '../modals/modalDelete';
import DinamicModal from '../dinamicModal/dinamicModal';
import DepartamentoForm from './formDepartamento';
import Request from "../../core/httpClient";
import cogoToast from 'cogo-toast';

const request = new Request();


class DepartamentosTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
			departamento: {},
			loadingSave: false
		}
    }

    delete = id => {
        this.handleDelete(id)
    }

    render() {

        let departamentos = []
        if (this.props.departamentos) {
            departamentos = this.props.departamentos
        }
        return (
            <div className="card-table card-owners column" id="card-owners">
                <div className="row">
                    <table className="full">
                        <thead className="sticky-th">
                            <tr className="text-left">
                                <th className="th-options-huge">
									<div className="row">
										Departamento &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'departamento' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'departamento')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'departamento')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'departamento')}></i>
											}
										</div>
									</div>
								</th>
                                <th className="text-center th-options-small">Editar</th>
								<th className="text-center th-options-small">Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {departamentos.slice(((this.props.page - 1) * this.props.pageLength), (this.props.page * this.props.pageLength)).map((departamento, key) => (
								<tr className={this.state.id_departamento_row === departamento.id_personal_departamento && 'row-active'} key={key}>
                                    <td>
                                        {departamento.departamento}
                                    </td>
									<td className="text-center">
                                        <button
											type        =   "button justify-center" 
											onClick     =   {this.openModal.bind(this, 'modal-edit-departamento', departamento)}
                                        >
											<i className="font-small fas fa-pen" />
										</button>
									</td>
									<td className="text-center">
										<button 
											className = "btn-full justify-center align-center btn-mini btn-secondary color-white"
											type = 'button' 
											onClick = {this.openModalDelete.bind(this, departamento)}
											style = {{ padding: '5px' }}
										>
											<i className="font-text fas fa-trash-alt " />
										</button>
									</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
				</div>
				<DinamicModal 
					idModal				=	{'modal-edit-departamento'}
					sizeModalContainer	=	{'small'}
					title				=	{'Editar departamento'} 
					success				=	{this.handleSubmit.bind(this)} 
					showBtnSuccess		=	{true}
					btnTextSuccess		=	{"GUARDAR"}
					showBtnCancel		=	{true}
					btnTextCancel		=	{"CANCELAR"}
					loadingSave			=	{this.state.loadingSave}
					message				=	{this.state.message}
					closeModal			=	{this.closeModal.bind(this)}
				>
					<DepartamentoForm departamento={this.state.departamento} departamentoInput={this.props.departamento} setDepartamento={this.props.setDepartamento} />
				</DinamicModal>
				<ModalBorrar
					id          =   {this.state.id_personal_departamento}
					delete      =   {this.handleDelete.bind(this)}
                />
            </div>
        );
	}

	closeModal(idModal) {
		this.setState({departamento: {}});

		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}

		this.props.setDepartamento('');
	}

	openModal(idModal, data) {
		this.setState({ departamento: data, id_departamento_row: data.id_personal_departamento});
        
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
        }
	}

	openModalDelete(departamento) {
		this.setState({ id_personal_departamento: departamento.id_personal_departamento, id_departamento_row: departamento.id_personal_departamento});
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

	async handleSubmit(event) {
		event.preventDefault();
		this.setState({loadingSave: true});
		const form = event.target;
		const data = {
			id_personal_departamento: this.state.departamento.id_personal_departamento,
			departamento: form.departamento_title.value
		}

		const response = await request.post('/personal/departamentos/update', data);

		if (response.updated) {
            this.props.reload();
            cogoToast.success('Departamento actualizado.', {
                position: 'bottom-right',
            });
        } else {
            cogoToast.error(response.message || 'No se pudo actualizar el departamento.', {
                position: 'bottom-right',
            });
        }

		this.props.setDepartamento('');
		this.setState({loadingSave: false});
	}

	async handleDelete(id) {
		this.setState({loadingSave: true});

        let data = {
            id_personal_departamento: id,
        }

        const response = await request.post('/personal/departamentos/delete', data);

		if (response.delete) {
            cogoToast.success('Departamento eliminado.', {
                position: 'bottom-right',
            });
			this.props.reload();
        } else {
            cogoToast.error('No se pudo eliminar el departamento.', {
                position: 'bottom-right',
            });
        }
		this.props.setDepartamento('');
		this.setState({loadingSave: false});
	}
}

export default DepartamentosTable;