import React, { Component } from 'react';
import ContactoEditarModal from './contactosEditar';
import ModalBorrar from '../modals/modalDelete'
import Request from "../../core/httpClient";
import cogoToast from 'cogo-toast';
import { AsYouType } from 'libphonenumber-js'

const request = new Request();


class ContactosTable extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    reload() { this.props.reload() }
    delete = id => {

        this.handleDelete(id)

    }

    getNumberFormat(cell_phone){

        try {
            /*if(cell_phone.includes('+'))
                return new AsYouType('MXN').input(cell_phone);

			return new AsYouType('MXN').input('+'+cell_phone);*/
			return `******${cell_phone.substring(cell_phone.length - 4)}`

        } catch (error) {
            return null;
        }
    }

    render() {

        let contactos = []
        if (this.props.contactos) {
            contactos = this.props.contactos
        }
        return (
            <div className="card-table card-owners column" id="card-owners">
                <div className="row">
                    <table className="full">
                        <thead className="sticky-th">
                            <tr className="text-left">
                                <th className="th-options-huge">
                                    <div className="row">
                                        Nombre &nbsp;
										<div className="align-center">
                                            {this.props.filterColumn.col === 'nombre' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                <th className="th-options-huge">Teléfono</th>
                                <th className="th-options-huge">
                                    <div className="row">
                                        Vivienda &nbsp;
										<div className="align-center">
                                            {this.props.filterColumn.col === 'numero_registro' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                <th className="text-center th-options-small">Editar</th>
                                <th className="text-center th-options-small">Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {contactos.slice(((this.props.page - 1) * this.props.pageLength), (this.props.page * this.props.pageLength)).map((contacto, key) => (
                                <tr className={this.state.id_contacto_row === contacto.id_contacto ? 'row-active' : ''} key={key}>
                                    <td>
                                        {contacto.nombre}
                                    </td>
                                    <td>
                                        {this.getNumberFormat(contacto.telefono)}
                                    </td>
                                    <td className='text-left'>
                                        {contacto.numero_registro}
                                    </td>
									<td className="text-center">
										<button type="button justify-center" onClick={this.openModalEdit.bind(this, contacto)} >
											<i className="font-small fas fa-pen" />
										</button>
									</td>
                                    <td>
                                        <button className="btn-full justify-center align-center btn-mini btn-secondary color-white" 
                                                type        =   'button' 
                                                onClick     =   {this.openModalDelete.bind(this, contacto)} 
                                                style       =   {{ padding: '5px' }}
                                                disabled    =   {!this.props.escritura} 
                                        >
											<i className="font-text fas fa-trash-alt " />
										</button>
									</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <ContactoEditarModal 
                    reload      =   {this.reload.bind(this)} 
                    contacto    =   {this.state.contacto} 
                    escritura   =   {this.props.escritura}
                    closeModal  =   {() => this.setState({contacto: {}})}
                />
				<ModalBorrar
					id          =   {this.state.id_contacto}
					delete      =   {this.handleDelete.bind(this)}
                />
            </div>
        );
	}

	openModalEdit(contacto) {
		this.setState({contacto, id_contacto_row: contacto.id_contacto});
		let modal = document.getElementById("modal-contacto-editar");
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
        }
        document.getElementsByName('fullName')[0].focus();
	}

	openModalDelete(inq) {
		this.setState({id_contacto: inq.id_contacto, id_contacto_row: inq.id_contacto});
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

    async handleDelete(id) {
        let data = {
            id_contacto: id,
        }
        const response = await request.post("/viviendas/contacto/delete", data);
        if (response && !response.error) {
            if (response.deleted && !response.empty) {
                //this.setState({ message: response.message });
                this.reload()
                cogoToast.success('Contacto eliminado.', {
                    position: 'bottom-right',
                });
            } else {
                this.setState({
                    empty: true,
                    //message: response.message,
                    loading: false
                });
                cogoToast.error('No se pudo eliminar contacto.', {
                    position: 'bottom-right',
                });
            }
        } else {
            cogoToast.error('No se pudo eliminar contacto.', {
                position: 'bottom-right',
            });
        }
    }
}

export default ContactosTable;