import React, { Component } from 'react';
import ReactModal from "react-modal";
import DinamicModal from '../dinamicModal/dinamicModal';
import Request from "../../core/httpClient";
import cogoToast from 'cogo-toast';
import DropdownTreeContainer from '../../pages/DropdownTreeContainer';
import { TreeState } from 'cp-react-tree-table';
import DropdownTreeSelect from 'react-dropdown-tree-select';

const request = new Request();

//metodo para encontrar un nodo dentro del arbol de niveles}
function* findAllNode(child, title) {
	for (const node of child) {
		if (node.label === title)
			yield node;
		if (node.children)
			yield* findAllNode(node.children, title);
	}
}

class ConceptModalEddit extends Component {
	constructor(props) {
		super(props);

		this.state = {
			nombre: this.props.datos.nombre,
			id: this.props.datos.id_concepto,
			presupuesto: this.props.datos.presupuesto,
			cuenta_contable: this.props.datos.cuenta_contable,
			id_parent: this.props.datos.id_parent,
			showModal: false,
			tree_conceptos: this.props.tree_conceptos,
			nivel_text: 'Seleccione un nivel'
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.datos !== prevProps.datos) {

			/*for (const node of findAllNode(this.props.tree_conceptos, "Externos")) {
				node.checked = true; // Modify node
				break; // Get Only first matched node;
			}*/

			this.setState({
				nombre: this.props.datos.nombre,
				id: this.props.datos.id_concepto,
				presupuesto: this.props.datos.presupuesto,
				cuenta_contable: this.props.datos.cuenta_contable,
				id_parent: this.props.datos.id_parent,
				tree_conceptos: this.props.tree_conceptos,
				nivel_text: this.props.datos.nombre
			});
		}
	}


	render() {

		return (
			<div>
				<DinamicModal
					idModal={'editBudget'}
					sizeModalContainer={'small'}
					title={'Editar concepto'}
					success={this.handleSubmit.bind(this)}
					showBtnSuccess={this.props.escritura}
					btnTextSuccess={"ACTUALIZAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.message}
				>
					{this.renderEditBudget()}
				</DinamicModal>

			</div>
		);
	}

	renderEditBudget() {
		return (
			<div className="full row align-center">
				<div className="column full">

					<div className="input-form-content row full align-center ">
						<div className="column label-duo-big">
							<p><b>Nivel:	</b></p>
						</div>
						<div className="column full">
							<DropdownTreeContainer data={this.state.tree_conceptos} mode="radioSelect"
								onChange={this.onChange} texts={{ placeholder: this.state.nivel_text }} />
						</div>
						<div className="column label-duo-normal align-end">

						</div>
						<div className="column full">

						</div>

					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center ">
						<div className="column label-duo-big">
							<p><b>Partida:	</b></p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="conceptName"
								id="conceptname"
								value={this.state.nombre}
								onChange={event => this.setState({ nombre: event.target.value })}
								className="input input-modals"
								autoComplete='off'
								maxLength="64"
								required
							/>
						</div>
						<div className="column label-duo-normal align-end">
							<label className="container-check">
								<input
									type="checkbox"
									id="presupuesto"
									checked={this.state.presupuesto === 1 ? true : false}
									name="presupuesto"
									onChange={(event) => {
										//console.log(this.state.residente);
										this.setState({ presupuesto: event.target.checked === true ? 1 : 0 });
									}}
								/>
								<span className="checkmark"></span>
							</label>
						</div>
						<div className="column full">
							<h5>
								{" "}
								<b>Mostrar en la App</b>{" "}
							</h5>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="white-space-8" />
					<div className="white-space-8" />
					<div className="input-form-content row full align-center ">
						<div className="column label-duo-big">
							<p><b>Cuenta contable:	</b></p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="cuenta_contable"
								id="cuenta_contable"
								value={this.state.cuenta_contable}
								onChange={event => this.setState({ cuenta_contable: event.target.value })}
								className="input input-modals"
								autoComplete='off'
								maxLength="64"
							/>
						</div>
						<div className="column label-duo-normal align-end">

						</div>
						<div className="column full">

						</div>
					</div>
					<div className="white-space-8" />
				</div>
			</div>
		)

	}

	onChange = (currentNode, selectedNodes) => {
		//console.log('onChange::', currentNode, selectedNodes);
		if (selectedNodes[0]) {
			console.log(selectedNodes[0].id);
			if (selectedNodes[0]._depth < 2)
				this.setState({ id_parent: selectedNodes[0].id });
			else {
				this.setState({ id_parent: selectedNodes[0].id_parent });
			}

		} else {
			this.setState({ id_parent: 0 });
		}


	}

	async handleSubmit(event) {
		event.preventDefault();

		let data = {
			id_concepto: this.state.id,
			id_parent: this.state.id_parent,
			nombre: this.state.nombre,
			presupuesto: this.state.presupuesto,
			cuenta_contable: this.state.cuenta_contable

		}
		const response = await request.post("/conceptos/concepto/update", data);
		if (response.updated && !response.empty) {
			this.props.reload();
			cogoToast.success('Concepto actualizado.', {
				position: 'bottom-right',
			});
		} else {
			this.setState({
				empty: true,
				//message: "NO SE PUDO ACTUALIZAR CONCEPTO"
			});
			cogoToast.error('No se pudo actualizar concepto.', {
				position: 'bottom-right',
			});
		}

	}
}

export default ConceptModalEddit;