/**
 *  header-simple.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Componente header simple para no-usuarios
 */

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Header from "../header/header";

class Error extends Component {
    constructor(props){
        super();
        //console.log(window.location);
        let path = window.location.pathname;
        if(path.includes(".")){
            window.location = "https://documentos.appmosphera.com/"+path;
        }

        
    }
    render() {
        
        return (
            <div className="dashboard column">
				<Helmet>
					<title>{process.env.REACT_APP_NAME} - Error</title>
				</Helmet>
				<Header  />
				<div className="row">
                    {/* <div className="container column justify-center"> */}
                        <div id="notfound">
                            <div className="notfound">
                                <div className="notfound-container ">
                                    <div className="notfound-404">
                                        <h3>Oops! Página no encontrada</h3>
                                        <h1><span>4</span><span>0</span><span>4</span></h1>
                                    </div>
                                    <h2>Lo sentimos, pero no se encontró la página que solicitó</h2>
                                </div>
                            </div>
                        </div>
                    {/* </div> */}
                </div>
            </div>
        );
    }
}

export default Error;
