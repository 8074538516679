/**
 *  guests.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de Invitados
 */

import React, {Component} from "react";
import {Consumer} from "../../../context";
import {Helmet} from "react-helmet";
import Header from "../../../components/header/header";
import Sidebar from "../../../components/header/sidebar";
import ReceiverTable from "../../../components/Billing/receiverTable";
import Paginador from "../../../components/paginador/paginador";
import Request from "../../../core/httpClient";
import Controls from "../../../components/controls/controls";
import Permissions from "../../../middlewares/Permissions";
import sortableData from '../../../helpers/sortableDataTable';

const request = new Request();

class ReceiverData extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: {
				idUsuario: 0,
				idPerfil: 0,
				idFraccionamiento: 0,
			},
			showModal: false,
			escritura: false,
			showModal: false,
			ownerState: "0",
			fraccionamientoNombre: "",
			propietarios: [],
			viviendas: [],
			vivienda: {},
			search: false,
			idSub: 0,
			idViv: 0,
			page: 1,
			filterData: [],
			filter: "",
			keys: [],
			valida: false,
			subfraccionamientoNombre: "",
			subfraccionamiento: localStorage.getItem("subfrac") || 0,
			subfraccionamientos: [],
			mesagge: null,
			monto: "",
			sub_fraccionamiento: localStorage.getItem("subfrac") || 0,
			escritura: false,
			pageLength: 20,
			direction: {
				numero_registro: 'desc',
				direccion: 'desc',
				nombre_subfrac: 'desc'
			},
			filterColumn: {
				col: '',
				filt: ''
			},
			submenu: ''
		};
		this.validarPermisos();
	}

	componentDidMount() {
		this.validarPermisos();
	}
	async validarPermisos() {
		let permisos = new Permissions();
		//  permisos.getResult();
		let permisosInfo = await permisos.getResult();
		await this.setState({escritura: permisosInfo.escritura});
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.subfraccionamiento !== prevState.subfraccionamiento) {
			this.getHouses();
		}
	}

	reload() {
		this.getHouses();
		//this.getNombreSubFraccionamiento()
	}

	/*funcion del paginador*/
	setPage(page) {
		this.setState({page});
	}

	async paginatorChange( length ){
        await this.setState({pageLength:length});
        this.setPage(1);
	}
	
	changeFrac() {
		this.getHouses();
		// this.setState({ viviendas: [] })
	}

	async subChange(sub) {
		await this.setState({subfraccionamiento: sub});
	}

	setFilter(filter) {
		this.setState({filter: filter.toLowerCase()});
	}

	filter(viviendas) {
		viviendas = viviendas.filter((viv) => {
			if (viv.numero_registro.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if (
				viv.direccion.toLowerCase().indexOf(this.state.filter) !== -1
			) {
				return true;
			} else {
				return false;
			}
		});
		return viviendas;
	}

	setInfo(state) {}

	sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, this.state.viviendas, this.state.direction[key]);

		this.setState({
			viviendas: items,
			direction: {
				[key]: direction
			},
			filterColumn: {
				col: key,
				filt: direction
			}
		});
	}

	render() {
		let viviendas = [];
		let dataSet;

		if (this.state.viviendas) {
			viviendas = this.state.viviendas;
			dataSet = viviendas;
			//Filters
			viviendas = this.filter(viviendas);
		}

		return (
			<div className="houses column">
				<Helmet>
					<title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
				</Helmet>
				<Header
					reload={this.reload.bind(this)}
					change={this.changeFrac.bind(this)}
					sidebar={true}
					active={"entorno"}
					parent={"admin"}
					panel={"panel4"}
					nav={"catalogos"}
				/>
				<div className="row">
					<div className="column" id="sidebar">
						<Sidebar />
					</div>
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								<Controls
									title={this.state.submenu}
									dataSet={dataSet}
									setFilter={this.setFilter.bind(this)}
									subChange={this.subChange.bind(this)}
									newButton={false}
									excelButton={false}
								/>
								{this.state.loadingViviendas ? (
									<div className="row justify-center">
										<i className="fas fa-spinner fa-spin"></i>
									</div>
								) : (
									<ReceiverTable
										dataHouses={viviendas}
										sub_id={this.state.subfraccionamiento}
										page={this.state.page}
										reload={this.reload.bind(this)}
										subfraccionamientos={this.state.subfraccionamientos}
										sfrac_name={this.state.subfraccionamientoNombre}
										frac_name={this.state.frac_name}
										frac_id={this.state.user.idFraccionamiento}
										escritura={this.state.escritura}
										userName={this.state.user.nombre}
										pageLength={this.state.pageLength}
										sortBy={this.sortableTable.bind(this)}
										filterColumn={this.state.filterColumn}
										subLabel={this.state.subLabel}
									/>
								)}
								<div className="white-space-16" />
								<Paginador
									pages={Math.ceil(viviendas.length / this.state.pageLength)}
									setPage={this.setPage.bind(this)}
									pageLength = {this.state.pageLength} 
									paginatorChange = {this.paginatorChange.bind(this)} 
									showPageLenght = {true}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	async getFraccionamiento() {
		const user = await this.props.context.loadUser();
		const frac = await localStorage.getItem("frac");
		if (user) {
			const idFraccionamiento = frac;
			const data = {IdFraccionamiento: idFraccionamiento};

			const response = await request.post(
				"/admin/administracion/get/fraccionamiento",
				data
			);

			if (response && !response.error) {
				if (response.fraccionamiento && !response.empty) {
					if (
						response.fraccionamiento[0].p_latitude !== "" ||
						response.fraccionamiento[0].p_longitude !== ""
					) {
						// this.setState({ subfraccionamientoNombre: response.fraccionamiento[0].nombre});
						await this.setState({
							frac_name: response.fraccionamiento[0].nombre,
						});
					}
				}
			}
		}
	}

	async updateAddress(sub) {
		await this.setState({direccion: sub.direccion});
	}

	// Da formato al monto
	async montoFormat(event) {
		await this.setState({monto: event.target.value});

		let monto = this.state.monto;
		let mon = monto
			.replace(/\D/g, "")
			.replace(/([0-9])([0-9]{2})$/, "$1 .$2")
			.replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ", ");
		await this.setState({monto: mon});
	}

	componentDidMount() {
		this.load();
		this.getHouses();
		this.getSubFraccionamientos();
		this.getFraccionamiento();
	}

	async load() {
		const submenu = await this.props.context.getSubmenuName();
		if (submenu) {
			this.setState({ submenu });
		}

		const subLabel = window.localStorage.getItem('subLabel');
		if (subLabel) {
			this.setState({ subLabel });
		}
		
		const user = await this.props.context.loadUser();
		if (user) {
			this.setState({
				user: {
					idUsuario: user.id.id_usuario,
					idPerfil: user.id.id_perfil,
					idFraccionamiento: user.id.id_fraccionamiento,
					nombre: user.id.nombre,
				},
			});
		}
	}

	// TRAER LAS VIVIENDAS DEL SUBFRACCIONAMIENTO
	async getHouses() {
		this.setState({viviendas: []});

		let idSub = this.state.subfraccionamiento || 0;
		const idFrac = localStorage.getItem("frac") || 0;
		this.setState({loadingViviendas: true});
		let data = {idSub, idFrac};
		const response = await request.post(
			"/subfraccionamientos/get/viviendas",
			data
		);

		if (response.subTotalViviendas) {
			this.setState({
				viviendas: response.subTotalViviendas,
				filterData: response.subTotalViviendas,
			});
		} else {
			this.setState({
				empty: true,
				message: response.message,
				loadingViviendas: false,
				viviendas: [],
			});
		}

		this.setState({
			error: true,
			message: response.message,
			loadingViviendas: false,
		});
	}

	// traer subfraccionamientos
	async getSubFraccionamientos() {
		const fraccionamiento = localStorage.getItem("frac");
		if (!fraccionamiento) {
			return;
		}
		let data = {Idsub: fraccionamiento, filter: 1};
		const response = await request.post(
			"/admin/administracion/get/subfraccionamientos",
			data
		);
		if (response.subfraccionamiento) {
			this.setState({subfraccionamientos: response.subfraccionamiento});
		} else {
			this.setState({subfraccionamientos: []});
		}
	}

	handleSearch() {
		this.setState({search: !this.state.search});
	}

	closeModal(id) {
		const modal = document.getElementById(id);
		if (!this.state.showModal && modal) {
			modal.classList.add("hide-dinamic-modal");
			modal.classList.remove("show-dinamic-modal");
		}

		this.setState({
			comentarios: "",
			monto: "",
			vacacional: false,
			pIndiviso: "",
			mts: "",
			direccion: "",
			Nregistro: "",
		});
	}
}

export default Consumer(ReceiverData);
