import React, { useState, useRef, useCallback, useMemo } from 'react';

const resetOptions = (resetOptions, min)  => {
    
    const { newOptions, optionsSelected } = setOptionsAndNewSelected(resetOptions);

    if (min && newOptions.length > 0) {

        const enabledIndeces = getMinEnabled(resetOptions, min);

        if (enabledIndeces.length === min) {

            if (optionsSelected.length === 0) {
                enabledIndeces.forEach((index) => {
                    newOptions[index].checked = true;
                });
            }
        }
        else {
            console.warn("Min enabled but not enough enabled options");
        }
    }

    return newOptions;
};

export default function useMuliSelectFormat(optionsInit = [], minInit = undefined) {
  
    const [options, setOptions] = useState(() => resetOptions(optionsInit, minInit));
    const [searchFilter, setSearchFilter] = useState("");

    const minOptionsSelectedRef = useRef(minInit);

    const setFilteredOption = (index) => {

        const checked = !optionsInfo.filteredOptions[index].checked;
        const min = minOptionsSelectedRef.current;

        if(min) {

            const isSelectedGreaterThanMin = getIsSelectedGreaterThanMin(options, min);

            if(!isSelectedGreaterThanMin && !checked) return null;
        }

        optionsInfo.filteredOptions[index].checked = checked;

        const { newOptions, optionsSelected } = setOptionsAndNewSelected(options)//[...options];

        setOptions(newOptions);

        return optionsSelected;
    }; 

    const setAllFilteredOptions = (all) => {

        const filteredArray = optionsInfo.filteredOptions.map(({ id }) => id);

        const filterCheck = searchFilter ?
            (option, checked) => {
                const foundFilteredIndex = filteredArray.indexOf(option.id);

                if (foundFilteredIndex !== -1) {
                    filteredArray.splice(foundFilteredIndex, 1);
                    option.checked = checked;
                }
            }
            :
            (option, checked) => { option.checked = checked; }
        
        const [newOptions, optionsSelected] = options.reduce((info, currentOption) => {
            const { id, disabled } = currentOption;

            if(!disabled) {

                filterCheck(currentOption, all);

                if(currentOption.checked) info[1].push(id);
            }

            info[0].push(currentOption);
            
            return info;
        }, [[], []]);

        const min = minOptionsSelectedRef.current;

        if (!all && min && optionsSelected.length === 0) {

            const enabledIndeces = getMinEnabled(newOptions, min);

            enabledIndeces.forEach((index) => {
                newOptions[index].checked = true;
                optionsSelected.push(newOptions[index].id);
            });
        }

        setOptions(newOptions);

        return optionsSelected;
    };

    const optionsInfo = useMemo(() => getFilteredInfo(options, searchFilter), [options, searchFilter]);

    const onSetOptions = useCallback((newOptions) => 

        setOptions(resetOptions(newOptions, minOptionsSelectedRef.current))

    , []);

    const getFilteredOptionsInfo = useCallback(() =>

        getOptionsInfo(optionsInfo.filteredOptions)
    
    , [optionsInfo.filteredOptions])

    const allFilteredEnabledSelectedOptions = optionsInfo.filterEnabled.length === optionsInfo.filteredEnabledChecked.length;

    return {
        filteredOptions: optionsInfo.filteredOptions,
        allFilteredSelectedOptions: allFilteredEnabledSelectedOptions,
        searchFilter,
        setSearchFilter,
        setOptions: onSetOptions,
        getFilteredOptionsInfo,
        selectOption: setFilteredOption, 
        selectAllOptions: () => setAllFilteredOptions(!allFilteredEnabledSelectedOptions),
    }
}

export const setSelectFormat = (id, option, checked = true, disabled = false) => {

    return { id, option, checked, disabled };
};

export const getSelected = (options) => options.reduce((selected, { id, disabled, checked }) => {

    if(!disabled && checked) selected.push(id);
    return selected;

}, []);

export const getMinEnabled = (options, min) => {

    const enabledIndeces = [];

    for (let index = 0; index < options.length; index++) {
        const { disabled } = options[index];
        
        if(disabled === undefined || disabled === false) {
            enabledIndeces.push(index);
            if(enabledIndeces.length === min) return enabledIndeces;
        }
    }

    return enabledIndeces;
};

const optionFilter = (option, searchFilter) => option.toLowerCase().includes(searchFilter.toLowerCase());

const getFilteredInfo = (options, searchFilter) => options.reduce((info, currentOption) => {

    const { option, checked, disabled } = currentOption;

    const filtered = searchFilter ? optionFilter(option, searchFilter) : true;

    if (filtered) {
        info.filteredOptions.push(currentOption);

        if (!disabled) {

            info.filterEnabled.push(option);
            if (checked) info.filteredEnabledChecked.push(option);
        }
    }

    return info;

}, { filteredOptions: [], filterEnabled: [], filteredEnabledChecked: [] });

const setOptionsAndNewSelected = (options) => options.reduce((info, currentOption) => {
    const { id, checked, disabled } = currentOption;

    if (checked && !disabled) info.optionsSelected.push(id);
    info.newOptions.push({ ...currentOption });

    return info;

}, { newOptions: [], optionsSelected: [] });

const getIsSelectedGreaterThanMin = (options, min) => {

    let selectedCount = 0;

    for (let index = 0; index < options.length; index++) {
        const { checked } = options[index];
        
        if(checked) {
            selectedCount += 1;
            if(selectedCount > min) return true;
        }
    }

    return false;
};



const getOptionsInfo = (options) => options.reduce((info, currentOption) => {
    const { option, checked, disabled } = currentOption;

    if(!disabled) {

        info.optionsEnabled.push(option);
        if(checked) info.optionsChecked.push(option);
    }

    return info;

}, { optionsChecked: [], optionsEnabled: [] });
