import React from "react";

class Paginator extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 1,
			start: 1,
			end: 5,
			pageLength: 7,
			showPageLenght: false,
		};
	}

	async componentDidUpdate(prevProps) {
		if (this.props.pages !== prevProps.pages) {
			this.setState({page: 1});
			this.props.setPage(1);
        }
        
		if (this.props.pageLength !== this.state.pageLength) {
			await this.setState({pageLength: this.props.pageLength});
		}

		if (this.props.showPageLenght !== this.state.showPageLenght) {
			await this.setState({showPageLenght: this.props.showPageLenght});
        } 
	}

	async setPageLength(page) {
		await this.setState({page: page});
		this.props.paginatorChange(page);
	}

	setPage(page) {
		this.props.setPage(page);
		let startPage = 1;
		let endPage = this.props.pages;
		if (page <= 4) {
			// less than 10 total pages so show all
			startPage = 1;
			endPage = 5;
		} else {
			// more than 10 total pages so calculate start and end pages
			if (page + 1 >= this.props.pages) {
				startPage = this.props.pages - 4;
				endPage = this.props.pages;
			} else {
				startPage = page - 2;
				endPage = page + 2;
			}
		}
		this.setState({start: startPage, end: endPage, page: page});
		if (this.props.scrollTop) {
			window.scrollTo(0, 0);
		}
	}

	render() {
		const pages = [];
		let start = this.state.start;
		let end = this.state.end;
		if (end >= this.props.pages) {
			end = this.props.pages;
		}
		for (start; start <= end; start++) {
			pages.push({index: start});
		}
		return (
			<div className="paginator justify-center">
				<div className="row full">
					<div className="column full">
						<div className="white-space-8"></div>
					</div>
					<div className="column full justify-center">
						<div className="row justify-center">
							<span className="prev page" onClick={() => this.setPage(1)}>
								{"<"}
							</span>
							{pages.map((page) => (
								<span
									key={page.index}
									onClick={() => this.setPage(page.index)}
									className={`${this.state.page === page.index ? "active page" : "page"}`}
								>
									{page.index}
								</span>
							))}
							<span className="next page" onClick={() => this.setPage(this.props.pages)}>
								{">"}
							</span>
						</div>
					</div>
					<div className="column full">
						<div className="row full justify-end">
							{this.state.showPageLenght ? (
								<select
									className="input input-select"
									value={this.state.pageLength.toString()}
									onChange={(event) => this.setPageLength(event.target.value)}
								>
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="20">20</option>
									<option value="40">40</option>
									<option value="50">50</option>
								</select>
							) : ""}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Paginator;
