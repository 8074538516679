import React, { Component } from 'react';
import moment from "moment";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import DinamicModal from '../dinamicModal/dinamicModal';
import Request from "../../core/httpClient";
import cogoToast from 'cogo-toast';
const request = new Request();

class ContactoEditarModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idPersona: 			 0,
            INombre: 			'',
            IDireccion: 		'',
            IMunicipio: 		'',
            IEstado: 			'',
            ICp: 				'',
            ITelefono: 			'',
            IPais: 				'',
            IGenero: 			 0,
            IId: 				 0,
            INumId: 			'',
            IVigencia: 			'',
            IFechaIngreso: 		moment().format("YYYY-MM-DD"),
            code: 				'',
            Iviv: 				'',
            IdViv: 				'',
			email: 				'',
			message: 			''
        }
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.props.contacto !== prevProps.contacto) {
			const phone = parsePhoneNumber(`+${this.props.contacto.telefono}`) || '';
			
			this.setState({
				idPersona: 				this.props.contacto.id_persona,
				INombre: 				this.props.contacto.nombre,
				IDireccion: 			this.props.contacto.direccion,
				IMunicipio: 			this.props.contacto.municipio,
				IEstado: 				this.props.contacto.estado,
				ICp: 					this.props.contacto.cp,
				ITelefono: 				phone.number || '',
				IPais: 					this.props.contacto.pais,
				IGenero: 				this.props.contacto.genero,
				IId: 					this.props.contacto.tipo_identificacion,
				INumId: 				this.props.contacto.num_identificacion,
				IVigencia: 				this.props.contacto.vigencia_identificacion,
				IFechaIngreso: 			moment(this.props.contacto.fecha_entrada).format("YYYY-MM-DD"),
				code: 					this.props.contacto.code,
				Iviv: 					this.props.contacto.numero_registro,
				IdViv: 					this.props.contacto.id_vivienda,
				email: 					this.props.contacto.email || '',
				IPrincipal: 			this.props.contacto.principal,
				message: 				'',
				citye:					this.props.contacto.ciudad
			});
			const checkboxPrincipal = document.getElementById('contacto-principal');
			if(checkboxPrincipal) {
				checkboxPrincipal.checked = this.props.contacto.principal;
			}
		}
		
	}

	closeModal() {
        this.setState({ message: ''});
        this.props.closeModal();
	}

    render() {
        return (
			<DinamicModal
				idModal					=	{'modal-contacto-editar'}
				sizeModalContainer		=	{'big'}
				title					=	{'Editar contacto'} 
				success					=	{this.handleSubmit.bind(this)} 
				showBtnSuccess			=	{this.props.escritura}
				btnTextSuccess			=	{"GUARDAR"}
				showBtnCancel			=	{true}
				btnTextCancel			=	{"CANCELAR"}
				loadingSave				=	{this.state.loadingSave}
				message					=	{this.state.message}
				closeModal				=	{this.closeModal.bind(this)}
			>
					<div className="full row align-center">
						<div className="column full">
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p><b>Vivienda:</b></p>
								</div>
								<div className="column full">
									<input 
										className	=	"input input-modals"  
										type		=	"text" 
										name		=	"vivienda" 
										value		=	{this.state.Iviv}
										onChange	=	{event => this.setState({ Iviv: event.target.value })}
										autoComplete = 'off'
										required 
										disabled 
									/>
								</div>
								{/* <div className="column label-duo-normal space">
									<p><b>Principal:</b></p>
								</div>
								<div className="column full">
									<input type="checkbox" name="principal" id="contacto-principal"
										onChange={event => this.setState({ IPrincipal: event.target.checked })}
										className="input input-modals" placeholder="N° de vivienda" maxLength="64" />
								</div> */}
							</div>
                            <div className="white-space-8" />
							<div className="input-form-content row full align-cente">
								<div className="column label-medium">
									<p><b>  Teléfono:	</b></p>
								</div>
								<div className="column full">
									<PhoneInput
										displayInitialValueAsLocalNumber
										name="phoneNumber"
										defaultCountry="MX"
										autoComplete = 'off'
										value={this.state.ITelefono}
										onChange={ value => this.setState({ ITelefono: value, telefonoError: undefined }) }
										error={ this.state.telefonoError }
										disabled={true}
										maxLength = "18"
                                        required
                                        readOnly/>
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p><b>  Nombre completo:	</b></p>
								</div>
								<div className="column full">
									<input 
										className	=	"input input-modals" 
										type		=	"text" 
										name		=	"fullName" 
										value		=	{this.state.INombre}
										onChange	=	{event => this.setState({ INombre: event.target.value })} 
										autoComplete = 'off'
										
                                        required
                                        disabled	 
									/>
								</div>
							</div>
                            <div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
                                <div className="column label-duo-big">
								    <p className="justify-start">
										<b>Género:</b>
									</p>
								</div>
								<div className="column full">
									<select name="sex" id="" value={this.state.IGenero} required
										onChange={event => this.setState({ IGenero: event.target.value })} className="input input-modals">
										<option value="0">Hombre</option>
										<option value="1">Mujer</option>
										<option value="2">No especificado</option>
									</select>
								</div>
								<div className="column label-duo-normal space">
									<p>
										<b>Correo :</b>
									</p>
								</div>
								<div className="column full">
									<input type="email" name="fullName" value={this.state.email}
										onChange={event => this.setState({ email: event.target.value })} 
										className="input input-modals"  
										maxLength="64" 
										autoComplete = 'off'
									/>
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Identificación:</b>
									</p>
								</div>
								<div className="column full">
									<select name="id" id="idEddit" className="input input-modals" value={this.state.IId} onChange={event => this.setState({ IId: event.target.value })}>
										<option value="0">INE</option>
										<option value="1">Pasaporte/Visa</option>
										<option value="2">Curp</option>
										<option value="3">Cartilla Militar</option>
									</select>
								</div>
								<div className="column label-duo-normal space">
									<p  className="justify-start" style={{  paddingLeft: '20px' }}>
										<b>ID:</b>
									</p>
								</div>
								<div className="column full">
									<input type="text" name="numIdEddit" value={this.state.INumId}
											onChange={event => this.setState({ INumId: event.target.value })} 
											className="input input-modals" 
											autoComplete = 'off' 
									/>
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p>
										<b>Dirección:</b>
									</p>
								</div>
								<div className="column full">
									<input 
										className="input input-modals" 
										type="text" 
										name="address" 
										value={this.state.IDireccion}
										onChange={event => this.setState({ IDireccion: event.target.value })} 
										autoComplete = 'off' 
									/>
								</div>
							</div>
							<div className="white-space-8" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Municipio:</b>
									</p>
								</div>
								<div className="column full">
									<input 
										className="input input-modals" 
										type="text"
										name="municipality" 
										value={this.state.IMunicipio}
										onChange={event => this.setState({ IMunicipio: event.target.value })}  
										required 
										autoComplete = 'off'
									/>
								</div>
								<div className="column label-duo-normal space">
								<p  className="justify-start">
										<b>C.P:</b>
									</p>
								</div>
								<div className="column full">
									<input type="text" value={this.state.ICp}
										className="input input-modals"  
										onChange={event => this.setState({ ICp: event.target.value })} 
										name="zip"
										required 
										autoComplete = 'off'
									/>
								</div>
							</div>
							<div className="white-space-8" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Estado:</b>
									</p>
								</div>
								<div className="column full">
									<input type="text" name="state" value={this.state.IEstado}
										className="input input-modals" 
										onChange={event => this.setState({ IEstado: event.target.value })} 
										autoComplete = 'off' 
										required 
									/>
								</div>
								<div className="column label-duo-normal space">
								<p  className="justify-start">
										<b>País:</b>
									</p>
								</div>
								<div className="column full">
									<input type="text" value={this.state.IPais}
										onChange={event => this.setState({ IPais: event.target.value })} 
										name="country" 
										className="input input-modals" 
										autoComplete = 'off' 
										required 
										maxLength="10" 
									/>
								</div>
							</div>


							<div className="white-space-8" />
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
								<p >
										<b>Ciudad:</b>
									</p>
								</div>
								<div className="column full">
									<input 
										className	=	"input input-modals" 
										required
										type		=	"text" 
										value		=	{this.state.citye}
										onChange	=	{event => this.setState({ citye: event.target.value })} 
										name		=	"country" 
										autoComplete = 'off'
									/>
								</div>
							</div>
							<div className="white-space-8" />
							<div className="input-form-content row full align-center">
								<div className="column">
									{/* <input 
										type="checkbox" 
										name="principal" 
										id="contacto-principal"
										onChange={event => this.setState({ IPrincipal: event.target.checked })}
										className="input input-modals" /> */}
										<label className="container-check" >
										<input 
											type		=	"checkbox" 
											name		=	"principal"  
											id 			= 'contacto-principal'
											// checked	    =	{this.state.vacacional == 1 ? (true) : (false)} 
											onChange={event => this.setState({ IPrincipal: event.target.checked })}
										/>
										<span className="checkmark" ></span>
									</label>
								</div>
								<div className="column">
									<p><b>Añadir contacto como principal:</b></p>
								</div>
							</div>
						</div>
					</div>
			</DinamicModal>
        );
    }

    handleOpen() {
        this.setState({ 
			showModal: 			true,
			idPersona: 			this.props.contacto.id_persona,
			INombre: 			this.props.contacto.nombre,
			IDireccion: 		this.props.contacto.direccion,
			IMunicipio: 		this.props.contacto.municipio,
			IEstado: 			this.props.contacto.estado,
			ICp: 				this.props.contacto.cp,
			ITelefono: 			this.props.contacto.telefono,
			IPais: 				this.props.contacto.pais,
			IGenero: 			this.props.contacto.genero,
			IId: 				this.props.contacto.tipo_identificacion,
			INumId: 			this.props.contacto.num_identificacion,
			IVigencia: 			this.props.contacto.vigencia_identificacion,
			IFechaIngreso: 		moment(this.props.contacto.fecha_entrada).format("YYYY-MM-DD"),
			code: 				this.props.contacto.code,
			Iviv: 				this.props.contacto.numero_registro,
			IdViv: 				this.props.contacto.id_vivienda,
			citye:				this.props.contacto.ciudad 
		});
    }

    handleClose() {
        this.setState({showModal: false});
    }

    async handleSubmit(event) {
		event.preventDefault();
		this.setState({loadingSave: true, message: ''});
		let principal = false;
		const checkboxPrincipal = document.getElementById('contacto-principal');
		if(checkboxPrincipal) {
			principal = checkboxPrincipal.checked;
		}
        let data = {
            idVivienda: 			this.state.IdViv,
            Nombre: 				this.state.INombre,
            Direccion: 				this.state.IDireccion,
            Municipio: 				this.state.IMunicipio,
            Estado: 				this.state.IEstado,
            cp: 					this.state.ICp,
            Telefono: 				this.state.ITelefono,
            Pais: 					this.state.IPais,
            Genero: 				this.state.IGenero,
            TipoId: 				this.state.IId,
            NumId: 					this.state.INumId,
            Vigencia: 				this.state.IVigencia,
            Code: 					this.state.code,
            IdPersona: 				this.state.idPersona,
			email: 					this.state.email,
			principal: 				principal,
			idFraccionamiento: 		localStorage.getItem('frac') || 0,
			city:					this.state.citye
		}
        const response = await request.post("/contactos/update", data);
		//this.setState({message: response.message});
        if(response.updated) {
			this.props.reload();
			cogoToast.success('Datos de contacto actualizados.', {
				position: 'bottom-right',
			});
		}else{
			//this.setState({message: "NO SE PUDO EDITAR CONTACTO"});
			cogoToast.error('No se pudieron actualizar los datos de contacto.', {
				position: 'bottom-right',
			});
		}
		this.setState({loadingSave: false});
    }
}

export default ContactoEditarModal;