/**
 *  guests.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Tablas de Visitas del día
 */

import React, { Component } from "react";
import moment from "moment";
import { Consumer } from "../../context";
import Request from "../../core/httpClient";
const request = new Request();

class Invitations extends Component {
  constructor() {
    super();
    this.state = {
      user: { idUsuario: 0, idPerfil: 0, idFraccionamiento: 0 },
      invitacionesDiarias: [],
      invitacionesActivas: [],
      invitacionesTotal: []
    };
  }

  render() {
    return (
      <div className="daily-invitations column">
        <div className="row-responsive">
          <div className="card-table card-table-1 column">
            <div className="title">
              <h4>Invitados del día</h4>
            </div>
            <div className="white-space-8" />
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th className="text-left">Nombre</th>
                    <th className="text-left">Teléfono</th>
                    <th className="text-left">Vivienda</th>
                    <th className="text-center">Hora entrada</th>
                    <th className="text-center">Hora salida</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.invitacionesDiarias.map((invitacion, key) => (
                    <tr key={key}>
                      <td>{invitacion.nombre_invitado}</td>
                      <td>{invitacion.telefono_invitado}</td>
                      <td>{invitacion.numero_registro}</td>
                      <td className="text-center">
                        {invitacion.hora_entrada
                          ? moment(
                              "1900-01-01 " + invitacion.hora_entrada
                            ).format("HH:mm")
                          : "N/A"}
                      </td>
                      <td className="text-center">
                        {invitacion.hora_salida
                          ? moment(
                              "1900-01-01 " + invitacion.hora_entrada
                            ).format("HH:mm")
                          : "N/A"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card-table card-table-2 column">
            <div className="title">
              <h4>Invitados en fraccionamiento</h4>
            </div>
            <div className="table-responsive">
              <table>
                <thead>
                  <tr className="text-left">
                    <th>Nombre</th>
                    <th>Vivienda</th>
                    <th className="text-center">Hora Acceso</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.invitacionesActivas.map((invitacion, key) => (
                    <tr key={key}>
                      <td>
                        <i className="fas fa-circle color-success" /> &nbsp;
                        {invitacion.nombre_invitado}
                      </td>
                      <td>{invitacion.numero_registro}</td>
                      <td className="text-center">
                        {moment("1900-01-01 " + invitacion.hora_entrada).format(
                          "HH:mm"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="white-space-24" />
        <h3>Historico de invitaciones</h3>
        <div className="white-space-24" />
        <div className="filters align-center">
          <p className="font-regular weight-medium color-dark">
            Filtrar desde:
          </p>
          <select name="filter-guests" className="input-select">
            <option value="0">Fecha</option>
          </select>
          <p className="font-regular weight-medium color-dark">&nbsp; Hasta:</p>
          <select name="filter-guests" className="input-select">
            <option value="0">Fecha</option>
          </select>
        </div>
        <div className="white-space-24" />
        <div className="card-table card-table-guests column">
          <div className="title">
            <h4>Todos los invitados</h4>
          </div>
          <div className="white-space-8" />
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  <th className="text-left">Nombre</th>
                  <th className="text-left">Teléfono</th>
                  <th className="text-left">Vivienda</th>

                  <th className="text-center">Hora entrada</th>
                  <th className="text-center">Hora salida</th>
                  <th className="text-left">fecha</th>
                </tr>
              </thead>
              <tbody>
                {this.state.invitacionesTotal.map((invitacion, key) => (
                  <tr key={key}>
                    <td>{invitacion.nombre_invitado}</td>
                    <td>{invitacion.telefono_invitado}</td>
                    <td>{invitacion.numero_registro}</td>

                    <td className="text-center">
                      {invitacion.hora_entrada
                        ? moment(
                            "1900-01-01 " + invitacion.hora_entrada
                          ).format("HH:mm")
                        : "N/A"}
                    </td>
                    <td className="text-center">
                      {invitacion.hora_salida
                        ? moment(
                            "1900-01-01 " + invitacion.hora_entrada
                          ).format("HH:mm")
                        : "N/A"}
                    </td>
                    <td>{moment(invitacion.fecha, ['YYYY/MM/DD', 'DD/MM/YYYY']).format("DD/MM/YYYY")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    const user = await this.props.context.loadUser();
    if (user) {
      this.setState({
        user: {
          idUsuario: user.id.id_usuario,
          idPerfil: user.id.id_perfil,
          idFraccionamiento: user.id.id_fraccionamiento
        }
      });
      this.getDiarias();
      this.getActivos();
      this.getInvitacionesTotal();
    }
  }
  // traer invitaciones diarias
  async getDiarias() {
    this.setState({ loadingDiarios: true });
    let data = {
      idUsuario: this.state.user.idUsuario,
      idFraccionamiento: this.state.user.idFraccionamiento,
      fecha: moment(Date.now()).format("YYYY-MM-DD")
    };
    const response = await request.post("/invitaciones/get/hoy", data);
    if (response && !response.error) {
      if (response.invitacionesDiarias && !response.empty) {
        this.setState({
          invitacionesDiarias: response.invitacionesDiarias,
          loadingDiarios: false
        });
      } else {
        this.setState({
          empty: true,
          message: response.message,
          loadingDiarios: false
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message,
        loadingDiarios: false
      });
    }
  }
  // traer invitaciones activas
  async getActivos() {
    this.setState({ loadingActivos: true });
    let data = {
      idUsuario: this.state.user.idUsuario,
      idFraccionamiento: this.state.user.idFraccionamiento,
      fechaDia: moment(Date.now()).format("YYYY-MM-DD")
    };
    const response = await request.post("/invitaciones/get/activas", data);
    if (response && !response.error) {
      if (response.invitacionesActivas && !response.empty) {
        this.setState({
          invitacionesActivas: response.invitacionesActivas,
          loadingActivos: false
        });
      } else {
        this.setState({
          empty: true,
          message: response.message,
          loadingActivos: false
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message,
        loadingActivos: false
      });
    }
  }

  // Traer total de invitaciones para mostrar en historico de invitaciones
  async getInvitacionesTotal() {
    let data = {
      IdFraccionamiento: this.state.user.idFraccionamiento
    };
    const response = await request.post("/invitaciones/get/todos", data);
    // ////console.log("response: ", response);
    if (response && !response.error) {
      if (response.invitacionesTotal && !response.empty) {
        this.setState({
          invitacionesTotal: response.invitacionesTotal
          // loadingDiarios: false
        });
        // ////console.log(this.state.invitacionesTotal);
      } else {
        this.setState({
          empty: true,
          message: response.message
          // loadingDiarios: false
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message
        // loadingDiarios: false
      });
    }
  }
}

export default Consumer(Invitations);
