import React, { Component } from 'react';
import { Consumer } from "../../context";
import moment from "moment";
import DinamicModal from '../../components/dinamicModal/dinamicModal';
import ReactModal from "react-modal"
import Request from "../../core/httpClient";
import cogoToast from 'cogo-toast';
const request = new Request();

class NotificationsSend extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tipo:               '',
			texto:              '',
			textoviejo:			'',
            showModal:          false,
            id:                 this.props.id,
            user: {},
            notificacionestipe: [],
            subfraccionamiento: localStorage.getItem('subfrac') || 0,
			subIdSelect:        0,
			subfracsList:       [],
			vivIdSelect:		0,
			viviendasList:		[]
        }
	}

	componentDidUpdate(prevProps) {
		if(this.props.id !== prevProps.id) {
			
			this.getNotificacion();
		}else{
			// this.setState({texto:this.state.textoviejo});
		}
		
    }
    
    render() { 
        
        return (
            <div>
				<DinamicModal 
					idModal                 =   {'sendNotificationsDef'}
					sizeModalContainer      =   {'small'}
					title                   =   {'Enviar notificación'} 
					success                 =   {this.handleSubmit.bind(this)} 
					showBtnSuccess          =   {this.props.escritura}
					btnTextSuccess          =   {"ENVIAR"}
					showBtnCancel           =   {true}
					btnTextCancel           =   {"CANCELAR"}
					loadingSave             =   {this.state.loadingSave}
					message                 =   {this.state.message}
				>
				{this.renderSendNotification()}
				</DinamicModal>
            </div>
        );
    }
    

    componentDidMount() {
		this.load();
		this.getTipeNotification();
        this.getNotificacion();
        this.getSubFraccionamientos();
		this.getViviendas(this.state.subIdSelect);
	}
	
	renderSendNotification() {
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>Tipo de notificación: </b></p>
						</div>
						<div className="column full">
							<select 
									name        =   "tipo" 
									id          =   "" 
									className   =   "input input-modals" 
									value       =   {this.state.tipo} 
									onChange    =   {event => this.setState({ tipo: event.target.value })}
									disabled	
								>
								{
									this.state.notificacionestipe.map((value, key) => (
									<option key={key} value={value.Descripcion}>{value.Descripcion}</option>
								))}
							</select>
						</div>
						
					</div>
					<div className="white-space-16"/>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b> {this.props.subLabel}:	</b></p>
						</div>

						<div className="column full">
							<select name="subfraccionamientoId" id="subfraccionamientoId" className="input input-modals"
								value={this.state.subIdSelect}
								onChange={this.subfracChange.bind(this)}
							>
								<option value="0">TODOS</option>
								{  
									this.props.subfracsList.map((subfraccionamiento, key) => (
									<option value={subfraccionamiento.id_subfraccionamiento} key={key}>{subfraccionamiento.nombre}</option>)) 
								}
							</select> 
						</div>
					</div>
					<div className="white-space-8"/>
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b> Vivienda:	</b></p>
						</div>

						<div className="column full">
							{this.state.loadingViviendas? 
								<div className="row justify-center align-center">
									<i className="fas fa-spinner fa-spin"></i>
								</div>
								:
								<select name="viviendaId" id="viviendaId" className="input input-modals"
									value={this.state.vivIdSelect}
									onChange={this.viviendaChange.bind(this)}
								>
									<option value="0">TODAS</option>
									{  
										this.state.viviendasList.map((vivienda, key) => (
										<option value={vivienda.id_vivienda} key={key}>{vivienda.numero_registro}</option>)) 
									}
								</select>
							}
						</div>
					</div>
					<div className="white-space-16"></div>
					<p>
						<b>Mensaje:</b>
					</p>
					<div className="white-space-4" />
					<textarea 
						className		=	"textarea input input-modals" 
						value			=	{this.state.texto} 
						onChange		=	{event => this.setState({ texto: event.target.value })} 
						name			=	"descriptionMessage" 
						cols			=	"50" 
						rows			=	"8" 
						maxLength		=	"1500"
						minLength		=	"20" 
						// disabled		=	"true"
					></textarea>
				</div>
			</div>
		)
	}

    closeModal(idModal) {
        this.setState({message:''});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
	}

    subfracChange(event) {
		let idSub = event.target.value;
		this.setState({subIdSelect: idSub});
		this.getViviendas(idSub);
	}

	viviendaChange(event) {
		let idViv = event.target.value;
		this.setState({vivIdSelect: idViv});
	}

    async getViviendas(idSubfrac) {
		const frac = localStorage.getItem('frac');
		this.setState({loadingViviendas: true});
		const response = await request.post("/viviendas/getViviendaBySubFraccionamiento", 
		{	id_fraccionamiento: frac,
			id_subfraccionamiento: idSubfrac}
		);
		if(Array.isArray(response.viviendas)) {
			
			this.setState({viviendasList: response.viviendas});
		} else {
			this.setState({viviendasList: []});
		}
		this.setState({loadingViviendas: false});
	}
	
	async getSubFraccionamientos() {
		const fraccionamiento = localStorage.getItem('frac');
		if(!fraccionamiento) {
			return;
		}
        let data = { Idsub: fraccionamiento }
        const response = await request.post("/admin/administracion/get/subfraccionamientos", data);
        if (response && !response.error) {
            if (response.subfraccionamiento && !response.empty) {
                this.setState({
					subfracsList: response.subfraccionamiento,
					subIdSelect: this.state.subfraccionamiento ?  this.state.subfraccionamiento[0].id_subfraccionamiento : ''
                });
            } else {
                this.setState({
                    empty: true,
                    // message: response.message,
                    subfracsList: []
                });
            }
        } else {
            this.setState({
                error: true,
                // message: response.message
            });
		}
		this.setState({loadingSub: false});
    }

    async load() {
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({
                user: {
                    idUsuario: user.id.id_usuario,
                    idPerfil: user.id.id_perfil,
                }
			});
			this.getNotificacion()
			this.getTipeNotification()
        }
	}

    handleOpen() {
        this.setState({ showModal: !this.state.showModal })
        this.getNotificacion()
        this.getTipeNotification()

    }

    async getTipeNotification() {
        this.setState({ notificacionestipe: [], loadingType: true });
        let data = {
            id: parseInt(localStorage.getItem('frac'))
        }
        const response = await request.post("/notificaciones/get/tipe", data);

        if (response && !response.error) {
            if (response.notifications && !response.empty) {
                this.setState({
                    notificacionestipe: response.notifications
                });
            }
		}
		this.setState({loadingType: false});
    }

    async getNotificacion() {
		
        let data = {
            idNotificacion: this.props.id
        }
        const response = await request.post("/notificaciones/get/notification", data)

        if (response && !response.error) {
            if (response.notifications && !response.empty) {
                this.setState({

                    tipo: response.notifications[0].tipo,
					texto: response.notifications[0].texto,
                    textoviejo: response.notifications[0].texto

                });
            } else {
                this.setState({ empty: true });
            }
        } else {
            this.setState({ error: true });
        }


    }

    async handleSubmit(event) {
		this.setState({loadingSave: true});
        event.preventDefault();
        let data = {
			idFraccionamiento:      localStorage.getItem('frac') || 0,
			idSub:                  this.state.subIdSelect || null,
			idViv:					this.state.vivIdSelect || null,
            texto:                  this.state.texto,
            idUser:                 this.state.user.idUsuario,
            fecha:                  moment(new Date()).format("YYYY-MM-DD"),
            tipo:                   this.state.tipo
		}

        const response = await request.post("/notificaciones/send/notification", data)

        
        if (response.created) {
            this.setState({
                reload: true,
                message: "",
                loading: false
            });
			this.closeModal("sendNotificationsDef");
			cogoToast.success('Notificación enviada.', {
				position: 'bottom-right',
			});
        } else {
			//this.setState({ empty: true, message: "NO SE PUDO ENVIAR NOTIFICACIÓN" });
			cogoToast.error('No se pudo enviar notificación.', {
				position: 'bottom-right',
			});
        }
        
		this.setState({loadingSave: false});
    }
}

export default Consumer(NotificationsSend);