import React from 'react';
import Request from '../../core/httpClient';
import cogoToast from 'cogo-toast';
import request from 'superagent';

const req = new Request();

let sendData = {};
let removeAll = false;
let id_delete = 0;
let url_delete = '';
let usuario = {};

class Dropzone extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
		};
		this.imgDropzone = null;
    }

	componentDidMount() {
		//DROPZONE
		this.initDropzone();
	}

	componentDidUpdate(prevProps) {
		if (this.props.id !== prevProps.id) {
			// Remover todas las imagenes
			removeAll = true;
			this.imgDropzone.removeAllFiles( true );

			sendData = this.props.data;
			// reiniciar removedor
			removeAll = false;
		}

		if (this.props.files !== prevProps.files) {
			this.reloadPreviews();
		}

		if (this.props.id_delete !== prevProps.id_delete) {
			id_delete = this.props.id_delete;
			url_delete = this.props.url_delete;
			usuario = this.props.usuario;
		}
	}

	reloadPreviews() {
		// Remover todas las imagenes
		removeAll = true;
		this.imgDropzone.removeAllFiles( true );
		// Cargar imagenes previas
		if (Array.isArray(this.props.files)) {
			this.props.files.forEach(file => {
				const fileLoaded = {
					name: file.name,
					signedUrl: file.url,
					size: this.getFileSize(file.url)
				};
				if (this.imgDropzone) {
					const mockFile = { name: fileLoaded.name, status: 'success' };
					this.imgDropzone.emit( "addedfile", mockFile );
					this.imgDropzone.emit( "thumbnail", mockFile, fileLoaded.signedUrl );
					this.imgDropzone.emit('success', mockFile);
					this.imgDropzone.emit('complete', mockFile);
					this.imgDropzone.files.push( mockFile );
				}
			});
		}
		// reiniciar removedor
		removeAll = false;
	}

	async getFileSize(url) {
		let size = 281;
		return size;
	}

	initDropzone() {
		const reloadPreviews = this.reloadPreviews.bind(this);
		this.imgDropzone = new window.Dropzone("div#dropzone-element", {
			dictDefaultMessage: 'Arrastre sus imágenes aquí, o de Click para subirlas.',
			url: this.props.url,
			headers: {'igate_key': '5fcae9a7be1dfa53da44c353492bc73d'},
			paramName: "file",
			maxFilesize: 1, // MB
			//maxFiles: 10,
			addRemoveLinks: true,
			acceptedFiles: "image/*",
			removedfile: async function(file) {
				var _ref;
				async function removeFile() {
					if (file.status === 200 || file.status === 'success') {
						file.previewElement.remove();
						const data = {
							id_delete,
							file: file.name,
							usuario
						};
						const res = await req.post(url_delete, data);
						//console.log(res);
						if (res.deleted) {
							cogoToast.success('Imagen eliminada.', {  position: 'bottom-right' });
						} else {
							cogoToast.error('No se pudo eliminar imagen.', {  position: 'bottom-right' });
							reloadPreviews();
						}
					} else {
						return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
					}
				}

				if (removeAll) {
					file.previewElement.remove();
				} else {
					const result = await removeFile();
					//console.log(result);
					return false;
				}
			},
			init: function() {
				this.on("sending", function(file, xhr, formData) {
					const keys = Object.keys(sendData);
					if (Array.isArray(keys)) {
						keys.forEach(key => {
							formData.append(key, sendData[key]);
						});
					}
					//console.log(sendData);
				});
			}
		});
	}

    render() {
        return (
            <div id="dropzone-element" className="dropzone"></div>
        )
	}
}//end class

export default Dropzone;