import React, { useState, useEffect } from 'react';
import Resizer from 'react-image-file-resizer';
import moment from 'moment-timezone';

const PaqueteForm = ({ viviendas, paquete }) => {

	const [id_vivienda, setVivienda] = useState(0);
	const [foto_paquete_url, setfoto_paquete_url] = useState(null);
	const [foto_entrega_url, setfoto_entrega_url] = useState(null);

	useEffect(() => {

		(async () => {

			if (paquete) {

				await colocaValor('entrega_empresa', paquete.empresa_paqueteria);
				await colocaValor('entrega_nombre_residente', paquete.nombre_residente);
				await colocaValor('entrega_nombre_recibo', paquete.nombre_recibo);
				await colocaValor('entrega_fecha_llegada', moment(paquete.fecha_llegada).format('DD/MM/YYYY [-] hh:mm A'));
				await colocaValor('entrega_comentarios', paquete.comentarios);

				setfoto_paquete_url(paquete.foto_paquete_url);

				await colocaValor('entrega-unidad', paquete.numero_registro);

				setVivienda(paquete.id_vivienda);

				if (paquete.fecha_entrega) {
					await colocaValor('entrega_fecha_entrega', moment(paquete.fecha_entrega).format('DD/MM/YYYY [-] hh:mm A'));
				};

				if (paquete.nombre_recibo) {
					await colocaValor('entrega_nombre_recibe', paquete.nombre_recibo);
				};

				if (paquete.foto_entrega_url) {
					setfoto_entrega_url(paquete.foto_entrega_url);
				};
			};
		})();

	}, [paquete]);

	async function colocaValor(id, valor) {

		const input = document.getElementById(id);

		if (input) {
			input.value = valor;
		};

	};

	async function changePaqueteImagen(event) {
		let file = event.target;
		if (file) {
			if (file.files[0]) {
				Resizer.imageFileResizer(
					file.files[0],
					400,//337
					200,//180
					'JPEG',
					30,
					0,
					async uri => {
						await setfoto_entrega_url(uri);
					},
					'base64'
				);
			}
		}
	};

	return (
		<div className="row justify-between full">
			<div className="column full">
				<div className="input-form-content row full align-center">
					<div className="column label-medium">
						<p><b>Vivienda:</b></p>
					</div>
					<input
						type="text"
						className="input input-modals"
						autoComplete="off"
						placeholder="Vivienda"
						id="entrega-unidad"
						name="entrega-unidad"
						list="lista-viviendas"
						disabled
						onChange={(event) => {
							let valor = event.target.value;
							let viviendaSelect = viviendas.filter((viv) => viv.numero_registro === valor);

							if (viviendaSelect[0]) {
								setVivienda(viviendaSelect[0].id_vivienda);
							} else {
								setVivienda(0);
							}
						}}
					/>
					<datalist id="lista-viviendas">
						<select className="input input-modals" name="conceptos">
							{viviendas.map((vivienda, key) => (
								<option value={vivienda.numero_registro} key={key}></option>
							))}
						</select>
					</datalist>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
					<div className="column label-medium">
						<p><b>Empresa:</b></p>
					</div>
					<div className="column full">
						<input
							className="input input-modals"
							type="text"
							name="entrega_empresa"
							id="entrega_empresa"
							autoComplete='off'
							required
							disabled
						/>
					</div>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
					<div className="column label-medium">
						<p><b>Destinatario:</b></p>
					</div>
					<div className="column full">
						<input
							className="input input-modals"
							type="text"
							name="entrega_nombre_residente"
							id="entrega_nombre_residente"
							autoComplete='off'
							required
							disabled
						/>
					</div>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
					<div className="column label-medium">
						<p>
							<b>Fecha llegada:</b>
						</p>
					</div>
					<div className="column full">
						<input
							id="entrega_fecha_llegada"
							name="entrega_fecha_llegada"
							className="input input-modals"
							type="text"
							autoComplete="off"
							required
							disabled
						/>
					</div>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
					<div className="column label-medium">
						<p><b>Nombre recibe:</b></p>
					</div>
					<div className="column full">
						<input
							id="entrega_nombre_recibe"
							name="entrega_nombre_recibe"
							className="input input-modals"
							type="text"
							autoComplete='off'
							disabled={paquete.fecha_entrega}
							required
						/>
					</div>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
					<div className="column label-medium">
						<p>
							<b>Fecha entrega:</b>
						</p>
					</div>
					<div className="column full">
						<input
							id="entrega_fecha_entrega"
							name="entrega_fecha_entrega"
							className="input input-modals"
							type={paquete.fecha_entrega ? "text" : "date"}
							autoComplete="off"
							disabled={paquete.fecha_entrega}
							required
						/>
					</div>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full">
					<div className="column label-medium">
						<p>
							<b>Comentarios:</b>
						</p>
					</div>
					<div className="column full">
						<textarea
							id="entrega_comentarios"
							name="comentarios"
							cols="30"
							rows="3"
							maxLength="500"
							className='textarea font-small full'
							disabled={paquete.fecha_entrega}
						>
						</textarea>
					</div>
				</div>
			</div>
			<div className="column">
				<div className="responsive-img column space align-start">
					<div className="img-width">
						<div className="img-personal-perfil container-img">
							<img src={foto_paquete_url ? foto_paquete_url : require('../../img/empty_image.png')} className="img-duo-preview" id="previewArea" alt="" />
						</div>
					</div>
				</div>
				<div className="white-space-8"></div>
				<div className="responsive-img column space align-start">
					<div className="img-width">
						<div className="img-personal-perfil container-img">
							<img src={foto_entrega_url ? foto_entrega_url : require('../../img/empty_image.png')} className="img-duo-preview" id="previewArea" alt="" />
							{!paquete.fecha_entrega &&
								<div className="middle-overlay">
									<label className="btn-upload-image column align-center justify-center">
										<i className="fas fa-file-upload"></i>
										<input
											type="file"
											id="entrega_foto_entrega"
											name="entrega_foto_entrega"
											accept="image/*"
											onChange={changePaqueteImagen.bind(this)} />
										<div className="white-space-8"></div>
										<p className="color-white">Subir imagen</p>
									</label>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PaqueteForm;