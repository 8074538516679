import React, { Component } from 'react';
import { Consumer } from "../context";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import Sidebar from "../components/header/sidebar";
import SubFRaccTable from "../components/subfraccionamiento/subfractable";
import Paginador from "../components/paginador/paginador";
import Request from "../core/httpClient";
import Controls from '../components/controls/controls';
import DinamicModal from '../components/dinamicModal/dinamicModal';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import Permissions from '../middlewares/Permissions';
import cogoToast from 'cogo-toast';
import sortableData from '../helpers/sortableDataTable';
import pluralize from 'pluralize';

const request = new Request();

const initForm = {
	nombre: '',
	telefono: '',
	indiviso: 0,
	direccion: ''
}

class SubFRaccPage extends Component {
	constructor(props) {
		super(props);
		this.focusAllValue = this.focusAllValue.bind(this);
		this.state = {
			user: {},
			subfraccionamientos: [],
			showModal: false,
			hidden: false,
			loadingSave: false,
			page: 1,
			filterData: [],
			filter: '',
			keys: [],
			mesagge: null,
			nombre: '',
			telefono: '',
			direccion: '',
			focus: true,
			escritura: false,
			lectura: false,
			indiviso: 0,
			logo: require("../img/logo-AppMosphera-r.png"),
			pageLength: 20,
			direction: {},
			filterColumn: {},
			submenu: '',
			subLabel: ''
		};
	};

	async componentDidMount() {
		await this.validarPermisos();
		await this.load()
		await this.getSubFraccionamientos();
	};

	async validarPermisos() {
		let permisos = new Permissions();
		//  permisos.getResult();
		let permisosInfo = await permisos.getResult()
		this.setState({ escritura: permisosInfo.escritura, lectura: permisosInfo.lectura });
	}

	openModal(idModal) {
		this.setState({ mesagge: null, ...initForm });
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
		let name = document.getElementById('namef');
		if (name) name.focus();
	}

	/*funcion del paginador*/
	setPage(page) {
		this.setState({ page });
	}

	async reload() {
		await this.getSubFraccionamientos()
	};

	save = async data => {
		await this.edditSub(data);
	};

	subChange(sub) {
		this.setState({ subfraccionamiento: sub });
	};

	paginatorChange(length) {
		this.setState({ pageLength: length });
		this.setPage(1);
	};

	filterGeneric(e) {
		e.preventDefault();
		const form = e.target;

		const param1 = form.filterIncidents.value;
		const param2 = form.textFilter.value;

		let filterData = this.state.filterData;

		if (param1 === "todo") {
			this.setState({ subfraccionamientos: this.state.filterData });
			form.textFilter.value = "";
		} else {
			const key = param1;
			filterData = filterData.filter(product => product[key].toLowerCase().indexOf(param2.toLowerCase()) !== -1);
			this.setState({ subfraccionamientos: filterData });
		};
	};

	nuevoSubfraccionamiento() {
		this.setState({ showModal: true })
	}

	setFilter(filter) {
		this.setState({ filter });
	}

	filter(subfraccionamientos) {
		subfraccionamientos = subfraccionamientos.filter(sub => {
			if (sub.nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if (sub.direccion.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if (sub.telefono.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		})
		return subfraccionamientos;
	}

	sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, this.state.subfraccionamientos, this.state.direction[key]);

		this.setState({
			subfraccionamientos: items,
			direction: {
				[key]: direction
			},
			filterColumn: {
				col: key,
				filt: direction
			}
		});
	};

	async load() {
		const user = await this.props.context.loadUser();
		if (user) {
			this.setState({
				user: {
					idUsuario: user.id.id_usuario,
					idPerfil: user.id.id_perfil,
					idFraccionamiento: user.id.id_fraccionamiento,
					idAdministracion: user.id.id_administracion
				}
			});
		};

		let submenu = await this.props.context.getSubmenuName();
		if (submenu) {
			this.setState({ submenu });

			let singular = pluralize.isPlural(submenu);
			if (singular) {
				submenu = pluralize.singular(submenu);
			};

			window.localStorage.setItem('subLabel', submenu);
			this.setState({ subLabel: submenu.toLowerCase() });
		};
	};

	handleOpen() {
		this.setState({ showModal: true })
	};

	handleClose() {
		this.setState({ showModal: false })
	};

	async getSubFraccionamientos() {
		try {
			this.setState({ loadingSub: true });
			const fraccionamiento = localStorage.getItem('frac');
			if (!fraccionamiento) {
				return;
			};

			const data = { Idsub: fraccionamiento };
			const response = await request.post("/admin/administracion/get/subfraccionamientos", data);

			if (response.subfraccionamiento) {
				this.setState({
					subfraccionamientos: response.subfraccionamiento,
					filterData: response.subfraccionamiento
				});

			} else {
				this.setState({
					empty: true,
					mesagge: response.message,
					subfraccionamientos: []
				});
			};

			this.setState({ loadingSub: false });
		} catch (error) {
			console.log(error);
		};
	};

	// guardar subfraccionamiento
	async handleSubmit(event) {
		event.preventDefault();
		try {
			this.setState({ loadingSave: true });
			const form = event.target;

			if (!isValidPhoneNumber(this.state.telefono)) {
				cogoToast.warn('Debes ingresar un número válido', {
				    position: 'bottom-right',
			    });
				this.setState({loadingSave: false });
				return;
			};

			const data = {
				IdFraccionamiento: localStorage.getItem('frac'),
				Nombre: form.name.value,
				Direccion: form.address.value,
				Telefono: this.state.telefono.replace('+', ''),
				indiviso: parseFloat(this.state.indiviso)
			};

			const response = await request.post("/subfraccionamientos/create/subfraccionamiento", data);

			if (response && !response.error) {
				if (response.created && !response.empty) {
					this.setState({
						reload: true,
						mesagge: "NUEVO FRACCIONAMIENTO AGREGADO",
						loading: false,
						showModal: false
					});
					form.name.value = "";
					form.address.value = "";
					form.phone.value = "";

					await this.getSubFraccionamientos();
					cogoToast.success('Nuevo subfraccionamiento agregado.', {
						position: 'bottom-right'
					});
				} else {
					this.setState({
						empty: true,
						loading: false,
						showModal: true
					});
					cogoToast.error('No se pudo crear un nuevo subfraccionamiento.', {
						position: 'bottom-right'
					});
				};
			} else {
				this.setState({
					error: true,
					loading: false,
					showModal: true
				});
				cogoToast.error('No se pudo crear un nuevo subfraccionamiento.', {
					position: 'bottom-right'
				});
			};

			const modal = document.getElementById("newSubfrac");
			if (this.state.showModal && modal) {
				modal.classList.remove('hide-dinamic-modal');
				modal.classList.add('show-dinamic-modal');
			} else {
				modal.classList.add('hide-dinamic-modal');
				modal.classList.remove('show-dinamic-modal');
			};

			this.setState({ loadingSave: false });

		} catch (error) {
			console.log(error);
		};
	};

	async edditSub(data) {
		try {

			const response = await request.post("/subfraccionamientos/eddit/subfraccionamiento", data);

			if (response && !response.error) {
				await this.getSubFraccionamientos();
			};

			this.setState({ loadingSave: false });
		} catch (error) {
			console.log(error);
		};
	};

	focusAllValue(event) {
		const element = event.currentTarget;
		if (document.activeElement === element) {
			element.select();
		};
	};

	render() {

		let subfraccionamientos = [];

		if (Array.isArray(this.state.subfraccionamientos)) {
			subfraccionamientos = this.state.subfraccionamientos;
			subfraccionamientos = this.filter(subfraccionamientos);
		};

		return (
			<div className="owners column">
				<Helmet>
					<title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
				</Helmet>
				<Header
					reload={this.reload.bind(this)}
					sidebar={true}
					active={'subfraccionamientos'}
					parent={'admin'}
					panel={'panel1'}
					nav={"catalogos"}
				></Header>
				<div className="row">
					<div className="column" id="sidebar">
						<Sidebar></Sidebar>
					</div>
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								{this.state.lectura ?
									<React.Fragment>
										<Controls
											title={this.state.submenu}
											nuevo={this.openModal.bind(this, "newSubfrac")}
											dataSet={subfraccionamientos}
											setFilter={this.setFilter.bind(this)}
											subChange={this.subChange.bind(this)}
											selectSub={false}
											newButton={this.state.escritura}
										/>
										{this.state.loadingSub ?
											<div className="row justify-center">
												<i className="fas fa-spinner fa-spin"></i>
											</div>
											:
											<SubFRaccTable
												sub={subfraccionamientos}
												page={this.state.page}
												getSubFraccionamientos={this.getSubFraccionamientos.bind(this)}
												reload={this.reload.bind(this)}
												escritura={this.state.escritura}
												pageLength={this.state.pageLength}
												sortBy={this.sortableTable.bind(this)}
												filterColumn={this.state.filterColumn}
												subLabel={this.state.subLabel}
											>
											</SubFRaccTable>
										}
										<div className="white-space-16"></div>
										<Paginador
											pages={Math.ceil(subfraccionamientos.length / this.state.pageLength)}
											setPage={this.setPage.bind(this)}
											pageLength={this.state.pageLength}
											paginatorChange={this.paginatorChange.bind(this)}
											showPageLenght={true}
										/>

									</React.Fragment> :
									null
								}
							</div>
						</div>
					</div>
				</div>

				<DinamicModal
					enforceFocus={true}
					idModal={'newSubfrac'}
					sizeModalContainer={'small'}
					title={`Añadir ${this.state.subLabel}`}
					success={this.handleSubmit.bind(this)}
					showBtnSuccess={true}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.mesagge}
				>
					{this.renderNewSubfrac()}
				</DinamicModal>

			</div>
		);
	};

	renderNewSubfrac() {
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center ">
						<div className="column label-normal">
							<p><b>Nombre:</b></p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="name"
								id='namef'
								className="input input-modals"
								minLength="6"
								maxLength="100"
								required
								value={this.state.nombre}
								onChange={(event) => { this.setState({ nombre: event.target.value }) }}
								placeholder=""
								autoComplete={'off'}
							/>
						</div>
					</div>
					<div className="white-space-16" />
					<div className="input-form-content row full align-center">
						<div className="column label-normal">
							<p>
								<b>Dirección:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="address"
								className="input input-modals"
								placeholder="Dirección"
								required minLength="6"
								maxLength="150"
								value={this.state.direccion}
								onChange={(event) => { this.setState({ direccion: event.target.value }) }}
								autoComplete={'off'} />
						</div>
					</div>
					<div className="white-space-16" />
					<div className="input-form-content row full align-center">
						<div className="column label-normal">
							<p>
								<b>Teléfono:</b>
							</p>
						</div>
						<div className="column">
							<PhoneInput
								displayInitialValueAsLocalNumber
								name="phone"
								maxLength="18"
								defaultCountry="MX"
								value={this.state.telefono}
								onChange={value => this.setState({ telefono: value, telefonoError: undefined })}
								error={this.state.telefonoError}
								className={'input-modals'}
								autoComplete={'off'}
							/>
						</div>

						<div className="column label-medium space">
							<p>
								<b>Indiviso:</b>
							</p>
						</div>
						<div className="column space tooltip left" data-tooltip="El valor del indiviso es base 100">
							<input className="input input-modals text-center"
								type="number"
								name=""
								id=""
								step='any'
								min={0}
								max={100}
								value={this.state.indiviso}
								onChange={(event) => { this.setState({ indiviso: event.target.value }) }}
								autoComplete={'off'}
								onClick={this.focusAllValue}
							/>
						</div>
					</div>

				</div>
			</div>
		);
	};
};

export default Consumer(SubFRaccPage);