import React, { Component } from 'react';
import PersonalDomesticoTableEdit from './personalDomesticoTableEdit';
import ModalBorrar from '../modals/modalDelete';
import Request from "../../core/httpClient";
import cogoToast from 'cogo-toast';
import { AsYouType } from 'libphonenumber-js';
import DinamicModal from '../dinamicModal/dinamicModal';
import * as htmlToImage from 'html-to-image';
import { Consumer } from "../../context";

const request = new Request();

class PersonalDomesticoHistoryTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invitado: {}
		}
	}

	openModal(idModal, invitado) {
		//console.log(invitado);
		this.setState({ invitado, id_invitado_row: invitado.id_frecuente_invitacion });
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
		document.getElementById('nom').focus();
	}

	openModalDelete(inv) {
		this.setState({ id_frecuente_invitacion: inv.id_frecuente_invitacion, id_invitado_row: inv.id_frecuente_invitacion });
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

	async handleDelete(id, close) {
		let user = this.props.context.user.id.usuario;

        let data = {
            id_frecuente_invitacion: id,
			usuario: user
		}
		const response = await request.post('/invitadosFrecuentes/invitados/borrar', data);
		if (response) {
			if (response.deleted) {
				cogoToast.success('Invitado eliminado.', {
					position: 'bottom-right',
				});
			} else {
				cogoToast.error('No se pudo eliminar invitado.', {
					position: 'bottom-right',
				});
			}
		} else {
			cogoToast.error('No se pudo eliminar invitado.', {
				position: 'bottom-right',
			});
		}
		//this.setState({message: response});
		this.props.reload();
		if(close) {
			close();
		}
	}
	
	getNumberFormat(cell_phone, oculto) {

        try {
			if(oculto)
				return `******${cell_phone.substring(cell_phone.length - 4)}`;

            if(cell_phone.includes('+'))
                return new AsYouType('MXN').input(cell_phone);

			return new AsYouType('MXN').input('+'+cell_phone);

        } catch (error) {
            return null;
        }
    }

	saveimg() {
		var node = document.getElementById('qr-print');
 
		htmlToImage.toPng(node, {quality:2})
		.then(function (dataUrl) {
			var a = document.createElement("a"); //Create <a>
			//console.log(a);
			a.href = dataUrl //Image Base64 Goes here
			a.download = "QR_PDom.png"; //File name Here
			a.click();
		})
		.catch(function (error) {
			console.error('oops, something went wrong!', error);
		});
	}

    render() {
        let invitados = []

        if (this.props.invitados) {
            invitados = this.props.invitados;
			// console.log('Invitado en Tabla', invitados);
        }

        return (
            <div>
                <div className="table-responsiv column">
                    <table>
						<thead className="sticky-th">
                            <tr className="text-left">
                                <th className="">
									<div className="row">
										Nombre &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'invitado' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'invitado')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'invitado')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'invitado')}></i>
											}
										</div>
									</div>
								</th>
								<th className="">Teléfono</th>
                                <th className="">
									<div className="row">
										Vivienda &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'numero_registro' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'numero_registro')}></i>
											}
										</div>
									</div>
								</th>
                                <th className="text-left">
									<div className="row">
										Acceso &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'acceso' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'acceso')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'acceso')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'acceso')}></i>
											}
										</div>
									</div>
								</th>
								<th className="text-center th-options-small">QR</th>
                                <th className="text-center th-options-small">Editar</th>
								<th className="text-center th-options-small">Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
							{invitados.slice((this.props.page - 1) * this.props.pageLength, this.props.page * this.props.pageLength).map((invitado, key) => (
								<tr className={this.state.id_invitado_row === invitado.id_frecuente_invitacion && 'row-active' } key={key}>
                                    <td>{invitado.invitado}</td>
									<td>{this.getNumberFormat(invitado.telefono, true)}</td>
                                    <td>{invitado.numero_registro}</td>
                                    <td>{invitado.acceso}</td>
									<td>
										<button className="btn-full justify-center btn-mini btn-secondary color-white" onClick={this.openModal.bind(this,"qrDomestic", invitado)}>
											<i className={`font-small fas fa-qrcode ${!invitado.activated && 'color-red'}`}></i>
										</button>
									</td>
									<td className="text-center">
										<button className="btn-full justify-center btn-mini btn-secondary color-white" type="button justify-center" onClick={this.openModal.bind(this,"editDomestico", invitado)}>
											<i className="font-small fas fa-pen" />
										</button>
									</td>
									<td>
										<button className="btn-full justify-center align-center btn-mini btn-secondary color-white" 
												type		=	'button' onClick={this.openModalDelete.bind(this, invitado)} 
												style		=	{{ padding: '5px' }} 
												disabled	=	{!this.props.escritura}
										>
											<i className="font-text fas fa-trash-alt " />
										</button>
									</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
				{<PersonalDomesticoTableEdit 
					reload		=	{this.props.reload.bind(this)} 
					invitado	=	{this.state.invitado} 
					escritura	=	{this.props.escritura}
					getNumberFormat	= {this.getNumberFormat.bind(this)}
				/>}
				<ModalBorrar
					id		=	{this.state.id_frecuente_invitacion}
					delete	=	{this.handleDelete.bind(this)}
				/>
				<DinamicModal
					idModal				=	{'qrDomestic'}
					sizeModalContainer	=	{'normal'}
					title				=	{'Personal'} 
					>
					<div className="column justify-center align-center full">
						<div id="qr-print" style={{ backgroundColor: 'white', width: '400px', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', display: 'flex'}}>
							<div style={{ width: '400px' }}>
								<div style={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '400px', display: 'flex' }}>
									<p style={{ width: '400px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>Nombre: {this.state.invitado.invitado}</p>
									<div style={{ height: '8px' }}></div>
									<p style={{ width: '400px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>Vivienda: {this.state.invitado.numero_registro}</p>
								</div>
								<div style={{ height: '8px' }}></div>
								<div className="justify-center align-center">
									<img src={`https://api.qrserver.com/v1/create-qr-code/?size=250x250&color=000000&bgcolor=F5F4F4&data=id_vivienda:${this.state.invitado.id_vivienda}/id_frecuente_invitacion:${this.state.invitado.id_frecuente_invitacion}`}></img>
								</div>
								<div style={{ height: '16px' }}></div>
							</div>
						</div>
						<button type="button" className="btn btn-primary btn-small color-white"
							onClick={this.saveimg.bind(this)}>
							Descargar
						</button>
					</div>
				</DinamicModal>
            </div>
        );
	}
}

export default Consumer(PersonalDomesticoHistoryTable);