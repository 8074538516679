// React and Third-party
import React, { useEffect, useRef } from 'react';

// Components
import SuperModal from '../../../components/coreModal/SuperModal';
import SuperCore from '../../../components/core/SuperCore';

const modal = new SuperCore();

export default function AbonarModal(props) {

    const { id, id_inquilino, onCloseModal, loadingAbono, AbonarFormComponent } = props;

    const form = useRef();

    useEffect(() => {
      
        if(id_inquilino && id_inquilino !== 0) {
        
            modal.superOpenModal(id);
        }

    }, [id_inquilino, id])

    return (
        <SuperModal
            id={id}
            title={"Nuevo Abono"}
            size={"small"}
            triggerCloseModal={onCloseModal}
            lblSubmit={"ABONAR"}
            showSubmit={true}
            showClose={true}
            id_menu_activo={0}
            loading={loadingAbono}
            onSubmit={() => form.current.dispatchEvent(new Event("submit"))}
            screens={[
                <AbonarFormComponent formRef={form} />
            ]}
        />
    )
}
