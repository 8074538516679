import React from "react";
import Core from "../core/SuperCore";
import XLSX from "xlsx";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
class Importar extends Core {
	constructor(props) {
		super(props);
		this.state = {
			confirmImport: this.props.confirmImport,
			sobreEscribeImport: this.props.sobreEscribeImport,
			cargosImportar: this.props.cargosImportar,
			file_name: this.props.file_name,
			total: this.props.total,
			dataSet: [{UNIDAD: "", MES: "", AÑO: "", CODIGO: "", IMPORTE: "", COMENTARIOS: ""}],
		};
	}

	componentDidMount() {}

	async componentDidUpdate(prevProps) {
		if (prevProps.confirmImport !== this.props.confirmImport) {
			await this.setState({confirmImport: this.props.confirmImport});
		}

		if (prevProps.sobreEscribeImport !== this.props.sobreEscribeImport) {
			await this.setState({sobreEscribeImport: this.props.sobreEscribeImport});
		}

		if (prevProps.cargosImportar !== this.props.cargosImportar) {
			await this.setState({cargosImportar: this.props.cargosImportar});
		}

		if (prevProps.file_name !== this.props.file_name) {
			await this.setState({file_name: this.props.file_name});
		}

		if (prevProps.total !== this.props.total) {
			await this.setState({total: this.props.total});
		}
	}

	async getPlantillaXLS() {
		this.props.updateExportData({});
	}

	uploadFile(event) {
		if (event.target.files[0]) {
			const reader = new FileReader();
			const rABS = !!reader.readAsBinaryString;
			let nombre = event.target.files[0].name;
			if (rABS) {
				reader.readAsBinaryString(event.target.files[0]);
			} else {
				reader.readAsArrayBuffer(event.target.files[0]);
			}
			reader.onload = (e) => {
				const bstr = e.target.result;
				const wb = XLSX.read(bstr, {type: rABS ? "binary" : "array", bookVBA: true});
				const wsname = wb.SheetNames[0];
				const ws = wb.Sheets[wsname];
				let data = XLSX.utils.sheet_to_json(ws);
				let total = 0;
				if (data) {
					data.forEach((cargo) => {
						total += parseFloat(cargo.IMPORTE);
					});
				}

				let t = new Intl.NumberFormat("es-MX", {style: "currency", currency: "MXN"}).format(total);
				total = t.toString();

				this.updateData({
					file_name: nombre,
					cargosImportar: data,
					total: total,
				});
			};
			event.target.value = null;
		}
	}

	async updateData(json) {
		await this.props.updateData(json);
		await this.setState(json);
	}

	render() {
		let dataExport = [];

		if (Array.isArray(this.state.dataSet)) {
			if (this.state.dataSet.length > 0) {
				let columns = Object.keys(this.state.dataSet[0]);
				const columnsUpper = columns.map((column) => column.toUpperCase().replace("_", " "));
				const data = this.state.dataSet.map((row) => {
					return columns.map((column) => row[column]);
				});
				if (this.state.dataSet) {
					const dataSet = [
						{
							columns: columnsUpper,
							data,
						},
					];
					dataExport = dataSet;
				}
			}
		}

		return (
			<div className="container">
				<div className="column full">
					<div className="white-space-24"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-big">
							<p>
								<b>Buscar archivo(xlsx):</b>
							</p>
						</div>
						<div className="column full">
							<input
								className="input input-modals input-file"
								id="file-input"
								type="file"
								accept=".xls,.xlsx"
								onChange={this.uploadFile.bind(this)}
								// disabled		=	{!this.state.escritura}
							></input>
							<input type="text" disabled className="input input-modals" value={this.state.file_name} />
						</div>
						<div className="column ">
							<label htmlFor="file-input" className="btn btn-primary btn-small color-white label-input-file">
								Importar
							</label>
						</div>
						&nbsp;
						<div className="column ">
							<ExcelFile element={<button className="btn btn-primary btn-small color-white">Plantilla</button>} filename={` Plantilla de importación`}>
								<ExcelSheet dataSet={dataExport || []} name={`Plantilla de importación`} />
							</ExcelFile>
						</div>
					</div>
					<div className="white-space-16"></div>
					<div className="full table-rules row scroll">
						<table className="table full ">
							<thead>
								<tr>
									<th className="text-left">Vivienda</th>
									<th className="text-left">Mes</th>
									<th className="text-left">Año</th>
									<th className="text-left">Código</th>
									<th className="text-left">Comentarios</th>
									<th className="text-center">Importe</th>
									<th className="text-center">Estatus</th>
								</tr>
							</thead>

							<tbody className="">
								{this.state.cargosImportar.length == 0 ? (
									<tr>
										<td colSpan="7" className="text-center">
											No hay datos que mostrar
										</td>
									</tr>
								) : (
									this.state.cargosImportar.map((cargo) => {
										return (
											<tr>
												<td className="text-left">{cargo.UNIDAD}</td>
												<td className="text-left">{cargo.MES}</td>
												<td className="text-left">{cargo.AÑO}</td>
												<td className="text-left">{cargo.CODIGO}</td>
												<td className="text-left">{cargo.COMENTARIOS}</td>
												<td className="text-right">{new Intl.NumberFormat("es-MX", {style: "currency", currency: "MXN"}).format(cargo.IMPORTE)}</td>
												<td className="text-center" style={{width: "14%"}}>
													<div 
													    className="tooltip right"
														data-tooltip={cargo.result ? cargo.result.message : ""}
													>
														{cargo.status === "OK" && <i className="fas fa-check-circle color-green"></i>}
														{cargo.status === "KO" && <i className="fas fa-exclamation-circle color-red"></i>}
													</div>
												</td>
											</tr>
										);
									})
								)}
							</tbody>
							<tfoot>
								<tr>
									<td colSpan={4} className="text-center td-transparent"></td>
									<td className="td-transparent text-center">
										<b>Total:</b>
									</td>

									<td className="td-transparent text-right">
										<b> {this.state.total}</b>
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
					<div className="white-space-16"></div>
					<div className="row justify-start align-center">
						<label className="container-check container-check-billing">
							<input
								type="checkbox"
								name="numDocs"
								id="numDocs"
								className="check-option"
								checked={this.state.confirmImport}
								onChange={(event) => {
									this.updateData({confirmImport: !this.state.confirmImport});
								}}
							/>
							<span className="checkmark"></span>
							<p>
								<b>{`Se van a importar ${this.state.cargosImportar.length} cargos`}</b>
							</p>
						</label>
						<label className="container-check container-check-billing">
							<input
								type="checkbox"
								name="sobreescribir"
								id="sobreescribir"
								className="check-option"
								checked={this.state.sobreEscribeImport}
								onChange={(event) => {
									this.updateData({sobreEscribeImport: !this.state.sobreEscribeImport});
								}}
							/>
							<span className="checkmark"></span>
							<p>
								<b>{`Sobreescribir datos`}</b>
							</p>
						</label>
					</div>
				</div>
			</div>
		);
	}
}

export default Importar;
