import React, {Component} from "react";
import DatePicker from "react-datepicker";
import {registerLocale, setDefaultLocale} from "react-datepicker";
import NumberFormat from "react-number-format";
import cogoToast from "cogo-toast";
import es from "date-fns/locale/es";
registerLocale("es", es);

class NewDoc extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mesAnio: "",
			subfraccionamientos: this.props.subfracs,
			userName: this.props.userName,
			viviendas: this.props.viviendas,
			configProductos: this.props.configProductos,
			configImpuestos: this.props.configImpuestos,
			lineasNewDoc: this.props.lineasNewDoc,
			impuestosNewDoc: this.props.impuestosNewDoc,
			idViviendaNew: this.props.impuestosNewDoc,
			fechaNew: this.props.fechaNew,
			comentarioNew: this.props.comentarioNew,
			currentMonth: new Date(),
			currentConcept: 0,
			currentMonto: 0
		};
	}

	async componentDidUpdate(prevProps, prevState) {
		if (this.props.subfracs != prevProps.subfracs) {
			await this.setState({
				subfraccionamientos: this.props.subfracs,
			});
		}

		if (this.props.userName != prevProps.userName) {
			await this.setState({
				userName: this.props.userName,
			});
		}

		if (this.props.configProductos != prevProps.configProductos) {
			await this.setState({
				configProductos: this.props.configProductos,
				currentMonth: new Date(),
				currentConcept: 0,
				currentMonto: 0,
			});
		}
		if (this.props.configImpuestos != prevProps.configImpuestos) {
			await this.setState({
				configImpuestos: this.props.configImpuestos,
			});
		}

		if (this.props.viviendas != prevProps.viviendas) {
			await this.setState({
				viviendas: this.props.viviendas,
			});
		}

		if (this.props.lineasNewDoc != prevProps.lineasNewDoc) {
			await this.setState({
				lineasNewDoc: this.props.lineasNewDoc,
			});
		}

		if (this.props.impuestosNewDoc != prevProps.impuestosNewDoc) {
			await this.setState({
				impuestosNewDoc: this.props.impuestosNewDoc,
			});
		}

		if (this.props.impuestosNewDoc != prevProps.impuestosNewDoc) {
			await this.setState({
				impuestosNewDoc: this.props.impuestosNewDoc,
			});
		}

		if (this.props.idViviendaNew != prevProps.idViviendaNew) {
			await this.setState({
				idViviendaNew: this.props.idViviendaNew,
				nombre_vivienda: this.props.nombre_vivienda
			});
		}
		if (this.props.fechaNew != prevProps.fechaNew) {
			await this.setState({
				fechaNew: this.props.fechaNew,
			});
		}

		if (this.props.comentarioNew != prevProps.comentarioNew) {
			await this.setState({
				comentarioNew: this.props.comentarioNew,
			});
		}
	}

	handleChange = (date) => {
		this.updateData({fechaNew: date});
	};

	handleChangeMonth = (date) => {
		this.setState({currentMonth: date});
	};

	generateId() {
		let result = "";
		let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		let charactersLength = characters.length;
		for (let i = 0; i < 7; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}

	handleClose() {
		//console.log("cerrando");
	}

	isOk(line) {
		//console.log(line);
		let { concept, monto, status } = line;
		//console.log(concept);
		return (concept !== 0 && concept !== '' && monto !== 0);
	}

	async addLinea() {
		let linea = {
			concept: "",
			month: new Date(),
			monto: 0,
			idLinea: Math.ceil(Math.random() * 100) * Math.ceil(Math.random() * 100),
			status: 0,
		};

		let nuevaLinea = this.state.lineasNewDoc;

		await nuevaLinea.push(linea);

		await this.updateData({lineasNewDoc: nuevaLinea});
	}

	async updateData(data) {
		await this.props.updateDataNew(data);
		await this.setState(data);
	}

	async updateContepto(linea, contepto) {
		let cuenta = this.state.lineasNewDoc.filter(
			(lin) =>
				lin.concept === contepto &&
				contepto !== 0 &&
				lin.month.getFullYear() === linea.month.getFullYear() &&
				lin.month.getMonth() === linea.month.getMonth() &&
				lin.idLinea !== linea.idLinea &&
				lin.status === 1
		);

		if (cuenta) {
			if (cuenta.length) {
				cogoToast.error("Ya existe un producto similar agregado.", {
					position: "bottom-right",
				});

				return;
			}
		}

		let replaceStatus = this.state.lineasNewDoc.map(lnd => {
			if (linea.idLinea === lnd.idLinea) {
				if (linea.monto === 0 && (contepto === "" || contepto === 0)) {
					//console.log("Hola");
					lnd.status = 0
				} else {
					//console.log("1");
					lnd.status = 1
				}
			}

			return lnd;
		});

		await this.setState({ lineasNewDoc: replaceStatus });

		

		let nuevosImpuestos = this.state.impuestosNewDoc.filter((impuesto) => impuesto.idLinea !== linea.idLinea);

		let impuestos = this.state.configImpuestos.filter((impuesto) => {
			return impuesto.Id_Producto === contepto;
		});

		if (Array.isArray(impuestos)) {
			impuestos.map((impuesto) => {
				let impuestoTotal = 0;
				let retencionTotal = 0;

				impuestoTotal = linea.monto * (impuesto.Valor / 100);
				retencionTotal = linea.monto * (impuesto.Retencion / 100);

				nuevosImpuestos.push({
					...impuesto,
					impuestoTotal: impuestoTotal,
					retencionTotal: retencionTotal,
					idLinea: linea.idLinea,
					status: 1,
				});
			});
		}

		let lineas = this.state.lineasNewDoc.map((lin) => {
			if (lin.idLinea === linea.idLinea) {
				lin.concept = contepto;
			}
			return lin;
		});

		await this.updateData({
			lineasNewDoc: lineas,
			impuestosNewDoc: nuevosImpuestos,
		});
	}

	async updateDate(linea, date) {
		let cuenta = this.state.lineasNewDoc.filter(
			(lin) =>
				lin.concept === linea.concept &&
				lin.month.getFullYear() === date.getFullYear() &&
				lin.month.getMonth() === date.getMonth() &&
				lin.idLinea !== linea.idLinea &&
				lin.status === 1
		);

		if (cuenta) {
			if (cuenta.length) {
				cogoToast.error("Ya existe un producto similar agregado.", {
					position: "bottom-right",
				});
				return;
			}
		}

		let lineas = this.state.lineasNewDoc.map((lin) => {
			if (lin.idLinea === linea.idLinea) {
				lin.month = date;
			}
			return lin;
		});

		await this.updateData({lineasNewDoc: lineas});
	}

	render() {
		let SubTotal = this.state.lineasNewDoc.filter((lin) => lin.status == 1).reduce((suma, linea) => suma + Number(linea.monto), 0);
		let Impuestos = this.state.impuestosNewDoc.filter((imp) => imp.status == 1).reduce((suma, impuesto) => suma + Number(impuesto.impuestoTotal), 0);
		let Retencion = this.state.impuestosNewDoc.filter((imp) => imp.status == 1).reduce((suma, impuesto) => suma + Number(impuesto.retencionTotal), 0);
		let Total = SubTotal + Impuestos - Retencion;
		return (
			<div className="container">
				<div className="column full">
					<div className="white-space-24"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-normal">
							<p>
								<b>Vivienda:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								className="input input-modals"
								autoComplete="off"
								placeholder="Elija"
								name="unidad-list"
								list="unidad-list"
								value={this.state.nombre_vivienda}
								onChange={(event) => {
									let valor = event.target.value;
									let viviendaSelect = this.state.viviendas.filter((viv) => viv.numero_registro === valor);

									if (viviendaSelect[0]) {
										this.updateData({ idViviendaNew: viviendaSelect[0].id_vivienda, nombre_vivienda: valor});
									} else {
										this.updateData({ idViviendaNew: 0, nombre_vivienda: valor});
									}

								}}
							/>
							<datalist id="unidad-list">
								<select className="input input-modals" name="conceptos">
									{this.state.viviendas.map((vivienda, key) => (
										<option value={vivienda.numero_registro} key={key}></option>
									))}
								</select>
							</datalist>
						</div>
						{/* <div className="column label-duo-small space">
							<p>
								<b>Fecha:</b>
							</p>
						</div>
						<div className="column full">
							<DatePicker className="input input-modals" selected={this.state.fechaNew} onChange={this.handleChange} dateFormat="dd/MM/yyyy" locale="es" />
						</div> */}
						{/* <div className="column label-duo-small space">
							<p>
								<b>Usuario:</b>
							</p>
						</div>
						<div className="column full">
							<input type="text" name="numMonths" className="input input-modals" value={this.state.userName} disabled />
						</div> */}
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-normal">
							<p>
								<b>Comentario :</b>
							</p>
						</div>
						<div className="column full">
							<textarea
								className="input input-modals"
								name="description"
								value={this.state.comentarioNew}
								onChange={(event) => this.updateData({comentarioNew: event.target.value})}
								required
								autoComplete="off"
							></textarea>
						</div>
					</div>
					<div className="white-space-16"></div>
					<div className="full table-history" style={{maxHeight: '225px'}} id="table-problem">
						<table className=" full ">
							<thead>
								<tr>
									<th className="th-sticky text-center">Producto</th>
									<th className="th-sticky text-center">Mes aplica</th>
									<th className="th-sticky text-center">Monto</th>
								</tr>
							</thead>
							<tbody>
								{this.state.lineasNewDoc
									
									.map((linea, key) => (
										<tr key={key} className={`${linea.status === 1 ? this.isOk(linea) ? "" : "not-ok" : ""}`}>
											<td>
												<select
													className="input input-modals"
													name="conceptos"
													value={linea.concept}
													onChange={(event) => {
														this.updateContepto(linea, Number(event.target.value));
													}}
													required
												>
													<option value="">Elija producto</option>
													{this.state.configProductos.map((producto, key) => (
														<option value={producto.Id_Producto} key={key}>
															{producto.Nombre}
														</option>
													))}
												</select>
											</td>
											<td>
												<DatePicker
													closeOnScroll={(e) => e.target === document.getElementById('table-problem')}
													className="input input-modals"
													dateFormat="MM/yyyy"
													selected={linea.month}
													onChange={(value) => {
														this.updateDate(linea, value);
													}}
													locale="es"
													showMonthYearPicker
												/>
											</td>
											<td>
												<NumberFormat
													className="input input-modals text-right"
													value={linea.monto}
													displayType={"number"}
													thousandSeparator={true}
													prefix = {"$"}
													decimalScale={2}
													onChange={(event) => {
														let impuestos = this.state.impuestosNewDoc;
														let lineas = this.state.lineasNewDoc.map((lin) => {
															if (lin.idLinea === linea.idLinea) {
																//console.log(event.target.value);
																let monto = Number(event.target.value.replace(/\$/g, "").replace(/,/g, ""));
																//console.log(monto);
																lin.monto = Number(event.target.value.replace(/\$/g, "").replace(/,/g, ""));

																impuestos.map((imp) => {
																	if (linea.idLinea === imp.idLinea) {
																		imp.impuestoTotal = lin.monto * (imp.Valor / 100);
																		imp.retencionTotal = lin.monto * (imp.Retencion / 100);
																	}
																});

																if (monto === 0 && (linea.concept === "" || linea.concept === 0)) {
																	lin.status = 0
																} else {
																	lin.status = 1
																}
															}

															return lin;
														});

														this.updateData({lineasNewDoc: lineas});
													}}
												/>
											</td>
										</tr>
									))}
								<tr>
									<td></td>
									<td></td>
									<td></td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className="row">
						<div className="white-space-8"></div>
					</div>
					<div className="row justify-end">
						<button
							className="btn btn-primary btn-small color-white"
							onClick={() => {
								this.addLinea();
							}}
						>
							<i className="fas fa-plus"></i> &nbsp;Agregar línea
						</button>
					</div>

					<div className="white-space-16"></div>
					<div className="input-form-content row full align-center">
						<div className="column label-duo-small space">
							<p className="text-center">
								<b>SubTotal :</b>
							</p>
						</div>

						<div className="column label-duo-small space">
							<p className="text-center">
								<b>Impuestos:</b>
							</p>
						</div>

						<div className="column label-duo-small space">
							<p className="text-center">
								<b>Retenciones:</b>
							</p>
						</div>
						<div className="column label-duo-small space">
							<p className="text-center">
								<b>Total:</b>
							</p>
						</div>
					</div>
					<div className="input-form-content row full align-center">
						<div className="column full space">
							<NumberFormat
								className="input input-modals text-right"
								value={SubTotal}
								displayType={"number"}
								thousandSeparator={true}
								prefix={"$"}
								decimalScale={2}
								disabled
								fixedDecimalScale={true}
							/>
						</div>
						<div className="column full space">
							<NumberFormat
								className="input input-modals text-right"
								value={Impuestos}
								displayType={"number"}
								thousandSeparator={true}
								prefix={"$"}
								decimalScale={2}
								disabled
								fixedDecimalScale={true}
							/>
						</div>
						<div className="column full space">
							<NumberFormat
								className="input input-modals text-right"
								value={Retencion}
								displayType={"number"}
								thousandSeparator={true}
								prefix={"$"}
								decimalScale={2}
								disabled
								fixedDecimalScale={true}
							/>
						</div>
						<div className="column full space">
							<NumberFormat
								className="input input-modals text-right"
								value={Total}
								displayType={"number"}
								thousandSeparator={true}
								prefix={"$"}
								decimalScale={2}
								disabled
								fixedDecimalScale={true}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default NewDoc;
