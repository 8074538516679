import React from 'react'
import Core from './../core/SuperCore';
import DatePicker from "react-datepicker";
import DinamicModal from '../dinamicModal/dinamicModal';
import ModalBorrar from '../modals/modalDelete';
import cogoToast from 'cogo-toast';
import { URL_API } from '../../core/urlsApi';
import GaleriaTable from '../galerias/galeria-table';
import Request from '../../core/httpClient';

const req = new Request();

class GaleriaCarpetas extends Core{

    constructor(props){
        super(props);
        this.state = {
			galerias: [],
			carpeta: {
				id_galeria_carpeta: 0,
				nombre: ''
			},
			nombre: ''
        }
    }

    async componentDidUpdate(prevProps, prevState){

        if(prevProps.galerias != this.props.galerias){
            this.setState({galerias: this.props.galerias});
        }
    }

    async beforeOpenModal(carpeta) {
		this.setState({ carpeta, nombre: carpeta.nombre, id_carpeta_row: carpeta.id_galeria_carpeta, id_carpeta: null})
        this.openModal('editCarpeta');
    }

	async openGalerias(id_carpeta) {
		this.setState({loadingGalerias: true});
		if (id_carpeta == this.state.id_carpeta) {
			await this.setState({id_carpeta: null});
		} else {
			await this.setState({id_carpeta, id_carpeta_row: id_carpeta});
			await this.loadGalerias(id_carpeta);
		}
		this.setState({loadingGalerias: false});
	}

	async loadGalerias(id_carpeta) {
		this.setState({loadingGalerias: true});
		const response = await req.get('/galerias/get/galerias', {
			id_carpeta,
			IdFraccionamiento: parseInt(localStorage.getItem('frac'))
		});

		if(response.error || response.empty){
			await this.setState({galerias: [], loadingGalerias: false});
			cogoToast.info(response.message, {position: 'bottom-right'});
			return false;
		}
		await this.setState({galerias: response});

		this.setState({loadingGalerias: false});
	}

    render() {
		let carpetas = [];
		if (Array.isArray(this.props.carpetas)) {
			carpetas = this.props.carpetas;
		}
        return (
            <div className="card-table card-owners column" id="card-owners">
					<table>
						<thead className="sticky-th">
						<tr className="text-left">
							<th className="th-options-huge">
								<div className="row">
									Carpeta &nbsp;
										<div className="align-center">
										{this.props.filterColumn.col === 'nombre' ? this.props.filterColumn.filt === 'asc' ?
											<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
											:
											<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
											:
											<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
										}
									</div>
								</div>
							</th>
							<th className="th-options-huge text-center" >Galerías</th>
							<th className="text-center th-options-small">Editar</th>
                        	<th className="text-center th-options-small">Eliminar</th>
						</tr>
						</thead>
						<tbody>
							{carpetas.slice(((this.props.page - 1) * this.props.pageLength), (this.props.page * this.props.pageLength)).map((c, key) => {
								return (
									<React.Fragment key={key}>
										<tr className={this.state.id_carpeta_row === c.id_galeria_carpeta && 'row-active'}>
											<td colSpan="">{c.nombre}</td>
											<td>
												<button 
														className   =   "btn-full justify-center align-center btn-mini btn-secondary color-white" 
														type        =   "button" 
														onClick     =   {(event)=>this.openGalerias(c.id_galeria_carpeta)} 
														
														>
													<i className="font-small fas fa-images" />
												</button>
											</td>
											<td>
												<button 
														className   =   "btn-full justify-center align-center btn-mini btn-secondary color-white" 
														type        =   "button" 
														onClick     =   {(event)=>this.beforeOpenModal(c)} 
														>
													<i className="font-small fas fa-pen" />
												</button>
											</td>
											<td>
												<button 
														className   =   "btn-full justify-center align-center btn-mini btn-secondary color-white" 
														type        =   "button" 
														onClick     =   {() => {
															//console.log(c.id_galeria_carpeta);
															this.setState({ id_carpeta_delete: c.id_galeria_carpeta, id_carpeta_row: c.id_galeria_carpeta, id_carpeta: null});
															this.openModalDelete('delete-carpeta-galeria')
														}}
														
														>
													<i className="font-small fas fa-trash-alt" />
												</button>
											</td>
										</tr>
										{
										this.state.id_carpeta == c.id_galeria_carpeta?
											this.state.loadingGalerias? 
												<tr>
													<td colSpan="5" style={{textAlign: 'center'}}>
														<i className="fas fa-spinner fa-spin"></i>
													</td>
												</tr>
												:
												<tr className="acordeon-row">
													<td className="acordeon" colSpan="5">
														<div className="row full">
															<GaleriaTable
																	galerias={this.state.galerias}
																	usuario={this.props.usuario}
																	id_carpeta={this.state.id_carpeta}
																	reload={this.loadGalerias.bind(this, this.state.id_carpeta)} />
														</div>
													</td>
												</tr>
											: null
										}
									</React.Fragment>
								)}
							)}
						</tbody>
					</table>

                <DinamicModal 
                    idModal					=	{'editCarpeta'}
                    enforceFocus		    =	{true}
                    sizeModalContainer		=	{'small'}
                    title					=	{'Editar carpeta'} 
                    success					=	{this.editCarpeta.bind(this)} 
                    showBtnSuccess			=	{true}
                    btnTextSuccess			=	{"GUARDAR"}
                    showBtnCancel			=	{true}
                    btnTextCancel			=	{"CANCELAR"}
                    loadingSave				=	{this.state.loadingSave}
                    message					=	{this.state.saveMessage}
                    closeModal				=	{this.closeModal.bind(this)}
                >
                	{this.renderCarpeta()}
                </DinamicModal>  
				<ModalBorrar
					name	=	{'delete-carpeta-galeria'}
                    id      =   {this.state.id_carpeta_delete} 
                    delete  =   {this.deleteCarpeta.bind(this)} 
                />
            </div>
        );
    }

    async editCarpeta(e) {
        e.preventDefault();
		this.setState({loadingSave: true});
		const data = {
			id_carpeta: this.state.carpeta.id_galeria_carpeta,
			Nombre: this.state.nombre,
			Usuario: this.props.usuario
		};
		const response = await req.post('/galerias/carpetas/update', data);
		if (response.updated) {
			cogoToast.success(response.message || 'Carpeta actualizada.', { position: 'bottom-right' });
			this.closeModal('editCarpeta');
			this.clearData();
			this.props.reload();
		} else {
			cogoToast.error(response.message || 'No se pudo actualizar la carpeta.', { position: 'bottom-right' });
		}
		this.setState({loadingSave: false});
    }

	async clearData(){
		this.setState({ nombre:''});
	}

	async deleteCarpeta(id) {
		//console.log(this.state, id);
        let data = { 
			id_carpeta: this.state.id_carpeta_delete,
			Usuario: this.props.usuario
		}
        const response = await req.post("/galerias/carpetas/delete", data);

        if(response.error || response.empty){
            cogoToast.error('No se pudo eliminar la carpeta.', {
                position: 'bottom-right',
            });
        }
        
        if (response.deleted) {
            cogoToast.success('Carpeta eliminada.', {
                position: 'bottom-right',
			});
			this.props.reload();
		}

        this.closeModalDelete('delete-carpeta-galeria');
	}

	renderCarpeta() {
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center"> 
						<div className="column label-medium">
							<p>
								<b>Nombre:</b>
							</p>
						</div>
						<div className="column full">
							<input 
								typeof      =   "text" 
								className   =   "input input-modals"
								required
								autoComplete = 'off'
								value   =   {this.state.nombre}
								onChange =  { (e)=> {this.setState({nombre: e.target.value})}}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}



export default GaleriaCarpetas;