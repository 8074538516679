import React, { Component } from 'react';
import ReactModal from "react-modal";
import DinamicModal from '../dinamicModal/dinamicModal';
import Request from "../../core/httpClient";
import cogoToast from 'cogo-toast';

const request = new Request();


class BudgetProveedorEdit extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id_proveedor: this.props.proveedor.id_proveedor,
			razon_social: this.props.proveedor.razon_social,
			rfc: this.props.proveedor.rfc,
			municipio: this.props.proveedor.municipio,
			cp: this.props.proveedor.cp,
			cuenta_contable: this.props.proveedor.cuenta_contable,
			observaciones: this.props.proveedor.observaciones,
			showModal: false,
			Id_Pais: this.props.proveedor.id_pais,
			Id_Estado: this.props.proveedor.id_estado,
			id_concepto: this.props.proveedor.id_concepto
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.proveedor !== prevProps.proveedor) {

			this.setState({
				id_proveedor: this.props.proveedor.id_proveedor,
				razon_social: this.props.proveedor.razon_social,
				rfc: this.props.proveedor.rfc,
				municipio: this.props.proveedor.municipio,
				cp: this.props.proveedor.cp,
				cuenta_contable: this.props.proveedor.cuenta_contable,
				observaciones: this.props.proveedor.observaciones,
				Id_Pais: this.props.proveedor.id_pais,
				Id_Estado: this.props.proveedor.id_estado,
				id_concepto: this.props.proveedor.id_concepto
			});
		}
	}


	render() {

		return (
			<div>
				<DinamicModal
					idModal={'editBudgetProveedor'}
					sizeModalContainer={'small'}
					title={'Editar proveedor'}
					success={this.handleSubmit.bind(this)}
					showBtnSuccess={this.props.escritura}
					btnTextSuccess={"ACTUALIZAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.message}
				>
					{this.renderEditBudget()}
				</DinamicModal>

			</div>
		);
	}

	renderEditBudget() {

		let paises = [];
		let estados = [];
		let conceptos = [];

		if (this.props.paises) {
			paises = this.props.paises
		}
		if (this.props.estados) {
			estados = this.props.estados
		}
		if (this.props.conceptos) {
			conceptos = this.props.conceptos
		}

		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="white-space-16" />
					<div className="input-form-content row full align-center ">
						<div className="column label-medium">
							<p><b>Razón social:	</b></p>
						</div>

						<div className="column full">
							<input
								type="text"
								name="razon_social"
								id="razon_social"
								className="input input-modals"
								autoComplete='off'
								maxLength="64"
								required
								value={this.state.razon_social}
								onChange={event => this.setState({ razon_social: event.target.value })}
							/>
						</div>
					</div>
					<div className="white-space-16" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>RFC:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="rfc"
								id="rfc"
								className="input input-modals"
								autoComplete='off'
								maxLength="64"
								required
								value={this.state.rfc}
								onChange={event => this.setState({ rfc: event.target.value })}
							/>
						</div>
						<div className="column label-duo-normal align-end">

						</div>
						<div className="column full">

						</div>
					</div>
					<div className="white-space-16" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Municipio:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="municipio"
								id="municipio"
								className="input input-modals"
								autoComplete='off'
								maxLength="64"
								required
								value={this.state.municipio}
								onChange={event => this.setState({ municipio: event.target.value })}
							/>
						</div>
						<div className="column label-duo-normal align-end">
							<div className="column label-duo-big">
								<p>
									<b>CP:</b>
								</p>
							</div>
						</div>
						<div className="column full">
							<input
								type="text"
								name="cp"
								className="input input-modals"
								id="cp"
								maxLength='10' required minLength='3'
								value={this.state.cp}
								onChange={(event) => this.setState({ cp: event.target.value })}
							/>
						</div>
					</div>
					<div className="white-space-16" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>País:</b>
							</p>
						</div>
						<div className="column full">
							<select
								className={"input input-modals"}
								name="pais"
								value={this.state.Id_Pais}
								onChange={(event) => {
									this.setState({ Id_Pais: event.target.value });
								}}
							>
								{paises.map((pais, key) => (
									<option value={pais.id} key={key}>
										{pais.nombre}
									</option>
								))}
							</select>
						</div>
						<div className="column label-duo-normal align-end">
							<div className="column label-duo-big">
								<p>
									<b>Estado:</b>
								</p>
							</div>
						</div>
						<div className="column full">
							<select
								className={"input input-modals"}
								name="estado"
								value={this.state.Id_Estado}
								onChange={(event) => {
									this.setState({
										Id_Estado: event.target.value
									},
									);
								}}
							>
								{estados.map((estado, key) => (
									<option value={estado.id_estado} key={key}>
										{estado.nombre}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="white-space-16" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Concepto:</b>
							</p>
						</div>
						<div className="column full">
							<select
								className={"input input-modals"}
								name="concepto"
								value={this.state.id_concepto}
								onChange={(event) => {
									this.setState({ id_concepto: event.target.value });
								}}
							>
								{conceptos.map((concepto, key) => (
									<option value={concepto.id} key={key}>
										{concepto.label}
									</option>
								))}
							</select>
						</div>
						<div className="column label-duo-normal align-end">
							<div className="column label-duo-big">
								<p>
									<b>Cuenta contable:</b>
								</p>
							</div>
						</div>
						<div className="column full">
							<input
								type="text"
								name="cuenta_contable"
								id="cuenta_contable"
								value={this.state.cuenta_contable}
								onChange={event => this.setState({ cuenta_contable: event.target.value })}
								className="input input-modals"
								autoComplete='off'
								maxLength="64"
							/>
						</div>
					</div>
					<div className="white-space-16" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Observaciones:</b>
							</p>
						</div>
						<div className="white-space-8" />
						<div className="align-center row full">
							<div className="column full">
								<textarea className='textarea font-small full' name="observaciones" id="observaciones" cols="5" rows="5" value={this.state.observaciones}
									onChange={event => this.setState({ observaciones: event.target.value })}>

								</textarea>
							</div>
						</div>
					</div>
				</div>
			</div>
		)

	}

	async handleSubmit(event) {
		event.preventDefault();

		let data = {
			id_proveedor: this.state.id_proveedor,
			razon_social: this.state.razon_social,
			rfc: this.state.rfc,
			municipio: this.state.municipio,
			id_pais: this.state.Id_Pais,
			id_estado: this.state.Id_Estado,
			id_concepto: this.state.id_concepto,
			cp: this.state.cp,
			cuenta_contable: this.state.cuenta_contable,
			observaciones: this.state.observaciones

		}
		const response = await request.post("/proveedores/proveedor/update", data);
		if (response.updated && !response.empty) {
			this.props.reload();
			cogoToast.success('Proveedor actualizado.', {
				position: 'bottom-right',
			});
		} else {
			this.setState({
				empty: true,
				//message: "NO SE PUDO ACTUALIZAR CONCEPTO"
			});
			cogoToast.error('No se pudo actualizar el proveeedor.', {
				position: 'bottom-right',
			});
		}

	}
}

export default BudgetProveedorEdit;