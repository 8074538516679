import React, { Component } from 'react';
import PersonaEditarModal from './personaEditar';
import ModalBorrar from '../modals/modalDelete';
import Request from "../../core/httpClient";
import { AsYouType } from 'libphonenumber-js'

const request = new Request();


class PersonasTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
			dataInquilino: '',
            residentes_status: [],
            dataPersona: {}
		}
    }

    delete = id => {
        this.handleDelete(id)
    }
    getNumberFormat(cell_phone){

        try {
            /*if(cell_phone.includes('+'))
                return new AsYouType('MXN').input(cell_phone);

			return new AsYouType('MXN').input('+'+cell_phone);*/
			return `******${cell_phone.substring(cell_phone.length - 4)}`

        } catch (error) {
            return null;
        }
    }

    render() {

        let personas = []
        if (this.props.personas) {
            personas = this.props.personas
        }
        return (
            <div className="card-table card-owners column" id="card-owners">
                <div className="row">
                    <table className="full">
                        <thead className="sticky-th">
                            <tr className="text-left">
                                <th className="th-options-huge">
                                    <div className="row">
                                        Nombre &nbsp;
										<div className="align-center">
                                            {this.props.filterColumn.col === 'nombre' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                <th className="th-options-huge">Teléfono</th>
                                <th className="text-center th-options-small">Editar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {personas.slice(((this.props.page - 1) * this.props.pageLength), (this.props.page * this.props.pageLength)).map((persona, key) => (
                                <tr className={this.state.id_persona_row === persona.id_persona ? 'row-active' : ''} key={key}>
                                    <td>
                                        {persona.nombre}
                                    </td>
                                    <td>
                                        {this.getNumberFormat(persona.telefono)}
                                    </td>
									<td className="text-center">
                                        <button 
                                                type        =   "button justify-center" 
                                                onClick     =   {this.openModal.bind(this, 'editPersona', persona)} 
                                                
                                        >
											<i className="font-small fas fa-pen" />
										</button>
									</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
				</div>
                <PersonaEditarModal 
                    reload      =   {this.props.reload.bind(this)} 
                    persona     =   {this.state.dataPersona} 
					escritura   =   {this.props.escritura}
					estadosAPP 	=   {this.state.residentes_status}
                />
                                  
				<ModalBorrar
					id          =   {this.state.id_persona}
					delete      =   {this.handleDelete.bind(this)}
                />
            </div>
        );
	}

	openModal(idModal, data) {
        this.setState({dataPersona: data, id_persona_row: data.id_persona});
        
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
        }
        document.getElementsByName('fullName')[0].focus();
	}

	openModalDelete(persona) {
        this.setState({ id_persona: persona.id_persona, id_persona_row: persona.id_persona });
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

    async handleDelete(id) {
        let data = {
            id_persona: id,
        }
        const response = await request.post("/personas/delete", data);
        if (response && !response.error) {
            if (response.deleted && !response.empty) {
                this.setState({
                    message: response.message
                });
                this.reload()

            } else {
                this.setState({
                    empty: true,
                    message: response.message,
                    loading: false
                });
            }

        }
    }
}

export default PersonasTable;