import React, {useState} from "react";

const ConfirmModal = (props) => {
    const {id, children, onSubmit, onClose} = props;
    const [loading, setloading] = useState(false);

    const handleConfirm = async () => {
        setloading(true);
        await onSubmit();
        setloading(false);
    };

    return (
        <div className="overlay-modal row hide-modal" id={id}>
            <div className="modal-delete">
                <form className="flex justify-center">
                    <div className="full column align-center">
                        <div className="white-space-32"></div>
                        <div className="column container">
                            <i className="fas fa-exclamation-circle color-purple fa-5x text-center"></i>
                            <div className="white-space-24"></div>
                            <h3 className="text-center">
                                {children}
                            </h3>
                        </div>
                        <div className="white-space-32"></div>
                        <div className="full">
                            {loading ? (
                                <button type="button" className="btn-modal-delete-success">
                                    <i className="fas fa-spinner fa-spin"></i>
                                </button>
                            ) : (
                                <button
                                    type="button"
                                    className="btn-modal-delete-success"
                                    onClick={handleConfirm}
                                >
                                    <i className="fas fa-check" /> &nbsp; ACEPTAR
                                </button>
                            )}
                            <button
                               type="button"
                               className="btn-modal-delete-cancel"
                               onClick={() => onClose(id)}
                            >
                                <i className="fas fa-times" /> &nbsp; CERRAR
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ConfirmModal;
