import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import cogoToast from "cogo-toast";
import TimeField from "react-simple-timefield";
import NumberFormat from "react-number-format";

import Request from "../../core/httpClient";
import Core from "../core/SuperCore";
import ModalBorrar from "../modals/modalDelete";
import DinamicModal from "../dinamicModal/dinamicModal";
import Preguntas from "./asamblea_preguntas_table";
import ReporteResultados from "./asamblea_reporte_resultados";

const request = new Request();

class EncuetasTable extends Core {
	constructor(props) {
		super(props);
		this.state = {
			id_encuesta: 0,
			id_tipo_encuesta: 0,
			activado: false,
			sub_fraccionamiento: 0,
			id_encuesta_aplicacion: 2,
			nombre: null,
			descripcion: null,
			desde: new Date(),
			hasta: new Date(),
			time1: "00:00",
			time2: "23:59",

			preguntas_tipos: this.props.preguntas_tipos,
			encuestas_tipo: this.props.encuestas_tipo,
			encuestas_aplicacion: this.props.encuestas_aplicacion,
			subfraccionamientos: this.props.subfraccionamientos,
			encuestas: this.props.encuestas,

			page_preguntas: 0,
			preguntas: [],
			encuesta: null,
			loadingSave: false,
			id_encuestaActiva: 0,
			asistenciaList: [],
			filtroLista: "",
			idEncuestaLista: 0,
			reporte_resultado: [],
			loadingLista: false,
		};
	}

	async componentDidUpdate(prevProps) {
		if (this.props.encuestas != prevProps.encuestas) {
			this.setState({ encuestas: this.props.encuestas });
		}

		if (this.props.encuestas_tipo != prevProps.encuestas_tipo) {
			this.setState({ encuestas_tipo: this.props.encuestas_tipo });
		}

		if (this.props.preguntas_tipos != prevProps.preguntas_tipos) {
			this.setState({ preguntas_tipos: this.props.preguntas_tipos });
		}
	}

	async getPreguntas() {
		await this.setState({ preguntas: [] });
		const response = await request.get("/encuestas/get/preguntas", {
			IdEncuesta: this.state.id_encuestaActiva,
		});

		if (response.error || response.empty) {
			await this.setState({ preguntas: [] });
		} else {
			await this.setState({ preguntas: response });
		}
	}

	render() {
		let encuestas = this.state.encuestas;

		return (
			<div className="card-table card-owners column" id="card-owners">
				<table>
					<thead className="sticky-th">
						<tr className="text-left">
							<th className="th-options-huge">
								<div className="row">
									Nombre &nbsp;
									<div className="align-center">
										{this.props.filterColumn.col === 'nombre' ? this.props.filterColumn.filt === 'asc' ?
											<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
											:
											<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
											:
											<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
										}
									</div>
								</div>
							</th>
							<th className="th-options text-start">
								<div className="row">
									Vigencia &nbsp;
									<div className="align-center">
										{this.props.filterColumn.col === 'hasta' ? this.props.filterColumn.filt === 'asc' ?
											<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'hasta')}></i>
											:
											<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'hasta')}></i>
											:
											<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'hasta')}></i>
										}
									</div>
								</div>
							</th>
							<th className="th-options-mini text-center">Preguntas</th>
							<th className="th-options-mini text-center">Asistencia</th>
							<th className="th-options-mini text-center">Resultados</th>
							<th className="th-options-mini text-center">Editar</th>
							<th className="th-options-mini text-center">Eliminar</th>
						</tr>
					</thead>
					<tbody>
						{encuestas
							.filter((enc) => enc.nombre.toLowerCase().includes(this.props.filter.toLowerCase()))
							.slice((this.props.page - 1) * this.props.pageLength, this.props.page * this.props.pageLength)
							.map((encuesta, index) => {
								return (
									<React.Fragment>
										<tr key={index} className={this.state.id_encuesta_row === encuesta.id_encuesta && 'row-active'} >
											<td>{encuesta.nombre}</td>
											<td>{moment(encuesta.hasta).format("DD/MM/YYYY [ - ] hh:mm A")}</td>
											<td>
												<button
													className="btn-full justify-center align-center btn-mini btn-secondary color-white"
													type="button"
													onClick={(e) => this.popRow(encuesta.id_encuesta)}
												>
													<i className="font-small fas fa-question" />
												</button>
											</td>
											<td>
												<button
													className="btn-full justify-center align-center btn-mini btn-secondary color-white"
													type="button"
													onClick={(event) => {
														this.openListaAsistencia(encuesta);
														this.setState({ id_encuesta_row: encuesta.id_encuesta, id_encuestaActiva: 0 })
													}}
												>
													<i className="font-small fas fa-list-alt" />
												</button>
											</td>
											<td>
												<button
													className="btn-full justify-center align-center btn-mini btn-secondary color-white"
													type="button"
													onClick={(e) => {
														this.reporteResultOpenModal(encuesta, "reporteEncuesta");
													}}
												>
													<i className="font-small fas fa-file" />
												</button>
											</td>
											<td>
												<button
													className="btn-full justify-center align-center btn-mini btn-secondary color-white"
													type="button"
													onClick={(e) => {
														this.beforeOpenModal(encuesta, "editEncuesta");
													}}
												>
													<i className="font-small fas fa-pen" />
												</button>
											</td>
											<td>
												<button
													className="btn-full justify-center align-center btn-mini btn-secondary color-white"
													type="button"
													onClick={(e) => {
														this.setState({
															id_encuesta: encuesta.id_encuesta,
															id_encuesta_row: encuesta.id_encuesta,
															id_encuestaActiva: 0
														});
														this.openModalDelete("deleteModal-encuesta");
													}}
													disabled={!this.props.escritura}
												>
													<i className="font-small fas fa-trash-alt" />
												</button>
											</td>
										</tr>
										{this.state.id_encuestaActiva === encuesta.id_encuesta ? (
											<Preguntas
												getPreguntas={this.getPreguntas.bind(this)}
												setActual={this.setActual.bind(this)}
												preguntas={this.state.preguntas}
												id_encuesta={this.state.id_encuestaActiva}
												preguntas_tipos={this.state.preguntas_tipos}
												escritura={this.props.escritura}
												cerrado={encuesta.cerrado}
											></Preguntas>
										) : null}
									</React.Fragment>
								);
							})}
					</tbody>
				</table>

				<DinamicModal
					idModal={"asistenciaList"}
					enforceFocus={true}
					sizeModalContainer={"normal"}
					title={"Lista de Asistencia"}
					success={this.saveAsistencia.bind(this)}
					showBtnSuccess={this.props.escritura && !this.state.cerrado}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.saveMessage}
					closeModal={this.closeModal.bind(this)}
				>
					{this.renderAsistencia()}
				</DinamicModal>

				<DinamicModal
					idModal={"editEncuesta"}
					enforceFocus={true}
					sizeModalContainer={"small"}
					title={"Editar encuesta rápida"}
					success={this.editEncuesta.bind(this)}
					showBtnSuccess={this.props.escritura && !this.state.cerrado}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.saveMessage}
					closeModal={this.closeModal.bind(this)}
				>
					{this.renderEncuesta()}
				</DinamicModal>

				<DinamicModal
					idModal={"reporteEncuesta"}
					enforceFocus={true}
					sizeModalContainer={"big"}
					title={"Resultados"}
					success={this.exportReportePDF.bind(this)}
					showBtnSuccess={true}
					btnTextSuccess={"EXPORTAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.saveMessage}
					closeModal={this.closeModal.bind(this)}
				>
					<ReporteResultados reporte_resultado={this.state.reporte_resultado} />
				</DinamicModal>

				<ModalBorrar name="deleteModal-encuesta" id={this.state.id_encuesta} delete={this.handleDelete.bind(this)} />
			</div>
		);
	}

	async handleDelete() {
		let usuario = await this.getUserInfo();

		let data = {
			IdEncuesta: this.state.id_encuesta,
			Usuario: usuario.info.usuario,
		};
		const response = await request.put("/encuestas/delete/encuesta", data);

		if (!response.error) {
			this.props.getEncuestas();
			cogoToast.success("Encuesta eliminada.", {
				position: "bottom-right",
			});
		} else {
			cogoToast.error("No se pudo eliminar encuesta.", {
				position: "bottom-right",
			});
		}
	}

	async editEncuesta(e) {
		e.preventDefault();
		this.setState({ loadingSave: true });

		let usuario = await this.getUserInfo();
		let d = this.state.desde;
		let t1 = this.state.time1;
		let desde_format = this.getDateFormat(d.getFullYear(), d.getMonth(), d.getDate(), t1);
		let h = this.state.hasta;
		let t2 = this.state.time2;
		let hasta_format = this.getDateFormat(h.getFullYear(), h.getMonth(), h.getDate(), t2);

		if (desde_format === hasta_format) {
			cogoToast.warn('La hora de fecha "hasta" debe ser mayor a la hora de fecha "desde".', {
				position: "bottom-right",
			});

			this.setState({ loadingSave: false });
			return;
		}

		if (hasta_format < desde_format) {
			cogoToast.warn('La hora de fecha "hasta" no puede ser mayor a la hora de fecha de "desde".', {
				position: "bottom-right",
			});

			this.setState({ loadingSave: false });
			return;
		}

		let data = {
			IdEncuesta: parseInt(this.state.id_encuesta),
			IdEncuestaTipo: parseInt(this.state.id_tipo_encuesta),
			IdSubfraccionamiento: parseInt(this.state.sub_fraccionamiento),
			IdEncuestaAplicacion: parseInt(this.state.id_encuesta_aplicacion),
			Nombre: this.state.nombre,
			Descripcion: this.state.descripcion ? this.state.descripcion : "",
			Desde: desde_format,
			Hasta: hasta_format,
			Activo: this.state.activado,
			Usuario: usuario.info.usuario,
			Politicas: this.state.politicas ? this.state.politicas : "",
		};

		const response = await request.put("/encuestas/update/encuesta", data);

		if (response.error) {
			let msj = "No se pudo editar encuesta.";

			if (response.Cerrado) msj = "La encuesta está cerrada, no se pueden realizar cambios.";

			cogoToast.error(msj, {
				position: "bottom-right",
			});
			this.setState({ loadingSave: false });
			return false;
		}

		cogoToast.success("Encuesta editada con éxito.", {
			position: "bottom-right",
		});
		this.props.getEncuestas();
		this.closeModal("editEncuesta");
		this.setState({ loadingSave: false });
	}

	async exportPDF(event) {
		let columns = "";
		let rows = "";

		const cols = ["Número Registro", "Inquilino", "Indiviso", "Asistencia", "Moroso"];
		cols.forEach((col) => {
			columns = columns + `<th>${col}</th>`;
		});

		this.state.asistenciaList
			.filter((lista) => lista.nombre.toLowerCase().includes(this.state.filtroLista.toLowerCase()))
			.forEach((asis) => {
				rows =
					rows +
					`<tr>
					<td class="text-center">${asis.numero_registro || ""}</td>
					<td class="text-left">${asis.nombre || ""}</td>
					<td class="text-center">${asis.pro_indiviso || ""}</td>
					<td class="">
						<label class="container-check justify-center">
							<input
								type="checkbox"
								class="check-option"
								${asis.asistencia == 1 && "checked"}
							/>
							<span className="checkmark"></span>
						</label>
					</td>
					<td className="">
						<label className="container-check justify-center">
							<input
								type="checkbox"
								className="check-option"
								${asis.moroso == 1 && "checked"}
							/>
							<span className="checkmark"></span>
						</label>
					</td>
				</tr>`;
			});

		const html = `<html>
        <head>
            <title>Appmosphera</title>
            <style>
                body {
                    font-family: "Mukta", sans-serif;
                    font-size: 11px;
                }
                th {
                    white-space: nowrap;
                }
                td {
                    color: #4e4a4a;
                }
            </style>
        </head>
        <body>
            <table class="table-asistencia">
                <thead>
                    <tr>
                        ${columns}
                    </tr>
                </thead>
                <tbody>
                    ${rows}
                </tbody>
			</table>
			<footer>
				${moment().format("DD/MM/YYYY HH:mm:ss")}
				${"Usuario Appmosphera"}
			</footer>
        </body></html>`;
		const mywindow = window.open("", "PRINT", "height=600,width=auto");
		mywindow.document.write(html);

		mywindow.document.close(); // necessary for IE >= 10
		mywindow.focus(); // necessary for IE >= 10*/

		mywindow.print();
		mywindow.close();

		return true;
	}

	async exportReportePDF(event) {
		event.preventDefault();

		const div = document.getElementById("tablaReporteAsamblea");

		const html = `
		<html>
			<head>
				<style>
					.table {
						width: 100%;
					}
				</style>
			</head>
			<body>
			${div.innerHTML}
			</body>
		</html>
		`;

		const mywindow = window.open("", "PRINT", "height=600,width=auto");
		mywindow.document.write(html);

		mywindow.document.close(); // necessary for IE >= 10
		mywindow.focus(); // necessary for IE >= 10*/

		mywindow.print();
		mywindow.close();

		return true;
	}

	renderEncuesta() {
		let encuestas_tipo = this.state.encuestas_tipo;
		let encuestas_aplicacion = this.state.encuestas_aplicacion;
		let sub_fraccionamientos = this.state.subfraccionamientos;

		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-duo-normal">
							<p>
								<b>Tipo de encuesta:</b>
							</p>
						</div>
						<div className="column full">
							<select
								name=""
								id=""
								className="input input-modals"
								onChange={(e) => {
									this.setState({ id_tipo_encuesta: e.target.value });
								}}
								required
								value={this.state.id_tipo_encuesta}
								disabled
							>
								{encuestas_tipo.map((et, i) => (
									<option key={i} value={et.id_encuesta_tipo}>
										{et.tipo}
									</option>
								))}
							</select>
						</div>

						<div className="column label-medium space align-end">
							<p>
								<b>Activar:</b>
							</p>
						</div>
						<div className="column space">
							<label className="container-check">
								<input type="checkbox" checked={this.state.activado} name="activar-check" onClick={(e) => this.setState({ activado: !this.state.activado })} />
								<span className="checkmark"></span>
							</label>
						</div>
					</div>

					<div className="white-space-16" />

					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Aplicación:</b>
							</p>
						</div>
						<div className="column full">
							<select name="" id="" className="input input-modals" disabled value={2}>
								<option value="2">Propietarios</option>
							</select>
						</div>
					</div>

					<div className="white-space-16" />

					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>{this.props.subLabel}:</b>
							</p>
						</div>
						<div className="column full">
							<select
								className="input input-modals"
								name="subfraccionamiento"
								id="subfrac"
								value={this.state.sub_fraccionamiento}
								onChange={(event) => this.setState({ sub_fraccionamiento: event.target.value })}
							>
								<option value="0">{"Todos"}</option>
								{sub_fraccionamientos.map((sub, index) => (
									<option key={index} value={sub.id_subfraccionamiento}>
										{sub.nombre}
									</option>
								))}
							</select>
						</div>
					</div>

					<div className="white-space-16" />

					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Nombre:</b>
							</p>
						</div>
						<div className="column full">
							<input
								className="input input-modals"
								type="text"
								name=""
								id=""
								autoComplete="off"
								required
								value={this.state.nombre}
								onChange={(e) => {
									this.setState({ nombre: e.target.value });
								}}
							/>
						</div>
					</div>

					<div className="white-space-16" />

					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Descripción:</b>
							</p>
						</div>
						<div className="column full">
							<div className="column full">
								<textarea
									className=" input input-modals"
									autoComplete="off"
									rows="1"
									value={this.state.descripcion}
									onChange={(e) => {
										this.setState({ descripcion: e.target.value });
									}}
								/>
							</div>
						</div>
					</div>

					<div className="white-space-16" />

					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Políticas:</b>
							</p>
						</div>
						<div className="column full">
							<div className="column full">
								<textarea
									className=" input input-modals"
									autoComplete="off"
									rows="2"
									value={this.state.politicas}
									onChange={(e) => {
										this.setState({ politicas: e.target.value });
									}}
								/>
							</div>
						</div>
					</div>

					<div className="white-space-16" />

					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Desde:</b>
							</p>
						</div>
						<div className="column full">
							<DatePicker
								className="input input-modals"
								selected={this.state.desde}
								onChange={(event) => {
									this.changeDate(event);
								}}
								dateFormat="dd/MM/yyyy"
								locale="es"
							/>
						</div>

						<div className="column label-duo-normal align-end">
							<p>
								<b>Hora:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="time"
								className="input input-modals"
								placeholder="hrs:mins"
								value={this.state.time1}
								onChange={(event) => {
									this.setState({ time1: event.currentTarget.value });
								}}
							/>
						</div>
					</div>

					<div className="white-space-16" />

					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p>
								<b>Hasta:</b>
							</p>
						</div>
						<div className="column full">
							<DatePicker
								className="input input-modals"
								selected={this.state.hasta}
								onChange={(event) => {
									this.setState({ hasta: event });
								}}
								dateFormat="dd/MM/yyyy"
								locale="es"
								minDate={this.state.desde}
							/>
						</div>

						<div className="column label-duo-normal align-end">
							<p>
								<b>Hora:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="time"
								className="input input-modals"
								placeholder="hrs:mins"
								value={this.state.time2}
								onChange={(event) => {
									this.setState({ time2: event.currentTarget.value });
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderAsistencia() {
		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="card-table card-owners column">
						<div className="row input-form-content full align-center">
							<div className="column label-medium">
								<p>
									<b>Buscar:</b>
								</p>
							</div>
							<div className="column full input-clean">
								<input
									className="input input-modals input-filter"
									type="text"
									name=""
									id=""
									autoComplete="off"
									value={this.state.filtroLista}
									onChange={(e) => {
										this.setState({ filtroLista: e.target.value });
									}}
								/>
								<i
									className="fas fa-times"
									onClick={() => {
										this.setState({ filtroLista: "" });
									}}
								></i>
							</div>
							<div className="column label-medium justify-center align-end">
								<button onClick={this.exportPDF.bind(this)} type="button" className="btn btn-primary btn-small color-white">
									Exportar
								</button>
							</div>
						</div>

						<div className="row">
							<div className="white-space-8"></div>
						</div>
						<div className="table-responsive table-asistencia-container">
							<table className="table-asistencia">
								<thead>
									<tr>
										<th className="th-options-huge text-center">Unidad</th>
										<th className="th-options-huge text-left">Propietario</th>
										<th className="th-options-huge text-center">Indiviso</th>
										<th className="th-options text-center">Asistencia</th>
										<th className="th-options text-center">Moroso</th>
										<th className="th-options text-center">Manual</th>
									</tr>
								</thead>
								<tbody>
									{this.state.loadingLista ? (
										<tr>
											<td className="text-center" colSpan="6">
												<i className="fas fa-spinner fa-spin"></i>.
											</td>
										</tr>
									) : this.state.asistenciaList ? (
										this.state.asistenciaList
											.filter((lista) => lista.nombre.toLowerCase().includes(this.state.filtroLista.toLowerCase()))
											.map((asis) => {
												return (
													<tr>
														<td className="text-center">{asis.numero_registro}</td>
														<td className="text-left">{asis.nombre}</td>
														<td className="text-center">{asis.pro_indiviso}</td>
														<td className="">
															<label className="container-check justify-center">
																<input
																	type="checkbox"
																	checked={asis.asistencia == 1}
																	className="check-option "
																	onClick={async () => {
																		let listaTemporal = this.state.asistenciaList.map((listItem) => {
																			if (listItem.id_vivienda === asis.id_vivienda) {
																				listItem.asistencia = Number.isInteger(asis.asistencia) ? (asis.asistencia === 1 ? 0 : 1) : 1;
																			}
																			return listItem;
																		});
																		await this.setState({
																			asistenciaList: listaTemporal,
																		});
																	}}
																/>
																<span className="checkmark"></span>
															</label>
														</td>
														<td className="">
															<label className="container-check justify-center">
																<input
																	type="checkbox"
																	checked={asis.moroso == 1}
																	className="check-option "
																	onClick={async () => {
																		let listaTemporal = this.state.asistenciaList.map((listItem) => {
																			if (listItem.id_vivienda === asis.id_vivienda) {
																				listItem.moroso = Number.isInteger(asis.moroso) ? (asis.moroso === 1 ? 0 : 1) : 1;
																			}
																			return listItem;
																		});
																		await this.setState({
																			asistenciaList: listaTemporal,
																		});
																	}}
																/>
																<span className="checkmark"></span>
															</label>
														</td>
														<td className="">
															<label className="container-check justify-center">
																<input
																	type="checkbox"
																	checked={asis.voto_manual == 1}
																	className="check-option "
																	onClick={async () => {
																		let listaTemporal = this.state.asistenciaList.map((listItem) => {
																			if (listItem.id_vivienda === asis.id_vivienda) {
																				listItem.voto_manual = Number.isInteger(asis.voto_manual) ? (asis.voto_manual === 1 ? 0 : 1) : 1;
																			}
																			return listItem;
																		});
																		await this.setState({
																			asistenciaList: listaTemporal,
																		});
																	}}
																/>
																<span className="checkmark"></span>
															</label>
														</td>
													</tr>
												);
											})
									) : (
										""
									)}
								</tbody>
								<tfoot>
									<tr>
										<td className="weight-bold font-mini text-center">Con derecho a voto</td>
										<td className="text-center">{this.state.asistenciaList.filter((as) => as.asistencia && !as.moroso).length}</td>
										<td className="text-center">
											<NumberFormat
												displayType={"text"}
												className="text-right"
												value={this.state.asistenciaList.filter((as) => as.asistencia).reduce((total, asis) => total + (asis.moroso ? 0 : asis.pro_indiviso), 0)}
												thousandSeparator={true}
												decimalScale={6}
											/>
											{ }
										</td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>
			</div>
		);
	}

	async reporteResultOpenModal(encuesta, idModal) {
		this.setState({ reporte_resultado: null, id_encuesta_row: encuesta.id_encuesta, id_encuestaActiva: 0 });

		const data = {
			id_encuesta: encuesta.id_encuesta,
		};

		const result = await request.post("/encuestas/get/reporte/asablea", data);

		if (result.reporte) {
			this.setState({ reporte_resultado: result.reporte });
		} else {
			cogoToast.error(result.message || "No se obtuvieron resultados.", {
				position: "bottom-right",
			});
		}

		this.openModal(idModal);
	}

	async beforeOpenModal(encuesta, idModal) {
		let { desde, hasta, cerrado } = encuesta;

		desde = moment(desde).format("YYYY-MM-DD HH:mm:ss");
		let t1 = moment(desde).format("HH:mm");
		hasta = moment(hasta).format("YYYY-MM-DD HH:mm:ss");
		let t2 = moment(hasta).format("HH:mm");

		this.setState({
			id_encuesta: encuesta.id_encuesta,
			id_tipo_encuesta: encuesta.id_encuesta_tipo,
			activado: encuesta.activo,
			id_encuesta_aplicacion: encuesta.id_encuesta_aplicacion,
			sub_fraccionamiento: encuesta.id_subfraccionamiento,
			nombre: encuesta.nombre,
			descripcion: encuesta.descripcion,
			desde: new Date(desde),
			time1: t1,
			hasta: new Date(hasta),
			time2: t2,
			cerrado,
			politicas: encuesta.politicas,
			id_encuesta_row: encuesta.id_encuesta,
			id_encuestaActiva: 0
		});

		this.openModal(idModal);
	}

	async openListaAsistencia(encuesta) {
		await this.setState({ loadingLista: true, asistenciaList: [] });
		this.openModal("asistenciaList");
		await this.setState({ idEncuestaLista: encuesta.id_encuesta });
		const response = await request.get("/encuestas/get/asistencia", {
			IdEncuesta: encuesta.id_encuesta,
		});

		if (!response.empty || !response.error) {
			this.setState({ asistenciaList: response.asistencia });
		} else {
			this.setState({ asistenciaList: [] });
		}
		this.setState({ loadingLista: false });
	}

	async saveAsistencia(event) {
		event.preventDefault();

		await this.setState({ loadingSave: true });
		// se eliminan los nulos para que no se haga grande el json (se repara en la versión 6.3 de mssql OJO)
		let guardar = this.state.asistenciaList
			.filter((item) => item.asistencia != null || item.moroso != null || item.voto_manual != null)
			.map((item) => {
				return {
					id_asistencia: item.id_asistencia,
					id_vivienda: item.id_vivienda,
					id_inquilino: item.id_inquilino,
					asistencia: item.asistencia,
					moroso: item.moroso,
					voto_manual: item.voto_manual,
				};
			});
		let data = {
			IdEncuesta: this.state.idEncuestaLista,
			ListaAsistencia: JSON.stringify(guardar),
		};

		const response = await request.put("/encuestas/save/asistencia", data);

		if (response.updated) {
			this.closeModal("asistenciaList");
			cogoToast.success("Se ha guardado la lista de manera correcta.", {
				position: "bottom-right",
			});
			await this.setState({ asistenciaList: [], loadingSave: false });
		} else {
			cogoToast.error("Hubo un error al guardar la lista.", {
				position: "bottom-right",
			});
			await this.setState({ loadingSave: false });
		}
	}

	getDateFormat(year, month, date, time) {
		month++; //ajustar de 1-12
		let fecha = `${year.toString()}-`;

		if (month < 10) {
			fecha += "0";
		}

		fecha += `${month.toString()}-`;

		if (date < 10) {
			fecha += "0";
		}

		fecha += `${date.toString()}T`;
		fecha += `${time.toString()}:00`;

		return fecha;
	}

	async changeDate(desde) {
		this.setState({ desde });

		if (this.state.hasta < desde) {
			this.setState({ hasta: desde });
		}
	}

	setPage(page) {
		this.setState({ page_preguntas: page });
	}

	async popRow(id_encuesta) {
		if (id_encuesta === this.state.id_encuestaActiva) {
			await this.setState({ id_encuestaActiva: null, preguntas: [] });
		} else {
			await this.setState({ id_encuestaActiva: id_encuesta, id_encuesta_row: id_encuesta });
			await this.getPreguntas();
		}
	}

	async setActual(pregunta) {
		const data = {
			IdEncuesta: this.state.id_encuestaActiva,
			IdEncuestaPregunta: pregunta.id_encuesta_pregunta,
			Es_actual: !pregunta.es_actual ? 1 : 0,
		};
		const response = await request.put("/encuestas/set/actual/pregunta", data);

		if (!response.error) {
			await this.getPreguntas();
		} else {
			cogoToast.error('No se pudo marcar como "actual".', {
				position: "bottom-right",
			});
		}
	}
}

export default EncuetasTable;
