import React, { Component } from "react";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import cogoToast from "cogo-toast";
import moment from "moment-timezone";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";

registerLocale("es", es);

class amounts extends Component {
	
	async changeData(data) {
		this.props.updateData(data);
	};

	render() {

		const { 
			importe_variable, valor_anual, valor_vivienda, fecha_dia, fecha_exacta_cargos, dia_mes_cargos,
			dia1, dia2, percent3, money3, percent1, money1, percent2, money2, percent4, money4,
			recargos_aplica, frecuencias, id_frecuencia, recargo_aplica_id, id_recargo, productos
		} = this.props;

		return (
			<div className="container">
				<div className="column full">
					<div className="white-space-24"></div>
					<div className="row full">
						<div className="card-info-purple column full">
							<div className="box-header justify-center">
								<p className="color-white">Monto</p>
							</div>
							<div className="box-body-small">
								<div className="row">
									<div className="column full">
										<div className="row alig-center">
											<label className="container-radio">
												<p>Importe variable</p>
												<input
													type="radio"
													name="typeImport"
													checked={importe_variable}
													onChange={(event) => {
														this.changeData({ importe_variable: !importe_variable });
													}}
												/>
												<span className="checkmarks"></span>
											</label>
										</div>
										<div className="white-space-8"></div>
										<div className="row align-center">
											<label className="container-radio">
												<p>Importe fijo</p>
												<input
													type="radio"
													name="typeImport"
													checked={!importe_variable}
													onChange={(event) => {
														this.changeData({ importe_variable: !importe_variable });
													}}
												/>
												<span className="checkmarks"></span>
											</label>
										</div>
									</div>
									<div className="input-form-content row full align-center">
										<div className="column full space">
											<NumberFormat
												className="input input-modals text-right"
												value={importe_variable ? valor_anual : valor_vivienda}
												placeholder={"Monto"}
												name="anual"
												displayType={"number"}
												thousandSeparator={true}
												decimalScale={2}
												prefix={"$"}
												onChange={(event) => {
													const valorNumber = Number(event.target.value.replace(/\$/g, "").replace(/,/g, ""));
													importe_variable
														? this.changeData({ valor_anual: valorNumber })
														: this.changeData({ valor_vivienda: valorNumber });
												}}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="card-info full column margin-left-16 justify-center">
							<div className="box-header">
								<p>Información</p>
							</div>
							<div className="box-body-small">
								<p>
									<b>VARIABLE: </b> Es el presupuesto total de ingreso anual.
								</p>
								<div className="white-space-8"></div>
								<p>
									<b>FIJO: </b> Es un monto fijo por vivienda.
								</p>
							</div>
						</div>
					</div>
					<div className="white-space-24"></div>
					<div className="row">
						<div className="card-info-purple column full">
							<div className="box-header justify-center">
								<p className="color-white">Descuento pronto pago</p>
							</div>
							<div className="box-body-big column">
								<table className="table-rules">
									<thead>
										<tr>
											<th>Días</th>
											<th>%</th>
											<th>$</th>
										</tr>
									</thead>
									<tbody>
										<tr className={!fecha_dia ? "input-disabled" : null}>
											<td>
												<NumberFormat
													displayType={"number"}
													name="dias1"
													className="input input-modals text-center"
													allowNegative={false}
													disabled={!fecha_dia}
													value={dia1}
													placeholder={"Días"}
													onChange={(event) => 
														this.changeData({ dia1: event.target.value})
													}
												/>
											</td>
											<td>
												<input
													type="number"
													className="input input-modals text-center"
													value={percent1}
													placeholder={"Porcentaje"}
													min={0}
													disabled={!fecha_dia}
													onChange={(event) => 
														this.changeData({ 
															percent1: Number(event.target.value.replace(/\$/g, "").replace(/,/g, "")), 
															money1: 0 
														})
													}
												/>
											</td>
											<td>
												<NumberFormat
													displayType={"number"}
													name="anual"
													className="input input-modals text-center"
													allowNegative={false}
													disabled={!fecha_dia}
													value={money1}
													placeholder={"Monto"}
													thousandSeparator={true}
													decimalScale={2}
													prefix={"$"}
													onChange={(event) => 
														this.changeData({ 
															money1: Number(event.target.value.replace(/\$/g, "").replace(/,/g, "")),
															percent1: 0
														})
													}
												/>
											</td>
										</tr>
										<tr className={!fecha_dia ? "input-disabled" : null}>
											<td>
												<NumberFormat
													displayType={"number"}
													name="dias2"
													className="input input-modals text-center"
													allowNegative={false}
													disabled={!fecha_dia}
													value={dia2}
													placeholder={"Días"}
													onChange={(event) => 
														this.changeData({ dia2: event.target.value})
													}
												/>
											</td>
											<td>
												<input
													type="number"
													className="input input-modals text-center"
													value={percent2}
													placeholder={"Porcentaje"}
													min={0}
													disabled={!fecha_dia}
													onChange={(event) => {
														this.changeData({ 
															percent2: Number(event.target.value.replace(/\$/g, "").replace(/,/g, "")),
															money2: 0
														});
													}}
												/>
											</td>
											<td>
												<NumberFormat
													displayType={"number"}
													name="anual"
													className="input input-modals text-center"
													allowNegative={false}
													disabled={!fecha_dia}
													value={money2}
													placeholder={"Monto"}
													thousandSeparator={true}
													decimalScale={2}
													prefix={"$"}
													onChange={(event) => 
														this.changeData({ 
															money2: Number(event.target.value.replace(/\$/g, "").replace(/,/g, "")),
															percent2: 0
														})
													}
												/>
											</td>
										</tr>
									</tbody>
								</table>
								<div className="white-space-32"></div>
								<div>
									<h5>Los descuentos se aplican al momento de aplicar el cobro.</h5>
								</div>
							</div>
						</div>
						<div className="card-info-purple column full margin-left-16">
							<div className="box-header justify-center">
								<p className="color-white">Recargos</p>
							</div>
							<div className="box-body-small column">
								<div className="input-form-content row  full align-center">
									<div className="column label-medium">
										<p>
											<b>Tipo:</b>
										</p>
									</div>
									<div className="column full">
										<select
											className="input input-modals  align-left"
											name="conceptos"
											required
											value={recargo_aplica_id}
											onChange={(event) => this.changeData({ recargo_aplica_id: event.target.value })}
										>
											{recargos_aplica.map((recargo, index) => {
												return <option key={index} value={recargo.Id_RecargoAplica}>{recargo.Nombre}</option>;
											})}
										</select>
									</div>
								</div>
								{Number.parseInt(recargo_aplica_id) === 4 &&
									<>
										<div className="white-space-8"></div>
										<div className="input-form-content row  full align-center">
											<div className="column label-medium">
												<p>
													<b>Fecha:</b>
												</p>
											</div>
											<div className="column full">
												<DatePicker
													className="input input-modals"
													selected={fecha_exacta_cargos}
													onChange={(value) => {
														this.changeData({ fecha_exacta_cargos: value });
													}}
													locale="es"
													dateFormat="dd/MM/yyyy"
												/>
											</div>
										</div>
									</>
								}
								{Number.parseInt(recargo_aplica_id) === 5 &&
									<>
										<div className="white-space-8"></div>
										<div className="input-form-content row  full align-center">
											<div className="column label-medium">
												<p>
													<b>Día de mes</b>
												</p>
											</div>
											<div className="column full">
												<input
													type="number"
													className="input input-modals"
													value={dia_mes_cargos}
													onChange={(event) => {
														this.changeData({ dia_mes_cargos: event.target.value });
													}}
													min={1}
													max={27}
													step={1}
												/>
											</div>
										</div>
									</>
								}
								{Number.parseInt(recargo_aplica_id) !== 3 &&
									<>
										<div className="white-space-8"></div>
										<div className="input-form-content row  full align-center">
											<div className="column label-medium">
												<p>
													<b>Concepto:</b>
												</p>
											</div>
											<div className="column full">
												<select
													className="input input-modals  align-center"
													name="conceptos"
													required
													value={id_recargo}
													onChange={(event) => {
														this.changeData({ id_recargo: event.target.value });
													}}
												>
													<option value={0}>Seleccionar recargo</option>
													{productos.map((producto, index) => {
														return <option key={index} value={producto.Id_Producto}>{producto.Nombre}</option>;
													})}
												</select>
											</div>
										</div>
									</>
								}
								{Number.parseInt(recargo_aplica_id) !== 3 &&
									<>
										<div className="white-space-8"></div>
										<div className="input-form-content row full align-center">
											<div className="column label-medium">
												<p>
													<b>Frecuencia:</b>
												</p>
											</div>
											<div className="column full align-center ">
												<select
													className="input input-modals  align-center"
													name="conceptos"
													required
													value={id_frecuencia}
													onChange={(event) => {
														this.changeData({ id_frecuencia: event.target.value });
													}}
												>
													<option value={0}>Seleccionar frecuencia</option>
													{frecuencias.map((frec, index) => {
														return <option key={index} value={frec.Id_RecargoFrecuencia}>{frec.Nombre}</option>;
													})}
												</select>
											</div>
										</div>
									</>
								}
								{Number.parseInt(recargo_aplica_id) !== 3 &&
									<>
										<div className="white-space-16"></div>
										<div className="input-form-content row  full align-center">
											<div className="column label-medium">
												<p>
													<b>Monto:</b>
												</p>
											</div>
											<div className="column  full">
												<table className="table-rules">
													<thead>
														<tr>
															<th>%</th>
															<th>$</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>
																<input
																	type="number"
																	className="input input-modals text-center"
																	value={percent4}
																	placeholder={"%"}
																	min={0}
																	onChange={(event) => {
																		const value = Number(event.target.value.replace(/\%/g, "").replace(/,/g, ""));
																		this.changeData({
																			percent4: value,
																			money4: 0,
																		});
																	}}
																/>
															</td>
															<td>
																<NumberFormat
																	displayType={"number"}
																	className="input input-modals text-center"
																	value={money4}
																	prefix={"$"}
																	placeholder={"$"}
																	thousandSeparator={true}
																	decimalScale={2}
																	onChange={(event) => {
																		const value = Number(event.target.value.replace(/\$/g, "").replace(/,/g, ""));
																		this.changeData({
																			percent4: 0,
																			money4: value,
																		});
																	}}
																/>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</>
								}
								{Number.parseInt(recargo_aplica_id) !== 3 && Number.parseInt(id_frecuencia) === 3 &&
									<>
										<div className="white-space-16"></div>
										<div>
											<h5>El monto total se dividira entre 30 para determinar el recargo por dia.</h5>
										</div>
									</>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default amounts;

export const importesOk = (data) => {

	let importesOk = true;

	const {
		importe_variable, valor_anual, valor_vivienda,
		dia1, percent1, money1, dia2, percent2, money2
	} = data;

	const cogoAlert = (message) => {
		cogoToast.warn(message, {
			position: "bottom-right",
			heading: "Importes",
			hideAfter: 5,
		});
	}

	if (importe_variable) {

		if (parseInt(valor_anual) === 0 || valor_anual == null) {
			cogoAlert("Debes asignar un presupuesto anual.");
			importesOk = false;
		};

	} else {

		if (parseInt(valor_vivienda) === 0 || valor_vivienda == null) {
			cogoAlert("Debes asignar un importe fijo por vivienda.");
			importesOk = false;
		};
	};

	const parsedDia1 = +dia1;
	const parsedDia2 = +dia2;

	if(parsedDia1 && (!percent1 && !money1)) {

		cogoAlert("Debes ingresar el tipo del primer descuento.");
		importesOk = false;
	}

	if(parsedDia2 && (!percent2 && !money2)) {

		cogoAlert("Debes ingresar el tipo del segundo descuento.");
		importesOk = false;
	}

	if(!parsedDia1 && (percent1 || money1)) {

		cogoAlert("Debes ingresar el dia del primer descuento.");
		importesOk = false;
	}

	if(!parsedDia2 && (percent2 || money2)) {

		cogoAlert("Debes ingresar el dia del segundo descuento.");
		importesOk = false;
	}

	if (parsedDia1 && parsedDia2) {

		if(parsedDia1 >= parsedDia2) {
			cogoAlert("Segundo descuento debe tener un dia mayor que el primer descuento.");
			importesOk = false;
		}

		if(parsedDia1 > 27 || parsedDia2 > 27) {
			cogoAlert("Descuentos deben ser entre dia 1 y 27");
			importesOk = false;
		}
	}

	if(!parsedDia1 && parsedDia2) {
		cogoAlert("Debes ingresar el primer descuento si se tendra un segundo descuento.");
		importesOk = false;
	}

	return importesOk;
};

export const cargosOk = (data, monthStart) => {

	let cargosOk = true;

	const { 
		recargo_aplica_id, fecha_exacta_cargos, dia_mes_cargos, id_recargo, 
		id_frecuencia, percent4, money4,
		dia1, dia2,
	} = data;

	const cogoAlert = (message) => {
		cogoToast.warn(message, {
			position: "bottom-right",
			heading: "Cargos",
			hideAfter: 5,
		});
	}

	if (Number.parseInt(recargo_aplica_id) !== 3) {

		if (recargo_aplica_id == 4) { //Fecha Exacta

			if (fecha_exacta_cargos == null || !moment(fecha_exacta_cargos).isValid()) {
				cogoAlert("Debes asignar una fecha exacta para el recargo.");
				cargosOk = false;
			}
			
			else
			{
				const diaFechaExacta = moment(fecha_exacta_cargos).dayOfYear();
				const fechaDia1 = moment(monthStart).startOf("month").set("days", +dia1);
				const fechaDia2 = moment(monthStart).startOf("month").set("days", +dia2);

				if ((+dia1 && fechaDia1.dayOfYear() >= diaFechaExacta)
					|| (+dia2 && fechaDia2.dayOfYear() >= diaFechaExacta)) {

					cogoAlert("Debes ingresar la fecha de recargo despues de los dias de descuento");
					cargosOk = false;
				}
			}
		};

		if (recargo_aplica_id == 5) { //Dia del mes

			if (dia_mes_cargos == null || dia_mes_cargos === 0 || dia_mes_cargos > 27) {
				cogoAlert("Debes asignar una día de mes para el recargo. (Entre el día 1 y 27)");
				cargosOk = false;
			};

			if((dia1 && dia1 >= dia_mes_cargos) 
				|| (dia2 && dia2 >= dia_mes_cargos)) {
				cogoAlert("Debes ingresar la fecha de recargo despues de los dias de descuento");
				cargosOk = false;
			}
		};

		if (id_recargo === 0) {
			cogoAlert("Debes elegir un concepto de recargo.");
			cargosOk = false;
		};

		if (id_frecuencia === 0) {
			cogoAlert("Debes elegir una frecuencia de recargo.");
			cargosOk = false;
		};

		let noHayPorcentaje_o_monto4 = percent4 == null || parseInt(percent4) === 0;

		noHayPorcentaje_o_monto4 *= money4 == null || parseInt(money4) === 0;

		if (noHayPorcentaje_o_monto4 === 1) {
			cogoAlert("Debes asignar un porcentaje o monto al recargo.");
			cargosOk = false;
		};

	};

	return cargosOk;
};