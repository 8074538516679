import React, {Component} from 'react';

class newRule extends Component{
    constructor(props){
        super(props);
        this.state = {
            startDate:              new Date(),
            subfraccionamientos:    this.props.subfraccionamientos,
            frac_name:              this.props.frac_name,
            name_rule:              "",
            comentarios:            "",
            id_sub:                 0,
            id_sub_init:            parseInt(localStorage.getItem('subfrac')) || 0,
            modalIsOpen:            false,
            rule:                   this.props.rule,
            nombre_sub:             this.props.nombre_sub,
            editSubFraccionamiento: this.props.editSubFraccionamiento,
            sublabel:               this.props.subLabel
        }
    }


    // id_sub:                 localStorage.getItem('subfrac') || 0,
    // name_rule:              null,
    // comentarios:            null,

    
    async componentDidUpdate(prevProps, prevState) {

        if(prevProps.id_sub != this.props.id_sub){
            await this.setState({id_sub: this.props.id_sub});
        }

        if(prevProps.name_rule != this.props.name_rule){
            await this.setState({name_rule: this.props.name_rule});
        }

        if(prevProps.comentarios != this.props.comentarios){
            await this.setState({comentarios: this.props.comentarios});
        }

        if(prevProps.frac_name != this.props.frac_name){
            await this.setState({frac_name: this.props.frac_name});
        }

        if(this.props.subfraccionamientos != prevProps.subfraccionamientos) { 
            await this.setState({
                subfraccionamientos:    this.props.subfraccionamientos,
            })
        }
        if(this.props.editSubFraccionamiento != prevProps.editSubFraccionamiento) { 
            await this.setState({
                editSubFraccionamiento:    this.props.editSubFraccionamiento,
            })
        }

        if(prevProps.clearData != this.props.clearData){
            await this.setState({clearData: this.props.clearData});
            if(this.props.clearData){
                await this.updateData({
                    name_rule:      '',
                    id_sub:         parseInt(localStorage.getItem('subfrac')) || 0,
                    comentarios:    ''
                });
            }
        }

        if (this.props.subLabel != prevProps.subLabel) {
            await this.setState({ sublabel: this.props.subLabel });
        }
    }

    render(){
        let subfrac = [];
        if(this.state.subfraccionamientos) {
            subfrac = this.state.subfraccionamientos;
        }

        let rule = [];
        if(this.state.rule) {
            rule = this.state.rule
        }
        
        return(
            <div className="container">
            <div className="column full">
                <div className="white-space-16"></div>
                <div className="input-form-content row full align-center">
                    <div className="column label-medium">
                        <p>
                            <b>{this.state.sublabel}:</b>
                        </p>
                    </div>
                    <div className="column full space">
                     <select 
                            className	=	"input input-modals"
                            name		=	"conceptos" 
                            value       =   {this.state.id_sub}
                            onChange    =   {(event)=>{this.updateData({id_sub: event.target.value})}}
                            required    
                            disabled   = {!this.state.editSubFraccionamiento}
                        >
                            <option value="0">Todos</option>
                                {  
									subfrac.map((subfraccionamiento, key) => (
									<option value={subfraccionamiento.id_subfraccionamiento} key={key}>{subfraccionamiento.nombre}</option>)) 
								}
                        </select>
                    </div>
                </div>
                <div className="white-space-16"></div>
                <div className="input-form-content row full align-center">
                    <div className="column label-medium">
                        <p>
                            <b>Nombre:</b>
                        </p>
                    </div>
                    <div className="column full">
                        <input className	=	"input input-modals" 
                            type		    =	"text" 
                            name		    =	"nombre" 
                            autoComplete    =   'off'
                            id			    = 	"name"
                            value           =   {this.state.name_rule}
                            onChange        =   {(event)=>{this.updateData({name_rule: event.target.value})}}
                            required 
                        />
                    </div>
                </div>
                <div className="white-space-16"></div>
                    <div className="row label-medium justify-start align-start">
                        <p>
                            <b>Comentarios:</b>
                        </p>
                    </div>
                    <div className="white-space-8"></div>
                    <div className="row full">
                        <textarea 
                            name            =   "descripcion" 
                            className           =   "input input-modals textarea full" 
                            cols            =   "50" 
                            rows            =   "5" 
                            autoComplete = 'off'
                            value           =   {this.state.comentarios}
                            onChange        =   {(event)=>{this.updateData({comentarios: event.target.value})}}
                        ></textarea>
                    </div>
                </div>
            </div>
        )
    }

    async updateData(data){
        this.props.updateData(data);
        await this.setState(data);
    }

}

export default newRule;