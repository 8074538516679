/**
 *  budgetTeoric.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Alexis
 *  @description: Página de presupuestos
 */

import React, { Component } from "react";
import { Consumer } from "../context";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import SidebarAdministracion from "../components/header/sidebarAdministracion";
import Paginador from "../components/paginador/paginador";
import BudgetTableTeoric from '../components/budgetTeoric/budgetTeoric';
import Controls from '../components/controls/controls';
import DinamicModal from '../components/dinamicModal/dinamicModal';
import moment from 'moment-timezone';
import Request from "../core/httpClient";
import Permissions from '../middlewares/Permissions';
import cogoToast from 'cogo-toast';
import sortableData from '../helpers/sortableDataTable';

const request = new Request();


class BudgetTeoric extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			subfraccionamientos: [],
			subfraccionamientoNombre: '',
			subfraccionamiento: localStorage.getItem('subfrac'),
			idSub: 0,
			presupuestos: [],
			id: 0,
			total: 0,
			totalReal: 0,
			conceptos: [],
			idYear: 0,
			totales: [],
			disabled: false,
			page: 1,
			year: parseInt(moment().format("YYYY")),
			IdPresupuesto: 0,
			filter: '',
			escritura: false,
			lectura: false,
			logo: require("../img/logo-AppMosphera-r.png"),
			direction: {
				concepto: 'desc',
				presupuesto: 'desc',
				total: 'desc'
			},
			filterColumn: {
				col: '',
				filt: ''
			},
			submenu: '',
			subLabel: '',
			meses: [{ id: 1, nombre: 'Enero' }, { id: 2, nombre: 'Febrero' }, { id: 3, nombre: 'Marzo' }, { id: 4, nombre: 'Abril' },
			{ id: 5, nombre: 'Mayo' }, { id: 6, nombre: 'Junio' }, { id: 7, nombre: 'Julio' }, { id: 8, nombre: 'Agosto' }, { id: 9, nombre: 'Septiembre' }, { id: 10, nombre: 'Octubre' },
			{ id: 11, nombre: 'Noviembre' }, { id: 12, nombre: 'Diciembre' }],
			niveles: [{ id: 1 }, { id: 2 }, { id: 3 }],
			idfrac: localStorage.getItem('frac') || 0,
			mostrar_app: 0,
			nombre: '',
			mes: 1,
			comentarios: '',
			nivel: 1,
		}

		this.validarPermisos();
	}

	async validarPermisos() {
		let permisos = new Permissions();
		let permisosInfo = await permisos.getResult()
		await this.setState({ escritura: permisosInfo.escritura, lectura: permisosInfo.lectura });
		//console.log(permisosInfo);

	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.subfraccionamiento !== prevState.subfraccionamiento) {
			this.getPresupuestos();
		}

		if (this.state.year !== prevState.year) {
			this.getPresupuestos();
		}
	}

	/*funcion del paginador*/
	setPage(page) {
		this.setState({ page });
	}

	reload() {
		this.getPresupuestos();
	}

	changeFrac() {
		this.setState({
			subfraccionamientos: [],
			idSub: 0,
			presupuestos: [],
			total: 0,
			conceptos: [],
			idYear: 0,
		})
		this.getPresupuestos();
		this.getConceptos()
	}

	subChange(sub) {
		this.setState({ subfraccionamiento: sub });
	}

	openModal(idModal) {
		this.clearValues();
		const { fraccionamiento } = this.props.context;
		this.setState({ formMessage: null, vivienda: { fraccionamiento } });
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
		//document.getElementById('sunfrac').focus();
	}
	clearValues() {
		//document.getElementById('importe').value = '';
		document.getElementById('comentarios').value = '';
	}
	closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
	}

	setFilter(filter) {
		this.setState({ filter: filter.toLowerCase() });
	}

	setYear(event) {
		this.setState({ year: event.target.value });
	}

	/* filter(presupuestos) {
		presupuestos = presupuestos.filter(con => {
			if (con.nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		})
		return presupuestos;
	} */

	filterIndex(presupuestos) {
		let aux = [];
		// //console.log(presupuestos);


		presupuestos.forEach(item => {

			aux.push({
				Año: item.anyo,
				ID_Presupuesto: item.id_presupuesto,
				Nombre: item.nombre,
				//Importe: 			Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(item.importe),
				//Gasto_Acumulado: 	Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(item.total)
			});

		});
		return aux;
	}

	sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, this.state.conceptos, this.state.direction[key]);

		this.setState({
			conceptos: items,
			direction: {
				[key]: direction
			},
			filterColumn: {
				col: key,
				filt: direction
			}
		});
	}

	render() {
		let conceptos = [];
		let presupuestos = [];
		let subfraccionamientos = [];
		let meses = [];
		let niveles = [];

		if (Array.isArray(this.state.conceptos)) {
			conceptos = this.state.conceptos;
		}

		if (Array.isArray(this.state.subfraccionamientos)) {
			subfraccionamientos = this.state.subfraccionamientos;
		}

		if (Array.isArray(this.state.presupuestos)) {
			presupuestos = this.state.presupuestos;
			// //console.log(dataSet);
			//presupuestos = this.filter(presupuestos);
		}
		if (Array.isArray(this.state.meses)) {
			meses = this.state.meses;
		}
		if (Array.isArray(this.state.niveles)) {
			niveles = this.state.niveles;
		}

		return (
			<div className="owners column">
				<Helmet>
					<title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
				</Helmet>
				<Header sidebar={true} active={'presupuestoTeorico'} parent={'presup'} panel={'panel2'} nav={"administracion"} />
				<div className="row">
					<div className="column" id="sidebar">
						<SidebarAdministracion />
					</div>
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								{this.state.lectura ?
									<React.Fragment>
										<Controls title={this.state.submenu}
											nuevo={this.openModal.bind(this, 'newBudget')}
											dataSet={presupuestos}
											//setFilter	=	{this.setFilter.bind(this)}
											//subChange	=	{this.subChange.bind(this)}
											newButton={this.state.escritura}
										/>
										{this.state.loadingPresupuestos ?
											<div className="row justify-center">
												<i className="fas fa-spinner fa-spin"></i>
											</div>
											:
											<BudgetTableTeoric
												disabled={this.state.disabled}
												totales={this.state.totales}
												conceptos={conceptos}
												presupuestos={presupuestos}
												page={this.state.page}
												totalReal={this.state.totalReal}
												total={this.state.total}
												id={this.state.idYear}
												showModal={this.state.showModal}
												handleClose={this.handleClose.bind(this)}
												setYear={this.setYear.bind(this)}
												year={this.state.year}
												getPresupuestos={this.getPresupuestos.bind(this)}
												IdPresupuesto={this.state.IdPresupuesto}
												subFrac={this.state.subfraccionamiento}
												escritura={this.state.escritura}
												reload={this.reload.bind(this)}
											//sortBy				=	{this.sortableTable.bind(this)}
											//filterColumn		=	{this.state.filterColumn}
											></BudgetTableTeoric>
										}
										<div className="white-space-16" />
										<Paginador pages={Math.ceil(presupuestos.length / 7)} setPage={this.setPage.bind(this)} />

									</React.Fragment> :
									null
								}

							</div>
						</div>
					</div>
				</div>
				<DinamicModal
					idModal={'newBudget'}
					sizeModalContainer={'normal'}
					title={'Añadir presupuesto'}
					success={this.handleSubmit.bind(this)}
					showBtnSuccess={true}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.formMessage}
				>
					<div className="full row align-center">
						<div className="column full">
							<div className="white-space-16" />
							<div className="input-form-content row full align-center ">
								<div className="column label-duo-big">
									<p><b>Nombre:	</b></p>
								</div>

								<div className="column full">
									<input
										type="text"
										name="nombre"
										id="nombre"
										className="input input-modals"
										autoComplete='off'
										maxLength="64"
										required
										value={this.state.nombre}
										onChange={event => this.setState({ nombre: event.target.value })}
									/>
								</div>
								<div className="column label-duo-normal align-end">
									<label className="container-check">
										<input
											type="checkbox"
											id="mostrar_app"
											checked={this.state.mostrar_app === 1 ? true : false}
											name="mostrar_app"
											onChange={(event) => {
												//console.log(this.state.residente);
												this.setState({ mostrar_app: event.target.checked === true ? 1 : 0 });
											}}
										/>
										<span className="checkmark"></span>
									</label>
								</div>
								<div className="column full">
									<h5>
										{" "}
										<b>Mostrar en la App</b>{" "}
									</h5>
								</div>
							</div>
							<div className="white-space-16" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Mes:</b>
									</p>
								</div>
								<div className="column full">
									<select className="input input-modals" name="mes" value={this.state.mes} onChange={(event) => this.setState({ mes: event.target.value })}>
										{meses.map(mes => <option key={mes.id} value={mes.id}>{mes.nombre}</option>)}
									</select>
								</div>
								<div className="column label-duo-normal align-end">

								</div>
								<div className="column full">

								</div>
							</div>
							<div className="white-space-16" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Año:</b>
									</p>
								</div>
								<div className="column full">
									<input
										type="number"
										name="year"
										className="input input-modals"
										placeholder="AAAA"
										maxLength='4' required minLength='2'
										value={this.state.year}
										onChange={(event) => this.setState({ year: event.target.value })}
									/>
								</div>
								<div className="column label-duo-normal align-end">

								</div>
								<div className="column full">

								</div>
							</div>
							<div className="white-space-16" />
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Nivel:</b>
									</p>
								</div>
								<div className="column full">
									<select className="input input-modals" name="nivel" value={this.state.nivel} onChange={(event) => this.setState({ nivel: event.target.value })}>
										{niveles.map(nivel => <option key={nivel.id} value={nivel.id}>{nivel.id}</option>)}
									</select>
								</div>
								<div className="column label-duo-normal align-end">

								</div>
								<div className="column full">

								</div>
							</div>
							<div className="white-space-16" />
							<div className="input-form-content row full align-center">
								<div className="column label-medium">
									<p>
										<b>Comentarios:</b>
									</p>
								</div>
								<div className="white-space-8" />
								<div className="align-center row full">
									<div className="column full">
										<textarea className='textarea font-small full' name="comentarios" id="comentarios" cols="5" rows="5" value={this.state.comentarios}
											onChange={event => this.setState({ comentarios: event.target.value })}>

										</textarea>
									</div>
								</div>
							</div>
						</div>
					</div>
				</DinamicModal>
			</div>
		);
	}

	componentDidMount() {
		this.getSubmenu();
		this.getConceptos();
		this.getPresupuestos();
		this.getSubFraccionamientos();
	}

	async getSubmenu() {
		const submenu = await this.props.context.getSubmenuAdminName();
		if (submenu) {
			this.setState({ submenu });
		}

		const subLabel = window.localStorage.getItem('subLabel');
		if (subLabel) {
			this.setState({ subLabel });
		}
	}

	handleClose() {
		this.setState({ showModal: false })
	}

	// traer presupuestos
	async getPresupuestos() {
		this.setState({ presupuesto: [], loadingPresupuestos: true });

		let data = {
			//IdSub: this.state.subfraccionamiento,
			id_fraccionamiento: localStorage.getItem('frac'),
			//year: this.state.year
		};

		const response = await request.post("/presupuestos/get/all/items", data);

		if (response && !response.error) {
			if (response.presupuestos && !response.empty) {
				this.setState({
					presupuestos: response.presupuestos,
					//idYear: response.presupuestos[0].id_presupuesto,
					//disabled: false
				});

			} else {
				this.setState({
					empty: true,
					message: response.message,
					presupuestos: [],
					disabled: true
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message
			});
		}
		this.getConceptos();
		this.setState({ presupuesto: [], loadingPresupuestos: false });
	}

	async handleSubmit(event) {
		event.preventDefault();
		this.setState({ loadingSave: true });
		//const form = event.target;
		let data = {
			id_fraccionamiento: this.state.idfrac,
			nombre: this.state.nombre,
			mes: this.state.mes,
			anyo: this.state.year,
			comentarios: this.state.comentarios,
			nivel: this.state.nivel,
			activo: this.state.mostrar_app
		}

		/* form.nombre.value = '';
		form.mes.value = 1;
		form.anyo.value = parseInt(moment().format("YYYY"));
		form.nivel.value = 1;
		form.comentarios.value =''; */

		const response = await request.post("/presupuestos/presupuesto/create", data)


		if (response.created) {
			this.setState({ showModal: false });
			this.closeModal("newBudget");
			this.getPresupuestos();
			cogoToast.success('Presupuesto agregado.', {
				position: 'bottom-right',
			});
		} else {
			this.setState({ empty: true/*, formMessage: "NO SE PUDO CREAR NUEVO PRESUPUESTO"*/ });
			cogoToast.error('No se pudo agregar nuevo presupuesto.', {
				position: 'bottom-right',
			});
		}

		this.setState({
			loadingSave: false, mostrar_app: 0, nombre: '', mes: 1, year: parseInt(moment().format("YYYY")), comentarios: '',
			nivel: 1
		});
	}

	async getIdPresupuesto() {
		let data = {
			anio: this.state.year,
			subfrac: localStorage.getItem('subfrac')
		};

		const response = await request.post("/presupuestos/presupuesto/get/id", data);
		if (response.id_presupuesto) {
			this.setState({ IdPresupuesto: response.id_presupuesto });
		}
	}

	// traer suma de los conceptos
	async getTotalConceptos() {
		let data = {
			anio: this.state.year,
			subfrac: this.state.subfraccionamiento
		};

		const response = await request.post("/presupuestos/presupuesto/get/total", data);

		if (response && !response.error) {
			if (response.total && !response.empty) {
				this.setState({
					total: response.total[0].total
				});
			} else {
				this.setState({
					empty: true,
					message: response.message
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message
			});
		}
		this.getTotalReal()
	}

	async getTotalReal() {
		let data = {
			anio: this.state.year,
			subfrac: this.state.subfraccionamiento
		};

		const response = await request.post("/presupuestos/presupuesto/get/sumas", data);

		if (response && !response.error) {
			if (response.total && !response.empty) {
				this.setState({
					totalReal: response.total[0].total
				});
			} else {
				this.setState({
					empty: true,
					message: response.message
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message
			});
		}
	}
	//Traer conceptos
	async getConceptos() {
		if (!localStorage.getItem('frac')) {
			return
		} else {
			let data = { idFraccionamiento: localStorage.getItem('frac') };
			const response = await request.post("/presupuestos/get/all", data);
			if (response && !response.error) {
				if (response.conceptos && !response.empty) {
					this.setState({
						conceptos: response.conceptos
					});
				} else {
					this.setState({
						empty: true,
						message: response.message
					});
				}
			} else {
				this.setState({ error: true, message: response.message });

			}
		}
	}
	// traer subfraccionamientos
	async getSubFraccionamientos() {
		const fraccionamiento = localStorage.getItem('frac');
		if (!fraccionamiento) {
			return;
		}
		let data = { Idsub: fraccionamiento }
		const response = await request.post("/admin/administracion/get/subfraccionamientos", data);
		if (response.subfraccionamiento) {
			this.setState({ subfraccionamientos: response.subfraccionamiento });
		} else {
			this.setState({ subfraccionamientos: [] });
		}
	}
}

export default Consumer(BudgetTeoric);