import React, { Component } from "react";
import { Consumer } from "../context";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import Sidebar from "../components/header/sidebar";
import HousesTable from "../components/houses/houses-table";
import Paginador from "../components/paginador/paginador";
import Request from "../core/httpClient";
import Controls from '../components/controls/controls';
import DinamicModal from '../components/dinamicModal/dinamicModal';
import Permissions from '../middlewares/Permissions';
import cogoToast from 'cogo-toast';
import sortableData from '../helpers/sortableDataTable';

const request = new Request();

const initForm = {
	sub_fraccionamiento: localStorage.getItem('subfrac') || 0,
	direccion: '',
	Nregistro: '',
	pIndiviso: 0,
	vacacional: false,
	mts: '',
	pagos: false,
	comentarios: ''
};

class Houses extends Component {
	constructor(props) {
		super(props);
		this.focusAllValue = this.focusAllValue.bind(this);
		this.getHouses = this.getHouses.bind(this);
		this.state = {
			user: {},
			showModal: false,
			ownerState: "0",
			fraccionamientoNombre: "",
			propietarios: [],
			viviendas: [],
			vivienda: {},
			search: false,
			idSub: 0,
			idViv: 0,
			page: 1,
			filterData: [],
			filter: '',
			keys: [],
			valida: false,
			subfraccionamientoNombre: '',
			subfraccionamiento: localStorage.getItem('subfrac') || 0,
			subfraccionamientos: [],
			mesagge: null,
			monto: "",
			sub_fraccionamiento: localStorage.getItem('subfrac') || 0,
			pagos: false,
			escritura: false,
			lectura: false,
			logo: require("../img/logo-AppMosphera-r.png"),
			pageLength: 20,
			direction: {},
			filterColumn: {},
			submenu: '',
			subLabel: '',
			direccion: ''
		};
	};

	async componentDidMount() {
		await this.validarPermisos();
		await this.load();
		await this.getHouses();
		await this.getSubFraccionamientos();
		await this.getFraccionamiento();
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.state.subfraccionamiento !== prevState.subfraccionamiento) {
			this.getHouses();
		};
	};

	async validarPermisos() {
		let permisos = new Permissions();

		let permisosInfo = await permisos.getResult()
		this.setState({ escritura: permisosInfo.escritura, lectura: permisosInfo.lectura });
	};

	
	async openModal(idModal) {

		const { fraccionamiento } = this.props.context;
		await this.setState({ formMessage: null, vivienda: { fraccionamiento }, ...initForm });

		if (this.state.subfraccionamiento != 0) {
			let subFrac = this.state.subfraccionamientos.find(sub => sub.id_subfraccionamiento == this.state.subfraccionamiento);
			await this.setState({ direccion: subFrac.direccion });
		};

		const modal = document.getElementById(idModal);

		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		};
		this.setState({ sub_fraccionamiento: localStorage.getItem('subfrac') || 0 });
	};

	/*funcion del paginador*/
	setPage(page) {
		this.setState({ page });
	};

	async changeFrac() {
		await this.getHouses();
	};

	subChange(sub) {
		this.setState({ subfraccionamiento: sub });
	};

	paginatorChange(length) {
		this.setState({ pageLength: length });
		this.setPage(1);
	};

	setFilter(filter) {
		this.setState({ filter: filter.toLowerCase() });
	};

	async getFraccionamiento() {
		try {
			const user = await this.props.context.loadUser();
			const idFraccionamiento = localStorage.getItem('frac');

			if (user) {
				const data = { IdFraccionamiento: idFraccionamiento };

				const response = await request.post("/admin/administracion/get/fraccionamiento", data);

				if (response && !response.error) {
					if (response.fraccionamiento && !response.empty) {
						if (response.fraccionamiento[0].p_latitude !== '' || response.fraccionamiento[0].p_longitude !== '') {
							this.setState({ frac_name: response.fraccionamiento[0].nombre });
						};
					};
				};
			};
		} catch (error) {
			console.log(error);
		};
	};

	updateAddress(sub) {
		this.setState({ direccion: sub.direccion });
	};

	handleChange(event) {
		let valor = event.target.checked

		if (valor === true) {
			valor = 1
		}
		else {
			valor = 0
		}
		this.setState({ vacacional: valor })
	};

	filter(viviendas) {
		viviendas = viviendas.filter(viv => {
			if (viv.numero_registro.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if (viv.direccion.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			};
		});
		return viviendas;
	};

	montoFormat(event) {
		this.setState({ monto: event.target.value });

		let monto = this.state.monto;
		let mon = monto.replace(/\D/g, "")
			.replace(/([0-9])([0-9]{2})$/, '$1 .$2')
			.replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ", ");
		this.setState({ monto: mon });
	};

	async load() {
		const submenu = await this.props.context.getSubmenuName();
		if (submenu) {
			this.setState({ submenu });
		};

		const subLabel = window.localStorage.getItem('subLabel');
		if (subLabel) {
			this.setState({ subLabel });
		};

		const user = await this.props.context.loadUser();
		if (user) {
			this.setState({
				user: {
					idUsuario: user.id.id_usuario,
					idPerfil: user.id.id_perfil,
					idFraccionamiento: user.id.id_fraccionamiento
				}
			});
		};
	};

	async getHouses() {
		try {
			let idSub = this.state.subfraccionamiento || 0;
			const idFrac = localStorage.getItem('frac') || 0;
			this.setState({ loadingViviendas: true });
			const data = { idSub, idFrac };

			const response = await request.post("/subfraccionamientos/get/viviendas", data);

			if (response.subTotalViviendas) {
				this.setState({
					viviendas: response.subTotalViviendas,
					filterData: response.subTotalViviendas
				});

			} else {
				this.setState({
					empty: true,
					message: response.message,
					loadingViviendas: false,
					viviendas: []
				});
			};

			this.setState({
				error: true,
				message: response.message,
				loadingViviendas: false
			});

		} catch (error) {
			console.log(error);
		};
	};

	// traer subfraccionamientos
	async getSubFraccionamientos() {
		try {
			const fraccionamiento = localStorage.getItem('frac');
			if (!fraccionamiento) {
				return;
			};

			const data = { Idsub: fraccionamiento, filter: 1 };
			const response = await request.post("/admin/administracion/get/subfraccionamientos", data);

			if (response.subfraccionamiento) {
				this.setState({ subfraccionamientos: response.subfraccionamiento });
			} else {
				this.setState({ subfraccionamientos: [] });
			};

		} catch (error) {
			console.log(error);
		};
	};

	handleOwner(event) {
		this.setState({ ownerState: event.target.value });
	};

	handleSearch() {
		this.setState({ search: !this.state.search })
	};

	async handleSubmit(event) {
		event.preventDefault();
		try {
			this.setState({ loadingSave: true });
			const form = event.target;

			let vacacional = form.vacation.checked
			let pro = form.proInd.value

			if (pro > 100) {
				this.setState({ valida: true, loadingSave: false });
			}

			if (vacacional === true) {
				vacacional = 1
			} else {
				vacacional = 0
			};

			if (parseInt(this.state.sub_fraccionamiento) === 0){
				this.setState({ loadingSave: false });
				cogoToast.warn('Debes seleccionar un subfraccionamiento', {
				    position: 'bottom-right',
			    });
				return 
			}

			const data = {
				direccion: form.houseAddress.value,
				numeroRegistro: form.houseNumber.value,
				idSub: form.subfraccionamiento.value,
				UPrivativa: form.houseNumber.value,
				mts: form.mts.value,
				proIn: pro,
				vacation: vacacional,
				pagos: this.state.pagos ? 1 : 0,
				comentarios: form.comentarios.value
			};

			const response = await request.post("/viviendas/crear", data);

			if (response.created) {
				this.setState({
					reload: true,
					loading: false,
					showModal: false,
					valida: false,
					pagos: false
				});

				this.closeModal('newHouse');
				await this.getHouses();
				cogoToast.success('Nueva vivienda agregada.', {
					position: 'bottom-right'
				});
			} else {
				this.setState({
					error: true,
					loading: false,
					showModal: true
				});
				cogoToast.error('Hubo un error al añadir nueva vivienda.', {
					position: 'bottom-right'
				});
			}

			this.setState({ loadingSave: false });

		} catch (error) {
			console.log(error);
		};
	};

	closeModal(id) {
		const modal = document.getElementById(id);
		if (!this.state.showModal && modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}

		this.setState({
			comentarios: '',
			monto: '',
			vacacional: false,
			pIndiviso: '',
			mts: '',
			direccion: '',
			Nregistro: ''
		});
	};

	sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, this.state.viviendas, this.state.direction[key]);

		this.setState({
			subfraccionamientos: items,
			direction: {
				[key]: direction
			},
			filterColumn: {
				col: key,
				filt: direction
			}
		});
	};

	focusAllValue(event) {
		const element = event.currentTarget;
		if (document.activeElement === element) {
			element.select();
		};
	};

	render() {
		let viviendas = [];

		if (this.state.viviendas) {
			viviendas = this.state.viviendas;
			viviendas = this.filter(viviendas);
		};

		return (
			<div className="houses column">
				<Helmet>
					<title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
				</Helmet>
				<Header
					reload={this.getHouses}
					change={this.changeFrac.bind(this)}
					sidebar={true}
					active={'viviendas'}
					parent={'admin'}
					panel={'panel1'}
					nav={"catalogos"}
				/>
				<div className="row">
					<div className="column" id="sidebar">
						<Sidebar />
					</div>
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								{this.state.lectura ?
									<React.Fragment>
										<Controls
											title={this.state.submenu}
											nuevo={this.openModal.bind(this, "newHouse")}
											dataSet={viviendas}
											setFilter={this.setFilter.bind(this)}
											subChange={this.subChange.bind(this)}
											newButton={this.state.escritura}
										/>
										{
											this.state.loadingViviendas ?
												<div className="row justify-center">
													<i className="fas fa-spinner fa-spin"></i>
												</div>
												:
												<HousesTable
													dataHouses={viviendas}
													sub_id={this.state.subfraccionamiento}
													page={this.state.page}
													reload={this.getHouses}
													subfraccionamientos={this.state.subfraccionamientos}
													sfrac_name={this.state.subfraccionamientoNombre}
													frac_name={this.state.frac_name}
													escritura={this.state.escritura}
													pageLength={this.state.pageLength}
													sortBy={this.sortableTable.bind(this)}
													filterColumn={this.state.filterColumn}
													subLabel={this.state.subLabel}
												/>
										}
										<div className="white-space-16" />
										<Paginador
											pages={Math.ceil(viviendas.length / this.state.pageLength)}
											setPage={this.setPage.bind(this)}
											pageLength={this.state.pageLength}
											paginatorChange={this.paginatorChange.bind(this)}
											showPageLenght={true}
										/>

										<DinamicModal
											idModal={'newHouse'}
											sizeModalContainer={'big'}
											title={'Añadir vivienda'}
											success={this.handleSubmit.bind(this)}
											showBtnSuccess={true}
											btnTextSuccess={"GUARDAR"}
											showBtnCancel={true}
											btnTextCancel={"CANCELAR"}
											loadingSave={this.state.loadingSave}
											message={this.state.formMessage}
										>
											{this.renderNewHouse()}
										</DinamicModal>
									</React.Fragment> :
									null
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	renderNewHouse() {

		let subfraccionamientos = [];

		if (Array.isArray(this.state.subfraccionamientos)) {
			subfraccionamientos = this.state.subfraccionamientos;
		};

		return (
			<div className="full row align-center">
				<div className="column full">
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b className="label-red">{this.state.subLabel}:</b>
							</p>
						</div>
						<select
							className="input input-modals"
							disabled={this.state.subfraccionamiento === 0 ? false : true}
							name="subfraccionamiento"
							value={this.state.sub_fraccionamiento}
							onChange={(event) => this.setState({ sub_fraccionamiento: event.target.value })}
						>
							<option value="0">Seleccionar</option>
							{subfraccionamientos.map((sub, index) => 
							    <option key={index} value={sub.id_subfraccionamiento}>
									{sub.nombre}
								</option>)}
						</select>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Vivienda:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="houseNumber"
								id="houseNum"
								className="input input-modals"
								maxLength="25"
								value={this.state.Nregistro}
								onChange={event => this.setState({ Nregistro: event.target.value })}
								autoComplete={'off'}
								required={true}
								autoFocus={true}

							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Dirección:</b>
							</p>
						</div>
						<div className="column full">
							<input
								type="text"
								name="houseAddress"
								className="input input-modals"
								required={false}
								maxLength="150"
								value={this.state.direccion}
								onChange={event => this.setState({ direccion: event.target.value })}
								autoComplete={'off'}
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column label-duo-big">
							<p >
								<b>Metros Cuadrados:</b>
							</p>
						</div>
						<div className="column full">
							<input type="number"
								className="input input-modals text-center"
								required
								name="mts"
								value={this.state.mts}
								onChange={event => this.setState({ mts: event.target.value })}
								step="any"
								autoComplete={'off'}
							/>
						</div>
						<div className="column label-duo-medium space">
							<p >
								<b>Indiviso:</b>
							</p>
						</div>
						<div className="column full space tooltip left" data-tooltip="El valor del indiviso es base 100">
							<input
								name="proInd"
								value={this.state.pIndiviso}
								type="number"
								min={0}
								max={100}
								onChange={event => this.setState({ pIndiviso: event.target.value })}
								className="input input-modals text-center"
								maxLength="12"
								step="any"
								required={true}
								autoComplete={'off'}
								onClick={this.focusAllValue}
							/>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">
						<div className="column  ">
							<label className="container-check" >
								<input
									type="checkbox"
									checked={this.state.vacacional === 1 ? (true) : (false)}
									name="vacation"
									onChange={this.handleChange.bind(this)}
								/>
								<span className="checkmark" ></span>
							</label>
						</div>
						<div className="column ">
							<p>
								<b>Esta vivienda es de renta vacacional</b>
							</p>
						</div>
					</div>
					<div className="white-space-8" />
					<div className="input-form-content row full align-center">

						<div className="column ">
							<label className="container-check" >
								<input
									type="checkbox"
									checked={this.state.pagos}
									name="pagos"
									onChange={(event) => { this.setState({ pagos: !this.state.pagos }) }}
								/>
								<span className="checkmark" ></span>
							</label>
						</div>
						<div className="column">
							<p>
								<b>No compartir información de pagos con la APP</b>
							</p>
						</div>
					</div>

					{this.state.valida ? (<p className="color-primary" >El porcentaje de Indiviso no puede ser mayor a 100%</p>) : (null)}
					<div className="white-space-8" />
					<div className="white-space-8" />
					<div className="row full justify-start">
						<p>
							<b>Comentarios:</b>
						</p>
					</div>
					<div className="white-space-8" />
					<div className="row full justify-start">
						<textarea
							name="comentarios"
							id="comentarios"
							value={this.state.comentarios}
							cols="30"
							rows="3"
							maxLength="500"
							className='textarea font-small full'
							onChange={(event) => this.setState({ comentarios: event.target.value })}>
						</textarea>
					</div>
				</div>
			</div>
		)
	};
};

export default Consumer(Houses);
