import React from 'react';
import SuperCore from './../core/SuperCore';
import RenderModal from './RenderModal';
class SuperModal extends SuperCore{
    constructor(props){
        super(props);
        this.state = {
            id:             this.props.id,
            title:          this.props.title,
            menus:          this.props.menus,
            id_menu_activo: this.props.id_menu_activo,
            screens:        this.props.screens,
            lblSubmit:      this.props.lblSubmit,
            lblCancel:      this.props.lblCancel,
            footer:         this.props.footer,
            nexFooter:      this.props.nexFooter,
            lblBack:        this.props.lblBack,
            lblNext:        this.props.lblNext,
            size:           this.props.size,
            showSubmit:     true,
            showClose:      true,
            triggerCloseModal: this.props.triggerCloseModal,
            cerrado:    false,

        }
    }
    async componentDidMount(){
        // this.addEventESC();
    }
    
    async addEventESC(){
        let idd = this.state.id;
        let triggerCloseModal = this.props.triggerCloseModal;

        document.addEventListener("keyup", async function(event ) {
            event.preventDefault();
            if (event.keyCode != 27) {
                return;
            }

            let fecha = document.getElementsByClassName("opened");
            if (fecha) {
                if (fecha.length !== 0) {
                    return;
                }
            }

            try {
                let modal =  document.getElementById(idd);

                if(modal){
                    if(modal.classList.contains('show-super-modal')){
                        modal.classList.remove('show-super-modal');
                        modal.classList.add('hide-super-modal');
                        
                        if(triggerCloseModal != null && typeof triggerCloseModal != 'undefined'){
                            triggerCloseModal();
                        }
                        
                    }
                }
            } catch (error) {
                //console.log(error);
            }   
        })
    }
    async componentDidUpdate(prevProps, prevState){

        if(prevState.id_menu_activo != this.props.id_menu_activo){
                await this.setState({id_menu_activo: this.props.id_menu_activo})            
        }

        if(prevProps.id != this.props.id){
            await this.setState({id: this.props.id});
        }

        if(prevProps.title != this.props.title){
            await this.setState({title: this.props.title});
        }

        if(prevProps.menus != this.props.menus){
            await this.setState({menus: this.props.menus});
        }

        if(prevProps.id_menu_activo != this.props.id_menu_activo){
            await this.setState({id_menu_activo: this.props.id_menu_activo})
        }

        if(prevProps.screens != this.props.screens){
            await this.setState({screens: this.props.screens})
        }

        if(prevProps.lblSubmit != this.props.lblSubmit){
            await this.setState({lblSubmit: this.props.lblSubmit});
        }

        if(prevProps.lblCancel != this.props.lblCancel){
            await this.setState({lblCancel: this.props.lblCancel});
        }

        if(prevProps.footer != this.props.footer){
            await this.setState({footer: this.props.footer});
        }

        if(prevProps.nexFooter != this.props.nexFooter){
            await this.setState({nexFooter: this.props.nexFooter});
        }

        if(prevProps.lblBack != this.props.lblBack){
            await this.setState({lblBack: this.props.lblBack});
        }

        if(prevProps.lblNext != this.props.lblNext){
            await this.setState({lblNext: this.props.lblNext});
        }

        if(prevProps.size != this.props.size){
            await this.setState({size: this.props.size})
        }
        if(this.props.id_menu_activo != this.state.id_menu_activo){
            await this.setState({id_menu_activo: this.props.id_menu_activo})
        }

        
        
        if(prevState.cerrado != this.state.cerrado){
            if(this.state.cerrado){
                //console.log("presionaron esq");
            }
        }

    }

    render(){
        if (!this.props.deshabilitarEsc) {
            this.addEventESC();
        }
        
        let showSubmit = this.props.showSubmit;
        if(this.props.showSubmit == null || typeof this.props.showSubmit == 'undefined'){
            showSubmit = true;
        }

        let showClose = this.props.showClose;
        if(this.props.showClose == null || typeof this.props.showClose == 'undefined'){
            showClose = true;
        }
        
        return(
            <RenderModal
                id              = {this.state.id}
                title           = {this.state.title}
                menus           = {this.state.menus}
                id_menu_activo  = {this.state.id_menu_activo}
                screens         = {this.state.screens} 
                nexFooter       = {this.state.nexFooter}
                footer          = {this.state.footer}
                disableFooter   = {this.props.disableFooter}
                lblSubmit       = {this.state.lblSubmit}
                lblCancel       = {this.state.lblCancel}
                lblBack         = {this.state.lblBack}
                lblNext         = {this.state.lblNext}
                size            = {this.state.size}
				onSubmit        = {this.props.onSubmit.bind(this)}
				loading			= {this.props.loading}


                showSubmit      =   {showSubmit}
                showClose       =   {showClose}

                onMenuChanged = {this.props.onMenuChanged}

                /**
                 * Se filtran los trigers por si no se envian
                 */
                triggerCloseModal   =   {
                    this.props.triggerCloseModal == null || typeof this.props.triggerCloseModal === 'undefined' ? 
                    ()=>{}:this.props.triggerCloseModal.bind(this)
                }

                // triggerCloseModal = {this.props.triggerCloseModal ? this.props.triggerCloseModal: ()=>{//console.log("NOTRIGGER");}}
            />
        )
    }
}

export default SuperModal;