import React, {Component} from 'react';

class ImpuestosModal extends Component{
    constructor(props){
        super(props);

        this.state = {
			valor: 16,
			retencion:	0
        }
	}
	async componentDidUpdate(prevProps, prevState){
		if(prevProps.impuestoEdit !== this.props.impuestoEdit){
			
			if(this.props.impuestoEdit !== null ){
				await this.setState({
					id_producto: 	this.props.impuestoEdit.Id_Producto,
					id_impuesto: 	this.props.impuestoEdit.Id_Impuesto,
					id_tipo: 		this.props.impuestoEdit.Id_TipoImpuesto,
					valor: 			this.props.impuestoEdit.Valor,
					retencion: 		this.props.impuestoEdit.Retencion
				});
				await this.props.updateData({
					id_producto: 	this.props.impuestoEdit.Id_Producto,
					id_impuesto: 	this.props.impuestoEdit.Id_Impuesto,
					id_tipo: 		this.props.impuestoEdit.Id_TipoImpuesto,
					valor: 			this.props.impuestoEdit.Valor,
					retencion: 		this.props.impuestoEdit.Retencion
				});
				
			}
		}
	}

    render(){
		let impuestos = this.props.impuestos;
		let tipos = this.props.tipos;
        return(
            <div className="full row ">
				<div className="column full">
                    <div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Impuestos:</b>
							</p>
						</div>
						<div className="column full">
							<select 
								className	=	"input input-modals"
								name		=	"unidades" 
								value={this.state.id_impuesto}
								onChange={(event) => this.changeInput({id_impuesto: event.target.value})}
								required>
								<option value={0}>Selecciona un impuesto</option>
								{  
								
                                impuestos.map((imp, key) => (
									<option 
										value	=	{imp.Id_Impuesto} 
										key		=	{key}
									>
										{imp.Descripcion}
									</option>)) 
								}
							</select>
						</div>
					</div>
					<div className="white-space-8"></div>



					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Tipos de impuesto:</b>
							</p>
						</div>
						<div className="column full">
							<select 
								className	=	"input input-modals"
								name		=	"unidades" 
								value={this.state.id_tipo}
								onChange={(event) => this.changeInput({id_tipo: event.target.value})}
								required>
								<option value={0}>Selecciona un tipo de impuesto</option>
								{  
								
                                tipos.map((imp, key) => (
									<option 
										value	=	{imp.Id_TipoImpuesto} 
										key		=	{key}
									>
										{imp.Descripcion}
									</option>)) 
								}
							</select>
						</div>
					</div>
					<div className="white-space-8"></div>


					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Valor:</b>
							</p>
						</div>
						<div className="column full">
							<input 
								className ="input input-modals"
								type		=	"number" 
								value	=	{this.state.valor}
								min		=	{0}
								step = {'any'}
								onChange =	{(event)=>{this.changeInput({valor: event.target.value})}}
							/>
							
						</div>
					</div>
					<div className="white-space-8"></div>


					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p>
								<b>Retención:</b>
							</p>
						</div>
						<div className="column full">
							<input 
								className ="input input-modals"
								type		=	"number" 
								value	=	{this.state.retencion}
								min=	{0}
								step = {'any'}
								onChange =	{(event)=>{this.changeInput({retencion: event.target.value})}}
							/>
							
						</div>
					</div>
					<div className="white-space-8"></div>

				


				</div>
			</div>
        );
	}
	
	async changeInput(data){

		await this.setState(data);
		await this.props.updateData(data);
	}



}

export default ImpuestosModal;